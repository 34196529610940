/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useGesture } from 'react-use-gesture';

import { formatDuration } from '../../lib/utils';

import InfoButton from '../buttons/InfoButton';
import MuteButton from '../buttons/MuteButton';
import PlayButton from '../buttons/PlayButton';
import Duration from '../typography/Duration';

import styles from '../../styles/partials/controls.module.css';

function isInButton(el, maxDepth = 10, depth = 1) {
    if (el.tagName === 'BUTTON') {
        return true;
    }
    const parent = el.tagName !== 'body' ? el.parentNode || null : null;
    return parent !== null && depth < maxDepth && isInButton(parent, maxDepth, depth + 1);
}

const stopEventPropagation = (e) => {
    if (!isInButton(e.target)) {
        e.stopPropagation();
    }
};
const stopDragEventsPropagation = {
    onTouchMove: (e) => e.stopPropagation(),
    onTouchStart: stopEventPropagation,
    onTouchEnd: stopEventPropagation,
    onPointerMove: (e) => e.stopPropagation(),
    onPointerUp: stopEventPropagation,
    onPointerDown: stopEventPropagation,
};

const propTypes = {
    compact: PropTypes.bool,
    playing: PropTypes.bool,
    muted: PropTypes.bool,
    currentTime: PropTypes.number,
    duration: PropTypes.number,
    theme: PropTypes.string,
    withInfoButton: PropTypes.bool,
    className: PropTypes.string,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onMute: PropTypes.func,
    onUnmute: PropTypes.func,
    onSeek: PropTypes.func,
    onSeekStart: PropTypes.func,
    onSeekEnd: PropTypes.func,
    onClickInfo: PropTypes.func,
};

const defaultProps = {
    compact: false,
    playing: false,
    muted: false,
    currentTime: null,
    duration: null,
    theme: null,
    withInfoButton: false,
    className: null,
    onPlay: null,
    onPause: null,
    onMute: null,
    onUnmute: null,
    onSeek: null,
    onSeekStart: null,
    onSeekEnd: null,
    onClickInfo: null,
};

function Controls({
    compact,
    playing,
    muted,
    currentTime,
    duration,
    buffer,
    theme,
    withInfoButton,
    className,
    onPlay,
    onPause,
    onMute,
    onUnmute,
    onSeek,
    onSeekStart,
    onSeekEnd,
    onClickInfo,
}) {
    const hasProgress = currentTime !== null && duration !== null;
    const progress = hasProgress ? currentTime / duration : null;
    const [seekProgress, setSeekProgress] = useState(null);

    const progressRef = useRef(null);
    const onDrag = useCallback(
        ({ xy: [x], elapsedTime, active, tap }) => {
            if (!active && elapsedTime > 300) {
                return;
            }
            const { left: elX = 0, width: elWidth = 0 } =
                progressRef.current.getBoundingClientRect();
            const newProgress = Math.max(0, Math.min(1, (x - elX) / elWidth));

            setSeekProgress(newProgress);
            if (onSeek !== null) {
                onSeek(newProgress * duration, tap);
            }
        },
        [onSeek, duration],
    );

    const onDragStart = useCallback(() => {
        setSeekProgress(progress);
        if (onSeekStart !== null) {
            onSeekStart();
        }
    }, [onSeekStart, progress]);

    const onDragEnd = useCallback(() => {
        setSeekProgress(null);
        if (onSeekEnd !== null) {
            onSeekEnd();
        }
    }, [onSeekEnd]);

    const bind = useGesture(
        {
            onDrag,
            onDragStart,
            onDragEnd,
        },
        { drag: { axis: 'x', filterTaps: true } },
    );

    const finalProgress = seekProgress !== null ? seekProgress : progress;

    return (
        <div
            className={classNames([
                styles.container,
                'drag-disabled',
                {
                    [styles.compact]: compact,
                    [styles.hasProgress]: hasProgress,
                },
                styles[theme],
                className,
            ])}
        >
            <div className={styles.inner} {...stopDragEventsPropagation}>
                <div className={styles.timeline} {...bind()}>
                    <div className={styles.progress} ref={progressRef}>
                        <div
                            className={styles.bar}
                            style={{
                                width: `${finalProgress * 100}%`,
                            }}
                        />
                    </div>
                </div>
                <div className={styles.compactControls}>
                    {muted ? (
                        <MuteButton
                            muted={muted}
                            className={classNames([styles.control, styles.mute])}
                            onClick={muted ? onUnmute : onMute}
                        />
                    ) : null}
                </div>
                <div className={styles.controls}>
                    <PlayButton
                        playing={playing}
                        className={classNames([styles.control, styles.play])}
                        onClick={playing ? onPause : onPlay}
                    />
                    <MuteButton
                        muted={muted}
                        className={classNames([styles.control, styles.mute])}
                        onClick={muted ? onUnmute : onMute}
                    />
                    {currentTime !== null || duration !== null ? (
                        <div className={styles.time}>
                            {currentTime !== null ? (
                                <Duration className={styles.part}>
                                    {formatDuration(currentTime)
                                        .split('')
                                        .map((it, index) => (
                                            <span key={`time-${index}`}>{it}</span>
                                        ))}
                                </Duration>
                            ) : null}
                            {currentTime !== null && duration !== null ? (
                                <span className={styles.slash}>/</span>
                            ) : null}
                            {duration !== null ? (
                                <Duration className={styles.part}>
                                    {formatDuration(duration)
                                        .split('')
                                        .map((it, index) => (
                                            <span key={`duration-${index}`}>{it}</span>
                                        ))}
                                </Duration>
                            ) : null}
                        </div>
                    ) : null}

                    {withInfoButton ? (
                        <InfoButton
                            className={classNames([styles.control, styles.info])}
                            onClick={onClickInfo}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;

export default Controls;
