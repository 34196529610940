/* eslint-disable react/no-array-index-key */

/* eslint-disable react/forbid-prop-types */

/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import { getPrefixedProps, getTrackingQuery } from '../../lib/utils';

import styles from '../../styles/lists/list.module.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    ),
    recommendationId: PropTypes.string,
    clickRef: PropTypes.string,
    header: PropTypes.node,
    footer: PropTypes.node,
    cardComponent: PropTypes.elementType.isRequired,
    cardTheme: PropTypes.string,
    cardSize: PropTypes.string,
    cardWidth: PropTypes.number,
    cardRatio: PropTypes.string,
    cardImageLoading: PropTypes.string,
    cardImageLazyLoadingStartIndex: PropTypes.number,
    cardWith: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    cardWithout: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    cardProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    cardLinkQuery: PropTypes.object,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemsStyle: PropTypes.object,
    itemClassName: PropTypes.string,
    itemStyle: PropTypes.object,
    cardClassName: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    onClickCard: PropTypes.func,
    containerRef: AppPropTypes.ref,
    itemsRef: AppPropTypes.ref,
};

const defaultProps = {
    items: null,
    recommendationId: null,
    clickRef: null,
    header: null,
    footer: null,
    cardTheme: null,
    cardSize: null,
    cardWidth: null,
    cardRatio: null,
    cardImageLoading: null,
    cardImageLazyLoadingStartIndex: null,
    cardWith: null,
    cardWithout: null,
    cardProps: null,
    cardLinkQuery: null,
    withBorder: false,
    className: null,
    itemsClassName: null,
    itemsStyle: null,
    itemClassName: null,
    itemStyle: null,
    cardClassName: null,
    thumbnailClassName: null,
    onClickCard: null,
    containerRef: null,
    itemsRef: null,
};

function List({
    items,
    recommendationId,
    clickRef,
    header,
    footer,
    cardComponent: CardComponent,
    cardTheme,
    cardSize,
    cardWidth,
    cardRatio,
    cardImageLoading,
    cardImageLazyLoadingStartIndex,
    cardWith,
    cardWithout,
    cardProps,
    cardLinkQuery,
    withBorder,
    className,
    itemsClassName,
    itemsStyle,
    itemClassName,
    itemStyle,
    cardClassName,
    thumbnailClassName,
    onClickCard,
    containerRef,
    itemsRef,
}) {
    const {
        ref: firstCardResizeRef,
        entry: { contentRect: firstCardContentRect = null },
    } = useResizeObserver({
        disabled: cardWidth !== null,
    });
    const { width: firstCardWidth = null } = firstCardContentRect || {};

    const finalLinkQuery = useMemo(
        () =>
            cardLinkQuery !== null || recommendationId !== null || clickRef !== null
                ? {
                      ...cardLinkQuery,
                      ...getTrackingQuery({
                          recommendationId,
                          clickRef,
                      }),
                  }
                : null,
        [cardLinkQuery, recommendationId, clickRef],
    );

    if (items === null || items.length === 0) {
        return null;
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            {header}
            <animated.div
                className={classNames([
                    styles.items,
                    {
                        [itemsClassName]: itemsClassName !== null,
                    },
                ])}
                style={itemsStyle}
                ref={itemsRef}
            >
                {(items || []).map((it, index) => {
                    const { cardComponent: CustomCardComponent = null, ...finalCardProps } =
                        (isFunction(cardProps) ? cardProps(it, index) : cardProps) || {};
                    const FinalCardComponent = CustomCardComponent || CardComponent;
                    return (
                        <animated.div
                            className={classNames([
                                styles.item,
                                {
                                    [itemClassName]: itemClassName !== null,
                                },
                            ])}
                            key={`item-${index}`}
                            style={isFunction(itemStyle) ? itemStyle(it, index) : itemStyle}
                            ref={index === 0 ? firstCardResizeRef : null}
                        >
                            <FinalCardComponent
                                theme={cardTheme}
                                size={cardSize}
                                ratio={cardRatio}
                                width={cardWidth || firstCardWidth}
                                withBorder={withBorder}
                                placeholder={it === null}
                                onClick={
                                    onClickCard !== null ? (e) => onClickCard(e, it, index) : null
                                }
                                linkQuery={finalLinkQuery}
                                {...it}
                                {...getPrefixedProps(cardWith, 'with')}
                                {...getPrefixedProps(cardWithout, 'without')}
                                {...finalCardProps}
                                imageLoading={
                                    cardImageLoading ||
                                    (cardImageLazyLoadingStartIndex !== null &&
                                    index >= cardImageLazyLoadingStartIndex
                                        ? 'lazy'
                                        : null)
                                }
                                className={classNames([
                                    styles.card,
                                    {
                                        [cardClassName]: cardClassName !== null,
                                    },
                                ])}
                                thumbnailClassName={thumbnailClassName}
                            />
                        </animated.div>
                    );
                })}
            </animated.div>
            {footer}
        </div>
    );
}

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <List containerRef={ref} {...props} />);
