import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export default function useLocationTypes(query = null, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['location_types', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.locations.locationTypes({ paginated: false, ...queryParam }, null, null, {
                signal,
            }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items = null, pagination = null } = data || {};

    return data !== null
        ? {
              items: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}
