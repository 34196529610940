/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/taxonomy-horizontal-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    label: PropTypes.string,
    image: AppPropTypes.image,
    ratio: PropTypes.number,
    theme: PropTypes.oneOf([null, 'pill', 'without_thumbnail']),
    size: AppPropTypes.cardSize,
    height: PropTypes.number,
    placeholder: PropTypes.bool,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'category',
    slug: null,
    label: null,
    image: null,
    theme: null,
    size: null,
    ratio: null,
    height: 90,
    placeholder: false,
    withBorder: false,
    className: null,
};

function TaxonomyHorizontalCard({
    type,
    slug,
    label,
    image,
    ratio,
    height,
    theme,
    size,
    placeholder,
    withBorder,
    className,
}) {
    const url = useUrlGenerator();
    const thumbnailRatio = getRatio(ratio, 5 / 4);

    const withoutThumbnail = theme === 'without_thumbnail' || theme === 'pill';

    return (
        <HorizontalCard
            href={
                slug !== null
                    ? url(type, {
                          slug,
                      })
                    : null
            }
            thumbnail={
                !withoutThumbnail ? (
                    <Image
                        width={thumbnailRatio * height}
                        height={height}
                        media={image}
                        withPlaceholder={placeholder}
                        className={styles.thumbnail}
                    />
                ) : null
            }
            thumbnailRatio={thumbnailRatio}
            height={!withoutThumbnail ? height : null}
            className={classNames([
                styles.container,
                styles[camelCase(size)],
                styles[camelCase(theme)],
                {
                    [styles.withBorder]: withBorder,
                },
                className,
            ])}
            innerClassName={styles.inner}
            thumbnailClassName={styles.thumbnailContainer}
        >
            <CardTitle tag="span" className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : label}
            </CardTitle>
        </HorizontalCard>
    );
}

TaxonomyHorizontalCard.propTypes = propTypes;
TaxonomyHorizontalCard.defaultProps = defaultProps;

export default TaxonomyHorizontalCard;
