import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function Toyota({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.86 105.76" className={className}>
            <path
                fill={color}
                d="M97.54,3.43c-6.71-2.16-15.07-3.43-24.12-3.43s-17.41,1.27-24.12,3.43c-17.86,5.74-30.12,17.66-30.12,31.43,0,19.38,24.28,35.09,54.24,35.09s54.24-15.71,54.24-35.09c0-13.78-12.27-25.69-30.12-31.43m-24.12,51.44c-4.47,0-8.12-8.79-8.34-19.84,2.69,.24,5.48,.37,8.34,.37s5.64-.13,8.33-.37c-.22,11.05-3.86,19.84-8.33,19.84m-7.77-28.48c1.22-7.75,4.24-13.25,7.77-13.25s6.55,5.49,7.77,13.25c-2.48,.23-5.09,.35-7.77,.35s-5.29-.12-7.77-.35m20.29-.59c-1.81-12.17-6.73-20.89-12.52-20.89s-10.71,8.72-12.52,20.89c-11.02-1.72-18.72-5.64-18.72-10.2,0-6.14,13.99-11.13,31.24-11.13s31.24,4.98,31.24,11.13c0,4.56-7.7,8.48-18.72,10.2m-58.84,7.78c0-5.94,2.29-11.51,6.28-16.3-.04,.33-.07,.67-.07,1,0,7.47,11.23,13.83,26.88,16.16-.01,.56-.02,1.13-.02,1.7,0,13.89,3.86,25.66,9.18,29.72-23.67-1.45-42.24-15.35-42.24-32.29m50.41,32.29c5.33-4.06,9.18-15.83,9.18-29.72,0-.57,0-1.14-.02-1.7,15.65-2.33,26.87-8.69,26.87-16.16,0-.34-.03-.67-.07-1,3.99,4.79,6.27,10.36,6.27,16.3,0,16.94-18.57,30.84-42.24,32.29"
            />
            <polygon
                fill={color}
                points="21.05 80.53 0 80.53 0 85.18 7.77 85.18 7.77 105.03 13.28 105.03 13.28 85.18 21.05 85.18 21.05 80.53"
            />
            <path
                fill={color}
                d="M35.27,79.79c-7.17,0-12.98,5.82-12.98,12.99s5.81,12.98,12.98,12.98,12.98-5.82,12.98-12.98-5.81-12.99-12.98-12.99m6.36,16.92c-.84,2.15-2.76,3.75-5.09,4.17-.41,.07-.84,.11-1.28,.11s-.86-.04-1.28-.11c-2.32-.41-4.24-2.02-5.09-4.17-.48-1.21-.74-2.54-.74-3.93s.27-2.72,.74-3.93c.84-2.14,2.76-3.75,5.09-4.16,.41-.08,.84-.11,1.28-.11s.86,.04,1.28,.11c2.32,.41,4.24,2.02,5.09,4.16,.48,1.22,.74,2.55,.74,3.93s-.26,2.72-.74,3.93"
            />
            <polygon
                fill={color}
                points="48.34 80.53 54.83 80.53 60.89 91.06 66.96 80.53 73.45 80.53 63.65 95.96 63.65 105.02 58.14 105.02 58.14 95.96 48.34 80.53"
            />
            <path
                fill={color}
                d="M73.55,92.77c0-7.17,5.81-12.98,12.99-12.98s12.98,5.81,12.98,12.98-5.81,12.99-12.98,12.99-12.99-5.82-12.99-12.99m12.99,8.21c.43,0,.86-.04,1.28-.11,2.33-.41,4.24-2.02,5.09-4.16,.48-1.22,.74-2.54,.74-3.93s-.26-2.71-.74-3.93c-.84-2.15-2.76-3.75-5.09-4.16-.41-.07-.84-.11-1.28-.11s-.86,.04-1.28,.11c-2.33,.41-4.25,2.02-5.09,4.16-.48,1.22-.75,2.54-.75,3.93s.27,2.72,.75,3.93c.84,2.14,2.76,3.75,5.09,4.16,.41,.07,.84,.11,1.28,.11"
            />
            <path
                fill={color}
                d="M138.72,99.62h-10.43s-2.01,5.39-2.01,5.39h-6.13l9.92-24.49h6.86l9.93,24.49h-6.13l-2.02-5.39h0Zm-1.6-4.29l-3.61-9.68-3.61,9.68h7.22Z"
            />
            <polygon
                fill={color}
                points="121.77 80.53 100.72 80.53 100.72 85.18 108.49 85.18 108.49 105.03 114 105.03 114 85.18 121.77 85.18 121.77 80.53"
            />
        </svg>
    );
}

Toyota.propTypes = propTypes;
Toyota.defaultProps = defaultProps;

export default Toyota;
