/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BasicButton from '../buttons/BasicButton';

import styles from '../../styles/partials/topics.module.css';

const propTypes = {
    topics: PropTypes.node,
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    topics: [
        {
            label: 'Rentrée scolaire',
            url: '/topic-1',
        },
        {
            label: 'Leadership féminin',
            url: '/topic-2',
        },
        {
            label: 'Finances personnelles',
            url: '/topic-3',
        },
        {
            label: 'Plein air',
            url: '/topic-4',
        },
    ],
    buttonClassName: null,
    className: null,
};

function Topics({ topics, buttonClassName, className }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {topics.map((topic, index) => {
                const { label = null } = topic;
                return (
                    <BasicButton
                        key={index}
                        className={classNames([
                            styles.button,
                            {
                                [buttonClassName]: buttonClassName !== null,
                            },
                        ])}
                    >
                        {label}
                        {/* {label && (
                                <FormattedMessage
                                    defaultMessage="{label}"
                                    description="Label button"
                                    values={{ label }}
                                />
                            )} */}
                    </BasicButton>
                );
            })}
        </div>
    );
}

Topics.propTypes = propTypes;
Topics.defaultProps = defaultProps;

export default Topics;
