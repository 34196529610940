/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#EEE',
    circleColor: 'currentColor',
    className: null,
};

function PlusIcon({ color, circleColor, className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="16"
            fill="none"
            className={className}
        >
            <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill={circleColor}
                transform="matrix(-1 0 0 1 15 .5)"
            />
            <path stroke={color} strokeLinecap="round" d="M7.5 4.5v7M11 8H4" />
        </svg>
    );
}

PlusIcon.propTypes = propTypes;
PlusIcon.defaultProps = defaultProps;

export default PlusIcon;
