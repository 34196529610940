/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#222',
    circleColor: '#0C9',
    className: null,
};

function LocationTypeIcon({ color, circleColor, className }) {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="22" cy="22" r="22" fill={circleColor} />
            <g>
                <path
                    d="M21.9998 9.61395C17.1335 9.61395 13.1528 13.5154 13.1528 18.2838C13.1528 23.0474 17.9008 29.4732 20.4402 32.5694C21.2599 33.5688 22.7398 33.5688 23.5595 32.5694C26.0989 29.4732 30.8469 23.0474 30.8469 18.2838C30.8469 13.5154 26.8662 9.61395 21.9998 9.61395ZM21.9998 21.3812C20.2892 21.4185 18.8745 20.0598 18.8372 18.3514C18.7999 16.6408 20.1587 15.2261 21.867 15.1888C21.9113 15.1888 21.9556 15.1888 21.9975 15.1888C23.7082 15.1515 25.1229 16.5102 25.1602 18.2186C25.1975 19.9293 23.8387 21.3439 22.1304 21.3812C22.0861 21.3812 22.0418 21.3812 21.9998 21.3812Z"
                    stroke={color}
                    strokeWidth="1.54823"
                />
            </g>
        </svg>
    );
}

LocationTypeIcon.propTypes = propTypes;
LocationTypeIcon.defaultProps = defaultProps;

export default LocationTypeIcon;
