/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRouteMatcher, useRoutes, useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
// import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import AuthorHorizontalCard from './AuthorHorizontalCard';
import CollectionHorizontalCard from './CollectionHorizontalCard';
import DocumentHorizontalCard from './DocumentHorizontalCard';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/timeline-horizontal-card.module.css';

const propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
    document: AppPropTypes.document,
    collection: AppPropTypes.collection,
    author: AppPropTypes.author,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(['box', null]),
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    label: null,
    url: null,
    document: null,
    collection: null,
    author: null,
    onClick: null,
    theme: null,
    placeholder: false,
    className: null,
};

function TimelineHorizontalCard({
    label,
    url,
    document,
    collection,
    author,
    onClick,
    theme,
    placeholder,
    className,
    ...props
}) {
    const isBox = theme !== null && theme.indexOf('box') === 0;

    const path = url !== null ? url.replace(/^https?:\/\/[^/]+\//, '/') : null;

    if (document !== null) {
        return (
            <DocumentHorizontalCard {...document} theme={theme} {...props} className={className} />
        );
    }

    if (author !== null) {
        return <AuthorHorizontalCard {...author} theme={theme} {...props} className={className} />;
    }

    if (collection !== null) {
        return (
            <CollectionHorizontalCard
                {...collection}
                theme={theme}
                {...props}
                className={className}
            />
        );
    }

    return (
        <HorizontalCard
            href={path}
            onClick={onClick}
            className={classNames([
                styles.container,
                {
                    [styles.box]: isBox,
                    [styles.menu]: theme === 'menu',
                },
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
        >
            <CardTitle className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : label || path}
            </CardTitle>
        </HorizontalCard>
    );
}

TimelineHorizontalCard.propTypes = propTypes;
TimelineHorizontalCard.defaultProps = defaultProps;

export default TimelineHorizontalCard;
