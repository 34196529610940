import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function fetchCollection(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['collection', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.collections.findBySlug(queryParam, { signal }),
    });
}

export default function useCollection(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: collection = null, isFetched = false, ...queryResult } = useQuery({
        queryKey: ['collection', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.collections.findBySlug(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        collection,
        notFound: collection === null && isFetched,
        isFetched,
        ...queryResult,
    };
}
