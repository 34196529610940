/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useRoutes, useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'wouter';

import { useIsCheckingAuth } from '../../hooks/useAuth';
import useScrollEnter from '../../hooks/useScrollEnter';

import UrbaniaButton from '../buttons/UrbaniaButton';
// import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import AccountForm from '../forms/AccountForm';
import AccountMenu from '../menus/AccountMenu';
import AccountSubscriptionsPanel from '../panels/AccountSubscriptionsPanel';
import AccountTimelinePanel from '../panels/AccountTimelinePanel';

import styles from '../../styles/pages/account-page.module.css';

const propTypes = {
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function AccountPage({
    entered,
    entering,
    leaving,
    current,
    className,
    contentClassName,
    onEnter,
    onLeave,
}) {
    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const routes = useRoutes();
    const [location] = useLocation();
    const url = useUrlGenerator();
    const user = useUser();

    const tracking = useTracking();
    useEffect(() => {
        if (current && user !== null) {
            tracking.trackPageView({
                pageType: 'account',
            });
        }
    }, [tracking, current]);

    const isCheckingAuth = useIsCheckingAuth();

    if (user === null && isCheckingAuth) {
        return null;
    }

    if (user === null && current) {
        return <Redirect to={`${url('login')}?next=${encodeURIComponent(location)}`} replace />;
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
        >
            <UrbaniaButton className={styles.urbania} />
            <div className={styles.inner}>
                <AccountMenu className={styles.menu} />
                <Switch>
                    <Route path={routes['account.timeline']}>
                        <AccountTimelinePanel className={styles.panel} />
                    </Route>
                    <Route path={routes['account.subscriptions']}>
                        <AccountSubscriptionsPanel className={styles.panel} />
                    </Route>
                    <Route>
                        <AccountForm className={styles.panel} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

AccountPage.propTypes = propTypes;
AccountPage.defaultProps = defaultProps;

export default AccountPage;
