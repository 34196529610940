/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-mollo.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageMollo({ brand, className, ...props }) {
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageMollo.propTypes = propTypes;
HomePageMollo.defaultProps = defaultProps;

export default HomePageMollo;
