/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';
import useTopic from '../../hooks/useTopic';

import { TaxonomyAdsTargetingProvider } from '../../contexts/AdsTargeting';
import { useGlobalBrand } from '../../contexts/LayoutContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import SortMenu from '../menus/SortMenu';

import styles from '../../styles/pages/topic-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function TopicPage({ slug, current, className, contentClassName, onEnter, onLeave }) {
    const globalBrand = useGlobalBrand();
    const url = useUrlGenerator();
    const { topic = null } = useTopic(slug, {
        suspense: false,
    });
    const { id: topicId = null, label, brand = null } = topic || {};
    const finalBrand = brand || globalBrand;
    const { handle: brandHandle = null } = finalBrand;
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current && topic !== null) {
            tracking.trackPageView({
                brand,
                categories: [topic],
                pageType: 'topic',
            });
        }
    }, [tracking, current, brand, topic, filters]);

    return (
        <TaxonomyAdsTargetingProvider taxonomy={topic}>
            <div
                className={classNames([styles.container, className, contentClassName])}
                data-brand={brandHandle}
            >
                <IndexDefaultHeader
                    title={label}
                    placeholder={topic === null}
                    className={styles.header}
                />
                <SortMenu
                    url={url('topic', {
                        slug,
                    })}
                    className={styles.filters}
                />
                <main className={styles.content}>
                    <InfiniteItemsBlocks
                        query={{
                            topic: topicId,
                            ...filters,
                        }}
                        disabled={topicId === null}
                    />
                </main>
            </div>
        </TaxonomyAdsTargetingProvider>
    );
}

TopicPage.propTypes = propTypes;
TopicPage.defaultProps = defaultProps;

export default TopicPage;
