/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import seedrandom from 'seedrandom';

import styles from '../../styles/partials/placeholder-text.module.css';

const propTypes = {
    lines: PropTypes.number,
    characters: PropTypes.number,
    minCharacters: PropTypes.number,
    maxCharacters: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    lines: 1,
    characters: null,
    minCharacters: 8,
    maxCharacters: 12,
    className: null,
};

function PlaceholderText({
    lines: linesCount,
    characters,
    minCharacters,
    maxCharacters,
    className,
}) {
    const lines = useMemo(() => {
        const rand = seedrandom(`${minCharacters}-${maxCharacters}`);
        return [...Array(linesCount).keys()].map(() => ({
            charactersCount:
                characters || minCharacters + Math.round(rand() * (maxCharacters - minCharacters)),
        }));
    }, [linesCount, characters, minCharacters, maxCharacters]);
    return (
        <span
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {lines.map(({ charactersCount }, index) => (
                <Fragment key={`line-${index}`}>
                    {[...Array(charactersCount).keys()].map(() => '█').join('')}
                </Fragment>
            ))}
        </span>
    );
}

PlaceholderText.propTypes = propTypes;
PlaceholderText.defaultProps = defaultProps;

export default PlaceholderText;
