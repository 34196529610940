import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearch } from 'wouter';

import PillButton from '../buttons/PillButton';

import styles from '../../styles/menus/sort-menu.module.css';

const propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function SortMenu({ url, className }) {
    const search = useSearch();
    const { sort: sortParam = null } = useMemo(() => queryString.parse(search), [search]);
    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.inner}>
                <PillButton
                    href={`${url}`}
                    compact
                    branded
                    bordered
                    active={sortParam === null}
                    className={styles.filter}
                >
                    <FormattedMessage defaultMessage="Plus récents" description="Filter label" />
                </PillButton>
                <PillButton
                    href={`${url}?sort=popular`}
                    compact
                    branded
                    bordered
                    active={sortParam === 'popular'}
                    className={styles.filter}
                >
                    <FormattedMessage defaultMessage="Plus populaires" description="Filter label" />
                </PillButton>
            </div>
        </div>
    );
}

SortMenu.propTypes = propTypes;
SortMenu.defaultProps = defaultProps;

export default SortMenu;
