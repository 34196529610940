import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function CompassIcon({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={className}>
            <path
                fill={color}
                d="M9.26,9c-.52,.52-.52,1.36,0,1.87s1.36,.52,1.87,0,.52-1.36,0-1.87h0c-.52-.52-1.36-.52-1.87,0,0,0,0,0,0,0Zm6.16-3.2l-2.73,5.98c-.13,.29-.36,.52-.65,.65l-5.98,2.73c-.41,.19-.9,0-1.09-.41-.1-.22-.1-.46,0-.68l2.73-5.98c.13-.29,.36-.52,.65-.65l5.98-2.73c.41-.19,.9,0,1.08,.4,.1,.22,.1,.47,0,.68h0Z"
            />
            <circle fill="none" cx="10" cy="10" r="10" />
            <circle fill="none" stroke={color} cx="10" cy="10" r="9.5" />
        </svg>
    );
}

CompassIcon.propTypes = propTypes;
CompassIcon.defaultProps = defaultProps;

export default CompassIcon;
