/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    circleColor: 'transparent',
    className: null,
};

function MenuIcon({ color, circleColor, className }) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="15" cy="15" r="15" fill={circleColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.153 12.7C15.153 10.9821 16.5599 9.57495 18.3134 9.57495C20.0668 9.57495 21.4737 10.9821 21.4737 12.7C21.4737 14.4178 20.0668 15.825 18.3134 15.825C16.5599 15.825 15.153 14.4178 15.153 12.7ZM18.3134 7.57495C15.4714 7.57495 13.153 9.86146 13.153 12.7C13.153 15.5384 15.4714 17.825 18.3134 17.825C19.3756 17.825 20.3648 17.5055 21.187 16.9572L21.7919 17.5569L21.7918 17.557L25.5084 21.242L26.9166 19.8218L25.6739 18.5897L25.6741 18.5896L22.6052 15.5469C23.1535 14.7335 23.4737 13.7548 23.4737 12.7C23.4737 9.86146 21.1553 7.57495 18.3134 7.57495ZM5 12.0498H11.5567V10.0498H5V12.0498ZM11.5567 16.9998H5V14.9998H11.5567V16.9998ZM5 21.95H21.6415V19.95H5V21.95Z"
                fill={color}
            />
        </svg>
    );
}

MenuIcon.propTypes = propTypes;
MenuIcon.defaultProps = defaultProps;

export default MenuIcon;
