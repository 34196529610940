/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/taxonomy-vertical-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    label: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'label-on-top', 'suggestion']),
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    placeholder: PropTypes.bool,
    withBorder: PropTypes.bool,
    width: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'category',
    slug: null,
    label: null,
    image: null,
    ratio: null,
    theme: null,
    size: null,
    placeholder: false,
    withBorder: false,
    width: 240,
    className: null,
};

function TaxonomyVerticalCard({
    type,
    slug,
    label,
    image,
    ratio,
    theme,
    size,
    placeholder,
    withBorder,
    width,
    className,
}) {
    const url = useUrlGenerator();

    const labelOnTop = theme === 'label-on-top';
    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <VerticalCard
            href={
                slug !== null
                    ? url(type, {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <Image
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    withPlaceholder={placeholder}
                    className={styles.thumbnail}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
            innerClassName={styles.inner}
            labelOnTop={labelOnTop}
        >
            <CardTitle className={styles.title}>
                {placeholder ? <PlaceholderText /> : label}
            </CardTitle>
        </VerticalCard>
    );
}

TaxonomyVerticalCard.propTypes = propTypes;
TaxonomyVerticalCard.defaultProps = defaultProps;

export default TaxonomyVerticalCard;
