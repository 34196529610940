import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function VeloDeRoute({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.01 34.82" className={className}>
            <path
                fill={color}
                d="M14.95,21.85c-1.25-1.82-3.14-3.1-5.29-3.59h-.08c-1.34-.11-2.69-.03-4.01,.21-1.33,.25-2.55,.92-3.47,1.91-.22,.26-.42,.53-.6,.82-.76,1.09-1.27,2.33-1.49,3.65-.11,2.04,.37,4.08,1.4,5.85,.17,.35,.35,.69,.56,1.02,.38,.59,.87,1.11,1.44,1.53,1.27,.84,2.73,1.34,4.25,1.47,.72,.11,1.45,.12,2.17,.04,1.77-.4,3.37-1.35,4.55-2.73,.63-.58,1.13-1.27,1.47-2.05,.21-.63,.32-1.28,.35-1.93,.23-1.47,.14-2.98-.26-4.41-.25-.63-.57-1.23-.97-1.78m-1.14,6.24c-.49,1.65-1.68,3.01-3.24,3.72-1.59,.72-3.42,.62-4.92-.26-.5-.31-.94-.71-1.43-1.04-.49-.26-.93-.6-1.3-1.01-.14-.21-.26-.44-.33-.69-.67-1.91-.42-4.01,.65-5.72,1.1-1.7,2.99-2.74,5.01-2.76l.84,.02c1.68,.36,3.14,1.39,4.06,2.85,.9,1.47,1.14,3.25,.66,4.9"
            />
            <path
                fill={color}
                d="M25.59,5.9c.18,.38,.42,.72,.73,1,.59,.44,1.31,.67,2.05,.67,.5,.03,1,.02,1.5-.03,.25-.02,.5-.07,.73-.16,.26-.13,.5-.31,.7-.53,.47-.41,.84-.92,1.08-1.5,.12-.38,.18-.78,.18-1.18,.05-.88-.14-1.76-.53-2.54-.41-.79-1.16-1.34-2.04-1.5l-.78-.11c-.85-.09-1.7,.12-2.41,.58-.71,.47-1.23,1.18-1.46,2-.09,.34-.14,.7-.16,1.05-.05,.43-.03,.87,.04,1.3,.08,.33,.2,.65,.37,.95"
            />
            <path
                fill={color}
                d="M40.85,24.23c-.16-.56-.41-1.09-.73-1.57-1.51-2.28-3.85-3.89-6.52-4.46l-.46-.06c-.54-.06-1.09-.04-1.62,.06-.07-.33-.15-.66-.24-.98-.15-.45-.33-.9-.53-1.33l-.38-.87h1.57c.32,.03,.64-.02,.92-.16,.46-.38,.67-.99,.52-1.58-.01-.38-.17-.73-.45-.99-.29-.17-.62-.24-.96-.21-1.74-.02-3.48-.02-5.22,0l-.38-.49c-.51-1.04-.92-2.12-1.23-3.23-.12-.5-.28-1-.46-1.49-.18-.49-.5-.92-.9-1.25-.38-.28-.83-.44-1.31-.47-.39-.03-.79,.02-1.16,.15-.25,.09-.49,.2-.72,.32-1.09,.55-2.17,1.12-3.25,1.68l-1.64,.86-.58,.33c-1.4,.49-2.69,1.24-3.81,2.22-.53,.41-.95,.96-1.2,1.59-.27,1.03,.02,2.14,.77,2.9,.74,.74,1.63,1.33,2.6,1.73,1.53,.72,3.09,1.36,4.68,1.92,.08,.02,.15,.06,.21,.11,.09,.11,.12,.26,.1,.4-.05,1.75-.17,3.49-.34,5.24-.07,.47-.08,.95-.03,1.42,.05,.48,.3,.91,.67,1.21,.48,.28,1.06,.36,1.6,.22,.22-.03,.44-.11,.62-.24,.17-.15,.26-.36,.24-.59l.03-.85c.14-2.2,.14-4.41,.29-6.61,.14-.75,0-1.53-.39-2.18-.36-.39-.81-.68-1.31-.84l-3.43-1.46c-.08-.03-.18-.1-.16-.18,0-.06,.06-.09,.11-.12l5.92-3.42c.18-.1,.42-.2,.58-.07,.07,.07,.11,.16,.13,.25,.3,1.01,.66,2,1.08,2.97,.09,.27,.25,.51,.46,.69,.3,.17,.64,.25,.99,.21h2.27c.27,.87,.61,1.71,1,2.52,.16,.46,.26,.95,.39,1.43,.01,.04,.02,.09,.04,.13-.93,.55-1.78,1.23-2.52,2.03-.46,.46-.85,.99-1.16,1.56-.44,.98-.7,2.04-.76,3.11-.1,.71-.12,1.43-.06,2.14,.11,.74,.35,1.45,.71,2.1,1.95,3.65,6.25,5.35,10.17,4.01,2.03-.68,3.69-2.16,4.6-4.1,.49-1.19,.75-2.47,.78-3.76,.06-.65,.01-1.31-.13-1.96m-2.09,2.23c-.1,.86-.29,1.7-.57,2.52-.15,.73-.57,1.37-1.18,1.81-.47,.46-1,.84-1.58,1.15-.94,.37-1.95,.51-2.95,.4-.51,0-1.01-.07-1.5-.2-1.13-.41-2.09-1.18-2.73-2.19-.7-.91-1.16-1.99-1.33-3.12-.11-1.11,.13-2.23,.68-3.21,.55-.97,1.3-1.81,2.2-2.47,.05-.04,.1-.07,.15-.11,.31,.7,.64,1.4,.93,2.11,.36,1.11,.87,2.17,1.5,3.15,.1,.15,.23,.27,.37,.37,.19,.1,.4,.15,.61,.15,.14,0,.28-.02,.4-.07,.24-.19,.37-.49,.33-.79-.06-.97-.32-1.91-.76-2.77-.4-.91-.81-1.81-1.16-2.73,.7-.02,1.4,.02,2.09,.13l.94,.2c.93,.49,1.73,1.19,2.34,2.04,.3,.34,.56,.72,.78,1.12,.37,.79,.51,1.66,.41,2.52"
            />
        </svg>
    );
}

VeloDeRoute.propTypes = propTypes;
VeloDeRoute.defaultProps = defaultProps;

export default VeloDeRoute;
