/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

// import * as AppPropTypes from '../../lib/PropTypes';
import Ad from '../ads/Ad';

import styles from '../../styles/blocks/ad-block.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AdBlock({ className, ...props }) {
    const [isEmpty, setIsEmpty] = useState(false);
    const [isFluid, setIsFluid] = useState(false);
    const onAdRender = useCallback(
        ({ isEmpty: newIsEmpty = true, isFluid: newIsFluid = false }) => {
            setIsEmpty(newIsEmpty);
            setIsFluid(newIsFluid);
        },
        [setIsEmpty],
    );
    return (
        <div
            className={classNames([
                styles.container,

                {
                    [styles.isEmpty]: isEmpty,
                    [styles.isFluid]: isFluid,
                    [className]: className !== null,
                },
            ])}
        >
            <Ad slot="content" className={styles.ad} onRender={onAdRender} {...props} />
        </div>
    );
}

AdBlock.propTypes = propTypes;
AdBlock.defaultProps = defaultProps;

export default AdBlock;
