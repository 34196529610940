export default function createRoutes(site) {
    const { brands = null, regions = null, locationTypes = null } = site;

    const brandsWithSlug = brands.filter(({ slug = null }) => slug !== null);
    const sections = brands.reduce(
        (allSections, { sections: brandSections = [] }) => [...allSections, ...brandSections],
        [],
    );

    return {
        'brand.wildcard':
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/:section(${[...sections.map(({ slug }) => slug), 'articles', 'videos', 'podcasts', 'micromag', 'populaire', 'recent', 'abonnement', 'abonner'].join('|')})?`
                : `/:section(${[...sections.map(({ slug }) => slug), 'articles', 'videos', 'podcasts', 'micromag', 'populaire', 'recent'].join('|')})?`,

        home:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?`
                : '/',
        section:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/:section(${sections.map(({ slug }) => slug).join('|')})`
                : `/:section(${sections.map(({ slug }) => slug).join('|')})`,

        'home.wildcard':
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/:section(${sections.map(({ slug }) => slug).join('|')})?`
                : `/:section(${sections.map(({ slug }) => slug).join('|')})?`,

        menu: '/menu',
        search: '/recherche',

        subscription:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/abonnement`
                : '/abonnement',

        subscribe:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/abonner`
                : '/abonner',

        micromags: '/micromag',
        videos:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/videos`
                : '/videos',
        articles:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/articles`
                : '/articles',
        podcasts:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/podcasts`
                : '/podcasts',
        popular:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/populaire`
                : '/populaire',
        all:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/recent`
                : '/recent',

        document: '/:type(article|video|micromag|podcast|concours|interactif)/:slug/:screen?',
        article: '/article/:slug',
        contest: '/concours/:slug',
        video: '/video/:slug',
        interactive: '/interactif/:slug/:path?',
        podcast: '/podcast/:slug',
        micromag: '/micromag/:slug/:path?',
        // micromag_screen: '/micromag/:slug/:screen',
        preview: '/preview/:token([^/]+)/:path?',

        authors: '/auteurs',
        author: '/auteurs/:slug',

        collections:
            brandsWithSlug.length > 0
                ? `/:brand(${brandsWithSlug.map(({ slug }) => slug).join('|')})?/collections`
                : '/collections',
        collection: '/collection/:slug',

        categories: '/categories',
        category: '/categorie/:slug',

        topics: '/sujets',
        topic: '/sujet/:slug',

        formats: '/formats',
        format: '/format/:slug',

        map: '/carte',
        'map.region':
            regions !== null && regions.length > 0
                ? `/carte/:region(${regions.map(({ slug }) => slug).join('|')})`
                : '/carte/:region',
        'map.location': '/carte/lieux/:location',
        'map.locationType':
            locationTypes !== null && locationTypes.length > 0
                ? `/carte/:locationType(${locationTypes.map(({ slug }) => slug).join('|')})`
                : '/carte/:locationType',
        'map.document': '/carte/contenu/:document',
        'map.wildcard': '/carte/:slug?/:subslug?',

        page: '/:slug',

        login: '/connexion',
        register: '/inscription',
        logout: '/deconnexion',
        'auth.wildcard': '/:slug(connexion|inscription|deconnexion)',

        account: '/compte',
        'account.subscriptions': '/compte/abonnements',
        'account.timeline': '/compte/historique',
        'account.wildcard': '/compte/:section?',
    };
}
