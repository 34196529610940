/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';

import styles from '../../styles/menus/menu.module.css';

const propTypes = {
    items: PropTypes.arrayOf(AppPropTypes.menuItem),
    buttonComponent: PropTypes.elementType,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    activeClassName: PropTypes.string,
};

const defaultProps = {
    items: [],
    buttonComponent: BasicButton,
    className: null,
    itemsClassName: null,
    itemClassName: null,
    buttonClassName: null,
    activeClassName: null,
};

function Menu({
    items,
    buttonComponent: ButtonComponent,
    className,
    itemsClassName,
    itemClassName,
    buttonClassName,
    activeClassName,
    ...props
}) {
    return (
        <nav className={classNames([styles.container, className])}>
            <ul className={classNames([styles.items, itemsClassName])}>
                {(items || []).map(
                    ({
                        id = null,
                        label = null,
                        href = null,
                        external = false,
                        active = false,
                        className: customClassName = null,
                        buttonClassName: customButtonClassName = null,
                        activeClassName: customActiveClassName = null,
                        ...itemProps
                    }) => (
                        <li
                            key={id}
                            className={classNames([styles.item, itemClassName, customClassName])}
                        >
                            <ButtonComponent
                                {...props}
                                {...itemProps}
                                href={href}
                                external={external}
                                active={active}
                                className={classNames([
                                    styles.button,
                                    buttonClassName,
                                    customButtonClassName,
                                    {
                                        [activeClassName]: active && activeClassName !== null,
                                        [customActiveClassName]:
                                            active && customActiveClassName !== null,
                                    },
                                ])}
                            >
                                {label}
                            </ButtonComponent>
                        </li>
                    ),
                )}
            </ul>
        </nav>
    );
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
