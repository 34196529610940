import { useQuery, useQueryClient, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useApi } from '../contexts/ApiContext';

export function fetchPage(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['page', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.pages.findBySlug(queryParam, { signal }),
    });
}

export function prefetchPage(queryClient, api, slug) {
    return queryClient.prefetchQuery({
        queryKey: ['page', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.pages.findBySlug(queryParam, { signal }),
    });
}

export function usePrefetchPage() {
    const api = useApi();
    const queryClient = useQueryClient();
    return useCallback((slug) => prefetchPage(queryClient, api, slug), [queryClient, api]);
}

export default function usePage(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: page = null, isFetched = false, ...queryResult } = useQuery({
        queryKey: ['page', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.pages.findBySlug(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : undefined,
        enabled: slug !== null,
        ...opts,
    });

    return {
        page,
        notFound: page === null && isFetched,
        isFetched,
        ...queryResult,
    };
}

export function fetchPagePreview(queryClient, api, token) {
    return queryClient.fetchQuery({
        queryKey: ['page_preview', token],
        queryFn: ({ queryKey: [, tokenParam], signal }) =>
            api.pages.findPreviewToken(tokenParam, { signal }),
    });
}

export function usePagePreview(token, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: page = null, isFetched = false, ...queryResult } = useQuery({
        queryKey: ['page_preview', token],
        queryFn: ({ queryKey: [, tokenParam], signal }) =>
            api.pages.findPreviewToken(tokenParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : undefined,
        enabled: token !== null,
        ...opts,
    });

    return {
        page,
        notFound: page === null && isFetched,
        isFetched,
        ...queryResult,
    };
}
