/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import PromotionSubscriptionCard from '../cards/PromotionSubscriptionCard';

import styles from '../../styles/blocks/subscription-block.module.css';

const propTypes = {
    subscription: PropTypes.string,
    image: AppPropTypes.image,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    subscription: 'urbania',
    image: null,
    title: 'Abonnez-vous à<br/>l’infolettre URBANIA',
    description: 'Chaque lundi et mercredi : nos contenus qui soulèvent les passions.',
    theme: 'urbania',
    className: null,
};

function SubscriptionBlock({ className, ...props }) {
    return (
        <div className={classNames([styles.container, className])}>
            <PromotionSubscriptionCard {...props} className={styles.card} />
        </div>
    );
}

SubscriptionBlock.propTypes = propTypes;
SubscriptionBlock.defaultProps = defaultProps;

export default SubscriptionBlock;
