/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useCollections from '../../hooks/useCollections';
import { getComponentFromName } from '@folklore/utils';

import * as CardComponents from '../cards/collections';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/collections-list.module.css';

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
    className: null,
    cardClassName: null,
};

function CollectionsList({ cardComponent, cardType, className, cardClassName, ...props }) {
    return (
        <PresentationList
            hook={useCollections}
            {...props}
            cardComponent={
                cardComponent || getComponentFromName(CardComponents, cardType, 'vertical')
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

CollectionsList.propTypes = propTypes;
CollectionsList.defaultProps = defaultProps;

export default CollectionsList;
