/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { Component } from '@niche-js/core/components';
import { useBlocksComponentsManager } from '@niche-js/core/contexts';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/blocks/blocks.module.css';

const propTypes = {
    blocks: PropTypes.arrayOf(AppPropTypes.block).isRequired,
    className: PropTypes.string,
    blockClassName: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    className: null,
    blockClassName: null,
    containerRef: null,
};

function Blocks({ blocks, className, blockClassName, containerRef }) {
    const blocksManager = useBlocksComponentsManager();
    if (blocks.length === 0) {
        return null;
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            {blocks.map((block, index) => {
                const {
                    id = index,
                    type = null,
                    uuid = null,
                    ...blockProps
                } = block || {};
                const BlockComponent = blocksManager.getComponent(type);
                return BlockComponent !== null ? (
                    <Component
                        component={block}
                        key={`block-${id}-${type}-${uuid}`}
                        inline={type === 'text' || type === 'heading'}
                        className={classNames([
                            'block',
                            `block-${type}`,
                            isFunction(blockClassName)
                                ? blockClassName(block, index)
                                : blockClassName,
                        ])}
                    >
                        <BlockComponent
                            id={id}
                            uuid={uuid}
                            {...blockProps}
                            key={`block-${index}`}
                        />
                    </Component>
                ) : null;
            })}
        </div>
    );
}

Blocks.propTypes = propTypes;
Blocks.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Blocks {...props} containerRef={ref} />);
