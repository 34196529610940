/* eslint-disable react/forbid-prop-types */

/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import createLayout from '../../lib/createLayout';
import { getPrefixedProps } from '../../lib/utils';

import DocumentHorizontalCard from '../cards/DocumentHorizontalCard';
import DocumentOverCard from '../cards/DocumentOverCard';
import DocumentVerticalCard from '../cards/DocumentVerticalCard';
import List from './List';

import styles from '../../styles/lists/layout.module.css';

const CardComponents = {
    Over: DocumentOverCard,
    Horizontal: DocumentHorizontalCard,
    Vertical: DocumentVerticalCard,
};

const propTypes = {
    items: PropTypes.array,
    layout: PropTypes.string.isRequired,
    listRef: AppPropTypes.ref,
    cardTheme: PropTypes.string,
    cardImageLoading: AppPropTypes.loading,
    cardWith: PropTypes.arrayOf(PropTypes.string),
    cardWithout: PropTypes.arrayOf(PropTypes.string),
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
};

const defaultProps = {
    items: null,
    listRef: null,
    cardTheme: null,
    cardImageLoading: undefined,
    cardWith: null,
    cardWithout: null,
    withBorder: false,
    className: null,
    itemsClassName: null,
};

function LayoutList({
    items,
    listRef,
    layout,
    cardImageLoading,
    cardTheme: globalCardTheme,
    cardWith,
    cardWithout,
    withBorder,
    className,
    itemsClassName,
    ...props
}) {
    const { cells = null } = useMemo(() => createLayout(layout), [layout]);
    const getItemStyle = useCallback(
        (cell, index) => ({
            gridArea: `item_${index}`,
        }),
        [],
    );
    return (
        <List
            {...props}
            className={classNames([
                styles.container,
                styles[camelCase(`layout_${layout}`)],
                className,
            ])}
            items={cells}
            itemsClassName={classNames([styles.items, itemsClassName])}
            itemClassName={styles.item}
            cardClassName={styles.card}
            cardWithout={cardWithout}
            cardWith={cardWith}
            itemStyle={getItemStyle}
            cardProps={(defaultCell, index) => {
                const {
                    cardType,
                    cardSize,
                    cardTheme = globalCardTheme,
                    cardRatio,
                    cardResponsive,
                    withBorder: cardWithBorder = withBorder,
                    withoutCategory,
                    withoutAuthor,
                    withoutAuthorImage,
                    withoutDuration,
                } = {
                    ...getPrefixedProps(cardWith, 'with'),
                    ...getPrefixedProps(cardWithout, 'without'),
                    ...defaultCell,
                };
                const it = (items || [])[index] || null;
                return {
                    placeholder: it === null,
                    ...it,
                    cardComponent: getComponentFromName(CardComponents, cardType),
                    size: cardSize,
                    theme: cardTheme,
                    ratio: cardRatio,
                    withBorder: !cardWithBorder,
                    withoutCategory,
                    withoutAuthor,
                    withoutAuthorImage,
                    withoutDuration,
                    responsive: cardResponsive,
                };
            }}
            cardImageLoading={cardImageLoading}
            ref={listRef}
        />
    );
}

LayoutList.propTypes = propTypes;
LayoutList.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <LayoutList listRef={ref} {...props} />);
