import Auth from './Auth';
import Authors from './Authors';
import Base from './Base';
import Collections from './Collections';
import Contests from './Contests';
import Documents from './Documents';
import Locations from './Locations';
import Pages from './Pages';
import Profile from './Profile';
import Promotions from './Promotions';
import Search from './Search';
import Submissions from './Submissions';
import Subscriptions from './Subscriptions';
import Taxonomies from './Taxonomies';
import Timeline from './Timeline';
import Visitor from './Visitor';

class Api extends Base {
    constructor(opts) {
        const options = {
            baseUrl: 'https://v2.urbania.ca/api',
            authBaseUrl: 'https://v2.urbania.ca/auth',
            ...opts,
        };
        super(options);

        const { authBaseUrl, ...authOptions } = options;

        this.auth = new Auth({
            ...authOptions,
            baseUrl: authBaseUrl,
        });
        this.visitor = new Visitor(options);
        this.profile = new Profile(options);
        this.promotions = new Promotions(options);
        this.subscriptions = new Subscriptions(options);
        this.documents = new Documents(options);
        this.authors = new Authors(options);
        this.taxonomies = new Taxonomies(options);
        this.collections = new Collections(options);
        this.search = new Search(options);
        this.timeline = new Timeline(options);
        this.locations = new Locations(options);
        this.contests = new Contests(options);
        this.pages = new Pages(options);
        this.submissions = new Submissions(options);
    }

    site(id, opts) {
        return this.getJSON(`/sites/${id}`, null, opts);
    }

    brands() {
        return this.getJSON('/brands');
    }
}

export default Api;
