/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from './BasicButton';

import styles from '../../styles/buttons/pill-button.module.css';

const propTypes = {
    branded: PropTypes.bool,
    bordered: PropTypes.bool,
    active: PropTypes.bool,
    compact: PropTypes.bool,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    branded: false,
    bordered: false,
    active: false,
    compact: null,
    className: null,
    buttonRef: null,
};

function PillButton({ branded, bordered, compact, active, className, buttonRef, ...props }) {
    return (
        <BasicButton
            className={classNames([
                styles.container,
                {
                    [styles.branded]: branded,
                    [styles.compact]: compact,
                    [styles.bordered]: bordered,
                    [styles.active]: active,
                },
                className,
            ])}
            ref={buttonRef}
            {...props}
        />
    );
}

PillButton.propTypes = propTypes;
PillButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <PillButton {...props} buttonRef={ref} />);
