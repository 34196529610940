import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import styles from '../../styles/partials/date.module.css';

const propTypes = {
    date: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    date: null,
    className: null,
};

function Date({ date, className }) {
    const dateObj = useMemo(() => date !== null ? parseISO(date) : null, [date]);
    return date !== null ? (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <FormattedDate
                day="numeric"
                // weekday="short"
                month="long"
                year="numeric"
                value={dateObj}
            />
        </div>
    ) : null;
}

Date.propTypes = propTypes;
Date.defaultProps = defaultProps;

export default Date;
