import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DurationTypo from '../typography/Duration';

import styles from '../../styles/partials/duration.module.css';

const propTypes = {
    duration: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function Duration({ duration, className }) {
    const formattedDuration =
        duration < 60 ? `${duration} sec` : `${Math.round(duration / 60)} min`;
    return (
        <DurationTypo
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {formattedDuration}
        </DurationTypo>
    );
}

Duration.propTypes = propTypes;
Duration.defaultProps = defaultProps;

export default Duration;
