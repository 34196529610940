import Base from './Base';

class Timeline extends Base {
    get(visitorId, query = null, page = null, count = null, opts = null) {
        return this.getJSONWithSession(
            '/timeline',
            {
                visitor_id: visitorId,
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    future(visitorId, query = null, page = null, count = null, opts = null) {
        return this.getJSONWithSession(
            '/timeline/future',
            {
                visitor_id: visitorId,
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    recommendations(visitorId, query = null, page = null, count = null, opts = null) {
        return this.getJSONWithSession(
            '/timeline/recommendations',
            {
                visitor_id: visitorId,
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    past(visitorId, query = null, page = null, count = null, opts = null) {
        return this.getJSONWithSession(
            '/timeline/past',
            {
                visitor_id: visitorId,
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    create(visitorId, url, opts = null) {
        return this.postJSONWithSession(
            '/timeline',
            {
                visitor_id: visitorId,
                url,
            },
            opts,
        );
    }

    skip(visitorId, url, opts = null) {
        return this.postJSONWithSession(
            '/timeline/skip',
            {
                visitor_id: visitorId,
                url,
            },
            opts,
        );
    }
}

export default Timeline;
