/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import DateSummary from '../summaries/DateSummary';
import SummaryRowBlock from './SummaryRowBlock';

const propTypes = {
    date: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    className: PropTypes.string,
    withBackground: PropTypes.bool,
};

const defaultProps = {
    query: null,
    withBackground: false,
    className: null,
};

function DateRowBlock({ date, query, className, withBackground, ...props }) {
    const { ref, visible: isVisible } = useIsVisible({
        persist: true,
    });

    const finalQuery = useMemo(
        () => ({
            date,
            ...query,
        }),
        [date, query],
    );

    return (
        <SummaryRowBlock
            summary={{
                date,
            }}
            summaryComponent={DateSummary}
            query={finalQuery}
            disabled={!isVisible}
            ref={ref}
            className={className}
            {...props}
        />
    );
}

DateRowBlock.propTypes = propTypes;
DateRowBlock.defaultProps = defaultProps;

export default DateRowBlock;
