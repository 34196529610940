import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function HomeMetadata() {
    const { brand: brandParam = null, section: sectionParam = null } = useParams();
    const defaultBrand = useDefaultBrand();
    const brand = useBrand(brandParam);
    const finalBrand = brandParam !== null ? brand : defaultBrand;
    const { label: brandLabel, sections, tagline, description } = finalBrand || {};
    const section =
        sectionParam !== null ? sections.find(({ slug }) => slug === sectionParam) || null : null;
    const { label: sectionLabel = null } = section || {};
    const url = useUrlGenerator();
    return (
        <Metadata
            brand={finalBrand}
            title={sectionLabel !== null ? sectionLabel : `${brandLabel} - ${tagline}`}
            description={description}
            withoutTitleSuffix={sectionLabel === null}
            url={url('home.wildcard', {
                brand: brandParam,
                section: sectionParam,
            })}
        />
    );
}

HomeMetadata.propTypes = propTypes;
HomeMetadata.defaultProps = defaultProps;

export default HomeMetadata;
