/* eslint-disable react/jsx-props-no-spreading */
import '../styles/styles.css';

import { AuthProvider } from '@folklore/auth';
import BlocksProvider from '@niche-js/blocks';
import { BLOCKS_NAMESPACE, ComponentsProvider } from '@niche-js/core/contexts';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useAuthUser } from '../hooks/useAuth';

import { AdsProvider } from '../contexts/AdsContext';
import { UserAdsTargetingProvider } from '../contexts/AdsTargeting';
import { ConsentContextProvider } from '../contexts/ConsentContext';
import { DeviceContextProvider } from '../contexts/DeviceContext';
import { PopupsProvider } from '../contexts/PopupsContext';
import { PromotionsProvider } from '../contexts/PromotionsContext';
import { useSite } from '../contexts/SiteContext';
import { TrackingProvider } from '../contexts/TrackingContext';
// import { LayoutContextProvider } from '../contexts/LayoutContext';
import { VisitorContextProvider } from '../contexts/VisitorContext';
import slots from '../data/adsSlots';
import * as richAdComponents from './ads/index';
import * as BlockComponents from './blocks/index';
import TimelineLayout from './layouts/TimelineLayout';
import RedirectorPage from './pages/RedirectorPage';

const propTypes = {
    device: PropTypes.shape({}),
    isRedirector: PropTypes.bool,
    tagManagerId: PropTypes.string,
    adSlotsPath: PropTypes.objectOf(PropTypes.string),
    bncDCId: PropTypes.string,
};

const defaultProps = {
    device: null,
    isRedirector: false,
    tagManagerId: 'GTM-WBRBHVQ',
    adSlotsPath: {
        default: '/62442913/URBANIA',
        bumper: '/62442913/timeline',
    },
    bncDCId: 'DC-9722614',
};

function App({ device, isRedirector, adSlotsPath: defaultSlotsPath, tagManagerId, bncDCId }) {
    const { adSlotsPath = defaultSlotsPath } = useSite();
    const { user = null } = useAuthUser({
        enabled: !isRedirector,
    });

    useEffect(() => {
        if (isRedirector) {
            return;
        }

        const firstScript = document.getElementsByTagName('script')[0];

        // GTM
        const gtm = document.createElement('script');
        gtm.async = true;
        gtm.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerId}`;
        firstScript.parentNode.insertBefore(gtm, firstScript);

        // GPT
        const gpt = document.createElement('script');
        gpt.async = true;
        gpt.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        firstScript.parentNode.insertBefore(gpt, firstScript);

        // BNC
        const bnc = document.createElement('script');
        bnc.async = true;
        bnc.src = `https://www.googletagmanager.com/gtag/js?id=${bncDCId}&l=bncDataLayer`;
        firstScript.parentNode.insertBefore(bnc, firstScript);
    }, [tagManagerId, bncDCId]);

    return (
        <BlocksProvider>
            <ComponentsProvider namespace={BLOCKS_NAMESPACE} components={BlockComponents}>
                <AuthProvider user={user}>
                    <ConsentContextProvider>
                        <VisitorContextProvider disabled={isRedirector}>
                            <TrackingProvider disabled={isRedirector}>
                                <PromotionsProvider>
                                    <AdsProvider
                                        slotsPath={adSlotsPath}
                                        slots={slots}
                                        disableTracking
                                        disabled={isRedirector}
                                        fetchMarginPercent={150}
                                        renderMarginPercent={100}
                                        richAdComponents={richAdComponents}
                                        // disableSingleRequest
                                        mobileScaling={1.0}
                                    >
                                        <DeviceContextProvider {...device}>
                                            <UserAdsTargetingProvider>
                                                <PopupsProvider>
                                                    {isRedirector ? (
                                                        <RedirectorPage />
                                                    ) : (
                                                        <TimelineLayout />
                                                    )}
                                                </PopupsProvider>
                                            </UserAdsTargetingProvider>
                                        </DeviceContextProvider>
                                    </AdsProvider>
                                </PromotionsProvider>
                            </TrackingProvider>
                        </VisitorContextProvider>
                    </ConsentContextProvider>
                </AuthProvider>
            </ComponentsProvider>
        </BlocksProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
