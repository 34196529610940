import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import EditContact from '../forms/EditContactForm';
import PromotionTitle from '../typography/PromotionTitle';
import Dialog from './Dialog';
import Modal from './Modal';

import styles from '../../styles/popups/edit-contact-modal.module.css';

const propTypes = {
    type: PropTypes.string,
    title: PropTypes.node,
    className: PropTypes.string,
    onClosed: PropTypes.func,
    onComplete: PropTypes.func,
};

const defaultProps = {
    type: 'email',
    title: null,
    className: null,
    onClosed: null,
    onComplete: null,
};

function EditContactModal({ type, title, className, onClosed, onComplete }) {
    const [opened, setOpened] = useState(true);
    const onFormComplete = useCallback(
        (response) => {
            setOpened(false);
            if (onComplete !== null) {
                onComplete(response);
            }
        },
        [setOpened, onComplete],
    );
    const onClickCancel = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    const inputRef = useRef(null);
    const onOpened = useCallback(() => {
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <Modal
            id="edit_contact"
            opened={opened}
            withBackdrop
            withTransition
            // withScrollLock
            className={classNames([styles.container, className])}
            onClosed={onClosed}
            onOpened={onOpened}
            onRequestClose={onRequestClose}
        >
            <Dialog>
                {title !== null ? (
                    <PromotionTitle className={styles.title}>{title}</PromotionTitle>
                ) : null}
                <EditContact
                    type={type}
                    label={title !== null ? false : null}
                    editOnly
                    inputRef={inputRef}
                    onComplete={onFormComplete}
                    onClickCancel={onClickCancel}
                />
            </Dialog>
        </Modal>
    );
}

EditContactModal.propTypes = propTypes;
EditContactModal.defaultProps = defaultProps;

export default EditContactModal;
