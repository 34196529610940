/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import AuthorImage from '../partials/AuthorImage';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import OutlineTitle from '../typography/OutlineTitle';
import OverCard from './OverCard';

import styles from '../../styles/cards/author-over-card.module.css';
import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';

const propTypes = {
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    name: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'without_text', 'outline']),
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    width: PropTypes.number,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    size: null,
    slug: null,
    linkQuery: null,
    name: null,
    image: null,
    ratio: null,
    theme: null,
    width: 100,
    placeholder: false,
    className: null,
};

function AuthorOverCard({ size, slug, linkQuery, name, image, theme, width, ratio, placeholder, className }) {
    const url = useAuthorUrlGenerator();

    const isOutline = theme === 'outline';
    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <OverCard
            href={
                url(
                    {
                        slug,
                    },
                    linkQuery,
                ) || '#'
            }
            thumbnail={
                <AuthorImage
                    name={name}
                    slug={slug}
                    media={image}
                    className={styles.thumbnail}
                    pictureClassName={styles.picture}
                    width={width}
                    height={width / thumbnailRatio}
                    size="small"
                    withPlaceholder={placeholder}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                styles[camelCase(theme)],
                styles[camelCase(size)],
                styles[camelCase(ratio)],
                {
                    [className]: className !== null,
                },
            ])}
            innerClassName={styles.inner}
            labelClassName={styles.label}
        >
            {isOutline ? (
                <OutlineTitle className={styles.title}>
                    {placeholder ? <PlaceholderText /> : name}
                </OutlineTitle>
            ) : (
                <CardTitle tag="strong" className={styles.title}>
                    {placeholder ? <PlaceholderText /> : name}
                </CardTitle>
            )}
        </OverCard>
    );
}

AuthorOverCard.propTypes = propTypes;
AuthorOverCard.defaultProps = defaultProps;

export default AuthorOverCard;
