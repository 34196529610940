/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import OverCard from './OverCard';

import styles from '../../styles/cards/taxonomy-over-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    label: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'menu']),
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    width: PropTypes.number,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'category',
    slug: null,
    label: null,
    image: null,
    theme: null,
    size: null,
    ratio: null,
    width: 120,
    placeholder: false,
    className: null,
};

function TaxonomyOverCard({
    type,
    slug,
    label,
    image,
    theme,
    size,
    width,
    ratio,
    placeholder,
    className,
}) {
    const url = useUrlGenerator();

    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <OverCard
            href={
                slug !== null
                    ? url(type, {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <Image
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    className={styles.thumbnail}
                    withPlaceholder={placeholder}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
            innerClassName={styles.inner}
        >
            <CardTitle className={styles.title}>
                {placeholder ? <PlaceholderText /> : label}
            </CardTitle>
        </OverCard>
    );
}

TaxonomyOverCard.propTypes = propTypes;
TaxonomyOverCard.defaultProps = defaultProps;

export default TaxonomyOverCard;
