import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import Base from './Base';

class Subscriptions extends Base {
    subscribe(handle, data = null, opts = null) {
        return this.postJSONWithSession(
            isString(handle) ? `/subscription/${handle}` : `/subscription`,
            isObject(handle) ? handle : data,
            opts,
        );
    }

    unsubscribe(handle, data, opts = null) {
        return this.postJSONWithSession(
            isString(handle) ? `/subscription/${handle}` : `/subscription`,
            {
                ...(isObject(handle) ? handle : data),
                _method: 'DELETE',
            },
            opts,
        );
    }
}

export default Subscriptions;
