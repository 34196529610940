import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import useCollection from '../../hooks/useCollection';

import Metadata from './Metadata';
import NotfoundMetadata from './NotfoundMetadata';

const propTypes = {};

const defaultProps = {};

function CollectionMetadata() {
    const { slug: slugParam } = useParams();
    const { collection = null, notFound = false } = useCollection(slugParam);
    const url = useUrlGenerator();
    if (notFound) {
        return <NotfoundMetadata />;
    }
    if (collection === null) {
        return null;
    }
    const { title, slug, metadata, image } = collection;
    const { description, brands, keywords } = metadata || {};
    const [brand] = brands || [];
    return (
        <Metadata
            brand={brand}
            title={title}
            description={description}
            keywords={keywords}
            image={image}
            url={url('collection', {
                slug,
            })}
        />
    );
}

CollectionMetadata.propTypes = propTypes;
CollectionMetadata.defaultProps = defaultProps;

export default CollectionMetadata;
