/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    circleColor: 'transparent',
    className: null,
};

function CaretIcon({ color, circleColor, className }) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="7" cy="7" r="7" transform="matrix(-1 0 0 1 14 0)" fill={circleColor} />
            <path d="M10 8L7 5L4 8" stroke={color} strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    );
}

CaretIcon.propTypes = propTypes;
CaretIcon.defaultProps = defaultProps;

export default CaretIcon;
