import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function LoisirsEtCulture({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.59 34.34" className={className}>
            <path
                fill={color}
                d="M40.55,19.57c-.42-4.09-1.58-8.07-3.4-11.76-.34-.77-.81-1.47-1.39-2.08-.61-.57-1.2-1.13-2.03-.99h-.14c-.09,0-.17-.02-.26-.04,.35-.32,.51-.79,.44-1.26-.19-.65-1.14-.76-1.7-.37-.2,.14-.36,.31-.5,.51-.04-.04-.08-.08-.11-.12-.53-.66-1.09-1.29-1.68-1.89C28.12,.1,25.7-.08,23.49,.02c-3.39,.16-6.74,.83-9.94,1.98,1.16,1.39,1.35,3.34,.48,4.92,3.42-.42,6.54,2.01,6.96,5.43,0,.02,0,.03,0,.05,1.5,.16,2.96,.56,4.34,1.18-.6,1.1-1.17,2.23-1.74,3.35-1.23,2.45-2.46,4.89-3.69,7.34l-4.66,9.26-.09,.19,.26,.17c.42-.01,.78,.65,1.13,.4,.07-.06,.12-.13,.16-.22,2.65-5.13,5.3-10.27,7.95-15.4,.74-1.43,1.48-2.86,2.25-4.28,1.62,.97,3.03,2.25,4.15,3.77,.2,.34,.49,.62,.82,.83,.2,.08,.42,.14,.64,.17,2.49,.44,4.77,1.67,6.51,3.51,.13-.72,1.02-1.03,1.37-1.67,.19-.45,.24-.94,.15-1.42"
            />
            <path
                fill={color}
                d="M13.55,14.64c0-.23-.05-.46-.16-.67-.3-.37-.73-.59-1.2-.62l-7.78-1.56c-.85-.11-.43,1.85-.43,1.85,.02,.08,.05,.15,.07,.23l-.59,8.78c-.79-.07-1.41,.53-2.02,1.07-.62,.58-1.07,1.31-1.29,2.12-.27,.6-.17,1.3,.26,1.79,.43,.26,.93,.34,1.42,.22,.74,0,1.47-.26,2.04-.73,.24-.28,.42-.6,.52-.96,.34-1.05,1.03-2.6,.07-3.28,.38-2.74,.54-5.52,.47-8.29,.05,.02,.1,.03,.14,.04,2.23,.62,5,.97,7.16,1.76-.36,2.56-.89,6.42-.9,8.34-.85,.05-1.65,.42-2.23,1.05-.73,.67-1.17,1.6-1.24,2.58-.04,.28-.02,.56,.07,.83,.22,.49,.68,.83,1.21,.9,.52,.06,1.05,.02,1.57-.11,.42-.06,.82-.19,1.19-.38,.82-.6,1.29-1.56,1.28-2.58,.05-.38,0-.78-.13-1.14-.15-.35-.26-.84-.65-.83l-.05-.02c.27-2.43,.53-6.09,.69-8.45,.2-.77,.56-1.15,.51-1.94"
            />
        </svg>
    );
}

LoisirsEtCulture.propTypes = propTypes;
LoisirsEtCulture.defaultProps = defaultProps;

export default LoisirsEtCulture;
