import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function SummerIcon({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.22 16.1" className={className}>
            <path
                fill={color}
                d="M8.17,4.09c.21,0,.42-.09,.57-.24,.15-.15,.23-.36,.24-.57,.01-.83,.06-1.65,.14-2.47,0-.21-.08-.42-.24-.57-.31-.32-.83-.32-1.14,0,0,0,0,0,0,0l-.13,.16c-.07,.12-.11,.26-.11,.41-.08,.82-.12,1.65-.14,2.47,0,.45,.36,.81,.81,.81h0Z"
            />
            <path
                fill={color}
                d="M11.62,5.4c.21,0,.42-.08,.57-.24,.34-.39,.72-.75,1.13-1.08,.11-.08,.22-.16,.34-.24,.18-.11,.31-.28,.37-.48,.06-.21,.03-.43-.08-.62-.11-.18-.28-.31-.48-.37-.21-.06-.43-.03-.62,.08-.66,.45-1.26,.97-1.79,1.57-.15,.15-.23,.36-.24,.57,0,.21,.09,.42,.24,.57,.15,.15,.36,.24,.57,.24Z"
            />
            <path
                fill={color}
                d="M15.98,7.96c-.15-.15-.36-.24-.57-.24-.49,.02-.98,0-1.47-.05-.15-.02-.3-.05-.44-.08-.21-.05-.43-.02-.62,.08-.38,.22-.51,.72-.29,1.1,.11,.18,.28,.32,.48,.37,.77,.16,1.56,.22,2.34,.18,.21,0,.42-.09,.57-.24,.32-.32,.32-.83,0-1.14h0Z"
            />
            <path
                fill={color}
                d="M14,12.5c-.48-.58-1.03-1.09-1.65-1.53-.09-.06-.19-.09-.3-.1-.11-.02-.22-.02-.32,.01-.2,.06-.37,.19-.48,.37-.11,.19-.14,.41-.08,.62l.08,.19c.07,.12,.17,.22,.29,.29,.04,.03,.09,.07,.13,.1,.44,.35,.84,.74,1.19,1.17,.16,.14,.36,.23,.57,.24,.21,0,.42-.09,.57-.24,.15-.15,.24-.36,.24-.57l-.03-.22c-.04-.13-.11-.25-.21-.35Z"
            />
            <path
                fill={color}
                d="M8.88,12.6c-.31-.32-.83-.32-1.14,0-.15,.15-.24,.36-.24,.57,.02,.71,0,1.42-.05,2.13,0,.21,.09,.42,.24,.57,.31,.32,.83,.32,1.14,0h0s.13-.16,.13-.16c.07-.12,.11-.26,.11-.41,.06-.71,.07-1.42,.05-2.13,0-.21-.09-.42-.24-.57Z"
            />
            <path
                fill={color}
                d="M5.16,10.95c-.19-.11-.41-.14-.62-.08-.2,.05-.38,.19-.48,.37-.28,.64-.7,1.2-1.22,1.66-.32,.31-.32,.83,0,1.14h0c.15,.15,.36,.24,.57,.24,.21,0,.42-.09,.57-.24,.33-.3,.62-.64,.88-1,.23-.31,.42-.64,.59-.99,.06-.09,.09-.19,.1-.3,.02-.11,.02-.22-.01-.32-.06-.2-.19-.38-.38-.48Z"
            />
            <path
                fill={color}
                d="M3.65,8.86c.32-.32,.32-.83,0-1.14-.15-.15-.36-.24-.57-.24-.58,.03-1.17,.01-1.75-.06-.1-.02-.21-.03-.31-.05-.1-.03-.22-.04-.32-.01-.11,0-.21,.04-.3,.1-.38,.22-.51,.72-.29,1.1,.11,.18,.28,.32,.48,.37,.82,.15,1.65,.21,2.49,.17,.21,0,.42-.09,.57-.24Z"
            />
            <path
                fill={color}
                d="M4.08,5.18c.31,.32,.83,.32,1.14,0,0,0,0,0,0,0,.15-.15,.24-.36,.24-.57,0-.21-.09-.42-.24-.57l-1.46-1.57c-.31-.32-.83-.32-1.14,0,0,0,0,0,0,0-.15,.15-.24,.36-.24,.57,0,.21,.09,.42,.24,.57l1.46,1.57Z"
            />
            <path
                fill={color}
                d="M11.6,7.47c-.25-1.35-1.25-2.44-2.58-2.8-.17-.04-.35-.05-.53-.06-.83,0-1.66,.2-2.41,.57-.5,.39-.92,.87-1.23,1.43-.23,.32-.41,.68-.52,1.07-.09,.56,0,1.13,.24,1.64,.32,.82,.9,1.51,1.66,1.96,.74,.35,1.55,.49,2.36,.43,.33,0,.65-.05,.96-.15,.27-.11,.53-.27,.74-.47,1.02-.9,1.51-2.26,1.3-3.61Z"
            />
        </svg>
    );
}

SummerIcon.propTypes = propTypes;
SummerIcon.defaultProps = defaultProps;

export default SummerIcon;
