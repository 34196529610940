import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import styles from '../../styles/partials/accordion.module.css';

const propTypes = {
    collapsed: PropTypes.bool,
    duration: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    collapsed: false,
    duration: '0.2s',
    children: null,
    className: null,
};

function Accordion({ collapsed, duration, children, className }) {
    const ref = useRef(null);

    const maxHeight = useMemo(() => {
        const height = ref.current !== null ? ref.current.clientHeight : 0;
        return `${collapsed ? 0 : height}px`;
    }, [children]);

    return (
        <div
            className={classNames([
                styles.container,
                className,
                {
                    [styles.collapsed]: collapsed,
                },
            ])}
        >
            <div
                className={styles.wrapper}
                style={{ maxHeight, transitionDuration: duration !== null ? duration : null }}
            >
                <div className={styles.children} ref={ref}>
                    {children}
                </div>
            </div>
        </div>
    );
}

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;
