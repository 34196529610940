/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useElementSize from '../../hooks/useElementSize';

import * as wrappers from './wrappers';

const propTypes = {
    wrapper: PropTypes.object,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    transformClassName: PropTypes.string,
};

const defaultProps = {
    wrapper: null,
    className: null,
    innerClassName: null,
    transformClassName: null,
};

function AdWrapper({ wrapper, children, className, innerClassName, transformClassName }) {
    const { ref: innerRef, width: innerWidth, height: innerHeight } = useElementSize();
    const {
        innerChildren,
        innerStyle,
        style,
        transformStyle,
        className: wrapperClassName,
        innerClassName: wrapperInnerClassName,
        transformClassName: wrapperTransformClassName,
    } = useMemo(() => {
        if (wrapper === null) {
            return null;
        }
        const { wrapper: wrapperType = null } = wrapper || {};
        const wrapperFunc = wrapperType !== null ? wrappers[wrapperType] || null : null;
        return wrapperFunc !== null
            ? wrapperFunc(wrapper, {
                  innerWidth,
                  innerHeight,
              })
            : null;
    }, [wrapper, innerWidth, innerHeight]) || {};
    return (
        <div className={classNames([className, wrapperClassName])} style={style}>
            <div
                className={classNames([innerClassName, wrapperInnerClassName])}
                ref={innerRef}
                style={innerStyle}
            >
                {innerChildren}
                <div
                    className={classNames([transformClassName, wrapperTransformClassName])}
                    style={transformStyle}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

AdWrapper.propTypes = propTypes;
AdWrapper.defaultProps = defaultProps;

export default AdWrapper;
