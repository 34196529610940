/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import CollectionImage from '../partials/CollectionImage';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import OverCard from './OverCard';

import styles from '../../styles/cards/collection-over-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'menu']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.oneOf(['square', 'square_double']),
    width: PropTypes.number,
    placeholder: PropTypes.bool,
    withoutText: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    title: null,
    image: null,
    theme: null,
    size: null,
    ratio: null,
    width: 120,
    placeholder: false,
    withoutText: false,
    className: null,
};

function CollectionOverCard({
    slug,
    title,
    image,
    theme,
    size,
    ratio,
    width,
    placeholder,
    withoutText,
    className,
}) {
    const url = useUrlGenerator();
    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <OverCard
            href={
                slug !== null
                    ? url('collection', {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <CollectionImage
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    className={styles.thumbnail}
                    withPlaceholder={placeholder}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                {
                    [styles.withoutText]: withoutText,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                styles[camelCase(ratio)],
                className,
            ])}
            thumbnailClassName={styles.thumbnail}
            labelClassName={styles.label}
            innerClassName={styles.inner}
        >
            {!withoutText ? (
                <CardTitle className={styles.title}>
                    {placeholder ? <PlaceholderText /> : title}
                </CardTitle>
            ) : null}
        </OverCard>
    );
}

CollectionOverCard.propTypes = propTypes;
CollectionOverCard.defaultProps = defaultProps;

export default CollectionOverCard;
