/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useRouteMatcher, useRoutes, useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, useLocation, useRoute, useSearch } from 'wouter';

import { useIsCheckingAuth } from '../../hooks/useAuth';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useApi, useApiContext } from '../../contexts/ApiContext';
import { useRequestBase } from '../../contexts/RequestContext';
import BasicButton from '../buttons/BasicButton';
import RoundedButton from '../buttons/RoundedButton';
import SsoButton from '../buttons/SsoButton';
import LoginForm from '../forms/LoginForm';
import FacebookIcon from '../icons/socials/FacebookIcon';
import GoogleIcon from '../icons/socials/GoogleIcon';
import HeaderDescription from '../typography/HeaderDescription';
import HeaderTitle from '../typography/HeaderTitle';

import styles from '../../styles/pages/login-page.module.css';

import urbaniaLogo from '../../assets/img/brands/urbania_logo.svg';

const propTypes = {
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function LoginPage({
    entered,
    entering,
    leaving,
    current,
    className,
    contentClassName,
    onEnter,
    onLeave,
}) {
    const user = useUser();
    const url = useUrlGenerator();
    const [location, setLocation] = useLocation();

    const routes = useRoutes();
    const api = useApi();
    const { baseUrl: authBaseUrl = null } = api.auth;
    const routeMatcher = useRouteMatcher();
    const [isRegister = false] = useRoute(routes.register);
    const tracking = useTracking();

    const [verifyMethod, setVerifyMethod] = useState(null);

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const locationSearch = useSearch();
    const baseUri = useRequestBase();
    const nextUrl = useMemo(() => {
        const { next = null } = queryString.parse(locationSearch) || {};
        return next;
    }, [locationSearch]);
    const absoluteNextUrl = `${baseUri}${nextUrl || url('account')}`;

    const onLoginComplete = useCallback(() => {
        setLocation(nextUrl || url('account'));
    }, [url, nextUrl, setLocation]);

    const onVerify = useCallback(
        (newMethod) => {
            setVerifyMethod(newMethod);
        },
        [setVerifyMethod],
    );

    const onCancelVerify = useCallback(() => {
        setVerifyMethod(null);
    }, [setVerifyMethod]);

    const onClickSso = useCallback(
        (provider) => {
            tracking.trackEvent('Auth', 'click_sso', provider);
        },
        [tracking],
    );

    const transitions = useTransition([location], {
        keys: (id) => `${id}`,
        initial: {
            x: '0%',
            opacity: 1,
        },
        from: {
            x: '100%',
            opacity: 0,
        },
        enter: {
            x: '0%',
            opacity: 1,
        },
        leave: {
            x: '-100%',
            opacity: 0,
        },
    });

    useEffect(() => {
        if (current && user === null) {
            tracking.trackPageView({
                pageType: 'auth',
            });
        }
    }, [tracking, current]);

    if (user !== null && current) {
        return <Redirect to={nextUrl || url('account')} replace />;
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                    [styles.isRegister]: isRegister,
                    [styles.isVerifying]: verifyMethod !== null,
                },
                className,
                contentClassName,
            ])}
        >
            <BasicButton href="/" className={styles.button}>
                <img src={urbaniaLogo} alt="Urbania" className={styles.logo} />
            </BasicButton>
            {transitions((style, transitionLocation, { phase }) => {
                const [transitionIsRegister = false] = routeMatcher(
                    routes.register,
                    transitionLocation,
                );
                return (
                    <animated.div
                        className={classNames([styles.wrapper, [styles[phase]]])}
                        style={style}
                    >
                        <div className={styles.inner}>
                            <HeaderTitle className={styles.title}>
                                {transitionIsRegister ? (
                                    <FormattedMessage
                                        defaultMessage="Inscrivez-vous"
                                        description="Page title"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Connectez-vous"
                                        description="Page title"
                                    />
                                )}
                            </HeaderTitle>
                            <HeaderDescription className={styles.description}>
                                {verifyMethod !== null ? (
                                    <FormattedMessage
                                        defaultMessage="Pour valider votre identité, nous vous avons envoyé un code de vérification par {method}."
                                        description="Login description"
                                        values={{
                                            method:
                                                verifyMethod === 'phone' ? (
                                                    <FormattedMessage
                                                        defaultMessage="SMS"
                                                        description="Method"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="courriel"
                                                        description="Method"
                                                    />
                                                ),
                                        }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Entrez votre courriel ou votre numéro de téléphone pour accéder à votre profil, votre historique ou gérer vos abonnements."
                                        description="Login description"
                                    />
                                )}
                            </HeaderDescription>
                            <LoginForm
                                onComplete={onLoginComplete}
                                onVerify={onVerify}
                                onCancelVerify={onCancelVerify}
                                fieldClassName={styles.field}
                                className={styles.form}
                            />
                            <div className={styles.ssoContainer}>
                                <div className={styles.continue}>
                                    <div className={styles.line} />
                                    <HeaderDescription className={styles.label}>
                                        <FormattedMessage
                                            defaultMessage="ou continuer avec"
                                            description="Form label"
                                        />
                                    </HeaderDescription>
                                    <div className={styles.line} />
                                </div>
                                <div className={styles.ssoButtons}>
                                    {['facebook', 'google'].map((provider) => (
                                        <SsoButton
                                            key={`sso-${provider}`}
                                            provider={provider}
                                            source="login"
                                            nextUrl={absoluteNextUrl}
                                            className={styles.ssoButton}
                                            onClick={() => onClickSso(provider)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <HeaderDescription className={styles.toggleContainer}>
                                {transitionIsRegister ? (
                                    <FormattedMessage
                                        defaultMessage="Déjà inscrit?"
                                        description="Label"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Pas de compte?"
                                        description="Label"
                                    />
                                )}
                                <BasicButton
                                    href={transitionIsRegister ? url('login') : url('register')}
                                    className={styles.toggle}
                                >
                                    {transitionIsRegister ? (
                                        <FormattedMessage
                                            defaultMessage="Connectez-vous"
                                            description="Label"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="Inscrivez-vous"
                                            description="Label"
                                        />
                                    )}
                                </BasicButton>
                            </HeaderDescription>
                        </div>
                    </animated.div>
                );
            })}
        </div>
    );
}

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

export default LoginPage;
