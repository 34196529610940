/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import AccountIcon from '../icons/AccountIcon';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/account-button.module.css';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    color: 'currentColor',
    circleColor: '#f5f5f5',
    className: null,
    buttonRef: null,
};

function AccountButton({ className, buttonRef, color, circleColor, ...props }) {
    const user = useUser();
    return (
        <BasicButton
            ref={buttonRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <AccountIcon
                className={styles.icon}
                color={color}
                circleColor={circleColor}
                loggedIn={user !== null}
            />
        </BasicButton>
    );
}

AccountButton.propTypes = propTypes;
AccountButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <AccountButton {...props} buttonRef={ref} />);
