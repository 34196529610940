/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/prefer-default-export */
import { AdsProvider as BaseAdsProvider } from '@folklore/ads';
import PropTypes from 'prop-types';
import React from 'react';

import { useConsentGiven } from './ConsentContext';

export function AdsProvider({ disabled, ...props }) {
    const consentGiven = useConsentGiven();
    return <BaseAdsProvider disabled={disabled || consentGiven === null} {...props} />;
}

AdsProvider.propTypes = {
    disabled: PropTypes.bool,
};
AdsProvider.defaultProps = {
    disabled: false,
};
