/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import BasicButton from '../buttons/BasicButton';

// import * as AppPropTypes from '../../lib/PropTypes';
import styles from '../../styles/cards/over-card.module.css';

const propTypes = {
    theme: PropTypes.string,
    brand: PropTypes.string,
    href: PropTypes.string,
    thumbnail: PropTypes.node,
    thumbnailRatio: PropTypes.number,
    width: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    theme: null,
    brand: null,
    href: null,
    thumbnail: null,
    thumbnailRatio: 4 / 3,
    width: null,
    children: null,
    className: null,
    thumbnailClassName: null,
    labelClassName: null,
    innerClassName: null,
    onClick: null,
};

function OverCard({
    theme,
    brand,
    href,
    thumbnail,
    thumbnailRatio,
    width,
    children,
    className,
    thumbnailClassName,
    labelClassName,
    innerClassName,
    onClick,
}) {
    const thumbnailStyle = {
        width: width || '100%',
        height: theme === 'box' || thumbnailRatio === null ? '100%' : width / thumbnailRatio,
        paddingBottom:
            width === null && theme !== 'box' && thumbnailRatio !== null
                ? `${(1 / thumbnailRatio) * 100}%`
                : null,
    };

    const Element = href !== null || onClick !== null ? 'Element' : 'div';
    const cardInner = (
        <Element
            className={classNames([
                styles.inner,
                {
                    [innerClassName]: innerClassName !== null,
                },
            ])}
        >
            <Element
                className={classNames([
                    styles.thumbnail,
                    {
                        [thumbnailClassName]: thumbnailClassName !== null,
                    },
                ])}
                style={thumbnailStyle}
            >
                {thumbnail}
                <Element
                    className={classNames([
                        styles.label,
                        {
                            [labelClassName]: labelClassName !== null,
                        },
                    ])}
                >
                    {children}
                </Element>
            </Element>
        </Element>
    );

    if (href !== null) {
        return (
            <Link
                href={href}
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                onClick={onClick}
                data-brand={brand}
            >
                {cardInner}
            </Link>
        );
    }

    if (onClick !== null) {
        return (
            <BasicButton
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                onClick={onClick}
                data-brand={brand}
            >
                {cardInner}
            </BasicButton>
        );
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            data-brand={brand}
        >
            {cardInner}
        </div>
    );
}

OverCard.propTypes = propTypes;
OverCard.defaultProps = defaultProps;

export default OverCard;
