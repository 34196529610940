/* eslint-disable react/forbid-prop-types */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import camelCase from 'lodash/camelCase';

import DocumentsList from '../lists/DocumentsList';
import CollectionSummary from '../summaries/CollectionSummary';

import styles from '../../styles/cards/collection-documents-card.module.css';

const propTypes = {
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'menu']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.oneOf(['square', 'square_double']),
    listTitle: PropTypes.node,
    listProps: PropTypes.object,
    listQuery: PropTypes.object,
    placeholder: PropTypes.bool,
    withoutText: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    slug: null,
    title: null,
    description: null,
    image: null,
    theme: null,
    size: null,
    ratio: null,
    listTitle: null,
    listProps: null,
    listQuery: null,
    placeholder: false,
    withoutText: false,
    className: null,
};

function CollectionDocumentsCard({
    id,
    slug,
    title,
    description,
    image,
    theme,
    size,
    ratio,
    listTitle,
    listProps,
    listQuery,
    placeholder,
    withoutText,
    className,
}) {
    const url = useUrlGenerator();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withoutText]: withoutText,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                styles[camelCase(ratio)],
                className,
            ])}
        >
            <CollectionSummary
                slug={slug}
                title={title}
                description={description}
                image={image}
                imagePosition="left"
                className={styles.summary}
                imageClassName={styles.summaryImage}
                descriptionClassName={styles.summaryDescription}
                withButton={false}
                withLink
                link={
                    slug !== null
                        ? url('collection', {
                              slug,
                          })
                        : '#'
                }
            />
            {listTitle !== null ? <div className={styles.title}>{listTitle}</div> : null}
            <DocumentsList
                query={
                    !placeholder
                        ? {
                              collection: id,
                              ...listQuery,
                          }
                        : null
                }
                disabled={placeholder}
                cardType="horizontal"
                cardRatio={ratio}
                cardSize={size}
                cardTheme={theme}
                count={3}
                columns={1}
                placeholder={placeholder}
                className={styles.list}
                {...listProps}
            />
        </div>
    );
}

CollectionDocumentsCard.propTypes = propTypes;
CollectionDocumentsCard.defaultProps = defaultProps;

export default CollectionDocumentsCard;
