/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import BrandLogoNormalized from '../icons/BrandLogoNormalized';
import Image from '../partials/Image';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/brand-horizontal-card.module.css';

const propTypes = {
    handle: PropTypes.string,
    slug: PropTypes.string,
    theme: PropTypes.oneOf([null, 'menu-reverse']),
    ratio: PropTypes.string,
    label: PropTypes.string,
    shortDescription: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    handle: null,
    slug: null,
    theme: null,
    ratio: null,
    label: null,
    shortDescription: null,
    className: null,
};

function BrandHorizontalCard({ handle, slug, label, shortDescription, theme, ratio, className }) {
    const thumbnailRatio = getRatio(ratio, null);

    return (
        <HorizontalCard
            href={slug !== null ? `/${slug}` : '/'}
            height={50}
            thumbnailRatio={theme === 'menu-reverse' ? 12 / 5 : thumbnailRatio}
            thumbnail={<BrandLogoNormalized brand={handle} className={styles.thumbnail} />}
            className={classNames([styles.container, styles[camelCase(theme)], className])}
            brand={handle}
            innerClassName={styles.inner}
            labelClassName={styles.label}
        >
            <CardTitle className={styles.name}>{label}</CardTitle>
            <CardSubtitle className={styles.description}>{shortDescription}</CardSubtitle>
        </HorizontalCard>
    );
}

BrandHorizontalCard.propTypes = propTypes;
BrandHorizontalCard.defaultProps = defaultProps;

export default BrandHorizontalCard;
