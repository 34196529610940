/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import usePage from '../../hooks/usePage';

import Blocks from '../blocks/Blocks';
import UrbaniaButton from '../buttons/UrbaniaButton';
import Body from '../typography/Body';
import NotFoundPage from './NotFoundPage';

import styles from '../../styles/pages/page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    disabled: false,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    className: null,
    contentClassName: null,
};
function Page({
    slug,
    disabled,
    entered,
    entering,
    leaving,
    current,
    className,
    contentClassName,
}) {
    const { page = null, notFound = false } = usePage(slug, {
        keepPreviousData: false,
        enabled: !disabled,
    });
    const { blocks = [] } = page || {};

    if (notFound) {
        return <NotFoundPage current={current} />;
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [styles.entering]: entering,
                    [styles.leaving]: leaving,
                },
                className,
                contentClassName,
            ])}
        >
            <div className={styles.inner}>
                <UrbaniaButton className={styles.urbania} />
                <Body>
                    <Blocks blocks={blocks} />
                </Body>
            </div>
        </div>
    );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
