import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';

import { useAuthUser, useIsCheckingAuth } from '../hooks/useAuth';
import { useVisitor } from '../hooks/useVisitor';

import { useConsent } from './ConsentContext';

// import * as AppPropTypes from '../lib/PropTypes';

export const VisitorContext = React.createContext({
    visitorId: null,
});

export function useVisitorContext() {
    const context = useContext(VisitorContext);
    return context;
}

export function useVisitorId() {
    const { visitorId } = useVisitorContext();
    return visitorId;
}

export function useVisitorAudiences() {
    const { audiences = null } = useVisitorContext();
    return audiences;
}

const propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

const defaultProps = {
    children: null,
    disabled: false,
};

export function VisitorContextProvider({ children, disabled }) {
    const hasConsent = useConsent('analytics_storage');
    const isCheckingAuth = useIsCheckingAuth();
    const { visitorId = null, audiences = null } = useVisitor({
        enabled: hasConsent && !isCheckingAuth && !disabled,
    });

    const value = useMemo(
        () => ({
            visitorId,
            audiences,
        }),
        [visitorId, audiences],
    );

    return <VisitorContext.Provider value={value}>{children}</VisitorContext.Provider>;
}

VisitorContextProvider.propTypes = propTypes;
VisitorContextProvider.defaultProps = defaultProps;
