import Base from './Base';

class Search extends Base {
    get(search, query = null, opts = null) {
        return this.getJSONWithSession('/search', {
            q: search,
            authors_count: 3,
            ...query,
        }, opts);
    }
}

export default Search;
