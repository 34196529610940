import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function Camping({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.83 33.29" className={className}>
            <path
                fill={color}
                d="M35.32,24.74c-4.54-6.97-9.41-13.7-14.62-20.2L0,32.62l.13,.08c6.8,.47,13.29,.4,20.1,.32l.26-20.92c1.35,4.65,3.34,9.08,5.92,13.18,1.47,2.6,3.52,4.82,5.99,6.51,2.54,1.56,5.86,2.09,8.51,.73-.41-.99-1.03-1.87-1.83-2.58-1.39-1.63-2.65-3.37-3.76-5.2"
            />
            <path
                fill={color}
                d="M42.22,10.71c-1.22,.02-2.43-.18-3.58-.59-1.16-.42-2.15-1.19-2.82-2.22-.68-1.24-.96-2.65-.81-4.06,.03-.83,.21-1.65,.52-2.42,.21-.53,.52-1.01,.93-1.41-.61-.06-1.23,.04-1.79,.29-.81,.45-1.49,1.08-1.99,1.86-1.92,2.83-1.75,6.58,.4,9.24,.6,.76,1.37,1.37,2.25,1.77,.57,.23,1.17,.38,1.78,.45,1.62,.32,3.29,.09,4.76-.66,.56-.36,1.08-.77,1.55-1.23,.56-.46,1.04-1,1.42-1.61-.79,.46-1.7,.66-2.61,.59"
            />
        </svg>
    );
}

Camping.propTypes = propTypes;
Camping.defaultProps = defaultProps;

export default Camping;
