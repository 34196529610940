/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import GradientButton from '../buttons/GradientButton';
import ArrowIcon from '../icons/ArrowIcon';
import Background from '../partials/Background';
import Category from '../partials/Category';

import styles from '../../styles/headers/document-custom-header.module.css';

const propTypes = {
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    color: PropTypes.string,
    background: AppPropTypes.background,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    coverImage: null,
    categories: null,
    background: null,
    color: '#fff',
    placeholder: false,
    className: null,
};

function DocumentCustomHeader({
    categories,
    color,
    coverImage,
    background,
    placeholder,
    className,
}) {
    const url = useUrlGenerator();
    const [category = null] = categories || [];

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{
                color,
            }}
        >
            {background !== null ? (
                <Background {...background} className={styles.background} />
            ) : null}

            <img src={coverImage.url} className={classNames([styles.coverImage])} alt="icon" />

            <div className={styles.inner}>
                <div className={styles.top}>
                    {category !== null || placeholder ? (
                        <Category
                            {...category}
                            placeholder={placeholder}
                            className={styles.category}
                        />
                    ) : null}
                </div>
                <div className={styles.bottom}>
                    <GradientButton className={styles.button} theme="documentCustomHeader">
                        <ArrowIcon className={styles.arrow} />
                    </GradientButton>
                </div>
            </div>
        </div>
    );
}

DocumentCustomHeader.propTypes = propTypes;
DocumentCustomHeader.defaultProps = defaultProps;

export default DocumentCustomHeader;
