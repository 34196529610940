/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePopularDocuments } from '../../hooks/useDocuments';

import DocumentsList from '../lists/DocumentsList';
import DocumentDescription from '../typography/DocumentDescription';
import DocumentTitle from '../typography/DocumentTitle';

import styles from '../../styles/pages/not-found-page.module.css';

const propTypes = {
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
};
function NotFoundPage({ current, className, contentClassName }) {
    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                pageType: '404',
            });
        }
    }, [tracking, current]);
    return (
        <div className={classNames([styles.container, className, contentClassName])}>
            <div className={styles.inner}>
                <DocumentTitle className={styles.title}>
                    <FormattedMessage
                        defaultMessage="Cette page n’existe pas"
                        description="Page title"
                    />
                </DocumentTitle>
                <DocumentDescription className={styles.description}>
                    <FormattedMessage
                        defaultMessage="Heureusement, nous en avons d’autres."
                        description="Page title"
                    />
                </DocumentDescription>

                <DocumentsList
                    // query={{
                    //     popular: 'week',
                    // }}
                    hook={usePopularDocuments}
                    presentation="layout"
                    layout="1_4_1_4"
                    count={10}
                    cardType="horizontal"
                    cardTheme="box-translucent"
                    className={styles.list}
                    itemsClassName={styles.items}
                />
            </div>
        </div>
    );
}

NotFoundPage.propTypes = propTypes;
NotFoundPage.defaultProps = defaultProps;

export default NotFoundPage;
