import { useResizeObserver, useWindowSize, windowEventsManager } from '@folklore/hooks';
import { useEffect, useRef } from 'react';

const useScrollTrigger = ({
    disabled = false,
    triggers = [0.1, 0.25, 0.5, 0.75, 0.9, 1.0],
    onTrigger = null,
} = {}) => {
    const triggersCompletedRef = useRef([]);
    const { height } = useWindowSize();
    const {
        ref,
        entry: { contentRect = null },
    } = useResizeObserver({
        disabled,
    });
    const { top: elementTop = 0, height: elementHeight = null } = contentRect || {};
    const elementScrollHeight = elementHeight !== null ? elementHeight + elementTop : 0;

    useEffect(() => {
        if (windowEventsManager === null || disabled) {
            return () => {};
        }
        function onScroll() {
            const scrollHeight =
                ref.current !== null && elementScrollHeight > 0
                    ? elementScrollHeight - height
                    : document.documentElement.scrollHeight - height;

            const progress = Math.min(Math.max(window.scrollY / scrollHeight, 0), 1);

            const newTriggersCompleted = triggers.filter(
                (step) => progress >= step && triggersCompletedRef.current.indexOf(step) === -1,
            );

            newTriggersCompleted.forEach((step) => {
                if (onTrigger != null) {
                    onTrigger(step);
                }
            });

            if (newTriggersCompleted.length > 0) {
                triggersCompletedRef.current = [
                    ...triggersCompletedRef.current,
                    ...newTriggersCompleted,
                ];
            }
        }

        windowEventsManager.subscribe('scroll', onScroll);
        onScroll();

        return () => {
            windowEventsManager.unsubscribe('scroll', onScroll);
        };
    }, [triggers, disabled, onTrigger, height, elementScrollHeight]);
    return {
        ref,
    };
};

export default useScrollTrigger;
