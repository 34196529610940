/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import List from './List';

import styles from '../../styles/lists/grid-list.module.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    ),
    columns: PropTypes.number,
    minColumns: PropTypes.number,
    maxColumns: PropTypes.number,
    autoColumns: PropTypes.bool,
    cardWidth: PropTypes.number,
    listRef: AppPropTypes.ref,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    items: null,
    columns: null,
    minColumns: null,
    maxColumns: null,
    autoColumns: false,
    cardWidth: null,
    listRef: null,
    className: null,
    itemsClassName: null,
    itemClassName: null,
    cardClassName: null,
};

function GridList({
    items,
    columns,
    minColumns,
    maxColumns,
    autoColumns,
    cardWidth,
    listRef,
    className,
    itemsClassName,
    itemClassName,
    cardClassName,
    ...props
}) {
    const itemsCount = items !== null ? items.length : null;
    let columnsTemplate = null;
    if (columns !== null) {
        columnsTemplate = `repeat(${columns}, 1fr)`;
    } else if (autoColumns) {
        columnsTemplate = `repeat(auto-fit, minmax(${cardWidth}px, 1fr))`;
    }
    return (
        <List
            {...props}
            ref={listRef}
            items={items}
            cardWidth={cardWidth}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                {
                    [styles.min2columns]: minColumns === 2,
                    [styles.min3columns]: minColumns === 3,
                    [styles.max3columns]:
                        maxColumns === 3 || (maxColumns === 'auto' && itemsCount === 3),
                    [styles.max4columns]:
                        maxColumns === 4 || (maxColumns === 'auto' && itemsCount === 4),
                    [styles.max5columns]:
                        maxColumns === 5 || (maxColumns === 'auto' && itemsCount === 5),
                    [styles.max6columns]:
                        maxColumns === 6 || (maxColumns === 'auto' && itemsCount === 6),
                    [styles.count4]: itemsCount === 4,
                    [styles.count6]: itemsCount === 6,
                    [styles.count8]: itemsCount === 8,
                    [styles.count12]: itemsCount === 12,
                },
                itemsClassName,
            ])}
            itemsStyle={
                columnsTemplate !== null
                    ? {
                          gridTemplateColumns: columnsTemplate,
                      }
                    : null
            }
            itemClassName={classNames([
                styles.item,
                {
                    [itemClassName]: itemClassName !== null,
                },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

GridList.propTypes = propTypes;
GridList.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <GridList listRef={ref} {...props} />);
