/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useLocations from '../../hooks/useLocations';
import { getComponentFromName } from '@folklore/utils';

import LocationHorizontalCard from '../cards/LocationHorizontalCard';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/locations-list.module.css';

const CardComponents = {
    Horizontal: LocationHorizontalCard,
};

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
    className: null,
    itemsClassName: null,
    cardClassName: null,
};

function LocationsList({
    cardType,
    cardComponent,
    className,
    itemsClassName,
    cardClassName,
    ...props
}) {
    return (
        <PresentationList
            hook={useLocations}
            paginated={false}
            {...props}
            cardComponent={
                cardComponent ||
                getComponentFromName(CardComponents, cardType, LocationHorizontalCard)
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                { [itemsClassName]: itemsClassName !== null },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

LocationsList.propTypes = propTypes;
LocationsList.defaultProps = defaultProps;

export default LocationsList;
