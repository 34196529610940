import Base from './Base';

class Documents extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/documents',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    related(id, query = null, count = null, opts = null) {
        return this.getJSON(
            `/documents/${id}/related`,
            {
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    popular(query = null, count = null, opts = null) {
        return this.getJSON(
            `/documents/popular`,
            {
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    findBySlug(slug, opts = null) {
        const { snippetOnly = null } = opts || {};
        return this.getJSON(
            '/documents',
            {
                uri: slug,
                snippet_only: snippetOnly === true ? 'true' : undefined,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }

    findPreviewToken(preview, opts = null) {
        return this.getJSON(
            '/documents',
            {
                preview,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Documents;
