/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';
import { Link } from 'wouter';

// import * as AppPropTypes from '../../lib/PropTypes';
import SurtitleTypography from '../typography/Surtitle';

import styles from '../../styles/partials/surtitle.module.css';

const propTypes = {
    surtitle: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]),
    tag: PropTypes.string,
    withLink: PropTypes.bool,
    placeholder: PropTypes.bool,
    editable: PropTypes.string,
    editablePicker: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    surtitle: null,
    tag: null,
    withLink: false,
    placeholder: false,
    editable: null,
    editablePicker: null,
    className: null,
};

function Surtitle({ surtitle, tag, placeholder, withLink, editable, editablePicker, className }) {
    const { label = null, url = null } =
        isString(surtitle) || isValidElement(surtitle) ? { label: surtitle } : surtitle || {};
    if (label === null) {
        return null;
    }
    const hasLink = withLink && url !== null;
    return (
        <SurtitleTypography
            className={classNames([styles.container, className])}
            tag={tag || 'h4'}
            placeholder={placeholder}
            editable={editable}
            editablePicker={editablePicker}
        >
            {hasLink ? (
                <Link href={url} className={styles.link}>
                    {label}
                </Link>
            ) : (
                label
            )}
        </SurtitleTypography>
    );
}

Surtitle.propTypes = propTypes;
Surtitle.defaultProps = defaultProps;

export default Surtitle;
