/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/icons/brand-icon.module.css';

const propTypes = {
    type: AppPropTypes.brandHandle.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function BrandIcon({ type, className }) {
    return <span className={classNames([styles.container, styles[type], className])} />;
}

BrandIcon.propTypes = propTypes;
BrandIcon.defaultProps = defaultProps;

export default BrandIcon;
