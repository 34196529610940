import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function SkiDeFond({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.43 26.24" className={className}>
            <path
                fill={color}
                d="M16.11,4.6c.47,.23,1,.33,1.53,.28,.56,.02,1.11-.16,1.54-.52,.2-.21,.35-.47,.44-.76,.33-.88,.26-1.86-.2-2.68l-.31-.42c-.16-.2-.4-.33-.65-.36-.73-.22-1.51-.19-2.23,.06-.59,.28-1.03,.81-1.21,1.44-.17,.63-.11,1.3,.17,1.89,.19,.44,.51,.81,.92,1.05"
            />
            <path
                fill={color}
                d="M30.3,22.07c-.31-.48-.95-.61-1.43-.3-.09,.06-.17,.13-.23,.21-.11,.19-.21,.4-.29,.61-.57,.96-1.6,1.54-2.71,1.53-1.09,.07-2.17-.17-3.26-.15l-7.65,.33c-3.11,.05-9.98-.2-13.08-.01-.39,0-.78,.08-1.13,.25-.36,.18-.56,.57-.51,.96,.14,.61,.93,.74,1.55,.74,4.91,0,13.58-.03,18.49-.02,1.22,0,2.44,0,3.66,0,.91,.02,1.82-.05,2.72-.23,1.31-.31,2.49-1.02,3.37-2.04,.53-.47,.72-1.21,.49-1.87"
            />
            <path
                fill={color}
                d="M7.23,9.75c.69,.28,1.87-.78,2.49-1.37,.67-.63,2.18-1.8,2.83-1.09,.22,.24,.1,.63-.01,.93-.45,1.17-1.09,2.28-1.5,3.47,0,0-1.13,3.05-1.49,4.25-.19,.65-.38,1.41-.63,2.04-.53,1.31-.93,2.67-1.18,4.07-.6,2.02,2.63,2.07,2.97,.65,.2-1.26,.52-2.5,.96-3.7,.31-.91,.8-1.82,1.12-2.73,1.19,.85,2.59,1.5,3.77,2.38,.11,.07,.2,.16,.25,.28,.04,.15,.03,.31-.03,.45-.36,.99-.6,2.01-.73,3.06-.13,1.65,2.56,1.86,3.18-.1,.28-1,.41-1.47,.69-2.47,.22-.68,.27-1.4,.15-2.1-.3-.68-.81-1.23-1.47-1.58l-2.57-1.76c.22-1.55,.65-3.07,1.28-4.5,.4,1.14,1.06,2.16,1.93,3,.09,.11,.2,.21,.33,.29,.16,.08,.34,.12,.52,.12,.75,.06,1.5,.06,2.25,0-.05,2.81-.11,5.62-.16,8.43-.43-.08-1.19,.28-1.18,.71l1.03,.23c.07,0,.13,.04,.18,.08,.05,.05,.08,.12,.09,.18,.05,.2,.11,.4,.16,.6,.04-.28,.21-.53,.46-.67,.61-.27,.04-1.05,.04-1.05-.07-.16-.1-.34-.06-.51,.29-2.24,.4-4.51,.33-6.77,0-.44,0-.88,.02-1.31,.18-.02,.36-.05,.54-.08,.48-.02,.92-.26,1.19-.64,.19-.41,.17-.89-.05-1.29-.37-.32-.84-.48-1.33-.46h-.29c.05-.32,.03-.62-.07-.91-.08-.18-.21-.18-.47-.19-.1,0-.19,.03-.28,.07-.09,.07-.07,.24-.08,.35,0,.21,0,.43-.01,.64-.31,0-.62,0-.93,0-.13,.02-.27,0-.39-.06-.1-.07-.17-.17-.21-.28-.37-1.13-.82-2.23-1.35-3.29-.98-1.43-1.8-1.69-3.48-2.12-1.61-.57-3.36-.66-5.02-.25-.58,.22-1.13,.52-1.64,.88-.86,.59-1.66,1.26-2.39,2l-.38,.38c-1.02,.84-.17,1.41,.62,1.74"
            />
            <path
                fill={color}
                d="M4.26,22.8c.26,.13,.3,.47,.31,.76l.28-.55c.03-.06,.07-.12,.12-.16,.06-.03,.13-.04,.19-.04,.35,0,.48-.94,.05-.96,.76-3.81,1.51-7.63,2.27-11.44,.02-.11,.07-.27,0-.36-.07-.06-.16-.11-.26-.13-.26-.05-.39-.07-.5,.08-.16,.26-.25,.56-.26,.86l-.09,.55c-.23,1.06-.41,2.12-.64,3.18-.55,2.19-.92,4.43-1.12,6.68,0,.16-.04,.32-.14,.44-.08,.08-.51,.11-.61,.15-.16,.05-.3,.15-.4,.28-.1,.14-.1,.32,0,.46,.18,.21,.54,.07,.79,.19"
            />
        </svg>
    );
}

SkiDeFond.propTypes = propTypes;
SkiDeFond.defaultProps = defaultProps;

export default SkiDeFond;
