/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#fff',
    circleColor: '#222',
    className: null,
};

function CloseIcon({ color, circleColor, className }) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="20" cy="20" r="20" fill={circleColor} />
            <path d="M12 12L28 28" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M12 28L28 12" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
