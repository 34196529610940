/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-restricted-globals */
import { useRoutes } from '@folklore/routes';
import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'wouter';

import AuthorMetadata from './metadata/AuthorMetadata';
import CategoryMetadata from './metadata/CategoryMetadata';
import CollectionMetadata from './metadata/CollectionMetadata';
import DocumentMetadata from './metadata/DocumentMetadata';
import FormatMetadata from './metadata/FormatMetadata';
import HomeMetadata from './metadata/HomeMetadata';
import MapMetadata from './metadata/MapMetadata';
import Metadata from './metadata/Metadata';
import NotfoundMetadata from './metadata/NotfoundMetadata';
import PageMetadata from './metadata/PageMetadata';
import SubscribeMetadata from './metadata/SubscribeMetadata';
import TopicMetadata from './metadata/TopicMetadata';

const propTypes = {};

const defaultProps = {};

function MetadataRoutes() {
    const intl = useIntl();
    const routes = useRoutes();
    return (
        <Switch>
            <Route path={routes.document}>
                <DocumentMetadata />
            </Route>
            <Route path={routes.preview}>
                <DocumentMetadata isPreview />
            </Route>
            <Route path={routes.collection}>
                <CollectionMetadata />
            </Route>
            <Route path={routes.author}>
                <AuthorMetadata />
            </Route>
            <Route path={routes.category}>
                <CategoryMetadata />
            </Route>
            <Route path={routes.topic}>
                <TopicMetadata />
            </Route>
            <Route path={routes.format}>
                <FormatMetadata />
            </Route>
            <Route path={routes.articles}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Articles',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes.videos}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Vidéos',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes.micromags}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Micromags',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes.podcasts}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Podcasts',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes.collections}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Collections',
                        description: 'Page title',
                    })}
                />
            </Route>

            <Route path={routes.map}>
                <MapMetadata />
            </Route>
            <Route path={routes['map.region']}>
                <MapMetadata />
            </Route>
            <Route path={routes['map.locationType']}>
                <MapMetadata />
            </Route>
            <Route path={routes['map.location']}>
                <MapMetadata />
            </Route>
            <Route path={routes['map.document']}>
                <MapMetadata />
            </Route>

            <Route path={routes.login}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Connexion',
                        description: 'Page title',
                    })}
                />
            </Route>

            <Route path={routes.register}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Inscription',
                        description: 'Page title',
                    })}
                />
            </Route>

            <Route path={routes.account}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Compte',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes['account.subscriptions']}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Abonnements - Compte',
                        description: 'Page title',
                    })}
                />
            </Route>
            <Route path={routes['account.timeline']}>
                <Metadata
                    title={intl.formatMessage({
                        defaultMessage: 'Historique - Compte',
                        description: 'Page title',
                    })}
                />
            </Route>

            <Route path={routes.subscription}>
                <SubscribeMetadata />
            </Route>

            <Route path={routes.subscribe}>
                <SubscribeMetadata />
            </Route>

            <Route path={routes['home.wildcard']}>
                <HomeMetadata />
            </Route>
            <Route path={routes.page}>
                <PageMetadata />
            </Route>
            <Route>
                <NotfoundMetadata />
            </Route>
        </Switch>
    );
}

MetadataRoutes.propTypes = propTypes;
MetadataRoutes.defaultProps = defaultProps;

export default MetadataRoutes;
