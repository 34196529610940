/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import arrowImage from '../../assets/img/icons/arrow.svg';

const propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    alt: 'Retour',
    className: null,
};

function ArrowIcon({ alt, className }) {
    return <img src={arrowImage} className={className} alt={alt} />;
}

ArrowIcon.propTypes = propTypes;
ArrowIcon.defaultProps = defaultProps;

export default ArrowIcon;
