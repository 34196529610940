import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateConsent } from '../../contexts/ConsentContext';
import { usePopupsContext } from '../../contexts/PopupsContext';
import BasicButton from '../buttons/BasicButton';
import IdentifyForm from '../forms/IdentifyForm';
import LineSpacer from '../partials/LineSpacer';
import ModalDescription from '../typography/ModalDescription';
import ModalTitle from '../typography/ModalTitle';
import Dialog from './Dialog';
import Modal from './Modal';

import styles from '../../styles/popups/consent-modal.module.css';

const propTypes = {
    brand: PropTypes.string,
    className: PropTypes.string,
    onClosed: PropTypes.func,
};

const defaultProps = {
    brand: null,
    className: null,
    onClosed: null,
};

function ConsentModal({ brand, className, onClosed }) {
    const [opened, setOpened] = useState(true);
    const tracking = useTracking();
    const updateConsent = useUpdateConsent();
    const [fieldHasValue, setFieldHasValue] = useState(null);
    const { addMessage } = usePopupsContext();
    const formRef = useRef(null);

    const consent = useCallback(
        (type) => {
            updateConsent(true);
            setOpened(false);
            tracking.trackEventNow('Consent', 'given', type);
        },
        [updateConsent, setOpened, tracking],
    );

    const onClickAccept = useCallback(() => {
        if (fieldHasValue) {
            formRef.current.submit();
            return;
        }
        consent('accept');
    }, [fieldHasValue, consent]);

    const onIdentify = useCallback(
        ({ user = null, isNew = false }) => {
            consent('identify');
            addMessage('hi', {
                isNew,
                user,
            });
        },
        [consent, addMessage],
    );

    const onClickSso = useCallback(
        (type) => {
            updateConsent(true);
            tracking.trackEventNow('Consent', 'given', `sso_${type}`);
        },
        [tracking],
    );

    const onFormChange = useCallback(
        (newFormValue) => {
            const { field = null } = newFormValue || {};
            setFieldHasValue(field !== null && field.length > 0);
        },
        [setFieldHasValue],
    );

    useEffect(() => {
        tracking.trackEventNow('Consent', 'impression', 'modal');
    }, []);

    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    return (
        <Modal
            id="consent"
            opened={opened}
            withBackdrop
            withTransition
            withScrollLock
            onRequestClose={onRequestClose}
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles[brand]]: brand !== null },
            ])}
            onClosed={onClosed}
        >
            <Dialog className={styles.dialog}>
                <div className={styles.content}>
                    <ModalTitle className={styles.title}>
                        <FormattedMessage
                            defaultMessage="Psst! Avant de continuer,{br}on a un secret pour vous…"
                            description="Consent modal title"
                        />
                    </ModalTitle>
                    <ModalDescription className={styles.description}>
                        <FormattedMessage
                            defaultMessage="<p><strong>Comme URBANIA est un média indépendant et gratuit, on dépend des revenus publicitaires. </strong></p><p>En nous parlant un peu de vous, vous nous aidez à vous proposer des contenus adaptés à vos intérêts et continuer d’exister. C’est donc un vote pour le contenu local et la qualité!</p>"
                            description="Consent modal description"
                        />
                    </ModalDescription>
                </div>
                <IdentifyForm
                    ref={formRef}
                    source="consent-modal"
                    className={styles.form}
                    onComplete={onIdentify}
                    onChange={onFormChange}
                    onClickSso={onClickSso}
                />
                <LineSpacer className={styles.spacer}>
                    <FormattedMessage defaultMessage="ou" description="Spacer label" />
                </LineSpacer>
                <ModalDescription
                    tag="div"
                    className={classNames([styles.description, styles.quiet])}
                >
                    <FormattedMessage
                        defaultMessage="<p>Vous voulez rester incognito? Alright! Acceptez simplement les cookies pour accéder au contenu de notre site.</p>"
                        description="Consent modal description"
                    />
                </ModalDescription>
                <BasicButton className={styles.button} onClick={onClickAccept}>
                    <FormattedMessage
                        defaultMessage="Accepter les cookies et continuer"
                        description="Spacer label"
                    />
                </BasicButton>
            </Dialog>
        </Modal>
    );
}

ConsentModal.propTypes = propTypes;
ConsentModal.defaultProps = defaultProps;

export default ConsentModal;
