/* eslint-disable react/jsx-props-no-spreading */
import { Editable } from '@niche-js/core/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'wouter';

import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';
import { getTrackingQuery } from '../../lib/utils';

import AuthorNameTypography from '../typography/AuthorName';
import AuthorImage from './AuthorImage';
import PlaceholderText from './PlaceholderText';

import styles from '../../styles/partials/author.module.css';

const propTypes = {
    slug: PropTypes.string,
    clickRef: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: AppPropTypes.image,
    role: PropTypes.string,
    prefix: PropTypes.node,
    isPerson: PropTypes.bool,
    placeholder: PropTypes.bool,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    imagePosition: PropTypes.oneOf(['left', 'right']),
    withoutImage: PropTypes.bool,
    withRole: PropTypes.bool,
    withBox: PropTypes.bool,
    withLink: PropTypes.bool,
    editable: PropTypes.string,
    imageClassName: PropTypes.string,
    initialsClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    nameClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    clickRef: null,
    image: null,
    role: null,
    prefix: null,
    isPerson: true,
    placeholder: false,
    imageWidth: null,
    imageHeight: null,
    imagePosition: 'left',
    withoutImage: false,
    withRole: false,
    withBox: false,
    withLink: false,
    editable: null,
    imageClassName: null,
    initialsClassName: null,
    labelClassName: null,
    nameClassName: null,
    className: null,
};

function Author({
    slug,
    clickRef,
    name,
    image,
    role,
    prefix,
    isPerson,
    placeholder,
    imageWidth,
    imageHeight,
    imagePosition,
    withoutImage,
    withRole,
    withBox,
    withLink,
    editable,
    nameClassName,
    imageClassName,
    initialsClassName,
    labelClassName,
    className,
}) {
    if (!isPerson) {
        return null;
    }

    const url = useAuthorUrlGenerator();

    const isEditable = editable !== null;
    let Component = 'div';
    if (isEditable) {
        Component = Editable;
    } else if (withLink) {
        Component = Fragment;
    }

    if (!isEditable && withoutImage && !withRole && !withLink) {
        return (
            <AuthorNameTypography
                className={classNames([styles.container, className, nameClassName])}
                placeholder={placeholder}
            >
                {name}
            </AuthorNameTypography>
        );
    }

    const innerClassName = classNames([
        styles.container,
        {
            [styles.right]: imagePosition === 'right',
            [styles.withBox]: withBox,
            [styles.withoutImage]: image === null,
        },
        className,
    ]);

    const inner = (
        <Component
            className={!withLink ? innerClassName : null}
            {...(isEditable ? { editable, editablePicker: 'credits' } : null)}
        >
            {prefix !== null && !withoutImage && image !== null ? (
                <AuthorNameTypography className={styles.prefix}>{prefix}</AuthorNameTypography>
            ) : null}

            {!withoutImage ? (
                <AuthorImage
                    name={name}
                    slug={slug}
                    media={image}
                    size="thumbnail"
                    width={imageWidth}
                    height={imageHeight}
                    withoutDefault
                    className={classNames([styles.image, imageClassName])}
                    initialsClassName={initialsClassName}
                />
            ) : null}
            <div className={classNames([styles.label, labelClassName])}>
                {prefix !== null && (withoutImage || image === null) ? (
                    <AuthorNameTypography className={styles.prefix}>{prefix}</AuthorNameTypography>
                ) : null}
                {withRole && (role !== null || placeholder) ? (
                    <div className={styles.role}>{placeholder ? <PlaceholderText /> : role}</div>
                ) : null}

                <AuthorNameTypography className={classNames([styles.name, nameClassName])} placeholder={placeholder}>
                    {name}
                </AuthorNameTypography>
            </div>
        </Component>
    );

    return withLink ? (
        <Link
            href={
                url(
                    {
                        slug,
                    },
                    getTrackingQuery({ clickRef }),
                ) || '#'
            }
            className={innerClassName}
        >
            {inner}
        </Link>
    ) : (
        inner
    );
}

Author.propTypes = propTypes;
Author.defaultProps = defaultProps;

export default Author;
