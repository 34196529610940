import { useSpring, animated, useSpringRef, useChain } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { useSubscriptions } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import Image from '../partials/Image';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/cards/promotion-button-card.module.css';

const propTypes = {
    title: PropTypes.string,
    image: AppPropTypes.image,
    callToAction: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
    }),
    theme: PropTypes.string,
    withTransition: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    image: null,
    callToAction: null,
    theme: null,
    withTransition: false,
    className: null,
    innerClassName: null,
};

function PromotionButtonCard({
    title,
    image,
    callToAction,
    theme,
    withTransition,
    className,
    innerClassName,
}) {
    const subscriptions = useSubscriptions();
    const { label = null, url = null } = callToAction || {};
    const { image: subscriptionImage } =
        subscriptions.find(({ handle }) => handle === 'notification_urbania') || {};
    const imageSpringRef = useSpringRef();
    const imageSpring = useSpring({
        ref: imageSpringRef,
        from: {
            scale: 0,
        },
        to: {
            scale: 1,
        },
    });
    const labelSpringRef = useSpringRef();
    const labelSpring = useSpring({
        ref: labelSpringRef,
        from: {
            scaleX: 0,
        },
        to: {
            scaleX: 1,
        },
    });
    useChain([imageSpringRef, labelSpringRef], [0, 0.6]);
    return (
        <BasicButton
            href={url}
            className={classNames([
                styles.container,
                {
                    [styles.hasImage]: image !== null,
                },
                className,
            ])}
            data-theme={theme}
        >
            <span className={classNames([styles.inner, innerClassName])}>
                <animated.span className={styles.label} style={withTransition ? labelSpring : null}>
                    <PromotionTitle className={styles.title}>{title || label}</PromotionTitle>
                </animated.span>
                <span className={styles.imageContainer}>
                    <animated.span
                        className={styles.imageFrame}
                        style={withTransition ? imageSpring : null}
                    >
                        <Image media={image || subscriptionImage} className={styles.image} />
                    </animated.span>
                </span>
            </span>
        </BasicButton>
    );
}

PromotionButtonCard.propTypes = propTypes;
PromotionButtonCard.defaultProps = defaultProps;

export default PromotionButtonCard;
