/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useCategories from '../../hooks/useCategories';
import { getComponentFromName } from '@folklore/utils';

import * as CardComponents from '../cards/taxonomies';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/categories-list.module.css';

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
    className: null,
    cardClassName: null,
};

function CategoriesList({ cardType, cardComponent, className, cardClassName, ...props }) {
    return (
        <PresentationList
            hook={useCategories}
            {...props}
            cardComponent={
                cardComponent ||
                getComponentFromName(CardComponents, cardType, CardComponents.Vertical)
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

CategoriesList.propTypes = propTypes;
CategoriesList.defaultProps = defaultProps;

export default CategoriesList;
