/* eslint-disable react/jsx-props-no-spreading */
import { useChain, useSpring, useSpringRef, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import CallToAction from '../partials/CallToAction';
import Image from '../partials/Image';
import PromotionDescription from '../typography/PromotionDescription';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/cards/promotion-card.module.css';

const propTypes = {
    image: AppPropTypes.image,
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf([null, 'image-popout']),
    callToAction: PropTypes.node,
    withTransition: PropTypes.bool,
    invert: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    callToActionClassName: PropTypes.string,
};

const defaultProps = {
    image: null,
    url: null,
    title: null,
    description: null,
    theme: null,
    callToAction: null,
    withTransition: false,
    invert: false,
    children: null,
    className: null,
    innerClassName: null,
    imageClassName: null,
    pictureClassName: null,
    contentClassName: null,
    titleClassName: null,
    descriptionClassName: null,
    callToActionClassName: null,
};

function PromotionCard({
    image,
    url,
    title,
    description,
    theme,
    callToAction,
    withTransition,
    invert,
    children,
    className,
    innerClassName,
    imageClassName,
    pictureClassName,
    contentClassName,
    titleClassName,
    descriptionClassName,
    callToActionClassName,
}) {
    // const containerSpringRef = useSpringRef();
    const containerSpring = useSpring({
        // ref: containerSpringRef,
        from: {
            scale: 0,
        },
        to: {
            scale: 1,
        },
    });

    const label = (
        <>
            {title !== null ? (
                <PromotionTitle
                    className={classNames([styles.title, titleClassName])}
                    html={title}
                />
            ) : null}
            {description !== null ? (
                <PromotionDescription
                    className={classNames([styles.description, descriptionClassName])}
                    html={description}
                />
            ) : null}
        </>
    );

    const imageElement =
        image !== null ? (
            <Image
                media={image}
                className={classNames([styles.image, imageClassName])}
                pictureClassName={classNames([styles.picture, pictureClassName])}
            />
        ) : null;

    return (
        <animated.div
            className={classNames([
                styles.container,
                {
                    [styles.invert]: invert,
                    [styles.hasImage]: image !== null,
                },
                className,
            ])}
            data-theme={theme}
            style={withTransition ? containerSpring : null}
        >
            <div className={classNames([styles.inner, innerClassName])}>
                {image !== null && url !== null ? (
                    <a href={url} target="_blank" rel="noreferrer" className={styles.link}>
                        {imageElement}
                    </a>
                ) : (
                    imageElement
                )}
                <animated.div className={classNames([styles.content, contentClassName])}>
                    {url !== null ? (
                        <a href={url} target="_blank" rel="noreferrer" className={styles.link}>
                            {label}
                        </a>
                    ) : (
                        label
                    )}
                </animated.div>
                {callToAction !== null ? (
                    <div className={classNames([styles.callToAction, callToActionClassName])}>
                        {isValidElement(callToAction) ? (
                            callToAction
                        ) : (
                            <CallToAction url={url} {...callToAction} />
                        )}
                    </div>
                ) : null}
                {children}
            </div>
        </animated.div>
    );
}

PromotionCard.propTypes = propTypes;
PromotionCard.defaultProps = defaultProps;

export default PromotionCard;
