/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useLocationTypes from '../../hooks/useLocationTypes';
import { getComponentFromName } from '@folklore/utils';

import LocationTypeHorizontalCard from '../cards/LocationTypeHorizontalCard';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/location-types-list.module.css';

const CardComponents = {
    Horizontal: LocationTypeHorizontalCard,
};

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
    className: null,
    itemsClassName: null,
};

function LocationTypesList({ cardType, cardComponent, className, itemsClassName, ...props }) {
    return (
        <PresentationList
            hook={useLocationTypes}
            paginated={false}
            {...props}
            cardComponent={
                cardComponent ||
                getComponentFromName(CardComponents, cardType, LocationTypeHorizontalCard)
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                { [itemsClassName]: itemsClassName !== null },
            ])}
        />
    );
}

LocationTypesList.propTypes = propTypes;
LocationTypesList.defaultProps = defaultProps;

export default LocationTypesList;
