import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTransition, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from '../../styles/partials/form-status.module.css';

const propTypes = {
    status: PropTypes.string,
    successTimeout: PropTypes.number,
    successMessage: PropTypes.node,
    iconRight: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    status: null,
    successTimeout: 2000,
    successMessage: null,
    iconRight: false,
    className: null,
};

function FormStatus({
    status: providedStatus,
    successTimeout,
    successMessage,
    iconRight,
    className,
}) {
    const [status, setStatus] = useState(providedStatus);
    const transitions = useTransition(status !== null ? [status] : [], {
        from: { opacity: 0, scale: 0.5 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.5 },
        keys: (item) => item,
    });
    useEffect(() => {
        setStatus(providedStatus);
    }, [providedStatus]);
    useEffect(() => {
        let timeout = null;
        if (status === 'success') {
            timeout = setTimeout(() => {
                setStatus(null);
            }, successTimeout);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [status, successTimeout]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.iconRight]: iconRight,
                },
                className,
            ])}
        >
            {transitions((style, currentStatus) => {
                let icon = null;
                if (currentStatus === 'loading') {
                    icon = faCircleNotch;
                } else if (currentStatus === 'success') {
                    icon = faCircleCheck;
                } else if (currentStatus === 'error') {
                    icon = faCircleExclamation;
                }
                return (
                    <animated.div style={style} className={styles.status}>
                        {icon !== null ? (
                            <FontAwesomeIcon
                                icon={icon}
                                spin={currentStatus === 'loading'}
                                className={styles.icon}
                            />
                        ) : null}
                        {currentStatus === 'success' && successMessage !== null ? (
                            <div className={styles.message}>{successMessage}</div>
                        ) : null}
                    </animated.div>
                );
            })}
        </div>
    );
}

FormStatus.propTypes = propTypes;
FormStatus.defaultProps = defaultProps;

export default FormStatus;
