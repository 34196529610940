import Base from './Base';

class Taxonomies extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/taxonomies',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    brands(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/brands',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    categories(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/categories',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    tags(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/tags',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    topics(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/topics',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    formats(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/formats',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }
}

export default Taxonomies;
