import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import { useBrand, useDefaultBrand, useSite } from '../../contexts/SiteContext';
import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function SubscribeMetadata() {
    const { brand: brandParam = null } = useParams();
    const defaultBrand = useDefaultBrand();
    const brand = useBrand(brandParam);
    const finalBrand = brandParam !== null ? brand : defaultBrand;
    const { label: brandLabel, handle: brandHandle } = finalBrand || {};
    const { subscriptions } = useSite();
    const { description } = subscriptions.find(({ handle }) => handle === brandHandle) || {};
    const url = useUrlGenerator();
    return (
        <Metadata
            brand={finalBrand}
            title={`Abonnez-vous à ${brandLabel}`}
            withoutTitleSuffix
            description={description}
            url={url('subscribe', {
                brand: brandParam,
            })}
        />
    );
}

SubscribeMetadata.propTypes = propTypes;
SubscribeMetadata.defaultProps = defaultProps;

export default SubscribeMetadata;
