import Base from './Base';

class Pages extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/pages',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    findBySlug(slug, opts = null) {
        return this.getJSON(
            '/pages',
            {
                uri: slug,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }

    findPreviewToken(preview, opts = null) {
        return this.getJSON(
            '/pages',
            {
                preview,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Pages;
