/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useDocuments from '../../hooks/useDocuments';

import * as CardComponents from '../cards/documents';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/documents-list.module.css';

const propTypes = {
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    itemsClassName: PropTypes.string,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    type: null,
    query: null,
    cardComponent: null,
    cardType: 'vertical',
    itemsClassName: null,
    className: null,
    cardClassName: null,
};

function DocumentsList({
    type,
    query,
    cardType,
    cardComponent,
    itemsClassName,
    className,
    cardClassName,
    ...props
}) {
    const finalQuery = useMemo(
        () =>
            type !== null
                ? {
                      type,
                      ...query,
                  }
                : query,
        [type, query],
    );
    const placeholderItem = useMemo(
        () => ({
            type: finalQuery !== null ? finalQuery.type || null : null,
        }),
        [finalQuery],
    );

    return (
        <PresentationList
            hook={useDocuments}
            query={finalQuery}
            placeholderItem={placeholderItem}
            {...props}
            cardComponent={
                cardComponent ||
                getComponentFromName(CardComponents, cardType, CardComponents.Vertical)
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([styles.items, itemsClassName])}
            rowClassName={styles.row}
            cardClassName={classNames([
                styles.card,
                {
                    [styles[type]]: typeof styles[type] !== 'undefined',
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

DocumentsList.propTypes = propTypes;
DocumentsList.defaultProps = defaultProps;

export default DocumentsList;
