/* eslint-disable react/jsx-no-useless-fragment */
import camelCase from 'lodash/camelCase';
import React, { useEffect, useState } from 'react';
import { useParams } from 'wouter';

import useWindowSize from '../../hooks/useWindowSize';

import { useBrands } from '../../contexts/SiteContext';

const propTypes = {};

const defaultProps = {};

function PreloadBumper() {
    const brands = useBrands();
    const { width: windowWidth = null, height: windowHeight = null } = useWindowSize();
    const hasSize =
        windowWidth !== null && windowWidth > 0 && windowHeight !== null && windowHeight > 0;
    const { slug: brandSlug = null } = useParams();
    const brand = brands.find(
        ({ slug, isDefault = false }) => slug === brandSlug || (brandSlug === null && isDefault),
    );
    const { videos = null } = brand || {};
    const mode = windowWidth > windowHeight ? 'horizontal' : 'vertical';
    const [video, setVideo] = useState(null);
    useEffect(() => {
        setVideo(hasSize && videos != null ? videos[camelCase(`bumper_${mode}`)] : null);
    }, [hasSize, videos, mode])
    return (video !== null ? video.sources : [])
        .filter(({ mime = '' }) => mime.match(/^video\//) !== null)
        .map(({ url, mime }) => <link rel="preload" href={url} type={mime} key={url} as="video" />);
}

PreloadBumper.propTypes = propTypes;
PreloadBumper.defaultProps = defaultProps;

export default PreloadBumper;
