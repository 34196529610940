/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useDocuments from '../../hooks/useDocuments';
import * as AppPropTypes from '../../lib/PropTypes';

import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';
import Topics from '../partials/Topics';

import styles from '../../styles/headers/featured-header.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    withBrandLogo: PropTypes.bool,
    withTopics: PropTypes.string,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
};

const defaultProps = {
    brand: null,
    query: null,
    withBrandLogo: false,
    withTopics: false,
    className: null,
    innerClassName: null,
};

function FeaturedHeader({
    brand,
    withBrandLogo,
    withTopics,
    query,
    className,
    innerClassName,
    ...props
}) {
    const { handle: brandHandle = null } = brand || {};
    const { items = null } = useDocuments({
        count: 1, // featured + overCount
        ...query,
    });
    const [featuredItem = null] = items || [];

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.withTopics]: withTopics === true,
                    [className]: className !== null,
                },
            ])}
        >
            {withTopics === true ? (
                <Topics className={styles.topics} buttonClassName={styles.button} />
            ) : null}

            <div className={classNames([styles.inner, innerClassName])}>
                <DocumentFeaturedCard
                    {...featuredItem}
                    placeholder={featuredItem === null}
                    theme="over-bottom-left"
                    className={styles.featuredItem}
                    withoutBorderRadius
                    withBreakpoints
                    {...props}
                />
            </div>
        </div>
    );
}

FeaturedHeader.propTypes = propTypes;
FeaturedHeader.defaultProps = defaultProps;

export default FeaturedHeader;
