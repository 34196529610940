/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/fields/select-field.module.css';

const propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
    value: PropTypes.string,
    placeholder: PropTypes.node,
    errors: AppPropTypes.formErrors,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    name: null,
    options: null,
    value: null,
    placeholder: null,
    errors: null,
    withBorder: false,
    className: null,
    onChange: null,
};

function SelectField({
    options,
    name,
    value,
    placeholder,
    errors,
    withBorder,
    onChange,
    className,
}) {
    const internalRef = useRef(null);
    const onInputChange = useCallback(
        (e) => {
            internalRef.current.setCustomValidity('');
            if (onChange !== null) {
                onChange(e.currentTarget.value);
            }
        },
        [onChange],
    );
    useEffect(() => {
        internalRef.current.setCustomValidity(
            errors !== null && errors.length > 0 ? errors[0] : '',
        );
        if (errors !== null && errors.length > 0) {
            internalRef.current.reportValidity();
        }
    }, [errors]);
    return (
        <select
            ref={internalRef}
            name={name}
            value={value || ''}
            placeholder={placeholder}
            onChange={onInputChange}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
        >
            {(options || []).map(({ label, value: optionValue }, index) => (
                <option key={`option-${index + 1}`} value={optionValue}>
                    {label}
                </option>
            ))}
        </select>
    );
}

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
