/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import ToggleIcon from '../icons/ToggleIcon';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/via-button.module.css';

const propTypes = {
    via: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    loading: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    via: false,
    value: null,
    loading: false,
    className: null,
    onChange: null,
};

function ViaButton({ via, value, loading, className, onChange }) {
    const onClick = useCallback(
        (newValue) => {
            if (onChange !== null) {
                onChange(newValue);
            }
        },
        [onChange],
    );
    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.control}>
                <div className={styles.methods}>
                    {via.map((method) => (
                        <BasicButton
                            className={classNames([
                                styles.method,
                                {
                                    [styles.active]: value === method,
                                },
                            ])}
                            onClick={() => onClick(method)}
                        >
                            {method}
                        </BasicButton>
                    ))}
                </div>
            </div>
        </div>
    );
}

ViaButton.propTypes = propTypes;
ViaButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default ViaButton;
