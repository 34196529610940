/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, react/no-array-index-key, react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useWindowScroll } from '@folklore/hooks';
import { useRoutes, useUrlGenerator, useRouteMatcher } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'wouter';

import useBrandFromLocation from '../../hooks/useBrandFromLocation';
import useCurrentUrl from '../../hooks/useCurrentUrl';
import useDocument from '../../hooks/useDocument';
import useElementSize from '../../hooks/useElementSize';
import useNavigationTimeline from '../../hooks/useNavigationTimeline';
import useRoutesMatch from '../../hooks/useRoutesMatch';
import useStableWindowHeight from '../../hooks/useStableWindowHeight';
import { useMutateTimeline } from '../../hooks/useTimeline';
import useWindowSize from '../../hooks/useWindowSize';
import { isInInteractiveElement } from '../../lib/isInElement';
import useScrollTracking from '../../lib/useScrollTracking';

import useSidebarSpring from '../../animations/useSidebarSpring';
import useTimelineSpring from '../../animations/useTimelineSpring';
import { useConsent } from '../../contexts/ConsentContext';
import { IdleContextProvider } from '../../contexts/IdleContext';
import { LayoutContextProvider } from '../../contexts/LayoutContext';
import {
    MODAL_NAMESPACE,
    NOTIFICATION_NAMESPACE,
    useHasModals,
} from '../../contexts/PopupsContext';
import { useDefaultBrand } from '../../contexts/SiteContext';
import { useVisitorId } from '../../contexts/VisitorContext';
import PageRoutes from '../PageRoutes';
import BumperAd from '../ads/BumperAd';
import AccountButton from '../buttons/AccountButton';
import BrandLogoButton from '../buttons/BrandLogoButton';
import MenuButton from '../buttons/MenuButton';
import MessagesContainer from '../popups/MessagesContainer';
import PopupsContainer from '../popups/PopupsContainer';
import PromotionsPopups from '../popups/PromotionsPopups';

import styles from '../../styles/layouts/timeline-layout.module.css';

const MainMenu = React.lazy(() => import('../menus/MainMenu'));

const propTypes = {
    minimumContentWidth: PropTypes.number,
    neighborPagesActive: PropTypes.number,
    loadNextDelay: PropTypes.number,
};

const defaultProps = {
    minimumContentWidth: 600,
    neighborPagesActive: 1,
    loadNextDelay: 500,
};

function TimelineLayout({ minimumContentWidth, neighborPagesActive, loadNextDelay }) {
    const routes = useRoutes();
    const routeMatcher = useRouteMatcher();
    const url = useUrlGenerator();
    const user = useUser();
    const tracking = useTracking();
    const isImmersive = useRoutesMatch([routes.micromag, routes.video]);
    const hasModals = useHasModals();

    const currentUrl = useCurrentUrl();
    const visitorId = useVisitorId();
    const { mutate: mutateTimeline } = useMutateTimeline();
    const hasPersonalizationConsent = useConsent('personalization_storage');
    const withoutTimeline = useRoutesMatch([routes.preview]);
    useEffect(() => {
        if (visitorId !== null && hasPersonalizationConsent && !withoutTimeline) {
            mutateTimeline(currentUrl);
        }
    }, [mutateTimeline, currentUrl, visitorId, hasPersonalizationConsent, withoutTimeline]);

    const {
        location,
        timeline,
        scroll: timelineScroll,
        index: timelineIndex,
        setIndex: setTimelineIndex,
    } = useNavigationTimeline({
        loadNextDelay,
    });
    const currentLocation = timeline[timelineIndex];
    const navigating = currentLocation !== location;
    const { brand: finalBrand, loaded: brandLoaded } = useBrandFromLocation(currentLocation);
    const [, documentRouteParams] = routeMatcher(routes.document, currentLocation);
    const { slug: documentParam = null } = documentRouteParams || {};

    const { document = null, isFetching: documentIsFetching = false } = useDocument(documentParam, {
        suspense: false,
        enabled: documentParam !== null,
        // keepPreviousData: false,
        placeholderData: null,
    });
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const { type: documentType = null } = document || {};
    const { handle: brandHandle = document !== null ? defaultBrandHandle : null } =
        finalBrand || {};
    const [globalBrand, setGlobalBrand] = useState(brandLoaded ? finalBrand : null);

    const withoutLogoInHeader = useRoutesMatch([routes.home]);

    const isNotDezoomable =
        useRoutesMatch([
            routes['account.wildcard'],
            routes['map.wildcard'],
            routes.login,
            routes['auth.wildcard'],
        ]) || documentType === 'micromag';

    const initialZoomedIn =
        useRoutesMatch([
            routes['map.wildcard'],
            routes.login,
            routes['auth.wildcard'],
            routes['account.wildcard'],
            routes.micromag,
        ]) || withoutLogoInHeader;

    useEffect(() => {
        if (brandLoaded && finalBrand !== null) {
            setGlobalBrand(finalBrand);
        }
    }, [brandLoaded, finalBrand]);

    const [bumperAdActive, setBumperAdActive] = useState(false);
    const [bumperAdPlaying, setBumperAdPlaying] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);
    const openMenu = useCallback(() => setMenuOpened(true), [setMenuOpened]);
    const closeMenu = useCallback(() => setMenuOpened(false), [setMenuOpened]);
    const onClickMenu = useCallback(() => {
        if (menuOpened) {
            closeMenu();
        } else {
            openMenu();
        }
        tracking.trackEvent('Navigation', 'click_menu', menuOpened ? 'close' : 'open');
    }, [menuOpened, openMenu, tracking]);
    const onClickAccount = useCallback(() => {
        tracking.trackEvent('Navigation', 'click_account');
    }, [tracking]);
    const onClickLogo = useCallback(() => {
        tracking.trackEvent('Navigation', 'click_timeline_logo', brandHandle);
    }, [tracking, brandHandle]);

    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const { ref: sidebarRef, width: sidebarWidth = 0 } = useElementSize();
    const { ref: containerRef, width: containerWidth = 0 } = useElementSize();
    const { ref: contentRef, width: contentWidth = windowWidth } = useElementSize();
    const { ref: brandLogoRef, height: brandLogoHeight = 70 } = useElementSize();
    const stableWindowHeight = useStableWindowHeight();
    const safeZone = Math.max(brandLogoHeight, 60);
    const safeZoneWidth = windowWidth - contentWidth * 0.02 * 2;
    const safeZoneHeight = stableWindowHeight - safeZone * 2;
    const contentScale = Math.min(safeZoneWidth / windowWidth, safeZoneHeight / stableWindowHeight);

    const sidebarOverContent = containerWidth - sidebarWidth < minimumContentWidth;

    const onClickClose = useCallback(() => {
        if (menuOpened && sidebarOverContent) {
            closeMenu();
        }
    }, [menuOpened, sidebarOverContent, closeMenu]);

    const {
        visible: sidebarVisible,
        sidebarClosed,
        contentStyle,
        sidebarStyle,
        menuButtonStyle,
    } = useSidebarSpring({
        opened: menuOpened,
        menuButtonVisible: !hasModals && !bumperAdPlaying,
        sidebarWidth,
        sidebarOverContent,
    });

    const refOpenedLocation = useRef(menuOpened ? location : null);
    useEffect(() => {
        if (menuOpened && refOpenedLocation.current === null) {
            refOpenedLocation.current = location;
        } else if (!menuOpened) {
            refOpenedLocation.current = null;
        }

        if (menuOpened && refOpenedLocation.current !== location && sidebarOverContent) {
            setMenuOpened(false);
        }
    }, [location, menuOpened, sidebarOverContent]);

    useEffect(() => {
        const root = window.document.querySelector(':root') || null;
        if (root !== null) {
            root.style.setProperty(
                '--sidebar-width',
                `${menuOpened && !sidebarOverContent && !sidebarClosed ? sidebarWidth : 0}px`,
            );
        }
    }, [menuOpened, sidebarOverContent, sidebarWidth, sidebarClosed]);

    const onIndexChange = useCallback(
        (newIndex) => {
            if (newIndex <= timeline.length - 1) {
                setTimelineIndex(newIndex);
            }
        },
        [timeline, setTimelineIndex],
    );

    const [navigationDisabled, setNavigationDisabled] = useState(false);

    const enableNavigation = useCallback(
        () => setNavigationDisabled(false),
        [setNavigationDisabled],
    );
    const disableNavigation = useCallback(
        () => setNavigationDisabled(true),
        [setNavigationDisabled],
    );

    const { y: scrollY = 0 } = useWindowScroll();

    const {
        dragging: isDragging,
        zoomInSpring,
        transitioned,
        getStyleByIndex,
        bind: dragContentBind,
        bindSwipe,
        zoomedIn,
        zoomingIn,
        zoomingOut,
        zoomIn,
        zoomOut,
        transitioning,
        direction,
    } = useTimelineSpring({
        timeline,
        timelineIndex,
        containerWidth,
        containerHeight: windowHeight,
        navigationDisabled,
        zoomOnDrag: scrollY === 0,
        neighborActive: neighborPagesActive,
        initialZoomedIn,
        zoomOutScale: Number.isNaN(contentScale) ? 0.85 : contentScale,
        scrollY,
        onIndexChange,
    });

    const onPageEnter = useCallback(
        (pageDisableNavigation = false) => {
            zoomIn();
            if (pageDisableNavigation) {
                disableNavigation();
            }
        },
        [zoomIn, disableNavigation],
    );
    const onPageLeave = useCallback(
        (pageDisableNavigation = false) => {
            zoomOut();
            if (pageDisableNavigation) {
                enableNavigation();
            }
        },
        [zoomOut, enableNavigation],
    );

    useEffect(() => {
        if (initialZoomedIn) {
            zoomIn();
        } else if (documentType !== 'micromag') {
            zoomOut();
            enableNavigation();
        }
    }, [initialZoomedIn, timelineIndex, documentType, enableNavigation, zoomIn, zoomOut]);

    useEffect(() => {
        if (!initialZoomedIn && zoomedIn) {
            tracking.trackEvent('Navigation', 'enter', 'zoomin');
        }
    }, [initialZoomedIn, zoomedIn]);

    const onClickCurrent = useCallback(
        (e) => {
            const isAtTop = window.scrollY === 0;
            const isZoomable = isAtTop;
            const isDezoomable =
                isAtTop && !isNotDezoomable && !isInInteractiveElement(e.target) && !hasModals;
            if (!zoomedIn && isZoomable) {
                zoomIn();
            } else if (zoomedIn && isDezoomable) {
                zoomOut();
            }
        },
        [zoomedIn, zoomIn, zoomOut, isNotDezoomable, documentType, hasModals],
    );

    useEffect(() => {
        if (isDragging) {
            tracking.trackEvent(
                'Navigation',
                'drag_timeline',
                direction >= 0 ? 'next' : 'previous',
            );
        }
    }, [isDragging, tracking]);

    const onClickNeighbor = useCallback(
        (e, index) => {
            tracking.trackEvent(
                'Navigation',
                'click_timeline_neighbor',
                index > timelineIndex ? 'next' : 'previous',
            );
        },
        [timelineIndex, tracking],
    );

    const pagesRef = useRef([]);

    const updateScroll = useCallback(
        (zoomInAfter = false) => {
            timelineScroll.forEach((pageScrollY, pageIndex) => {
                const page = pagesRef.current[pageIndex] || null;
                if (page === null) {
                    return;
                }
                if (pageIndex !== timelineIndex || transitioning || navigating) {
                    if ((page.firstChild || null) !== null) {
                        // page.firstChild.style.minHeight = `calc(100vh + ${pageScrollY}px)`;
                    }
                    // page.scrollTop = pageScrollY;
                } else {
                    window.scrollTo(0, pageScrollY);
                    if (pageScrollY > 0 && zoomInAfter) {
                        zoomIn();
                    }
                }
            });
            if (timelineIndex >= timelineScroll.length) {
                window.scrollTo(0, 0);
            }
        },
        [timeline, timelineIndex, transitioned, transitioning, navigating],
    );

    useLayoutEffect(() => updateScroll(), [updateScroll]);
    useEffect(() => updateScroll(true), [updateScroll]);

    const onBumperAdPlay = useCallback(() => {
        setBumperAdPlaying(true);
    }, [setBumperAdPlaying]);
    const onBumperAdEnd = useCallback(() => {
        setBumperAdPlaying(false);
    }, [setBumperAdPlaying]);

    const [firstPage, setFirstPage] = useState(true);
    useEffect(() => {
        if (!firstPage) {
            setBumperAdActive(true);
        }
        setFirstPage(false);
    }, [currentUrl]);

    const { ref: scrollRef } = useScrollTracking();

    return (
        <LayoutContextProvider
            menuOpened={menuOpened}
            closeMenu={closeMenu}
            openMenu={openMenu}
            menuOverContent={sidebarOverContent}
            brand={globalBrand}
        >
            <div
                className={classNames([
                    styles.container,
                    styles[brandHandle],
                    {
                        [styles.isTransitioning]: isDragging || transitioning,
                        [styles.menuButtonOverContent]: !isImmersive,
                        [styles.menuOpened]: menuOpened,
                        [styles.zoomedIn]: (zoomedIn && !zoomingOut) || zoomingIn,
                        [styles.sidebarOverContent]: sidebarOverContent,
                    },
                ])}
                {...bindSwipe()}
                ref={(ref) => {
                    containerRef.current = ref;
                    scrollRef.current = ref;
                }}
            >
                <animated.div style={menuButtonStyle} className={styles.menuButtons}>
                    <AccountButton
                        href={user === null ? url('login') : url('account')}
                        circleColor="#222"
                        onClick={onClickAccount}
                        className={styles.accountButton}
                    />
                    <MenuButton
                        className={styles.menuButton}
                        onClick={onClickMenu}
                        circleColor="#222"
                    />
                </animated.div>

                <animated.aside className={styles.sidebar} style={sidebarStyle} ref={sidebarRef}>
                    {sidebarVisible ? (
                        <Suspense fallback={null}>
                            <MainMenu
                                opened={menuOpened}
                                lockBodyScroll={sidebarOverContent}
                                closeMenu={closeMenu}
                                className={styles.menu}
                            />
                        </Suspense>
                    ) : null}
                </animated.aside>

                <animated.main
                    className={styles.content}
                    style={contentStyle}
                    ref={contentRef}
                    {...dragContentBind()}
                >
                    <button
                        type="button"
                        className={styles.contentCloseButton}
                        onClick={onClickClose}
                    />

                    {!withoutLogoInHeader && globalBrand !== null ? (
                        <animated.div
                            className={styles.header}
                            style={{
                                width: contentStyle.width,
                                opacity:
                                    scrollY > safeZoneHeight
                                        ? 0
                                        : zoomInSpring.to((value) => 1 - value),
                            }}
                        >
                            <BrandLogoButton
                                brand={globalBrand}
                                withSponsor={zoomedIn ? false : 'auto'}
                                className={classNames([styles.brandLogo, styles[brandHandle]])}
                                logoClassName={styles.logo}
                                ref={brandLogoRef}
                                onClick={onClickLogo}
                            />
                        </animated.div>
                    ) : null}

                    {timeline.map((pageLocation, index) => {
                        const pageStyle = getStyleByIndex(index, null);
                        const current = index === timelineIndex && pageLocation === location;
                        const next = timelineIndex + direction === index;
                        const finalNeighborPagesActive = zoomedIn
                            ? neighborPagesActive
                            : neighborPagesActive;
                        const isActive =
                            Math.abs(index - timelineIndex) <= finalNeighborPagesActive;

                        return (
                            <animated.div
                                key={`timeline-${index}`}
                                className={classNames([
                                    styles.pageContainer,
                                    {
                                        [styles.neighbor]: !current,
                                        [styles.current]: current,
                                        [styles.transitioning]: transitioning,
                                        [styles.entering]: current && !zoomedIn && zoomingIn,
                                        [styles.leaving]: current && zoomedIn && zoomingOut,
                                        [styles.entered]: current && zoomedIn,
                                    },
                                ])}
                                data-active={isActive ? 'true' : 'false'}
                                data-location={pageLocation}
                                ref={(ref) => {
                                    pagesRef.current[index] = ref;
                                }}
                                onPointerUp={current ? onClickCurrent : null}
                                style={pageStyle}
                            >
                                <div
                                    className={styles.pageSize}
                                    style={
                                        index !== timelineIndex || transitioning || navigating
                                            ? {
                                                  transform: `translateY(-${timelineScroll[index]}px)`,
                                              }
                                            : null
                                    }
                                >
                                    {pageLocation !== null && isActive ? (
                                        <IdleContextProvider idle={!current && !next}>
                                            <PageRoutes
                                                location={pageLocation}
                                                current={current}
                                                next={next}
                                                paused={bumperAdPlaying}
                                                entered={current && zoomedIn}
                                                leaved={current && !zoomedIn && !zoomingIn}
                                                entering={current && zoomingIn}
                                                leaving={current && zoomingOut}
                                                disabled={!current && documentIsFetching}
                                                onEnter={onPageEnter}
                                                onLeave={onPageLeave}
                                                disableNavigation={disableNavigation}
                                                enableNavigation={enableNavigation}
                                                className={styles.page}
                                                contentClassName={styles.pageContent}
                                            />
                                        </IdleContextProvider>
                                    ) : null}
                                    {pageLocation === null && isActive ? (
                                        <div className={styles.placeholder} />
                                    ) : null}
                                    {pageLocation !== null && isActive && !current ? (
                                        <Link
                                            href={pageLocation}
                                            className={styles.pageLink}
                                            onClick={(e) => onClickNeighbor(e, index)}
                                        />
                                    ) : null}
                                </div>
                            </animated.div>
                        );
                    })}
                    <BumperAd
                        url={currentUrl}
                        active={bumperAdActive && !hasModals}
                        onPlay={onBumperAdPlay}
                        onEnd={onBumperAdEnd}
                        className={styles.bumperAd}
                    />
                </animated.main>
            </div>
            <div data-brand={brandHandle}>
                <PromotionsPopups />
                <PopupsContainer
                    namespace={MODAL_NAMESPACE}
                    activeClassName={styles.modalsOpened}
                />
                <PopupsContainer
                    namespace={NOTIFICATION_NAMESPACE}
                    className={styles.notifications}
                />
                <MessagesContainer className={styles.messages} />
            </div>
        </LayoutContextProvider>
    );
}

TimelineLayout.propTypes = propTypes;
TimelineLayout.defaultProps = defaultProps;

export default TimelineLayout;
