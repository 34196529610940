import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import isArray from 'lodash/isArray';

import { useApi } from '../contexts/ApiContext';

export default function useAuthors(query = null, opts = {}) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['authors', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.authors.get(queryParam, null, null, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}
