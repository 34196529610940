/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable react/jsx-props-no-spreading */
import { Ad } from '@folklore/ads';
import { loadGoogleIma } from '@folklore/services';
import { getSizeWithinBounds } from '@folklore/size';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import createDebug from 'debug';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearch } from 'wouter';

import useElementSize from '../../hooks/useElementSize';

import BasicButton from '../buttons/BasicButton';
import CloseButton from '../buttons/CloseButton';
import MuteIcon from '../icons/MuteIcon';
import Duration from '../typography/Duration';

import styles from '../../styles/ads/bumper-ad.module.css';

const debug = createDebug('app:ads:bumper');

const propTypes = {
    url: PropTypes.string,
    tagUrl: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    bannerDuration: PropTypes.number,
    skipDuration: PropTypes.number,
    onPlay: PropTypes.func,
    onEnd: PropTypes.func,
    onProgress: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    url: null,
    tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/62442913/timeline&tfcd=0&npa=0&sz=300x250%7C320x480%7C400x300%7C480x320%7C640x480%7C768x1024%7C970x90%7C1024x768%7C1080x1920%7C1080x1920%7C1920x1080%7C1920x1080&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&vad_type=linear',
    // tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/62442913/timeline&tfcd=0&npa=0&sz=400x300%7C640x480%7C1920x1080%7C1080x1920&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=&vad_type=linear',
    // tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
    disabled: false,
    active: false,
    bannerDuration: 5000,
    skipDuration: 1000,
    onPlay: null,
    onEnd: null,
    onProgress: null,
    className: null,
};

function BumberAd({
    url,
    tagUrl,
    disabled,
    active,
    bannerDuration,
    skipDuration,
    onPlay,
    onEnd,
    onProgress,
    className,
}) {
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [adsManagerLoaded, setAdsManagerLoaded] = useState(false);
    const [duration, setDuration] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [visible, setVisible] = useState(false);
    const [muted, setMuted] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const [noVideo, setNoVideo] = useState(false);
    const tracking = useTracking();

    const search = useSearch();
    const { locale } = useIntl();
    const finalTagUrl = useMemo(() => {
        if (tagUrl === null) {
            return null;
        }
        const { google_preview: googlePreviewToken = null } = queryString.parse(search);
        const tagQuery = {
            hl: locale,
        };
        if (url !== null) {
            tagQuery.description_url = url;
        }
        if (googlePreviewToken !== null) {
            tagQuery.gct = googlePreviewToken;
        }
        return `${tagUrl}&${queryString.stringify(tagQuery)}`;
    }, [tagUrl, search, locale, url]);

    const containerRef = useRef(null);
    const adContainerRef = useRef(null);
    const adDisplayContainerRef = useRef(null);
    const adsLoaderRef = useRef(null);
    const adsManagerRef = useRef(null);
    const { ref: playerRef, width: playerWidth, height: playerHeight } = useElementSize();
    const { ref: videoRef, width: videoWidth, height: videoHeight } = useElementSize();
    useEffect(() => {
        let canceled = false;
        debug('Load IMA SDK');
        loadGoogleIma().then(() => {
            if (!canceled) {
                debug('IMA SDK loaded');
                setSdkLoaded(true);
            }
        });
        return () => {
            canceled = true;
        };
    }, []);

    // Create container
    useEffect(() => {
        if (!sdkLoaded || disabled) {
            return () => {};
        }

        if (videoRef.current === null) {
            videoRef.current = document.createElement('video');
            videoRef.current.setAttribute('playsinline', null);
        }
        const { current: videoElement } = videoRef;

        debug('Create Display Container');

        const { ima } = window.google;
        adDisplayContainerRef.current = new ima.AdDisplayContainer(
            adContainerRef.current,
            videoElement,
        );
        const { current: adDisplayContainer } = adDisplayContainerRef;

        return () => {
            adDisplayContainer.destroy();
            adDisplayContainerRef.current = null;
        };
    }, [sdkLoaded, disabled]);

    // Request ads
    useEffect(() => {
        if (!sdkLoaded || disabled || finalTagUrl === null || !active) {
            return () => {};
        }

        debug('Request ads');

        const { ima } = window.google;
        const { current: adDisplayContainer } = adDisplayContainerRef;
        const { current: videoElement } = videoRef;
        adsLoaderRef.current = new ima.AdsLoader(adDisplayContainer);
        const { current: adsLoader } = adsLoaderRef;

        function onAdsManagerLoaded(adsManagerLoadedEvent) {
            debug('onAdsManagerLoaded');
            setNoVideo(false);
            // Instantiate the AdsManager from the adsLoader response and pass it the video element
            adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(videoElement);
            adDisplayContainer.initialize();
            setAdsManagerLoaded(true);
        }

        function onAdError(adErrorEvent) {
            const { current: adsManager = null } = adsManagerRef;
            // Handle the error logging.
            const error = adErrorEvent.getError();
            setNoVideo(true);
            debug('onAdError %O', {
                code: error.getErrorCode(),
                message: error.getMessage(),
                vastCode: error.getVastErrorCode(),
            });
            if (adsManager !== null) {
                adsManager.destroy();
                adsManagerRef.current = null;
                setAdsManagerLoaded(false);
            }
        }

        adsManagerRef.current = null;
        setAdsManagerLoaded(false);

        adsLoader.addEventListener(
            ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            onAdsManagerLoaded,
            false,
        );
        adsLoader.addEventListener(ima.AdErrorEvent.Type.AD_ERROR, onAdError, false);

        const adsRequest = new ima.AdsRequest();
        adsRequest.adTagUrl = finalTagUrl;
        // Specify the linear and nonlinear slot sizes. This helps the SDK to
        // select the correct creative if multiple are returned.
        adsRequest.linearAdSlotWidth = videoElement.clientWidth;
        adsRequest.linearAdSlotHeight = videoElement.clientHeight;
        adsRequest.setAdWillAutoPlay(true);
        adsRequest.setAdWillPlayMuted(true);

        // Pass the request to the adsLoader to request ads
        adsLoader.requestAds(adsRequest);

        return () => {
            adsLoader.removeEventListener(
                ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                onAdsManagerLoaded,
            );
            adsLoader.removeEventListener(ima.AdErrorEvent.Type.AD_ERROR, onAdError);
            adsLoader.destroy();
            adsLoaderRef.current = null;
        };
    }, [sdkLoaded, disabled, finalTagUrl, active]);

    useEffect(() => {
        const { current: adsManager = null } = adsManagerRef;

        if (!adsManagerLoaded || adsManager === null) {
            return () => {};
        }
        const { ima } = window.google;
        const {
            AD_PROGRESS,
            CONTENT_PAUSE_REQUESTED,
            CONTENT_RESUME_REQUESTED,
            DURATION_CHANGE,
            USER_CLOSE,
            SKIPPED,
        } = ima.AdEvent.Type;

        function onContentPauseRequested() {
            setPlaying(true);
            if (onPlay !== null) {
                onPlay();
            }
        }

        function onContentResumeRequested() {
            setPlaying(false);
            setRemainingTime(0);
            tracking.trackEvent('Ad', 'complete', 'bumper_video');
            if (onEnd !== null) {
                onEnd();
            }
        }

        function onAdProgress() {
            const newRemainingTime = adsManager.getRemainingTime();
            setRemainingTime(newRemainingTime);
            if (onProgress !== null) {
                onProgress(newRemainingTime);
            }
        }

        function onDurationChange(event) {
            const newDuration = event.getAd().getDuration();
            setDuration(newDuration);
        }

        function onUserClose() {
            tracking.trackEvent('Ad', 'close', 'bumper_video');
        }

        function onSkipped() {
            tracking.trackEvent('Ad', 'skip', 'bumper_video');
        }

        adsManager.addEventListener(DURATION_CHANGE, onDurationChange);
        adsManager.addEventListener(AD_PROGRESS, onAdProgress);
        adsManager.addEventListener(CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
        adsManager.addEventListener(CONTENT_RESUME_REQUESTED, onContentResumeRequested);
        adsManager.addEventListener(USER_CLOSE, onUserClose);
        adsManager.addEventListener(SKIPPED, onSkipped);
        return () => {
            adsManager.removeEventListener(DURATION_CHANGE, onDurationChange);
            adsManager.removeEventListener(AD_PROGRESS, onAdProgress);
            adsManager.removeEventListener(CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
            adsManager.removeEventListener(CONTENT_RESUME_REQUESTED, onContentResumeRequested);
            adsManager.removeEventListener(USER_CLOSE, onUserClose);
            adsManager.removeEventListener(SKIPPED, onSkipped);
        };
    }, [adsManagerLoaded, onPlay, onEnd, onProgress, tracking]);

    useEffect(() => {
        if (!adsManagerLoaded || !active) {
            return;
        }

        const { ima } = window.google;
        const { current: adsManager = null } = adsManagerRef;
        const { current: videoElement } = videoRef;
        const width = videoElement.clientWidth;
        const height = videoElement.clientHeight;
        try {
            debug('AdsManager started %o', {
                width,
                height,
            });
            adsManager.init(width, height, ima.ViewMode.FULLSCREEN);
            adsManager.start();
        } catch (adError) {
            debug('AdsManager could not be started');
        }
    }, [adsManagerLoaded, active]);

    useEffect(() => {
        const { current: adsManager = null } = adsManagerRef;
        if (!adsManagerLoaded || adsManager === null || !active || !playing) {
            return;
        }

        const { ima } = window.google;
        adsManager.resize(videoWidth, videoHeight, ima.ViewMode.FULLSCREEN);
        debug('Resize AdsManager %o', {
            width: videoWidth,
            height: videoHeight,
        });
    }, [adsManagerLoaded, videoWidth, videoHeight, active, playing]);

    const onClickMute = useCallback(() => {
        setMuted(!muted);
        const { current: adsManager = null } = adsManagerRef;
        if (adsManager !== null) {
            adsManager.setVolume(muted ? 1 : 0);
        }
    }, [muted]);

    const onAdRender = useCallback(
        ({ isEmpty }) => {
            debug('onAdRender %o', { isEmpty });
            if (isEmpty) {
                return;
            }

            setPlaying(true);
            setRemainingTime(bannerDuration / 1000);
            if (onPlay !== null) {
                onPlay();
            }
        },
        [bannerDuration, onPlay],
    );

    const closeBanner = useCallback(() => {
        setPlaying(false);
        setRemainingTime(0);
        if (onEnd !== null) {
            onEnd();
        }
    }, [setPlaying, setRemainingTime, onEnd]);

    const onClickSkip = useCallback(() => {
        closeBanner();
        tracking.trackEvent('Ad', 'close', 'bumper_banner');
    }, [closeBanner, tracking]);

    useEffect(() => {
        if (!noVideo || !playing) {
            return () => {};
        }
        const timeout = setTimeout(() => {
            const newRemainingTime = remainingTime - 1;
            if (newRemainingTime > 0) {
                setRemainingTime(newRemainingTime);
            } else {
                closeBanner();
                tracking.trackEvent('Ad', 'complete', 'bumper_banner');
            }
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [noVideo, playing, remainingTime, closeBanner]);

    const { ref: bannerRef, width: bannerWidth = 0, height: bannerHeight = 0 } = useElementSize();
    const { scale } = getSizeWithinBounds(bannerWidth, bannerHeight, playerWidth, playerHeight);
    const canSkip = bannerDuration - remainingTime * 1000 >= skipDuration;

    useEffect(() => {
        setTimeout(
            () => {
                setVisible(playing);
            },
            playing ? 200 : 500,
        );
    }, [playing]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.canSkip]: playing && canSkip,
                    [styles.noVideo]: noVideo,
                    [styles.visible]: visible,
                    [styles.playing]: playing,
                },
                className,
            ])}
            ref={containerRef}
        >
            <div className={styles.player} ref={playerRef}>
                <BasicButton onClick={onClickMute} className={styles.muteButton}>
                    <MuteIcon muted={muted} className={styles.icon} />
                </BasicButton>
                <video ref={videoRef} playsInline className={styles.video} muted={muted} />
                <div className={styles.adContainer} ref={adContainerRef} />
                <div className={styles.bannerContainer}>
                    <div
                        style={{
                            transform: `scale(${Math.min(scale, 1)})`,
                            transformOrigin: '50% 50%',
                        }}
                    >
                        <div ref={bannerRef}>
                            <Ad
                                slot="bumper"
                                key={`bumper-${url}`}
                                disabled={disabled || !noVideo || !active}
                                onRender={onAdRender}
                                className={styles.banner}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CloseButton onClick={onClickSkip} className={styles.closeButton} />
            <BasicButton onClick={onClickSkip} className={styles.skipButton}>
                Fermer
            </BasicButton>
            {remainingTime !== null && remainingTime > 0 ? (
                <Duration className={styles.remainingTime}>
                    <FormattedMessage
                        defaultMessage="Le contenu s’affichera dans <span>{time}</span> {time, plural,
      =0 {seconde}
      =1 {seconde}
      other {secondes}
    }"
                        values={{ time: Math.floor(remainingTime) }}
                    />
                </Duration>
            ) : null}
        </div>
    );
}

BumberAd.propTypes = propTypes;
BumberAd.defaultProps = defaultProps;

export default BumberAd;
