/* eslint-disable react/jsx-props-no-spreading */
import { getSizeWithinBounds } from '@folklore/size';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useElementSize from '../../hooks/useElementSize';

// import PropTypes from 'prop-types';
// import * as AppPropTypes from '../../../lib/PropTypes';
import styles from '../../styles/ads/parallax-ad.module.css';

const propTypes = {
    image: PropTypes.string.isRequired,
    imagePortrait: PropTypes.string,
    imageSquare: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    isFluid: PropTypes.bool,
    format: PropTypes.oneOf(['cover', 'contain', 'centered']),
    backgroundColor: PropTypes.string,
    clickUrl: PropTypes.string.isRequired,
};

const defaultProps = {
    imagePortrait: null,
    imageSquare: null,
    width: null,
    height: '50%',
    isFluid: false,
    format: 'cover',
    backgroundColor: null,
};

function ParallaxAd({
    width,
    height,
    isFluid,
    image,
    imagePortrait,
    imageSquare,
    format,
    backgroundColor,
    clickUrl,
}) {
    const imageRef = useRef(null);
    const [{ width: imageWidth, height: imageHeight }, setImageSize] = useState({
        width: 0,
        height: 0,
    });
    const { ref: containerRef, width: containerWidth } = useElementSize();
    const {
        ref: imageContainerRef,
        width: imageContainerWidth,
        height: imageContainerHeight,
    } = useElementSize();
    // const [imageStyle, setImageStyle] = useState({
    //     top: 0,
    //     left: 0,
    //     width: 0,
    //     height: 0,
    // });
    // useEffect(() => {
    //     function updateImageStyle() {
    //         const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    //         const { width: containerWidth } = containerRef.current.getBoundingClientRect();

    //     }
    //     updateImageStyle();
    //     window.addEventListener('resize', updateImageStyle);
    //     return () => {
    //         window.removeEventListener('resize', updateImageStyle);
    //     };
    // }, [format, imageWidth, imageHeight, imageContainerWidth, imageContainerHeight]);

    const imageStyle = useMemo(() => {
        const {
            width: newWidth,
            height: newHeight,
            scale: newScale,
        } = getSizeWithinBounds(
            imageWidth,
            imageHeight,
            imageContainerWidth,
            imageContainerHeight,
            {
                cover: format === 'cover',
            },
        );
        return {
            // width: newWidth,
            // height: newHeight,
            // top: (windowHeight - newHeight) / 2,
            // left: (windowWidth - newWidth) / 2,
            top: format === 'centered' && newScale >= 1 ? '50%' : null,
            left: format === 'centered' && newScale >= 1 ? '50%' : null,
            width: imageWidth,
            height: imageHeight,
            transform:
                format === 'centered' && newScale >= 1
                    ? 'translate3d(-50%, -50%, 0)'
                    : `translate3d(${(imageContainerWidth - newWidth) / 2}px, ${(imageContainerHeight - newHeight) / 2}px, 0px) scale(${newScale})`,
        };
    }, [imageWidth, imageHeight, imageContainerWidth, imageContainerHeight, format]);

    const onImageLoad = useCallback(() => {
        setImageSize({
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight,
        });
    }, [setImageSize]);
    useEffect(() => {
        setImageSize({
            width: imageRef.current.naturalWidth || 0,
            height: imageRef.current.naturalHeight || 0,
        });
    }, [setImageSize]);

    let finalHeight = imageContainerHeight * 0.5;
    if (height !== null && height.match(/^([0-9]+)%$/) !== null) {
        const [, percentage] = height.match(/^([0-9]+)%$/);
        finalHeight = imageContainerHeight * (parseInt(percentage, 10) / 100);
    } else if (height !== null && height.match(/^([0-9]+)(px)?$/) !== null) {
        const [, pixel] = height.match(/^([0-9]+)(px)?$/);
        finalHeight = parseInt(pixel, 10);
    }

    const ratio = imageContainerWidth / imageContainerHeight;
    let finalImage = image;
    if (imagePortrait !== null && imagePortrait.length > 0 && ratio < 0.8) {
        finalImage = imagePortrait;
    } else if (imageSquare !== null && imageSquare.length > 0 && ratio > 0.8 && ratio < 1.2) {
        finalImage = imageSquare;
    }

    return (
        <div
            className={styles.container}
            style={{
                width: isFluid ? '100%' : width,
                height: finalHeight,
            }}
            ref={containerRef}
        >
            <div className={styles.frame}>
                <div
                    className={styles.imageContainer}
                    style={{
                        width: containerWidth,
                        backgroundColor:
                            backgroundColor !== 'blur' ? backgroundColor : 'transparent',
                    }}
                    ref={imageContainerRef}
                >
                    {backgroundColor === 'blur' ? (
                        <div
                            className={styles.background}
                            style={{
                                backgroundImage: `url(${image})`,
                            }}
                        />
                    ) : null}
                    <a
                        href={clickUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles.link}
                    >
                        <img
                            src={finalImage}
                            alt="Ad"
                            className={styles.image}
                            width={imageWidth}
                            height={imageHeight}
                            ref={imageRef}
                            style={imageStyle}
                            onLoad={onImageLoad}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

ParallaxAd.propTypes = propTypes;
ParallaxAd.defaultProps = defaultProps;

export default ParallaxAd;
