/* eslint-disable jsx-a11y/control-has-associated-label, react/button-has-type,  react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { useDefaultBrand } from '../../contexts/SiteContext';
import Video from '../partials/Video';

import styles from '../../styles/documents/video-document.module.css';

const propTypes = {
    id: PropTypes.string,
    video: AppPropTypes.video,
    metadata: AppPropTypes.documentMetadata,
    paused: PropTypes.bool,
    controls: PropTypes.bool,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onComplete: PropTypes.func,
    onProgress: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    video: null,
    metadata: null,
    paused: false,
    controls: true,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    onEnter: null,
    onLeave: null,
    onComplete: null,
    onProgress: null,
    className: null,
};

function VideoDocument({
    id,
    video,
    metadata,
    paused,
    controls,
    current,
    entered,
    entering,
    leaving,
    onEnter,
    onLeave,
    onComplete,
    onProgress,
    className,
}) {
    const [userActive, setUserActive] = useState(true);
    const [playing, setPlaying] = useState(!paused);
    const [muted, setMuted] = useState(true);
    const [ended, setEnded] = useState(false);
    const { brands } = metadata || {};
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();

    useEffect(() => {
        setPlaying(!paused);
    }, [paused]);

    const onActivityChange = useCallback(
        (newActivity) => {
            setUserActive(newActivity);
        },
        [setUserActive],
    );

    const onPlay = useCallback(() => {
        setEnded(false);
        setPlaying(true);
    }, [setPlaying, onEnter]);

    const onPause = useCallback(() => {
        setPlaying(false);
        if (onLeave !== null && current) {
            onLeave();
        }
    }, [setPlaying, onLeave, current]);

    const onMute = useCallback(() => {
        setMuted(true);
    }, [setMuted]);

    const onUnmute = useCallback(() => {
        setMuted(false);
    }, [setMuted]);

    const onEnded = useCallback(() => {
        setEnded(true);
        if (onLeave !== null && current) {
            onLeave();
        }
        if (onComplete !== null) {
            onComplete();
        }
    }, [onLeave, current, onComplete]);

    const onClickVideo = useCallback(
        (newPlaying) => {
            if (newPlaying && onEnter !== null) {
                onEnter();
            }
        },
        [onEnter],
    );

    const onClickInfo = useCallback(() => {
        // window.scrollTo({
        //     top: 100,
        //     left: 0,
        //     behaviour: 'smooth'
        // });
        // setSidebarOpen(true);
        // if (onEnter !== null) {
        //     onEnter();
        // }
    }, [onEnter]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.entered]: entered,
                    [className]: className !== null,
                },
            ])}
        >
            {video !== null ? (
                <Video
                    media={video}
                    className={styles.video}
                    muted={muted}
                    controls={controls}
                    controlsCompact={!current || (!entered && !entering && playing) || leaving}
                    controlsTheme={brandHandle || defaultBrandHandle}
                    autoPlay
                    playing={playing && !paused}
                    // withMuteIcon={!entered && muted && playing}
                    onPlay={onPlay}
                    onPause={onPause}
                    onMute={onMute}
                    onUnmute={onUnmute}
                    onProgress={onProgress}
                    onActivityChange={onActivityChange}
                    onEnded={onEnded}
                    onClick={onClickVideo}
                    onClickInfo={onClickInfo}
                />
            ) : null}
        </div>
    );
}

VideoDocument.propTypes = propTypes;
VideoDocument.defaultProps = defaultProps;

export default VideoDocument;
