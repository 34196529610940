/* eslint-disable react/jsx-props-no-spreading */
import { useIntersectionObserver } from '@folklore/hooks';
import classNames from 'classnames';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';

import useBrandMatcher from '../../hooks/useBrandMatcher';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import { useTimelineInfinite } from '../../hooks/useTimeline';
import useTransformTimelineItem from '../../hooks/useTransformTimelineItem';

import { useDefaultBrand } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import DocumentHorizontalCard from '../cards/DocumentHorizontalCard';
import TimelineHorizontalCard from '../cards/TimelineHorizontalCard';
import BrandIcon from '../icons/BrandIcon';
import CaretIcon from '../icons/CaretIcon';
import Accordion from '../partials/Accordion';
import Duration from '../typography/Duration';
import ItemCount from '../typography/ItemCount';
import ListTitle from '../typography/ListTitle';

import styles from '../../styles/panels/account-timeline-panel.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AccountTimelinePanel({ className }) {
    const intl = useIntl();
    const [search, setSearch] = useState(null);

    const defaultBrand = useDefaultBrand();

    const onSearchChange = useCallback(
        (newSearch) => {
            setSearch(newSearch);
        },
        [setSearch],
    );

    const {
        pages = null,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useTimelineInfinite(
        search !== null
            ? {
                  search,
              }
            : {},
        {
            enabled: false,
        },
    );

    const {
        ref: refEnd,
        entry: { isIntersecting: endInView = false },
    } = useIntersectionObserver({
        disabled: isFetching || isFetchingNextPage,
    });

    useEffect(() => {
        if (endInView && !isFetching && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [isFetching, isFetchingNextPage, endInView]);

    const placeholderItems = usePlaceholderItems(5);

    const transformTimelineItem = useTransformTimelineItem();

    const items = useMemo(() => {
        if (pages === null) {
            return [
                { key: 'placeholder', items: [{ brand: defaultBrand, items: placeholderItems }] },
            ];
        }

        return pages
            .reduce((allItems, { data: pageItems = [] }) => [...allItems, ...pageItems], [])
            .map((item) => transformTimelineItem(item))
            .reduce((groups, item) => {
                const { createdAt = null } = item || {};
                if (createdAt === null) {
                    return [...groups];
                }
                const dateObject = parseISO(createdAt);
                const dateKey = format(dateObject, 'yyyyMMdd') || null;

                const currentGroupIndex = groups.findIndex(({ key = null }) => key === dateKey);

                return currentGroupIndex !== -1
                    ? [
                          ...groups.slice(0, currentGroupIndex),
                          {
                              ...groups[currentGroupIndex],
                              items: [...groups[currentGroupIndex].items, item],
                          },
                          ...groups.slice(currentGroupIndex + 1),
                      ]
                    : [
                          ...groups,
                          {
                              key: dateKey,
                              date: dateObject,
                              items: [item],
                          },
                      ];
            }, [])
            .map(({ key, items: groupItems, ...group }) => {
                const groupedByBrand = groupItems.reduce((brandGroups, item) => {
                    const { brand } = item || {};
                    const { handle: brandHandle } = brand || {};

                    const currentBrandIndex = brandGroups.findIndex(
                        ({ brand: groupBrand }) => groupBrand?.handle === brandHandle,
                    );

                    if (currentBrandIndex === -1) {
                        return [
                            ...brandGroups,
                            {
                                brand,
                                items: [item],
                            },
                        ];
                    }

                    const { document: lastDocument = null } =
                        brandGroups[currentBrandIndex].items[
                            brandGroups[currentBrandIndex].items.length - 1
                        ];
                    const { id: lastDocumentId = null } = lastDocument || {};
                    const { document = null } = item;
                    const { id: documentId = null } = document || {};

                    if (
                        documentId !== null &&
                        lastDocumentId !== null &&
                        documentId === lastDocumentId
                    ) {
                        return brandGroups;
                    }

                    return [
                        ...brandGroups.slice(0, currentBrandIndex),
                        {
                            ...brandGroups[currentBrandIndex],
                            items: [...brandGroups[currentBrandIndex].items, item],
                        },
                        ...brandGroups.slice(currentBrandIndex + 1),
                    ];
                }, []);

                return {
                    key,
                    items: groupedByBrand,
                    count: groupItems.length,
                    ...group,
                };
            });
    }, [pages, placeholderItems]);

    const [collapsed, setCollapsed] = useState([]);

    const onClickSectionButton = useCallback(
        (key) => {
            const currentKeyIndex = collapsed.findIndex((sectionKey) => sectionKey === key);

            const newCollapsed =
                currentKeyIndex !== -1
                    ? [
                          ...collapsed.slice(0, currentKeyIndex),
                          ...collapsed.slice(currentKeyIndex + 1),
                      ]
                    : [...collapsed, key];

            setCollapsed(newCollapsed);
        },
        [collapsed],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {/* <SearchField
                value={search}
                placeholder={intl.formatMessage({
                    defaultMessage: "Rechercher dans l'historique...",
                    description: 'Field placeholder',
                })}
                loading={isFetching || isFetchingNextPage}
                onChange={onSearchChange}
                className={styles.field}
            /> */}
            <div className={styles.list}>
                {(items || []).map(({ key, date, count, items: dateItems = [] }) => {
                    const isCollapsed = collapsed.indexOf(key) !== -1;
                    return (
                        <div
                            key={key}
                            className={classNames([
                                styles.dateSection,
                                {
                                    [styles.collapsed]: isCollapsed,
                                },
                            ])}
                        >
                            <div className={styles.header}>
                                <BasicButton
                                    onClick={() => onClickSectionButton(key)}
                                    className={styles.dateButton}
                                >
                                    <ListTitle tag="span" className={styles.dateTitle}>
                                        <FormattedDate
                                            value={date}
                                            day="2-digit"
                                            month="short"
                                            x
                                            year="numeric"
                                        />
                                        <CaretIcon
                                            className={classNames([
                                                styles.caret,
                                                { [styles.rotate]: isCollapsed },
                                            ])}
                                        />
                                    </ListTitle>
                                    <ItemCount tag="span" className={styles.count}>
                                        <FormattedMessage
                                            defaultMessage="{count} {count, plural, one {item} other {items}}"
                                            description="List count"
                                            values={{ count }}
                                        />
                                    </ItemCount>
                                </BasicButton>
                            </div>
                            <Accordion
                                collapsed={isCollapsed}
                                duration={`${count > 5 ? count * 0.02 : count * 0.1}s`}
                            >
                                {(dateItems || []).map(({ brand, items: brandItems = [] }, i) => {
                                    const { handle: brandHandle = null } = brand || defaultBrand;
                                    return (
                                        <div
                                            key={`${key}-${brandHandle}-${i + 1}`}
                                            className={styles.brandSection}
                                        >
                                            <BrandIcon
                                                type={brandHandle}
                                                className={styles.brandIcon}
                                            />
                                            <div className={styles.brandItems}>
                                                {(brandItems || []).map(
                                                    ({ id, date: itemDate, ...props }) => (
                                                        <div
                                                            key={`item-${id}`}
                                                            className={styles.item}
                                                        >
                                                            <TimelineHorizontalCard
                                                                {...props}
                                                                theme="menu"
                                                                ratio="square"
                                                                size="tiny"
                                                                brand={brand}
                                                                placeholder={
                                                                    isFetching && pages === null
                                                                }
                                                                className={styles.card}
                                                            />
                                                            <Duration className={styles.date}>
                                                                <FormattedTime value={itemDate} />
                                                            </Duration>
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Accordion>
                        </div>
                    );
                })}
            </div>
            <div
                className={styles.end}
                ref={refEnd}
                style={{
                    height: 40,
                }}
            />
        </div>
    );
}

AccountTimelinePanel.propTypes = propTypes;
AccountTimelinePanel.defaultProps = defaultProps;

export default AccountTimelinePanel;
