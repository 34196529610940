/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/icons/mute-icon.module.css';

const propTypes = {
    color: PropTypes.string,
    muted: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    muted: false,
    className: null,
};

function MuteIcon({ color, muted, className }) {
    return (
        <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            {muted ? (
                <g>
                    <path
                        d="M4.91935 5.01873H0.924501C0.562771 5.01873 0.269531 5.31197 0.269531 5.6737V9.60352C0.269531 9.96525 0.562771 10.2585 0.9245 10.2585H4.92479C5.09 10.2585 5.2491 10.3209 5.37022 10.4333L9.64864 14.402C10.0678 14.7909 10.749 14.4936 10.749 13.9219V1.2344C10.749 0.658168 10.0586 0.362752 9.64179 0.760665L5.37163 4.83749C5.24979 4.95382 5.08781 5.01873 4.91935 5.01873Z"
                        fill={color}
                    />
                    <path
                        d="M13.928 8.04125L15.3338 9.44711L15.7969 8.98397L14.3911 7.57812L15.7969 6.17227L15.3338 5.70914L13.928 7.11499L12.5221 5.70914L12.059 6.17227L13.4648 7.57812L12.059 8.98397L12.5221 9.44711L13.928 8.04125Z"
                        fill={color}
                    />
                </g>
            ) : (
                <g>
                    <path
                        d="M4.79923 5.01873H0.804383C0.442654 5.01873 0.149414 5.31197 0.149414 5.6737V9.60351C0.149414 9.96524 0.442654 10.2585 0.804383 10.2585H4.80468C4.96988 10.2585 5.12899 10.3209 5.25011 10.4333L9.52852 14.402C9.94769 14.7909 10.6289 14.4936 10.6289 13.9219V1.2344C10.6289 0.658168 9.93845 0.362752 9.52167 0.760665L5.25151 4.83749C5.12967 4.95382 4.96769 5.01873 4.79923 5.01873Z"
                        fill={color}
                    />
                    <path
                        d="M15.5412 7.57812C15.5412 5.58861 13.9284 3.97579 11.9389 3.97579V3.32082C14.2901 3.32082 16.1962 5.22688 16.1962 7.57812C16.1962 9.92937 14.2901 11.8354 11.9389 11.8354V11.1805C13.9284 11.1805 15.5412 9.56764 15.5412 7.57812Z"
                        fill={color}
                    />
                    <path
                        d="M13.5763 7.57812C13.5763 6.6738 12.8432 5.9407 11.9389 5.9407V5.28573C13.2049 5.28573 14.2313 6.31207 14.2313 7.57812C14.2313 8.84418 13.2049 9.87052 11.9389 9.87052V9.21555C12.8432 9.21555 13.5763 8.48245 13.5763 7.57812Z"
                        fill={color}
                    />
                </g>
            )}
        </svg>
    );
}

MuteIcon.propTypes = propTypes;
MuteIcon.defaultProps = defaultProps;

export default MuteIcon;
