/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import AuthorInitials from '../typography/AuthorInitials';

import styles from '../../styles/partials/initials.module.css';

const propTypes = {
    name: PropTypes.string,
    withoutCircle: PropTypes.bool,
    className: PropTypes.string,
    initialsClassName: PropTypes.string,
};

const defaultProps = {
    name: null,
    withoutCircle: false,
    className: null,
    initialsClassName: null,
};

function Initials({ name, withoutCircle, className, initialsClassName }) {
    const initials = useMemo(() => {
        if (name === null) {
            return null;
        }

        return name
            .split(' ')
            .slice(0, 2)
            .map((word) => (word !== null && word.length > 0 ? word[0] : ''))
            .join('');
    }, [name]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withoutCircle]: withoutCircle,
                },
                className,
            ])}
        >
            <div className={styles.circle}>
                <AuthorInitials className={classNames([styles.initials, initialsClassName])}>
                    {initials}
                </AuthorInitials>
            </div>
        </div>
    );
}

Initials.propTypes = propTypes;
Initials.defaultProps = defaultProps;

export default Initials;
