import { useRoutes, useRouteMatcher } from '@folklore/routes';

import { useBrand, useDefaultBrand } from '../contexts/SiteContext';
import useCollection from './useCollection';
import useDocument from './useDocument';

export default function useBrandFromLocation(fullLocation) {
    const location = (fullLocation || '').split('?')[0];
    const routeMatcher = useRouteMatcher();
    const routes = useRoutes();

    const [brandMatch = false, { brand: brandParam = null } = {}] = routeMatcher(routes['brand.wildcard'], location);

    const [documentMatch = false, { slug: documentParam = null } = {}] = routeMatcher(routes.document, location);
    const { document = null } = useDocument(documentParam, {
        suspense: false,
        enabled: documentParam !== null,
        // keepPreviousData: false,
        placeholderData: null,
    });

    const [collectionMatch = false, { slug: collectionParam = null } = {}] = routeMatcher(
        routes.collection,
        location,
    );
    const { collection = null } = useCollection(collectionParam, {
        suspense: false,
        enabled: collectionParam !== null,
        // keepPreviousData: false,
        placeholderData: null,
    });

    const defaultBrand = useDefaultBrand();
    const routeBrand = useBrand(brandParam);
    const { metadata: { brands: documentBrands } = {} } = document || {};
    const [documentBrand = null] = documentBrands || [];
    const { metadata: { brands: collectionBrands } = {} } = collection || {};
    const [collectionBrand = null] = collectionBrands || [];
    let finalBrandHandle = defaultBrand;
    let brandLoaded = true;
    if (documentMatch) {
        finalBrandHandle = (documentBrand || {}).handle || null;
        brandLoaded = document !== null;
    } else if (collectionMatch) {
        finalBrandHandle = (collectionBrand || {}).handle || null;
        brandLoaded = collection !== null;
    } else if (brandMatch) {
        finalBrandHandle = (routeBrand || {}).handle || null;
        brandLoaded = routeBrand !== null || brandParam === null;
    }

    const finalBrand = useBrand(finalBrandHandle);

    return {
        brand: finalBrand || defaultBrand,
        loaded: brandLoaded,
    };
}
