import classNames from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import dateLocale from '../../lib/dateLocale';

import DurationTypo from '../typography/Duration';

import styles from '../../styles/partials/time.module.css';

const propTypes = {
    date: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function Time({ date, className }) {
    const dateObj = parseISO(date);
    const intl = useIntl();
    const time = useMemo(() => {
        const now = new Date();
        const days = differenceInDays(now, dateObj);
        if (days === 1) {
            return intl.formatMessage({
                defaultMessage: 'Hier',
                description: 'Time label',
            });
        }
        if (now.getFullYear() !== dateObj.getFullYear()) {
            return intl.formatDate(dateObj, {
                month: 'short',
                day: 'numeric',
                year: '2-digit',
            });
        }
        return days >= 2
            ? intl.formatDate(dateObj, {
                  month: 'short',
                  day: 'numeric',
              })
            : formatDistanceToNowStrict(dateObj, { locale: dateLocale, addSuffix: true });
    }, [intl, dateObj]);
    return (
        <DurationTypo
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {time}
        </DurationTypo>
    );
}

Time.propTypes = propTypes;
Time.defaultProps = defaultProps;

export default Time;
