import { useTransition, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

// import { KEYCODES, useKeyboardKeys } from '../../hooks/useKeyboardKeys';
import { usePopupsContext } from '../../contexts/PopupsContext';

import styles from '../../styles/popups/messages-container.module.css';

const propTypes = {
    duration: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    duration: 5000,
    className: null,
};

const MessagesContainer = ({ duration, className }) => {
    const { messages: originalMessages = null, unregisterMessage } = usePopupsContext();
    const timeoutRef = useRef({});

    const messages = useMemo(() => (originalMessages || []).slice().reverse(), [originalMessages]);

    const removeMessage = useCallback(
        (id) => {
            unregisterMessage(id);
            if (typeof timeoutRef.current[id] !== 'undefined') {
                clearTimeout(timeoutRef.current[id]);
            }
            timeoutRef.current = Object.keys(timeoutRef.current)
                .filter((timeoutId) => timeoutId !== id)
                .reduce(
                    (subAcc, key) => ({
                        ...subAcc,
                        [key]: timeoutRef.current[key],
                    }),
                    {},
                );
        },
        [unregisterMessage],
    );

    useEffect(() => {
        const existingTimeouts = {
            ...timeoutRef.current,
        };
        timeoutRef.current = (messages || []).reduce(
            (acc, { id }) => ({
                ...acc,
                [id]:
                    timeoutRef.current[id] ||
                    setTimeout(() => {
                        removeMessage(id);
                    }, duration),
            }),
            {},
        );
        Object.keys(existingTimeouts).forEach((id) => {
            if (typeof timeoutRef.current[id] === 'undefined') {
                clearTimeout(existingTimeouts[id]);
            }
        });
    }, [messages, duration, unregisterMessage]);

    const onClickMessage = useCallback(
        (id) => {
            removeMessage(id);
        },
        [removeMessage],
    );

    useEffect(
        () => () => {
            Object.keys(timeoutRef.current).forEach((id) => {
                clearTimeout(timeoutRef.current[id]);
            });
        },
        [],
    );

    const transitions = useTransition(messages, {
        from: { opacity: 0, y: '-100%', maxHeight: 0 },
        enter: { opacity: 1, y: '0%', maxHeight: 200 },
        leave: { opacity: 0, y: '100%', maxHeight: 200 },
        keys: (item) => item.id,
    });

    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.messages}>
                {transitions((style, { id, message, theme }) => (
                    <animated.div
                        style={style}
                        className={styles.message}
                        data-theme={theme}
                        onClick={() => onClickMessage(id)}
                    >
                        {message}
                    </animated.div>
                ))}
            </div>
        </div>
    );
};

MessagesContainer.propTypes = propTypes;
MessagesContainer.defaultProps = defaultProps;

export default MessagesContainer;
