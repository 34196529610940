/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import List from './List';

import styles from '../../styles/lists/inline-list.module.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    ),
    listRef: AppPropTypes.ref,
    itemsRef: AppPropTypes.ref,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    items: null,
    listRef: null,
    itemsRef: null,
    className: null,
    itemsClassName: null,
    itemClassName: null,
    cardClassName: null,
};

function InlineList({
    items,
    listRef,
    itemsRef,
    className,
    itemsClassName,
    itemClassName,
    cardClassName,
    ...props
}) {
    return (
        <List
            {...props}
            ref={listRef}
            itemsRef={itemsRef}
            items={items}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                {
                    [itemsClassName]: itemsClassName !== null,
                },
            ])}
            itemClassName={classNames([
                styles.item,
                {
                    [itemClassName]: itemClassName !== null,
                },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

InlineList.propTypes = propTypes;
InlineList.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <InlineList listRef={ref} {...props} />);
