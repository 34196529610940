import { useRouteMatcher, useRoutes } from '@folklore/routes';
import { useCallback, useMemo } from 'react';

import { useBrands, useDefaultBrand } from '../contexts/SiteContext';
import useBrandMatcher from './useBrandMatcher';
import { parseISO } from 'date-fns/parseISO';

export default function useTransformTimelineItem() {
    const brandMatcher = useBrandMatcher();
    const routeMatcher = useRouteMatcher();
    const defaultBrand = useDefaultBrand();
    const brands = useBrands();
    const routes = useRoutes();

    const pageLabels = useMemo(
        () => [
            {
                route: routes.login,
                label: 'Connexion',
            },
            {
                route: routes.register,
                label: 'Inscription',
            },
            {
                route: routes.account,
                label: 'Compte',
            },
            {
                route: routes.articles,
                label: 'Tous les articles',
            },
            {
                route: routes.videos,
                label: 'Toutes les vidéos',
            },
            {
                route: routes.micromags,
                label: 'Tous les micromags',
            },
            {
                route: routes.podcasts,
                label: 'Tous les balados',
            },
            {
                route: routes[`account.timeline`],
                label: 'Compte / Historique',
            },
            {
                route: routes[`account.subscriptions`],
                label: 'Compte / Abonnement',
            },
        ],
        [routes],
    );

    const transformTimelineItem = useCallback(
        (item) => {
            const { document = null, url, createdAt = null } = item || {};
            const pathname = url.replace(/^https?:\/\/([^/]+)\/?/, '/');

            // Find label
            const { label: pageLabel = null } =
                pageLabels.find(({ route }) => routeMatcher(route, pathname)[0]) || {};

            const [homeMatch = false, { brand: brandSlug = null, section: sectionSlug = null } = {}] =
                routeMatcher(routes['home.wildcard'], pathname) || [];
            let homeLabel = null;
            if (homeMatch) {
                const brand = brands.find(({ slug = null }) => slug === brandSlug);
                const { sections = [] } = brand || {};
                const section = sections.find(({ slug = null }) => slug === sectionSlug);
                const { label: sectionLabel = null } = section || {};
                homeLabel = sectionLabel !== null ? `Section: ${sectionLabel}` : 'Page d’accueil';
            }

            // Find brand
            const { brands: documentBrands = null } = document || {};
            const [documentBrand = null] = documentBrands || [];
            const brandMatch = brandMatcher(pathname);

            return {
                ...item,
                date: parseISO(createdAt),
                label: pageLabel || homeLabel,
                brand: documentBrand || brandMatch || defaultBrand,
            };
        },
        [brandMatcher, defaultBrand, pageLabels, brands, routes],
    );

    return transformTimelineItem;
}
