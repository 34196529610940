import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import Api from '../lib/api/Api';

export const ApiContext = React.createContext({
    api: new Api(),
});

export function useApiContext() {
    const context = useContext(ApiContext);
    return context;
}

export function useApi() {
    const { api } = useApiContext();
    return api;
}

const propTypes = {
    client: PropTypes.instanceOf(Api),
    children: PropTypes.node,
};

const defaultProps = {
    client: null,
    children: null,
};

export function ApiContextProvider({ client, children }) {
    const value = useMemo(
        () => ({
            api: client,
        }),
        [client],
    );

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

ApiContextProvider.propTypes = propTypes;
ApiContextProvider.defaultProps = defaultProps;
