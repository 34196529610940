import { useResizeObserver } from '@folklore/hooks';

function useElementSize(opts) {
    const {
        ref,
        entry: { contentRect = null },
    } = useResizeObserver(opts);
    const { width, height } = contentRect || {};
    return {
        ref,
        width,
        height,
    };
}

export default useElementSize;
