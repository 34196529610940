import { useIsEditor } from '@niche-js/core/contexts';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useElementSize from '../../hooks/useElementSize';
import * as AppPropTypes from '../../lib/PropTypes';
import camelCase from 'lodash/camelCase';

import Picture from './Picture';

import styles from '../../styles/partials/image.module.css';

const propTypes = {
    media: AppPropTypes.image,
    width: PropTypes.number,
    height: PropTypes.number,
    ratio: PropTypes.number,
    size: PropTypes.string,
    position: AppPropTypes.position,
    loading: AppPropTypes.loading,
    alt: PropTypes.string,
    children: PropTypes.node,
    withoutSize: PropTypes.bool,
    responsive: PropTypes.bool,
    autoSize: PropTypes.bool,
    withPlaceholder: PropTypes.bool,
    withoutLoadingTransition: PropTypes.bool,
    className: PropTypes.string,
    pictureClassName: PropTypes.string,
};

const defaultProps = {
    media: null,
    width: null,
    height: null,
    ratio: null,
    size: null,
    position: null,
    loading: null,
    alt: null,
    children: null,
    withoutSize: false,
    responsive: false,
    autoSize: false,
    withPlaceholder: false,
    withoutLoadingTransition: false,
    className: null,
    pictureClassName: null,
};

function Image({
    media,
    width,
    height,
    ratio,
    size,
    position,
    loading,
    alt,
    children,
    withoutSize,
    responsive,
    autoSize,
    withPlaceholder,
    withoutLoadingTransition,
    className,
    pictureClassName,
}) {
    const {
        ref: refResize,
        width: containerWidth = 0,
        height: containerHeight = 0,
    } = useElementSize({
        disabled: !autoSize,
    });
    const isEditor = useIsEditor();
    const finalWidth = autoSize ? width || containerWidth : width;
    const finalHeight = autoSize ? height || containerHeight : height;
    let style = null;
    if (responsive) {
        style = {
            width: '100%',
            height: 0,
            paddingBottom: `${(ratio || height / width) * 100}%`,
        };
    } else if (!withoutSize) {
        style = {
            width: finalWidth,
            height: finalHeight,
        };
    }

    const pictureRef = useRef(null);
    const [loaded, setLoaded] = useState(true);
    const onPictureLoaded = useCallback(() => {
        setLoaded(true);
    }, [setLoaded]);

    useEffect(() => {
        if (media !== null) {
            setLoaded(pictureRef.current.naturalWidth > 0);
        } else {
            setLoaded(false);
        }
    }, [media]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isEditor]: isEditor,
                    [styles.placeholder]: withPlaceholder,
                    [styles.loaded]: loaded || isEditor || withoutLoadingTransition,
                },
                styles[camelCase(position)],
                className,
            ])}
            ref={refResize}
            style={style}
        >
            {media !== null ? (
                <Picture
                    media={media}
                    className={classNames([
                        styles.picture,
                        {
                            [pictureClassName]: pictureClassName !== null,
                        },
                    ])}
                    ref={pictureRef}
                    width={finalWidth}
                    height={finalHeight}
                    loading={loading}
                    size={size}
                    alt={alt}
                    onLoad={onPictureLoaded}
                />
            ) : null}
            {children}
        </div>
    );
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
