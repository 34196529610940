import React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentTypeIcon from '../components/icons/DocumentTypeIcon';
import { useRelatedDocuments } from '../hooks/useDocuments';
import SectionTitle from '../components/typography/SectionTitle';
import { Link } from 'wouter';

export function createDocumentsRow(query, blockProps = null) {
    return {
        type: 'documents-row',
        query,
        // presentation: 'grid',
        cardWidth: 200,
        cardSize: 'small',
        cardTheme: 'box',
        count: 6,
        placeholderCount: 6,
        summaryPosition: 'inline',
        background: 'rgba(255, 255, 255, 0.5)',
        withBorderRadius: true,
        ...blockProps,
    };
}

export function createRelatedDocumentsRow(id, query, blockProps = null) {
    return {
        type: 'documents-row',
        hook: useRelatedDocuments,
        paginated: false,
        query: {
            id,
            ...query,
        },
        // presentation: 'grid',
        cardWidth: 200,
        cardSize: 'small',
        cardTheme: 'box',
        count: 6,
        placeholderCount: 6,
        summaryPosition: 'inline',
        background: 'rgba(255, 255, 255, 0.5)',
        withBorderRadius: true,
        ...blockProps,
    };
}

export function createAuthorRow(slug, blockProps = null) {
    return createDocumentsRow(null, {
        type: 'author-row',
        slug,
        ...blockProps,
    });
}

export function createCollectionRow(slug, blockProps = null) {
    return {
        type: 'collection-row',
        slug,
        // presentation: 'grid',
        cardWidth: 200,
        count: 6,
        placeholderCount: 6,
        withBorderRadius: true,
        cardTheme: 'box',
        // cardType: 'over',
        // cardTheme: 'outline',
        cardSize: 'small',
        ...blockProps,
    };
}

export function createMicromagRow(blockProps = null) {
    const { query = null, ...props } = blockProps || {};
    return {
        type: 'summary-row',
        summary: {
            title: 'Micromag URBANIA',
            description:
                'Découvrez notre nouveau format de magazine gratuit en format numérique. Vous pouvez y consulter du contenu exclusif, nos suggestions de la semaine et même votre horoscope.',
            icon: <DocumentTypeIcon type="micromag" />,
            buttonLabel: <FormattedMessage defaultMessage="S’abonner" description="Button label" />,
            link: '/micromags',
            withButton: true,
        },
        // summaryPosition: 'bottom',
        presentation: 'row',
        itemsType: 'documents',
        cardType: 'over',
        cardSize: 'big',
        cardTheme: 'outline',
        cardRatio: 'micromag',
        cardWidth: 250,
        cardWithout: 'icon',
        loading: 'lazy',
        background: 'rgba(255, 255, 255, 0.5)',
        withBorderRadius: true,
        count: 12,
        placeholderCount: 12,
        query: {
            type: 'micromag',
            ...query,
        },
        ...props,
    };
}

export function createVideosRow(blockProps = null) {
    const { query = null, ...props } = blockProps || {};
    return {
        role: 'block',
        type: 'documents-row',
        presentation: 'row',
        query: {
            type: 'video',
            ...query,
        },
        loading: 'lazy',
        cardType: 'over',
        cardSize: 'big',
        cardTheme: 'outline',
        cardRatio: 'vertical',
        cardWidth: 300,
        cardWithout: ['brand-icon'],
        ...props,
    };
}

export function createLayoutRow(layout, blockProps = null) {
    return {
        type: 'documents-row',
        presentation: 'layout',
        layout,
        withSeparator: true,
        count: layout.split('_').reduce((total, count) => total + parseInt(count, 10), 0),
        ...blockProps,
    };
}

export function createDocumentsGrid(count, blockProps = null) {
    return {
        type: 'items',
        itemsType: 'documents',
        presentation: 'grid',
        count,
        ...blockProps,
    };
}

export function createRegionsList(query = null, blockProps = null) {
    return {
        type: 'items',
        itemsType: 'regions',
        query,
        presentation: 'grid',
        ...blockProps,
    };
}

export function createLocationsList(items, query = null, blockProps = null) {
    return {
        type: 'items',
        itemsType: 'locations',
        items,
        withoutLoader: items !== null,
        query,
        presentation: 'grid',
        columns: 1,
        cardType: 'horizontal',
        cardTheme: 'box-translucent-dark',
        ...blockProps,
    };
}

export function createLocationTypesList(count = 10, query = null, blockProps = null) {
    return {
        type: 'items',
        itemsType: 'location_types',
        query: {
            for: 'locations',
            order: 'count',
            ...query,
        },
        count,
        cardSize: 'small',
        cardTheme: 'pill',
        presentation: 'inline',
        ...blockProps,
    };
}

export function createDocumentsMapList(query = null, blockProps = null) {
    return {
        type: 'items',
        itemsType: 'documents',
        query,
        presentation: 'grid',
        columns: 1,
        cardType: 'horizontal',
        cardTheme: 'box-translucent-dark',
        cardRatio: 'square',
        cardWithout: ['duration', 'time'],
        cardWith: 'locations',
        ...blockProps,
    };
}

export function createHomeSectionGrid({ cardWithout = [], ...props }) {
    return {
        role: 'block',
        type: 'documents-row',
        count: 12,
        loading: 'lazy',
        cardType: 'vertical',
        cardWithout: ['brand-icon', ...cardWithout],
        cardWidth: 200,
        ...props,
    };
}
