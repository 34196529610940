import Base from './Base';

class Locations extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/locations',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    findBySlug(slug, opts = null) {
        return this.getJSON(
            '/locations',
            {
                uri: slug,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }

    locationTypes(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/location-types',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    regions(query = null, slug = null, opts = null) {
        return this.getJSON(
            '/regions',
            {
                uri: slug,
                ...query,
            },
            opts,
        );
    }
}

export default Locations;
