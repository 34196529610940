import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { useMemo } from 'react';
import tinycolor from 'tinycolor2';

export default function useBackgroundIsDark(background) {
    return useMemo(() => {
        const { color = null, imageFade = null } = background || {};
        let backgroundColor = null;
        if (isString(imageFade)) {
            backgroundColor = imageFade;
        } else if (isArray(imageFade)) {
            [backgroundColor = null] = imageFade;
        } else if (color !== null) {
            backgroundColor = color;
        }
        return backgroundColor !== null ? tinycolor(backgroundColor).isDark() : false;
    }, [background]);
}
