import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// import { KEYCODES, useKeyboardKeys } from '../../hooks/useKeyboardKeys';
import { usePopupsContext } from '../../contexts/PopupsContext';

const propTypes = {
    namespace: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
};

const defaultProps = {
    namespace: null,
    className: null,
    activeClassName: null,
};

const PopupsContainer = ({ namespace, className, activeClassName }) => {
    const { popups = null, setContainer } = usePopupsContext();

    const containerRef = useRef(null);

    const finalPopups =
        namespace !== null
            ? (popups || []).filter(
                  ({ namespace: popupNamespace, outsideContainer = false }) =>
                      popupNamespace === namespace && !outsideContainer,
              )
            : popups;

    useEffect(() => {
        setContainer(containerRef.current, namespace);
    }, [setContainer, namespace]);

    // If we want a blocking modal someday?
    // useEffect(() => {
    //     if (document.body) {
    //         if (modals !== null && modals.length > 0) {
    //             document.body.className = 'modal-open';
    //         } else {
    //             document.body.className = '';
    //         }
    //     }
    // }, [modals]);

    // useKeyboardKeys({
    //     [KEYCODES.ESCAPE]: closeLastModal,
    // });

    return (
        <div className={className}>
            <div
                className={classNames([
                    {
                        [activeClassName]: finalPopups !== null && finalPopups.length > 0,
                    },
                ])}
                ref={containerRef}
            />
        </div>
    );
};

PopupsContainer.propTypes = propTypes;
PopupsContainer.defaultProps = defaultProps;

export default PopupsContainer;
