/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useBrandsQuery from '../../hooks/useBrands';
import * as AppPropTypes from '../../lib/PropTypes';

import { useBrands } from '../../contexts/SiteContext';
import * as CardComponents from '../cards/brands';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/brands-list.module.css';

const propTypes = {
    excludeBrand: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    cardRatio: AppPropTypes.cardRatio,
    children: PropTypes.node,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: 'horizontal',
    cardRatio: 5 / 3,
    excludeBrand: null,
    query: null,
    children: null,
    className: null,
    itemsClassName: null,
};

function BrandsList({
    excludeBrand,
    query,
    cardComponent,
    cardType,
    className,
    itemsClassName,
    ...props
}) {
    const items = useBrands();
    const finalItems = useMemo(
        () =>
            [...(items || [])].filter(
                ({ handle }) => excludeBrand === null || handle !== excludeBrand,
            ),
        [excludeBrand, items],
    );

    return (
        <PresentationList
            hook={query != null ? useBrandsQuery : null}
            items={query === null ? finalItems : null}
            withoutLoader={query === null}
            query={query}
            paginated={false}
            placeholderCount={4}
            cardComponent={
                cardComponent || getComponentFromName(CardComponents, cardType, 'horizontal')
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                {
                    [itemsClassName]: itemsClassName !== null,
                },
            ])}
            {...props}
        />
    );
}

BrandsList.propTypes = propTypes;
BrandsList.defaultProps = defaultProps;

export default BrandsList;
