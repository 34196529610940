import PropTypes from 'prop-types';

/* Core */
export const ref = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        current: PropTypes.object,
    }),
]);

export const menuItem = PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
});

/* Medias */
export const embed = PropTypes.shape({
    provider: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    iframeUrl: PropTypes.string,
    html: PropTypes.string,
});

export const mediaSource = PropTypes.shape({
    url: PropTypes.string.isRequired,
});

const mediaShape = {
    url: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(mediaSource),
};

export const media = PropTypes.shape({
    ...mediaShape,
});

export const imageSize = PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
});

export const image = PropTypes.shape({
    ...mediaShape,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    sizes: PropTypes.arrayOf(imageSize),
});

export const video = PropTypes.shape({
    ...mediaShape,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    embed,
});

export const loading = PropTypes.oneOf(['lazy', null]);

/* Taxonomies */
const taxonomyShape = {
    slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    image,
};
export const taxonomy = PropTypes.shape(taxonomyShape);

export const taxonomies = PropTypes.arrayOf(taxonomy);

export const brandHandle = PropTypes.oneOf(['quatre95', 'urbania', 'dehors', 'periodelibre', 'mollo']);

export const brand = PropTypes.shape({
    ...taxonomyShape,
    handle: brandHandle.isRequired,
    description: PropTypes.string,
});
export const brands = PropTypes.arrayOf(brand);

/* Credits */
export const authorLink = PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
});

export const authorLinks = PropTypes.arrayOf(authorLink);

export const author = PropTypes.shape({
    name: PropTypes.string,
    links: authorLinks,
});

export const credit = PropTypes.shape({
    author,
    role: PropTypes.string,
});

export const credits = PropTypes.arrayOf(credit);

/* Credits */
export const organisation = PropTypes.shape({
    name: PropTypes.string,
    image,
});

export const sponsor = PropTypes.shape({
    organisation,
    url: PropTypes.string,
    image,
});

export const sponsors = PropTypes.arrayOf(sponsor);

export const location = PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    image,
});

export const locations = PropTypes.arrayOf(location);

export const callToAction = PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
});

export const contest = PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    theme: PropTypes.string,
    success: PropTypes.string,
});

/* Components */
const componentShape = {
    role: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    presentation: PropTypes.string,
    theme: PropTypes.string,
};
export const component = PropTypes.shape(componentShape);
export const block = PropTypes.shape({
    ...componentShape,
    role: PropTypes.oneOf(['block']),
});

export const blocks = PropTypes.arrayOf(block);

/* Documents */
export const documentType = PropTypes.oneOf(['article', 'video', 'micromag', 'newsletter']);

export const documentMetadata = PropTypes.shape({
    credits: PropTypes.arrayOf(credit),
});

export const articleTheme = PropTypes.shape({
    name: PropTypes.string,
    primaryColor: PropTypes.string,
    backgroundColor: PropTypes.string,
});

export const document = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: documentType,
    blocks,
    metadata: documentMetadata,
});
export const documents = PropTypes.arrayOf(document);

export const collectionType = PropTypes.oneOf([
    'collection',
    'serie',
    'season',
    'serie_podcast',
    'season_podcast',
]);

export const collection = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: collectionType,
});

export const site = PropTypes.shape({
    brands,
    regions: taxonomies,
});

/* Pages */
export const page = PropTypes.shape({
    id: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(block),
});
export const pages = PropTypes.arrayOf(page);

/* Cards */
export const cardRatio = PropTypes.oneOf([
    'horizontal',
    'micromag',
    'square',
    'vertical',
    'square_double',
    'vertical_double',
]);

export const cardSize = PropTypes.oneOf(['small', null, 'big']);

/* Backgrounds */
export const position = PropTypes.oneOf([
    'top',
    'topLeft',
    'topRight',
    'bottom',
    'bottomLeft',
    'bottomRight',
    'left',
    'right',
]);

export const background = PropTypes.shape({
    gradient: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
    image,
    imageRepeat: PropTypes.bool,
    imageFade: PropTypes.bool,
    imagePosition: position,
});

/**
 * Promotions
 */
export const promotion = PropTypes.shape({
    id: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placement: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
});

/**
 * User
 */
export const userContact = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
});

export const userSubscription = PropTypes.shape({
    id: PropTypes.string,
    subscribed: PropTypes.bool,
});

export const user = PropTypes.shape({
    contacts: PropTypes.arrayOf(userContact),
    subscriptions: PropTypes.arrayOf(userSubscription),
});

/**
 * Forms
 */
export const errors = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);
export const formErrors = PropTypes.objectOf(errors);
