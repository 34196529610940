/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscribed, useSubscription } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSubscriptions } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import SubscriptionForm from '../forms/SubscriptionForm';
import AuthorImage from '../partials/AuthorImage';
import Background from '../partials/Background';
import PlaceholderText from '../partials/PlaceholderText';
import HeaderDescription from '../typography/HeaderDescription';
import HeaderTitle from '../typography/HeaderTitle';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/headers/author-default-header.module.css';

const linkLabels = {
    website: <FormattedMessage defaultMessage="Site web" description="Link label" />,
    facebook: <FormattedMessage defaultMessage="Facebook" description="Link label" />,
    twitter: <FormattedMessage defaultMessage="Twitter" description="Link label" />,
    youtube: <FormattedMessage defaultMessage="YouTube" description="Link label" />,
    tiktok: <FormattedMessage defaultMessage="TikTok" description="Link label" />,
    linkedin: <FormattedMessage defaultMessage="LinkedIn" description="Link label" />,
    instagram: <FormattedMessage defaultMessage="Instagram" description="Link label" />,
};

const propTypes = {
    id: PropTypes.string,
    background: AppPropTypes.background,
    color: PropTypes.string,
    description: PropTypes.string,
    image: AppPropTypes.image,
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    links: AppPropTypes.authorLinks,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    background: null,
    color: null,
    description: null,
    image: null,
    slug: null,
    name: null,
    title: null,
    links: null,
    placeholder: false,
    className: null,
};

function AuthorDefaultHeader({
    id,
    color,
    background,
    slug,
    description,
    image,
    name,
    title,
    links,
    placeholder,
    className,
}) {
    const subscriptions = useSubscriptions();
    const subscription =
        subscriptions.find(({ author = null }) => author !== null && author.id === id) || null;
    const { subscribed = false, unsubscribe } = useSubscription({
        subscription: subscription !== null ? subscription.handle : null,
    });
    return (
        <div
            className={classNames([styles.container, className])}
            style={{
                color,
            }}
        >
            {background !== null ? (
                <Background {...background} className={styles.background} />
            ) : null}

            <div className={styles.inner}>
                {image !== null || placeholder ? (
                    <div className={styles.imageContainer}>
                        <AuthorImage
                            name={name}
                            slug={slug}
                            media={image}
                            size="responsive"
                            className={styles.image}
                            withPlaceholder={placeholder}
                            withoutCircle
                            pictureClassName={styles.picture}
                            initialsClassName={styles.initials}
                        />
                    </div>
                ) : null}
                <div className={styles.label}>
                    <HeaderTitle className={styles.name}>
                        {placeholder ? <PlaceholderText /> : name}

                        {subscription !== null && subscribed !== null ? (
                            <div className={styles.subscription}>
                                {!subscribed ? (
                                    <SubscriptionForm
                                        subscription={subscription.handle}
                                        source="author_page"
                                        buttonOnly
                                        className={styles.form}
                                    />
                                ) : (
                                    <div>
                                        Vous êtes abonné·e! (
                                        <BasicButton
                                            onClick={() => unsubscribe()}
                                            className={styles.link}
                                        >
                                            Se désabonner
                                        </BasicButton>
                                        )
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </HeaderTitle>
                    {title !== null ? (
                        <HeaderDescription html={title} className={styles.roles} />
                    ) : null}
                    {description !== null ? (
                        <HeaderDescription html={description} className={styles.description} />
                    ) : null}
                    {links !== null && links.length > 0 ? (
                        <div className={styles.links}>
                            <ul className={styles.items}>
                                {links.map(({ type, url }) => (
                                    <li key={type} className={styles.item}>
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={styles.link}
                                        >
                                            {linkLabels[type]}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

AuthorDefaultHeader.propTypes = propTypes;
AuthorDefaultHeader.defaultProps = defaultProps;

export default AuthorDefaultHeader;
