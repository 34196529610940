import { useLocation } from 'wouter';

import { useRequestBase } from '../contexts/RequestContext';

function useCurrentUrl() {
    const baseUri = useRequestBase();
    const [routeLocation] = useLocation();
    return `${baseUri}${routeLocation}`;
}

export default useCurrentUrl;
