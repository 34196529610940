import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import SponsorTypo from '../typography/Sponsor';

import styles from '../../styles/partials/sponsor.module.css';

const propTypes = {
    organisation: AppPropTypes.organisation.isRequired,
    image: AppPropTypes.image,
    withImage: PropTypes.bool,
    url: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    imageClassName: PropTypes.string,
};

const defaultProps = {
    image: null,
    withImage: false,
    url: null,
    className: null,
    labelClassName: null,
    imageClassName: null,
};

function Sponsor({
    organisation,
    image,
    withImage,
    url,
    className,
    labelClassName,
    imageClassName,
}) {
    const { name = null } = organisation || {};
    const { url: imageUrl = null } = image || {};
    return name !== null && (!withImage || image === null) ? (
        <SponsorTypo className={classNames([styles.container, className])}>
            <FormattedMessage
                defaultMessage="Présenté par <strong>{sponsor}</strong>"
                description="Sponsor label"
                values={{
                    sponsor: name,
                }}
            />
        </SponsorTypo>
    ) : (
        <SponsorTypo
            tag={withImage && url !== null ? 'a' : 'div'}
            href={withImage ? url : null}
            target={url !== null ? '_blank' : null}
            className={classNames([
                styles.container,
                {
                    [styles.withImage]: withImage && imageUrl !== null,
                    [styles.link]: url !== null,
                },
                className,
            ])}
        >
            <div className={classNames([styles.label, labelClassName])}>
                <FormattedMessage defaultMessage="Présenté par" description="Sponsor label" />
            </div>

            {withImage && imageUrl !== null ? (
                <img
                    src={imageUrl}
                    className={classNames([styles.image, imageClassName])}
                    alt={name || 'icon'}
                    title={name || 'icon'}
                />
            ) : null}
        </SponsorTypo>
    );
}

Sponsor.propTypes = propTypes;
Sponsor.defaultProps = defaultProps;

export default Sponsor;
