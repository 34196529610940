/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Image from './Image';

import styles from '../../styles/partials/background.module.css';

const propTypes = {
    color: PropTypes.string,
    image: AppPropTypes.image,
    imageRepeat: PropTypes.bool,
    imageFade: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    imageFadeOpacity: PropTypes.number,
    imagePosition: AppPropTypes.position,
    imageSize: PropTypes.string,
    gradient: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
};

const defaultProps = {
    color: null,
    image: null,
    imageRepeat: false,
    imageFade: false,
    imageFadeOpacity: 0.8,
    imagePosition: null,
    imageSize: 'responsive',
    gradient: null,
    className: null,
    imageClassName: null,
    pictureClassName: null,
};

function Background({
    color,
    image,
    imageRepeat,
    imageFade,
    imageFadeOpacity,
    imagePosition,
    imageSize,
    gradient,
    className,
    imageClassName,
    pictureClassName,
}) {
    const { url: imageUrl = null } = useMemo(() => {
        const { sizes = null } = image || {};
        const size =
            imageSize !== null ? (sizes || []).find(({ id }) => id === imageSize) || null : null;
        if (size !== null) {
            return size;
        }
        return image || {};
    }, [imageSize, image]);

    const style = useMemo(() => {
        if (gradient !== null) {
            return {
                backgroundImage:
                    imageUrl !== null
                        ? `linear-gradient(${gradient.join(',')}), url("${imageUrl}")`
                        : `linear-gradient(${gradient.join(',')})`,
                backgroundSize: 'cover',
            };
        }
        return {
            background: color,
            backgroundImage: imageRepeat ? `url(${imageUrl})` : null,
        };
    }, [imageUrl, gradient]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.imageRepeat]: imageRepeat,
                },
                className,
            ])}
            style={style}
        >
            {imageFade !== null && imageFade !== false ? (
                <div
                    className={styles.fade}
                    style={{
                        opacity: imageFadeOpacity,
                        ...(isString(imageFade)
                            ? {
                                  backgroundColor: imageFade,
                              }
                            : null),
                        ...(isArray(imageFade)
                            ? {
                                  background: `linear-gradient(top bottom, ${imageFade.join(',')})`,
                              }
                            : null),
                    }}
                />
            ) : null}
            {image !== null && !imageRepeat && gradient === null ? (
                <Image
                    className={classNames([styles.image, imageClassName])}
                    pictureClassName={pictureClassName}
                    media={image}
                    position={imagePosition}
                    size={imageSize}
                />
            ) : null}
        </div>
    );
}

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
