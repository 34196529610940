import { useSpring } from '@react-spring/web';
import { useCallback, useState } from 'react';

export default function useSidebarSpring({
    opened = false,
    menuButtonVisible = true,
    sidebarWidth = 0,
    sidebarOverContent = false,
} = {}) {
    const [sidebarClosed, setSidebarClosed] = useState(!opened);
    const onSidebarSpringResolve = useCallback(() => {
        setSidebarClosed(!opened);
    }, [opened, setSidebarClosed]);
    const { marginRight, ...contentStyle } = useSpring({
        from: {
            marginRight: 0,
            opacity: 1,
        },
        to: {
            opacity: opened && sidebarOverContent ? 0.4 : 1,
            marginRight: opened && !sidebarOverContent ? sidebarWidth : 0,
        },
    });
    const sidebarStyle = useSpring({
        from: {
            x: '100%',
        },
        to: {
            x: opened ? '0%' : '100%',
        },
        onResolve: onSidebarSpringResolve,
    });
    const menuButtonStyle = useSpring({
        from: {
            x: '0%',
        },
        to: {
            x: opened || !menuButtonVisible ? '100%' : '0%',
        },
    });

    return {
        contentStyle: {
            ...contentStyle,
            width: marginRight.to((val) => `calc(100% - ${val}px)`),
        },
        sidebarStyle,
        menuButtonStyle,
        sidebarClosed,
        visible: !sidebarClosed || opened,
    };
}
