/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import { usePopularDocuments } from '../../hooks/useDocuments';
import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import * as AppPropTypes from '../../lib/PropTypes';
import { createLayoutRow, createVideosRow } from '../../lib/blocks';

import PillButton from '../buttons/PillButton';
import UrbaniaHeader from '../headers/UrbaniaHeader';
import HomeSectionTitle from '../partials/HomeSectionTitle';
import SectionTitle from '../typography/SectionTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-urbania.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageUrbania({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const { sections = [] } = brand || {};
    const blocks = useUniqueDocumentsBlocks([
        // {
        //     role: 'block',
        //     type: 'header_featured_over',
        //     brand,
        //     withBrandLogo: false,
        //     // withShadow: true,
        //     // cardType: 'over',
        //     // cardTheme: 'outline',
        //     // cardRatio: 'vertical',
        //     cardTheme: 'box-translucent',
        //     cardWidth: 200,
        //     cardWithout: ['brand-icon'],
        //     count: 1,
        //     className: styles.featuredBlock,
        //     listClassName: styles.featuredList,
        //     query: {
        //         brand: brand.id,
        //     },
        // },
        {
            role: 'block',
            type: 'header_featured_list',
            brand,
            // withShadow: true,
            // cardType: 'over',
            // cardTheme: 'outline',
            // cardRatio: 'vertical',
            cardTheme: 'over-bottom-left',
            cardWithout: ['category'],
            count: 1,
            className: styles.featuredBlock,
            innerClassName: styles.featuredInner,
            cardClassName: styles.featuredCard,
            query: {
                brand: brand.id,
                exclude_type: 'contest',
            },
            uniqueDocuments: true,
        },
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <HomeSectionTitle
                    url={url('all', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus récents"
                        description="Section title"
                    />
                </HomeSectionTitle>
            ),
            query: {
                brand: brand.id,
                // type: ['article', 'video'],
            },
            cardWithout: ['brand-icon'],
            loading: 'lazy',
            cardImageLoading: 'lazy',
            uniqueDocuments: true,
        }),
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <HomeSectionTitle
                    url={url('popular', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus populaires"
                        description="Section title"
                    />
                </HomeSectionTitle>
            ),
            query: {
                brand: brand.id,
                // popular: 'week',
            },
            hook: usePopularDocuments,
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        {
            role: 'block',
            type: 'header_featured_sidebar',
            presentation: 'row',
            itemsType: 'documents',
            title: <HomeSectionTitle url={url('micromags')}>Micromag URBANIA</HomeSectionTitle>,
            query: {
                type: 'micromag',
                collection: 'micromag-urbania',
                // topic: 'micromag-urbania',
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            featuredCardType: 'featured-micromag',
            featuredCardTheme: 'left',
            featuredCardRatio: 'micromag',
            featuredCardWith: ['image-link'],
            featuredCardWithout: ['link'],
            cardType: 'over',
            cardSize: 'big',
            cardTheme: 'outline',
            cardRatio: 'micromag',
            responsive: true,
            cardWidth: 200,
            // count: 9,
            cardWithout: ['text', 'brand-icon', 'type-icon'],
            withBorderRadius: false,
            featuredCardClassName: styles.featuredMicromag,
        },
        createVideosRow({
            title: (
                <HomeSectionTitle url={url('videos')}>
                    <FormattedMessage
                        defaultMessage="Vidéos récentes"
                        description="Section title"
                    />
                </HomeSectionTitle>
            ),
            query: {
                brand: brand.id,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['category'],
            uniqueDocuments: true,
        }),
        {
            type: 'collections-row',
            presentation: 'grid',
            title: (
                <HomeSectionTitle url={url('videos')}>
                    <FormattedMessage defaultMessage="Séries vidéos" description="Section title" />
                </HomeSectionTitle>
            ),
            count: 6,
            query: {
                // brand: brand.id,
                handle: [
                    'courrier-recommande',
                    'entre-moi-et-moi',
                    'passes-croises',
                    'fragments',
                    'comment-faire-lamour',
                    'notre-dame-du-stand-up',
                ],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'over',
            cardRatio: 'vertical',
            cardWidth: 200,
            cardWithout: ['brand-icon', 'text'],
        },
        {
            type: 'collections-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            title: (
                <HomeSectionTitle url={url('podcasts')}>
                    <FormattedMessage defaultMessage="Balados" description="Section title" />
                </HomeSectionTitle>
            ),
            count: 3,
            query: {
                // brand: brand.id,
                handle: [
                    'les-pires-moments-de-lhistoire',
                    'pas-nees-pour-un-petit-pain',
                    'histoires-de-mauvais-couts',
                ],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: null,
            cardTheme: 'box-translucent',
            cardWidth: 350,
            cardProps: {
                listTitle: (
                    <HomeSectionTitle>
                        <FormattedMessage
                            defaultMessage="Derniers épisodes"
                            description="Section title"
                        />
                    </HomeSectionTitle>
                ),
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon', 'category', 'serie'],
                },
                listQuery: {
                    type: 'podcast',
                },
            },
            cardWithout: ['brand-icon', 'text'],
        },
        // {
        //     role: 'block',
        //     type: 'documents-row',
        //     // presentation: 'row',

        //     title: (
        //         <SectionTitle className={classNames([styles.sectionTitle])}>
        //             <Link href={url('podcasts')}>Derniers épisodes</Link>
        //         </SectionTitle>
        //     ),
        //     query: {
        //         type: 'podcast',
        //         brand: brand.id,
        //         // offset:2,
        //     },
        //     loading: 'lazy',
        //     cardType: 'horizontal',
        //     cardSize: null,
        //     cardTheme: 'box-translucent',
        //     cardRatio: 'square',
        //     cardWithout: ['brand-icon', 'author'],
        //     cardWidth: 300,
        //     fixedColumns: [1, 2, 3],
        //     // columns: 1,
        //     count: 6,
        // },

        ...sections.map(({ id: sectionId, label, slug }) =>
            createLayoutRow('1_1_1_4', {
                role: 'block',
                title: (
                    <HomeSectionTitle url={url('section', { brand: brand.slug, section: slug })}>
                        {label}
                    </HomeSectionTitle>
                ),
                loading: 'lazy',
                query: {
                    brand: brand.id,
                    section: sectionId,
                },
                cardType: 'vertical',
                cardTheme: 'box-translucent',
                cardWithout: slug !== 'culture' ? ['brand-icon', 'category'] : ['brand-icon'],
                uniqueDocuments: true,
            }),
        ),
        {
            type: 'authors-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            title: <HomeSectionTitle>Dans l’univers URBANIA</HomeSectionTitle>,
            count: 4,
            query: {
                // brand: brand.id,
                handle: ['hugo-meunier', 'jean-bourbeau', 'benoit-lelievre', 'malia-kounkou'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: 'medium',
            cardTheme: 'box-translucent',
            cardWidth: 300,
            cardProps: {
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                },
                listCount: 4,
            },
            cardWithout: ['brand-icon', 'text'],
        },
        {
            type: 'brands-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            title: (
                <>
                    {/* <SectionTitle className={classNames([styles.sectionTitle])}>
                        Nos médias
                    </SectionTitle> */}
                    {/* <SectionTitle className={classNames([styles.sectionTitle, styles.sub])}>
                            <Link href={url('podcasts')}>En primeur</Link>
                        </SectionTitle> */}
                </>
            ),
            count: 4,
            query: {
                // brand: brand.id,
                handle: ['dehors', 'quatre95', 'mollo', 'periodelibre'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: 'medium',
            cardTheme: 'box-translucent',
            cardWidth: 300,
            cardProps: {
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                    hook: usePopularDocuments,
                },
                listCount: 5,
            },
            cardWithout: ['brand-icon', 'text'],
        },
    ]);
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            blocks={blocks}
            header={<UrbaniaHeader className={styles.header} />}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageUrbania.propTypes = propTypes;
HomePageUrbania.defaultProps = defaultProps;

export default HomePageUrbania;
