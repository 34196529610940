/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from '@folklore/forms';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAuthLogin, useAuthSendToken } from '../../hooks/useAuth';

import BasicButton from '../buttons/BasicButton';
import ContactField from '../fields/ContactField';
import FieldArrow from '../icons/FieldArrowIcon';
import LoadingIcon from '../icons/LoadingIcon';
import FormControl from './FormControl';
import VerifyToken from './VerifyTokenForm';

import styles from '../../styles/forms/login-form.module.css';

const propTypes = {
    onComplete: PropTypes.func,
    onVerify: PropTypes.func,
    onCancelVerify: PropTypes.func,
    onTypeChange: PropTypes.func,
    fieldClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    onComplete: null,
    onVerify: null,
    onCancelVerify: null,
    onTypeChange: null,
    fieldClassName: null,
    className: null,
};

function LoginForm({
    onVerify,
    onCancelVerify,
    onComplete,
    onTypeChange,
    fieldClassName,
    className,
}) {
    const intl = useIntl();
    const { sendTokenAsync } = useAuthSendToken();
    const { loginAsync } = useAuthLogin();
    const tracking = useTracking();
    const formFields = useMemo(() => ['field', 'phone', 'email'].filter(Boolean), []);

    const [fieldType, setFieldType] = useState('email');
    const [verifyToken, setVerifyToken] = useState(false);
    const method = fieldType === 'tel' ? 'phone' : 'email';

    const postSendToken = useCallback(
        (formAction, { field }) =>
            sendTokenAsync({
                [method]: field,
            }),
        [method, sendTokenAsync],
    );
    const onSendTokenComplete = useCallback(() => {
        setVerifyToken(true);
        if (onVerify !== null) {
            onVerify(method);
        }
        tracking.trackEvent('Auth', 'send_token', method);
    }, [setVerifyToken, onVerify, method, tracking]);

    const { fields, onSubmit, status, setValue, errors, setErrors } = useForm({
        fields: formFields,
        postForm: postSendToken,
        onComplete: onSendTokenComplete,
    });

    const {
        field: { value: fieldValue },
    } = fields;

    const onFieldTypeChange = useCallback(
        (newType) => {
            setFieldType(newType);
            setErrors(null);
            if (onTypeChange !== null) {
                onTypeChange(newType);
            }
        },
        [setFieldType, onTypeChange],
    );

    const postVerifyToken = useCallback(
        (formAction, { token }) =>
            loginAsync({
                [method]: fieldValue,
                token,
            }),
        [method, loginAsync, fieldValue],
    );

    const onClickCancelVerify = useCallback(() => {
        setVerifyToken(false);
        setValue({
            field: null,
        });
        if (onCancelVerify !== null) {
            onCancelVerify();
        }
        tracking.trackEvent('Auth', 'cancel_verify', method);
    }, [setVerifyToken, setValue, onCancelVerify, tracking, method]);

    const onVerifyTokenComplete = useCallback(
        (newUser) => {
            if (onComplete !== null) {
                onComplete(newUser, method);
            }
            tracking.trackEvent('Auth', 'login', method);
        },
        [onComplete, method, tracking],
    );

    const { errors: fieldErrors = null } = fields[method] || {};

    if (verifyToken) {
        return (
            <VerifyToken
                postForm={postVerifyToken}
                autoFocus
                className={classNames([styles.container, className])}
                onClickCancel={onClickCancelVerify}
                onComplete={onVerifyTokenComplete}
            />
        );
    }
    return (
        <form
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                },
                className,
            ])}
            onSubmit={onSubmit}
        >
            <FormControl {...fields[method]} withoutErrors>
                <div className={styles.fieldGroup}>
                    <ContactField
                        {...fields.field}
                        required
                        errors={fieldErrors}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Inscris ton n. de tel ou courriel',
                            description: 'Field placeholder',
                        })}
                        onTypeChange={onFieldTypeChange}
                        className={fieldClassName}
                    />
                    <BasicButton
                        type="submit"
                        transparent
                        className={styles.button}
                        disabled={status === 'loading'}
                    >
                        {status === 'loading' ? (
                            <LoadingIcon className={styles.icon} />
                        ) : (
                            <FieldArrow className={styles.icon} />
                        )}
                    </BasicButton>
                </div>
            </FormControl>
        </form>
    );
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
