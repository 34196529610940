import { useMemo } from 'react';
import { useTouchScreen } from '../contexts/DeviceContext';
import useWindowSize from './useWindowSize';


function useStableWindowHeight() {
    const isTouchScreen = useTouchScreen();
    const { width, height } = useWindowSize();
    return useMemo(() => height, [width, isTouchScreen ? null : height]);
}

export default useStableWindowHeight;
