import Base from './Base';

class Visitor extends Base {
    get(opts = null) {
        return this.getJSONWithSession('/visitor', null, opts);
    }

    addAudiences(audiences, opts = null) {
        return this.postJSONWithSession(
            '/visitor/audiences',
            {
                audiences,
            },
            opts,
        );
    }
}

export default Visitor;
