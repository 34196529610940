/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

// import PropTypes from 'prop-types';
// import * as AppPropTypes from '../../../lib/PropTypes';
import ContactField from './ContactField';

const propTypes = {};

const defaultProps = {};

const PhoneField = (props) => <ContactField type="tel" {...props} />;

PhoneField.propTypes = propTypes;
PhoneField.defaultProps = defaultProps;

export default forwardRef((props, ref) => <PhoneField {...props} inputRef={ref} />);
