/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import AuthorImage from '../partials/AuthorImage';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/author-vertical-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    name: PropTypes.string,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf(['menu', null, 'label-on-top']),
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    placeholder: PropTypes.bool,
    width: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    size: null,
    slug: null,
    linkQuery: null,
    name: null,
    image: null,
    theme: null,
    ratio: null,
    placeholder: false,
    width: 100,
    className: null,
};

function AuthorVerticalCard({
    size,
    slug,
    linkQuery,
    name,
    image,
    theme,
    width,
    ratio,
    placeholder,
    className,
}) {
    const url = useAuthorUrlGenerator();

    const thumbnailRatio = getRatio(ratio, 1);
    const labelOnTop = theme === 'label-on-top';
    const isMenu = theme === 'menu';

    return (
        <VerticalCard
            href={
                url(
                    {
                        slug,
                    },
                    linkQuery,
                ) || '#'
            }
            thumbnail={
                <AuthorImage
                    name={name}
                    slug={slug}
                    media={image}
                    className={styles.thumbnail}
                    width={width}
                    height={width / thumbnailRatio}
                    size="medium"
                    withoutSize
                    withPlaceholder={placeholder}
                />
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                styles[camelCase(theme)],
                styles[camelCase(size)],
                styles[camelCase(ratio)],
                {
                    [className]: className !== null,
                },
            ])}
            labelOnTop={labelOnTop}
        >
            <div className={styles.titleContainer}>
                {placeholder ? (
                    <CardTitle tag="strong" className={styles.title}>
                        <PlaceholderText lines={1} maxCharacters={10} />
                    </CardTitle>
                ) : (
                    <CardTitle tag="strong" className={styles.title}>
                        {!isMenu ? (
                            <FormattedMessage
                                defaultMessage="Découvrez les articles de {name}"
                                description="Author article"
                                values={{
                                    name,
                                }}
                            />
                        ) : (
                            name
                        )}
                    </CardTitle>
                )}
            </div>
        </VerticalCard>
    );
}

AuthorVerticalCard.propTypes = propTypes;
AuthorVerticalCard.defaultProps = defaultProps;

export default AuthorVerticalCard;
