import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    border: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    border: '#222',
    className: null,
};

function Pin({ color, border, className }) {
    return (
        <svg
            width="42"
            height="58"
            viewBox="0 0 42 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                id="Icon_ionic-md-pin"
                d="M21 1.47949C9.99895 1.47949 1 10.2993 1 21.0791C1 33.374 14.9915 50.5674 19.5694 55.8638C20.3337 56.7481 21.6663 56.7481 22.4306 55.8638C27.0085 50.5674 41 33.374 41 21.0791C41 10.2993 32.0011 1.47949 21 1.47949Z"
                fill={color}
                stroke={border}
            />
        </svg>
    );
}

Pin.propTypes = propTypes;
Pin.defaultProps = defaultProps;

export default Pin;
