/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'wouter';

import { getTrackingQuery } from '../../lib/utils';

import CategoryTypography from '../typography/Category';

import styles from '../../styles/partials/category.module.css';

const propTypes = {
    slug: PropTypes.string,
    clickRef: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.bool,
    withLink: PropTypes.bool,
    editable: PropTypes.string,
    editablePicker: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    clickRef: null,
    label: null,
    placeholder: false,
    withLink: false,
    editable: null,
    editablePicker: null,
    className: null,
};

function Category({
    slug,
    clickRef,
    label,
    placeholder,
    withLink,
    editable,
    editablePicker,
    className,
}) {
    const url = useUrlGenerator();
    return (
        <CategoryTypography
            className={classNames([styles.container, className])}
            tag="div"
            editable={editable}
            editablePicker={editablePicker}
            placeholder={placeholder}
        >
            {withLink ? (
                <Link
                    href={
                        slug !== null
                            ? `${url('category', {
                                  slug,
                              })}${clickRef !== null ? `?${queryString.stringify(getTrackingQuery({ clickRef }))}` : ''}`
                            : '#'
                    }
                    className={styles.link}
                >
                    {label}
                </Link>
            ) : (
                label
            )}
        </CategoryTypography>
    );
}

Category.propTypes = propTypes;
Category.defaultProps = defaultProps;

export default Category;
