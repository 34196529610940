/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { isValidElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import PillButton from '../buttons/PillButton';
import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import Sponsor from '../partials/Sponsor';
import SummaryDescription from '../typography/SummaryDescription';
import SummaryTitle from '../typography/SummaryTitle';
import SurtitleTypography from '../typography/Surtitle';

import styles from '../../styles/summaries/summary.module.css';

const propTypes = {
    title: PropTypes.string,
    surtitle: PropTypes.string,
    description: PropTypes.string,
    sponsor: AppPropTypes.sponsor,
    image: AppPropTypes.image,
    imagePosition: PropTypes.oneOf(['top', 'inline']),
    icon: PropTypes.node,
    buttonLabel: PropTypes.string,
    link: PropTypes.string,
    children: PropTypes.node,
    placeholder: PropTypes.bool,
    withLink: PropTypes.bool,
    withButton: PropTypes.bool,
    withoutImage: PropTypes.bool,
    withoutTitle: PropTypes.bool,
    withoutDescription: PropTypes.bool,
    withoutSurtitle: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    surtitleClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    surtitle: null,
    description: null,
    sponsor: null,
    image: null,
    imagePosition: null,
    icon: null,
    buttonLabel: 'Voir',
    link: null,
    children: null,
    placeholder: false,
    withLink: false,
    withButton: false,
    withoutImage: false,
    withoutTitle: false,
    withoutDescription: false,
    withoutSurtitle: false,
    className: null,
    innerClassName: null,
    iconClassName: null,
    imageClassName: null,
    pictureClassName: null,
    titleClassName: null,
    surtitleClassName: null,
    descriptionClassName: null,
    buttonClassName: null,
};

function Summary({
    surtitle,
    title,
    description,
    sponsor,
    image,
    imagePosition,
    icon,
    buttonLabel,
    link,
    children,
    placeholder,
    withLink,
    withButton,
    withoutImage,
    withoutTitle,
    withoutDescription,
    withoutSurtitle,
    className,
    innerClassName,
    iconClassName,
    imageClassName,
    pictureClassName,
    titleClassName,
    surtitleClassName,
    descriptionClassName,
    buttonClassName,
}) {
    const inner = (
        <div className={classNames([styles.inner, innerClassName])}>
            {icon !== null ? (
                <div className={classNames([styles.icon, iconClassName])}>{icon}</div>
            ) : null}
            {!withoutImage && (image !== null || placeholder) && !isValidElement(image) ? (
                <Image
                    className={classNames([
                        styles.image,
                        {
                            [imageClassName]: imageClassName !== null,
                        },
                    ])}
                    pictureClassName={classNames([styles.picture, pictureClassName])}
                    media={image}
                    size="small"
                    withPlaceholder={placeholder}
                />
            ) : null}
            {!withoutImage && image !== null && isValidElement(image)
                ? React.cloneElement(image, {
                      className: classNames([
                          styles.image,
                          {
                              [imageClassName]: imageClassName !== null,
                          },
                      ]),
                  })
                : null}
            {!withoutSurtitle && surtitle !== null ? (
                <SurtitleTypography
                    className={classNames([styles.surtitle, surtitleClassName])}
                    placeholder={placeholder}
                >
                    {surtitle}
                </SurtitleTypography>
            ) : null}
            {!withoutTitle ? (
                <SummaryTitle
                    className={classNames([styles.title, titleClassName])}
                    placeholder={placeholder}
                >
                    {title}
                </SummaryTitle>
            ) : null}

            {!withoutDescription && (description !== null || placeholder) ? (
                <SummaryDescription
                    className={classNames([styles.description, descriptionClassName])}
                    html={description}
                    placeholder={placeholder}
                >
                    {null}
                </SummaryDescription>
            ) : null}
            {sponsor !== null ? (
                <Sponsor
                    {...sponsor}
                    withImage
                    className={styles.sponsor}
                    imageClassName={styles.sponsorImage}
                />
            ) : null}
            {children !== null ? <div className={styles.children}>{children}</div> : null}
            {withButton && buttonLabel !== null && link !== null ? (
                <PillButton
                    href={link}
                    compact
                    bordered
                    className={classNames([styles.button, buttonClassName])}
                >
                    {buttonLabel}
                    {/* <FormattedMessage
                defaultMessage="{label}"
                description="Label button"
                values={{
                    label: buttonLabel,
                }}
            /> */}
                </PillButton>
            ) : null}
        </div>
    );

    if (withLink && link !== null) {
        return (
            <Link
                href={link}
                className={classNames([
                    styles.container,
                    {
                        [styles.withIcon]: icon !== null,
                        [styles.withoutImage]: (image === null && !placeholder) || withoutImage,
                        [styles[imagePosition]]:
                            imagePosition !== null &&
                            (image !== null || icon !== null || placeholder),
                    },
                    className,
                ])}
            >
                {inner}
            </Link>
        );
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withIcon]: icon !== null,
                    [styles.withoutImage]: (image === null && !placeholder) || withoutImage,
                    [styles[imagePosition]]:
                        imagePosition !== null && (image !== null || icon !== null || placeholder),
                },
                className,
            ])}
        >
            {inner}
        </div>
    );
}

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
