import { useRoutes, useRouteMatcher } from '@folklore/routes';
import { useCallback } from 'react';

import { useBrand } from '../contexts/SiteContext';

export default function useBrandMatcher() {
    const routeMatcher = useRouteMatcher();
    const routes = useRoutes();

    const matcher = useCallback(
        (location) => {
            const [, { brand: brandParam = null } = {}] = routeMatcher(
                routes['brand.wildcard'],
                location,
            );

            const routeBrand = useBrand(brandParam);
            if (routeBrand !== null) {
                return routeBrand;
            }

            return null;
        },
        [routeMatcher, routes],
    );

    return matcher;
}
