/* eslint-disable react/jsx-no-script-url */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';

import DocumentTypeIcon from '../icons/DocumentTypeIcon';
// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import CardTitle from '../typography/CardTitle';

import styles from '../../styles/cards/document-text-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    linkQuery: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf([null, 'small', 'big']),
    className: PropTypes.string,
    linkClassName: PropTypes.string,
};

const defaultProps = {
    type: null,
    slug: null,
    linkQuery: null,
    title: null,

    size: null,

    className: null,
    linkClassName: null,
};

function DocumentTextCard({ type, slug, linkQuery, title, size, className, linkClassName }) {
    const url = useDocumentUrlGenerator();
    return (
        <div className={classNames([styles.container, styles[size], className])}>
            <Link
                href={
                    url(
                        {
                            type,
                            slug,
                        },
                        linkQuery,
                    ) || '#'
                }
                className={classNames([styles.link, linkClassName])}
            >
                <DocumentTypeIcon type={type} className={styles.icon} />
                <CardTitle className={styles.title}>{title}</CardTitle>
            </Link>
        </div>
    );
}

DocumentTextCard.propTypes = propTypes;
DocumentTextCard.defaultProps = defaultProps;

export default DocumentTextCard;
