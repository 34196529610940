/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function EditIcon({ color, className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            className={className}
        >
            <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
                <path d="M5.5 2.238H2.18a.946.946 0 0 0-.947.947v6.634c0 .523.424.947.947.947h6.634a.946.946 0 0 0 .947-.947V6.503" />
                <path d="M9.05 1.527a1.005 1.005 0 0 1 1.422 1.42l-4.5 4.5-1.896.474.474-1.895 4.5-4.5Z" />
            </g>
        </svg>
    );
}

EditIcon.propTypes = propTypes;
EditIcon.defaultProps = defaultProps;

export default EditIcon;
