/* eslint-disable react/jsx-props-no-spreading */
import { useBlockComponent } from '@niche-js/core/contexts';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/blocks/images-block.module.css';

const propTypes = {
    items: PropTypes.arrayOf(AppPropTypes.block).isRequired,
    presentation: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    presentation: 'grid',
    className: null,
};

function ImagesBlock({ items, presentation, className }) {
    const ImageBlock = useBlockComponent('image');
    return (
        <div className={classNames([styles.container, styles[presentation], className])}>
            <div className={styles.items}>
                {items.map((it) => (
                    <div className={styles.item}>
                        <ImageBlock {...it} className={styles.block} />
                    </div>
                ))}
            </div>
        </div>
    );
}

ImagesBlock.propTypes = propTypes;
ImagesBlock.defaultProps = defaultProps;

export default ImagesBlock;
