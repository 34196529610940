/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import useDocumentsInfinite from '../../hooks/useDocumentsInfinite';
import * as AppPropTypes from '../../lib/PropTypes';

import Blocks from '../blocks/Blocks';

import styles from '../../styles/lists/infinite-items-blocks.module.css';

const getCountFromBlock = ({ count = 6, sidebarCount = null }) =>
    count || (sidebarCount !== null ? sidebarCount + 1 : 0);

const propTypes = {
    blocks: PropTypes.arrayOf(AppPropTypes.block),
    itemsBlocks: PropTypes.arrayOf(AppPropTypes.block),
    separatorBlocks: PropTypes.arrayOf(AppPropTypes.block),
    infiniteSeparators: PropTypes.bool,
    hook: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    itemsType: PropTypes.string,
    cardType: PropTypes.string,
    cardTheme: PropTypes.string,
    cardRatio: PropTypes.string,
    cardSize: PropTypes.string,
    cardWithout: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    blocks: [],
    itemsBlocks: [
        {
            type: 'items',
            itemsType: 'documents',
            presentation: 'grid',
            cardWidth: 350,
            cardSize: 'big',
            cardTheme: 'box-translucent',
            cardRatio: 'horizontal',
            count: 8,
            maxColumns: 4,
        },
        {
            type: 'items',
            itemsType: 'documents',
            presentation: 'grid',
            cardWidth: 220,
            cardTheme: 'box-translucent',
            cardRatio: 'horizontal',
            cardSize: 'responsive',
            count: 12,
            minColumns: 2,
        },
    ],
    separatorBlocks: [
        {
            type: 'ad',
            slot: 'fullwidth',
        },
    ],
    infiniteSeparators: true,
    hook: useDocumentsInfinite,
    query: null,
    itemsType: 'documents',
    cardType: undefined,
    cardTheme: undefined,
    cardRatio: undefined,
    cardSize: undefined,
    cardWithout: undefined,
    disabled: false,
    className: null,
};
function InfiniteItemsBlocks({
    blocks: initialBlocks,
    itemsBlocks,
    separatorBlocks,
    infiniteSeparators,
    hook,
    query,
    itemsType,
    cardType,
    cardTheme,
    cardRatio,
    cardSize,
    cardWithout,
    disabled,
    className,
}) {
    const [blocks, setBlocks] = useState(initialBlocks);
    const [separatorBlockIndex, setSeparatorIndex] = useState(0);
    const { ref: endOfPageRef, visible: endIsVisible } = useIsVisible({
        rootMargin: '50%',
    });

    useEffect(() => {
        if (blocks !== initialBlocks) {
            setBlocks(initialBlocks);
            setSeparatorIndex(0);
        }
    }, [initialBlocks]);

    // const initialCount = initialBlocks.reduce((acc, block) => acc + getCountFromBlock(block), 0);

    const {
        pages = null,
        isFetching = false,
        isFetchingNextPage = false,
        isInitialLoading = false,
        fetchNextPage,
    } = hook(
        {
            // count: Math.min(initialCount, 3),
            // count: initialCount,
            ...query,
        },
        {
            enabled: !disabled,
            // suspense: true,
            withOffset: true,
        },
    );

    const items = useMemo(
        () =>
            pages !== null
                ? pages.reduce((allItems, { data: pageItems }) => [...allItems, ...pageItems], [])
                : [],
        [pages],
    );
    const total = (pages || []).reduce(
        (currentTotal, { pagination: { total: newTotal = 0 } }) =>
            currentTotal === null || newTotal > currentTotal ? newTotal : currentTotal,
        null,
    );
    const blockItemsCount = blocks
        .filter(({ separator = false }) => !separator)
        .reduce((acc, block) => acc + getCountFromBlock(block), 0);
    const fetchCount = blockItemsCount - items.length;
    const endIsReached = total !== null && total <= blockItemsCount;

    useEffect(() => {
        if (!disabled) {
            setBlocks(initialBlocks);
        }
    }, [disabled]);

    useEffect(() => {
        if (
            !disabled &&
            fetchCount > 0 &&
            !isFetching &&
            !isFetchingNextPage &&
            !isInitialLoading
        ) {
            fetchNextPage();
        }
    }, [disabled, fetchCount, isFetching, isFetchingNextPage, isInitialLoading]);

    useEffect(() => {
        if (
            !endIsVisible ||
            endIsReached ||
            isFetchingNextPage ||
            (isFetching && !isInitialLoading) ||
            (isInitialLoading && blocks.length === initialBlocks.length)
        ) {
            return;
        }

        const separatorBlock =
            infiniteSeparators || separatorBlockIndex < separatorBlocks.length
                ? separatorBlocks[separatorBlockIndex % separatorBlocks.length] || null
                : null;
        const hasSeparator = separatorBlock !== null;
        const availableBlocks = itemsBlocks
            .map((block, index) => ({
                ...block,
                infiniteIndex: index,
            }))
            .filter(
                ({ withSeparator = null }) =>
                    withSeparator === null || hasSeparator === withSeparator,
            );
        const { infiniteIndex: lastInfiniteBlockIndex = null } =
            [...blocks].reverse().find(({ infiniteIndex = null }) => infiniteIndex !== null) || {};
        const lastRelativeIndex = availableBlocks.findIndex(
            ({ infiniteIndex }) => infiniteIndex === lastInfiniteBlockIndex,
        );
        const nextInifiniteBlockIndex =
            lastRelativeIndex !== -1 && lastRelativeIndex < availableBlocks.length - 1
                ? lastRelativeIndex + 1
                : 0;
        const newBlock = availableBlocks[nextInifiniteBlockIndex];
        setBlocks(
            [
                ...blocks,
                newBlock !== null
                    ? {
                          id: `infinite-${blocks.length}`,
                          ...newBlock,
                      }
                    : null,
                separatorBlock !== null && newBlock !== null
                    ? {
                          id: `separator-${blocks.length + 1}`,
                          ...separatorBlock,
                          separator: true,
                      }
                    : null,
            ].filter((it) => it !== null),
        );
        if (separatorBlockIndex !== null) {
            setSeparatorIndex(separatorBlockIndex + 1);
        }
    }, [
        disabled,
        endIsVisible,
        endIsReached,
        isFetching,
        isFetchingNextPage,
        isInitialLoading,
        pages,
        blocks.length,
        infiniteSeparators,
    ]);

    const blocksWithItems = useMemo(() => {
        const { blocks: newBlocks } = blocks.reduce(
            ({ blocks: currentBlocks, items: currentItems }, block, index) => {
                const { count = 6, sidebarCount = null, separator = false } = block;
                const finalCount = count || (sidebarCount !== null ? sidebarCount + 1 : 0);
                if (separator) {
                    return {
                        blocks: [
                            ...currentBlocks,
                            {
                                cardImageLoading: index > 0 ? 'lazy' : null,
                                ...block,
                            },
                        ],
                        items: currentItems,
                    };
                }
                const blockItems = currentItems.slice(0, finalCount);

                return !endIsReached || blockItems.length > 0
                    ? {
                          blocks: [
                              ...currentBlocks,
                              {
                                  cardImageLoading: index > 0 ? 'lazy' : null,
                                  ...block,
                                  itemsType: itemsType || block.itemsType,
                                  cardType: cardType || block.cardType,
                                  cardWithout: cardWithout || block.cardWithout,
                                  cardTheme: cardTheme || block.cardTheme,
                                  cardRatio: cardRatio || block.cardRatio,
                                  cardSize: cardSize || block.cardSize,
                                  items: blockItems.length > 0 ? blockItems : null,
                                  withoutLoader: true,
                              },
                          ],
                          items: currentItems.slice(finalCount),
                      }
                    : {
                          blocks: currentBlocks,
                          items: currentItems,
                      };
            },
            {
                blocks: [],
                items: items || [],
            },
        );
        return newBlocks;
    }, [
        blocks,
        items,
        endIsReached,
        itemsType,
        cardType,
        cardWithout,
        cardTheme,
        cardRatio,
        cardSize,
    ]);

    const hasNotLoaded = isFetching && isInitialLoading && blocksWithItems.length === 0;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <Blocks
                blocks={hasNotLoaded ? [{
                    id: `infinite-0`,
                    ...itemsBlocks[0],
                    withoutLoader: true,
                }] : blocksWithItems}
                blockClassName={({ separator = false }) =>
                    classNames([
                        styles.block,
                        {
                            [styles.separator]: separator,
                        },
                    ])
                }
            />
            <div className={styles.endOfPage} ref={endOfPageRef} />
        </div>
    );
}

InfiniteItemsBlocks.propTypes = propTypes;
InfiniteItemsBlocks.defaultProps = defaultProps;

export default InfiniteItemsBlocks;
