/* eslint-disable react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';

import styles from '../../styles/pages/micromags-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function MicromagsPage({ brandSlug, current, className, contentClassName, onEnter, onLeave }) {
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                pageType: 'index',
            });
        }
    }, [tracking, current, brand, filters]);

    const blocks = useMemo(
        () => [
            {
                role: 'block',
                type: 'header_featured_sidebar',
                presentation: 'row',
                itemsType: 'documents',
                query: {
                    type: 'micromag',
                    collection: 'micromag-urbania',
                    // topic: 'micromag-urbania',
                },
                loading: 'lazy',
                cardImageLoading: 'lazy',
                featuredCardType: 'featured-micromag',
                featuredCardTheme: 'left',
                featuredCardRatio: 'micromag',
                featuredCardWith: ['image-link'],
                featuredCardWithout: ['link'],
                cardType: 'over',
                cardSize: 'big',
                cardTheme: 'outline',
                cardRatio: 'micromag',
                responsive: true,
                cardWidth: 200,
                // count: 9,
                cardWithout: ['text', 'brand-icon', 'type-icon'],
                withBorderRadius: false,
                className: styles.featured,
                count: 7,
            },
        ],
        [],
    );

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
            ])}
            data-brand={brandHandle}
        >
            {/* <IndexDefaultHeader
                brand={brand}
                title={<FormattedMessage defaultMessage="Micromags" description="Page title" />}
                className={styles.header}
            /> */}
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        type: 'micromag',
                        collection: 'micromag-urbania',
                        ...filters,
                    }}
                    blocks={blocks}
                    itemsBlocks={[
                        {
                            type: 'items',
                            itemsType: 'documents',
                            presentation: 'grid',
                            cardType: 'over',
                            cardRatio: 'micromag',
                            cardTheme: 'outline',
                            cardWidth: 200,
                            // cardSize: 'big',
                            cardWithout: ['brand-icon', 'type-icon'],
                            minColumns: 2,
                            count: 12,
                        },
                    ]}
                />
            </main>
        </div>
    );
}

MicromagsPage.propTypes = propTypes;
MicromagsPage.defaultProps = defaultProps;

export default MicromagsPage;
