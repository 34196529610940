import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function Pause({ color, className }) {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.576172" y="0.578125" width="5.35714" height="14" rx="2" fill={color} />
            <rect x="7.71875" y="0.578125" width="5.35714" height="14" rx="2" fill={color} />
        </svg>
    );
}

Pause.propTypes = propTypes;
Pause.defaultProps = defaultProps;

export default Pause;
