/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import RoundedButton from '../buttons/RoundedButton';

const propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    external: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'button',
    label: null,
    url: null,
    external: false,
    className: null,
};

function CallToAction({ type, label, url, external, className }) {
    if (type === 'button') {
        return (
            <RoundedButton href={url} external={external} compact className={className}>
                {label}
            </RoundedButton>
        );
    }
    return null;
}

CallToAction.propTypes = propTypes;
CallToAction.defaultProps = defaultProps;

export default CallToAction;
