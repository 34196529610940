/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import { getComponentFromName } from '@folklore/utils';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import * as HomeComponents from './homes';

const propTypes = {
    brandSlug: PropTypes.string,
};

const defaultProps = {
    brandSlug: null,
};

function HomePage({ brandSlug, ...props }) {
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};
    const HomeComponent = getComponentFromName(HomeComponents, brandHandle, 'default');
    return <HomeComponent brand={brand} {...props} />;
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
