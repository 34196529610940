/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import LocationTypeIcon from '../icons/LocationTypeIcon';
import MapMarker from '../icons/MapMarker';
import PlaceholderText from '../partials/PlaceholderText';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/location-horizontal-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    onClick: PropTypes.func,
    name: PropTypes.string,
    streetNumber: PropTypes.string,
    address: PropTypes.string,
    streetName: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postalCode: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    ratio: PropTypes.string,
    theme: PropTypes.oneOf(['menu', null]),
    placeholder: PropTypes.bool,
    iconClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    onClick: null,
    name: null,
    streetNumber: null,
    address: null,
    streetName: null,
    city: null,
    region: null,
    postalCode: null,
    types: null,
    ratio: null,
    theme: null,
    placeholder: false,
    iconClassName: null,
    className: null,
};

function LocationHorizontalCard({
    slug,
    onClick,
    name,
    streetNumber,
    address: streetAddress,
    streetName,
    city,
    region,
    postalCode,
    types,
    ratio,
    theme,
    placeholder,
    iconClassName,
    className,
}) {
    const url = useUrlGenerator();

    const isBox = theme !== null && theme.indexOf('box') === 0;

    const thumbnailRatio = getRatio(ratio, 1);
    const height = 60;

    const finalAddress = useMemo(
        () =>
            (streetNumber !== null || streetName !== null
                ? [streetNumber, streetName, city, region, postalCode]
                : [streetAddress, city, region, postalCode]
            )
                .filter((it) => it !== null && it.length > 0)
                .join(', '),
        [streetNumber, streetName, city, region, postalCode, streetAddress],
    );

    const icons = useMemo(
        () =>
            (types || []).reduce(
                (acc, { slug: typeSlug = null }) => (typeSlug !== null ? [...acc, typeSlug] : acc),
                [],
            ),

        [types],
    );

    return (
        <HorizontalCard
            href={
                slug !== null
                    ? url('map.location', {
                          location: slug,
                      })
                    : null
            }
            onClick={onClick}
            thumbnail={
                <div
                    className={styles.iconContainer}
                    style={{
                        width: height,
                        height,
                    }}
                >
                    {icons !== null ? (
                        <MapMarker
                            icons={icons}
                            className={classNames([styles.typeIcon, iconClassName])}
                            pinClassName={styles.iconPin}
                        />
                    ) : (
                        <LocationTypeIcon className={styles.typeIcon} circleColor="transparent" />
                    )}
                </div>
            }
            thumbnailRatio={thumbnailRatio}
            height={height}
            className={classNames([
                styles.container,
                {
                    [styles.box]: isBox,
                    [styles.menu]: theme === 'menu',
                },
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
        >
            <CardTitle tag="strong" className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : name}
            </CardTitle>
            {finalAddress !== null ? (
                <CardSubtitle tag="span" className={styles.subtitle}>
                    {finalAddress}
                </CardSubtitle>
            ) : null}
        </HorizontalCard>
    );
}

LocationHorizontalCard.propTypes = propTypes;
LocationHorizontalCard.defaultProps = defaultProps;

export default LocationHorizontalCard;
