/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/fields/checkbox-field.module.css';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.node,
    value: PropTypes.string,
    errors: AppPropTypes.formErrors,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

const defaultProps = {
    name: null,
    value: null,
    label: null,
    errors: null,
    className: null,
    onChange: null,
};

function CheckboxField({ name, label, value, errors, onChange, className }) {
    const internalRef = useRef(null);
    const onInputChange = useCallback(
        (e) => {
            internalRef.current.setCustomValidity('');
            if (onChange !== null) {
                onChange(e.currentTarget.checked);
            }
        },
        [onChange],
    );
    useEffect(() => {
        internalRef.current.setCustomValidity(
            errors !== null && errors.length > 0 ? errors[0] : '',
        );
        if (errors !== null && errors.length > 0) {
            internalRef.current.reportValidity();
        }
    }, [errors]);
    return (
        <label
            htmlFor={name}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <input
                ref={internalRef}
                type="checkbox"
                name={name}
                id={name}
                checked={value || false}
                onChange={onInputChange}
                className={styles.input}
            />
            <span className={styles.label}>{label}</span>
        </label>
    );
}

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default CheckboxField;
