import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import isTouchScreen from '../lib/isTouchScreen';

const defaultIsTouch = isTouchScreen();

export const DeviceContext = React.createContext({
    touchScreen: defaultIsTouch,
});

export function useDeviceContext() {
    const context = useContext(DeviceContext);
    return context;
}

export function useTouchScreen() {
    const { touchScreen } = useDeviceContext();

    return touchScreen;
}

const propTypes = {
    touchScreen: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    touchScreen: defaultIsTouch,
    children: null,
};

export function DeviceContextProvider({ touchScreen, children }) {
    const value = useMemo(
        () => ({
            touchScreen: touchScreen !== null ? touchScreen : isTouchScreen(),
        }),
        [touchScreen],
    );

    return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
}

DeviceContextProvider.propTypes = propTypes;
DeviceContextProvider.defaultProps = defaultProps;
