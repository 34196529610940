/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIsVisible } from '@folklore/hooks';
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useDocuments from '../../hooks/useDocuments';
import useElementSize from '../../hooks/useElementSize';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import useWindowSize from '../../hooks/useWindowSize';
import * as AppPropTypes from '../../lib/PropTypes';
import { getPrefixedProps } from '../../lib/utils';

import * as CardComponents from '../cards/documents';
import GridList from '../lists/GridList';

import styles from '../../styles/headers/featured-sidebar-header.module.css';

const propTypes = {
    header: PropTypes.node,
    title: PropTypes.node,
    brand: AppPropTypes.brand,
    count: PropTypes.number,
    sidebarCount: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    loading: PropTypes.string,
    featuredCardType: PropTypes.string,
    featuredCardTheme: PropTypes.string,
    featuredCardRatio: PropTypes.string,
    featuredCardWith: PropTypes.arrayOf(PropTypes.string),
    featuredCardWithout: PropTypes.arrayOf(PropTypes.string),
    cardType: PropTypes.string,
    cardTheme: PropTypes.string,
    cardRatio: PropTypes.string,
    cardWith: PropTypes.arrayOf(PropTypes.string),
    cardWithout: PropTypes.arrayOf(PropTypes.string),
    cardImageLoading: PropTypes.oneOf(['lazy', null]),
    withoutLoader: PropTypes.bool,
    withShadow: PropTypes.bool,
    responsive: PropTypes.bool,
    className: PropTypes.string,
    mainClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    featuredCardClassName: PropTypes.string,
    featuredLabelClassName: PropTypes.string,
    featuredImageContainerClassName: PropTypes.string,
    onLoaded: PropTypes.func,
};

const defaultProps = {
    header: null,
    title: null,
    brand: null,
    count: null,
    sidebarCount: 6,
    query: null,
    items: null,
    loading: null,
    featuredCardType: 'featured',
    featuredCardTheme: 'over-bottom-left',
    featuredCardRatio: undefined,
    featuredCardWith: null,
    featuredCardWithout: null,
    cardType: null,
    cardTheme: 'box',
    cardRatio: null,
    cardWith: null,
    cardWithout: null,
    cardImageLoading: undefined,
    withoutLoader: false,
    withShadow: false,
    responsive: false,
    className: null,
    mainClassName: null,
    titleClassName: null,
    featuredCardClassName: null,
    featuredLabelClassName: null,
    featuredImageContainerClassName: null,
    onLoaded: null,
};

function FeaturedSidebarHeader({
    header,
    title,
    brand,
    count,
    sidebarCount,
    query,
    loading,
    items: providedItems,
    featuredCardType,
    featuredCardTheme,
    featuredCardRatio,
    featuredCardWith,
    featuredCardWithout,
    cardType,
    cardTheme,
    cardRatio,
    cardWith,
    cardWithout,
    cardImageLoading,
    withoutLoader,
    withShadow,
    responsive,
    className,
    mainClassName,
    titleClassName,
    featuredCardClassName,
    featuredLabelClassName,
    featuredImageContainerClassName,
    onLoaded,
}) {
    const { ref: visibleRef, visible: isVisible } = useIsVisible({
        persist: true,
        disabled: loading !== 'lazy',
    });
    const finalCount = count || 1 + sidebarCount;
    const finalSidebarCount = finalCount - 1;
    const { handle: brandHandle = null } = brand || {};
    const { items = providedItems } = useDocuments(
        {
            count: finalCount,
            ...query,
        },
        {
            enabled: !withoutLoader && (loading !== 'lazy' || isVisible),
            keepPreviousData: false,
        },
    );
    const [featuredItem = null, ...otherItems] = items || [];
    const placeholderItems = usePlaceholderItems(finalSidebarCount);

    const FeaturedCard = getComponentFromName(
        CardComponents,
        featuredCardType,
        CardComponents.Featured,
    );

    useEffect(() => {
        if (items !== null && onLoaded !== null) {
            onLoaded(items);
        }
    }, [items]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.responsive]: responsive,
                    [styles.withShadow]: withShadow,
                    [className]: className !== null,
                },
            ])}
            ref={visibleRef}
        >
            {header ||
                (title !== null ? (
                    <div className={classNames([styles.title, titleClassName])}>{title}</div>
                ) : null)}
            <div className={styles.inner}>
                <div className={classNames([styles.main, mainClassName])}>
                    <FeaturedCard
                        {...featuredItem}
                        {...getPrefixedProps(featuredCardWithout || cardWithout, 'without')}
                        {...getPrefixedProps(featuredCardWith || cardWith, 'with')}
                        placeholder={featuredItem === null}
                        // placeholder
                        theme={featuredCardTheme}
                        ratio={featuredCardRatio}
                        responsive={responsive}
                        className={classNames([
                            styles.featuredCard,
                            {
                                [styles.isOver]:
                                    featuredCardTheme !== null &&
                                    featuredCardTheme.indexOf('over') === 0,
                                [styles.withRatio]: featuredCardRatio !== null,
                            },
                            featuredCardClassName,
                        ])}
                        innerClassName={styles.featuredInner}
                        labelClassName={classNames([styles.featuredLabel, featuredLabelClassName])}
                        imageContainerClassName={classNames([
                            styles.featuredImageContainer,
                            featuredImageContainerClassName,
                        ])}
                        imageClassName={styles.featuredImage}
                        imageLoading={cardImageLoading}
                        withBreakpoints
                        withBorderRadius
                    />
                </div>
                <aside className={styles.sidebar}>
                    <GridList
                        items={
                            otherItems.length < finalSidebarCount
                                ? [
                                      ...otherItems,
                                      ...placeholderItems.slice(
                                          0,
                                          finalSidebarCount - otherItems.length,
                                      ),
                                  ]
                                : otherItems
                        }
                        cardWidth={150}
                        cardComponent={getComponentFromName(
                            CardComponents,
                            cardType,
                            CardComponents.Vertical,
                        )}
                        cardTheme={cardTheme}
                        cardRatio={cardRatio}
                        cardSize="small"
                        cardWith={cardWith}
                        cardWithout={cardWithout}
                        cardResponsive={responsive}
                        cardImageLoading={cardImageLoading}
                        className={classNames([
                            styles.list,
                            {
                                [styles.grid]: true,
                            },
                        ])}
                        itemsClassName={styles.items}
                        itemClassName={styles.item}
                        cardClassName={styles.card}
                    />
                </aside>
            </div>
        </div>
    );
}

FeaturedSidebarHeader.propTypes = propTypes;
FeaturedSidebarHeader.defaultProps = defaultProps;

export default FeaturedSidebarHeader;
