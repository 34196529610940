/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading, react/no-array-index-key */
import { getComponentFromName } from '@folklore/utils';
import { Component } from '@niche-js/core/components';
import { useIsEditor } from '@niche-js/core/editor/contexts';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import useScrollTrigger from '../../hooks/useScrollTrigger';
import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import * as AppPropTypes from '../../lib/PropTypes';
import { createLocationsList } from '../../lib/blocks';
import getBlocksWithAddons from '../../lib/getBlocksWithAddons';
import getImageSize from '../../lib/getImageSize';

import { useDefaultBrand } from '../../contexts/SiteContext';
import Ad from '../ads/Ad';
import Blocks from '../blocks/Blocks';
import * as HeaderComponents from '../headers/document';
import ArticleTheme from '../themes/ArticleTheme';
import Body from '../typography/Body';

import styles from '../../styles/documents/article-document.module.css';

const propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    surtitle: PropTypes.string,
    image: AppPropTypes.image,
    header: AppPropTypes.component,
    blocks: PropTypes.arrayOf(AppPropTypes.component),
    contest: AppPropTypes.contest,
    metadata: AppPropTypes.documentMetadata,
    theme: AppPropTypes.articleTheme,
    placeholder: PropTypes.bool,
    isPreview: PropTypes.bool,
    className: PropTypes.string,
    current: PropTypes.bool,
    entering: PropTypes.bool,
    sectionOnly: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    editorRef: AppPropTypes.ref,
    containerRef: AppPropTypes.ref,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onProgress: PropTypes.func,
    onComplete: PropTypes.func,
};

const defaultProps = {
    id: null,
    type: null,
    title: null,
    subtitle: null,
    surtitle: null,
    image: null,
    metadata: null,
    theme: null,
    header: null,
    blocks: [],
    contest: null,
    placeholder: false,
    isPreview: false,
    current: false,
    entering: false,
    className: null,
    sectionOnly: null,
    editorRef: null,
    containerRef: null,
    onEnter: null,
    onLeave: null,
    onProgress: null,
    onComplete: null,
};

function ArticleDocument({
    id,
    type,
    image,
    title,
    slug,
    header,
    subtitle,
    surtitle,
    metadata,
    theme,
    blocks,
    contest,
    placeholder,
    isPreview,
    className,
    current,
    entering,
    sectionOnly,
    editorRef,
    containerRef,
    onEnter,
    onLeave,
    onComplete,
    onProgress,
}) {
    const isEditor = useIsEditor();
    const { brands, categories, credits, publishedAt, sponsors } = metadata || {};
    const { name: themeName = null, headerAnimation = null } = theme || {};
    const defaultBrand = useDefaultBrand();
    const { handle: defaultBrandHandle = 'urbania' } = defaultBrand || {};
    const [brand = null] = brands || [];
    const { handle: brandHandle = defaultBrandHandle } = brand || {};

    const finalSurtitle = useDocumentSurtitle({
        surtitle,
        metadata,
    });

    const finalHeader = useMemo(
        () => ({
            role: 'header',
            type: 'default',
            title,
            surtitle: finalSurtitle,
            subtitle,
            categories,
            credits,
            date: type !== 'contest' ? publishedAt : null,
            image,
            brand: brand || defaultBrand,
            sponsors,
            animation: headerAnimation,
            ...header,
        }),
        [
            type,
            header,
            title,
            finalSurtitle,
            subtitle,
            categories,
            credits,
            publishedAt,
            image,
            brand,
            defaultBrand,
            sponsors,
        ],
    );

    const { type: headerType } = finalHeader || {};
    const HeaderComponent = getComponentFromName(HeaderComponents, headerType, 'default');

    const { locations = null } = metadata || {};

    const blocksWithAddons = useMemo(
        () => [
            // {
            //     id: 'subscription',
            //     type: 'subscription',
            // },
            ...(!isEditor
                ? getBlocksWithAddons(
                      placeholder
                          ? [
                                {
                                    id: 'placeholder-1',
                                    type: 'placeholder',
                                },
                                {
                                    id: 'placeholder-2',
                                    type: 'placeholder',
                                },
                            ]
                          : blocks,
                      {
                          addonsProps: {
                              ad: ({ count = 0 }) => ({
                                  disabled: !current || placeholder,
                                  slot: count === 0 ? 'article_native' : 'article',
                              }),
                          },
                      },
                  )
                : blocks),
            ...(contest !== null
                ? [
                      {
                          id: 'contest',
                          type: 'contest',
                          contest,
                      },
                  ]
                : []),
            ...(locations !== null && locations.length > 0 ? [createLocationsList(locations)] : []),
        ],
        [blocks, isEditor, current, entering, locations, placeholder, contest],
    );

    const onScrollTrigger = useCallback(
        (trigger) => {
            if (onProgress !== null) {
                onProgress(trigger);
            }

            if (trigger === 1 && onComplete !== null) {
                onComplete();
            }
        },
        [onProgress, onComplete],
    );
    const { ref: scrollRef } = useScrollTrigger({
        disabled: !current || isPreview,
        onTrigger: onScrollTrigger,
    });

    return (
        <div
            id={`article-${id}`}
            className={classNames([styles.container, className])}
            data-brand={brandHandle}
            data-theme={themeName}
            ref={(ref) => {
                if (containerRef !== null) {
                    containerRef.current = ref;
                }
                scrollRef.current = ref;
            }}
        >
            <ArticleTheme id={`article-${id}`} className={styles.container} {...theme}>
                {sectionOnly === null || sectionOnly === 'header' ? (
                    editorRef === null ? (
                        finalHeader !== null ? (
                            <Component component={finalHeader}>
                                <HeaderComponent
                                    {...finalHeader}
                                    placeholder={placeholder}
                                    className={classNames([
                                        styles.header,
                                        {
                                            [styles[brandHandle]]: brandHandle !== null,
                                        },
                                    ])}
                                />
                            </Component>
                        ) : null
                    ) : (
                        <div ref={editorRef} />
                    )
                ) : null}

                <div className={styles.topAdContainer}>
                    <Ad slot="top" disabled={!current} className={styles.topAd} />
                </div>

                {sectionOnly === null || sectionOnly === 'content' ? (
                    <Body className={styles.body}>
                        {editorRef === null ? (
                            <Blocks blocks={blocksWithAddons} />
                        ) : (
                            <div ref={editorRef} />
                        )}
                    </Body>
                ) : null}
            </ArticleTheme>
        </div>
    );
}

ArticleDocument.propTypes = propTypes;
ArticleDocument.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <ArticleDocument {...props} containerRef={ref} />);
