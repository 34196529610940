/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as Components from '../../animations/index';

const propTypes = {
    name: PropTypes.string,
};

const defaultProps = {
    name: null,
};

function AnimationBlock({ name, ...otherProps }) {
    const Component = getComponentFromName(Components, name, null);
    return Component !== null ? <Component {...(otherProps || {})} /> : null;
}

AnimationBlock.propTypes = propTypes;
AnimationBlock.defaultProps = defaultProps;

export default AnimationBlock;
