import isNumber from 'lodash/isNumber';

function getRatio(name = null, defaultRatio = 1) {
    if (name === null) {
        return defaultRatio;
    }
    if (isNumber(name)) {
        return name;
    }
    const ratios = {
        square: 1,
        // horizontal: 5 / 4,
        horizontal: 4 / 3,
        vertical: 4 / 5,
        micromag: 4 / 6,
        wide: 16 / 9,
    };
    const [ratioName, double = null] = name.split('_');
    return ratios[ratioName] * (double === 'double' ? 0.5 : 1);
}

export default getRatio;
