import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function Randonnee({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.96 38.85" className={className}>
            <path
                fill={color}
                d="M.43,35.55c.81-1.83,1.91-3.51,2.83-5.28,1.15-2.36,2.13-4.8,2.94-7.3l.26-1.06c.33-.95,.62-1.9,.88-2.84-.82,0-1.64,0-2.46-.03-.21,0-.41-.02-.61-.07-.47-.16-.84-.53-1-1-.16-.46-.23-.95-.2-1.44-.07-1.81,.53-3.58,1.68-4.97l-.59,.03c-.06,.01-.12,0-.18-.03-.05-.06-.08-.15-.06-.23,.05-.7,.14-1.41,.27-2.1,.1-.76,.36-1.48,.76-2.13,.05-.08,.11-.15,.18-.2,.1-.06,.22-.09,.33-.1,.57-.03,1.15,.02,1.71,.15,.98,.16,1.95,.39,2.89,.69,.3,.1,.59,.22,.87,.35,.23-.29,.53-.52,.88-.66,.76-.23,1.59-.07,2.21,.43,.6,.5,1.12,1.1,1.53,1.77l3.05,4.46c.11,.19,.26,.35,.45,.46,.19,.07,.39,.1,.58,.08,.92-.02,1.83-.04,2.75-.07,.04-1.1,.08-2.2,.12-3.3-.02-.24,.05-.48,.2-.67,.22-.22,.56-.28,.85-.16,.15,.09,.24,.25,.25,.42,.18,1.04,.17,2.1-.03,3.14l-.12,.52c.23,0,.47-.02,.7-.03,0,0,.54-.16,.56,.35,.03,.32,.04,.64,.04,.96,0,.09-.03,.17-.08,.24-.06,.06-.15,.1-.23,.12-.45,.13-.91,.26-1.36,.37-.42,3.15-.13,6.35-.27,9.52-.18,4.02-1.06,8.02-.76,12.04,.02,.31,0,.7-.41,.83-.34,.09-.7-.03-.92-.31-.13-.24-.16-.52-.1-.79,.78-7.01,1.18-14.03,1.48-21.06-.24,.05-.47,.09-.71,.13-1.21,.15-2.43,.21-3.65,.18-.24,.01-.48-.01-.71-.08-.28-.13-.54-.32-.73-.56-.76-.81-1.48-1.66-2.14-2.56-.14,.66-.24,1.33-.31,2l-.11,.53c-.45,1.13-.52,2.37-.22,3.54,.2,.45,.45,.88,.75,1.27,.84,1.22,1.61,2.5,2.29,3.82,.32,.59,.58,1.21,.77,1.85,.15,.68,.23,1.37,.25,2.06,.14,2.64,.28,5.27,.42,7.91,.04,.32,.01,.64-.09,.94-.27,.51-.79,.85-1.37,.88-.32,.06-.65,0-.93-.18-.27-.27-.42-.64-.44-1.02-.37-2.36-.72-4.73-1.04-7.11-.05-.56-.17-1.11-.34-1.64-.52-1.09-1.29-2.04-2.26-2.77-.26-.3-.64-.47-1.03-.46-.39,.08-.61,.47-.78,.83-1.91,3.89-4.13,7.62-6.65,11.16-.26,.4-.58,.76-.95,1.06-.57,.51-1.45,.47-1.96-.1-.25-.28-.38-.65-.35-1.02,0-.61,.15-1.21,.43-1.75"
            />
            <path
                fill={color}
                d="M18.24,6.17c-.16,.12-.33,.22-.52,.29-.7,.3-1.45,.46-2.21,.48-.52,.02-1.04-.05-1.53-.22-.49-.17-.93-.48-1.24-.89-.16-.23-.29-.47-.38-.73-.19-.48-.31-.99-.37-1.5-.05-.36-.03-.72,.04-1.07,.21-.62,.59-1.18,1.09-1.6,.4-.46,.95-.77,1.55-.86l.65-.06c.44,0,.87,.04,1.3,.11,.75,.05,1.42,.46,1.81,1.1,.18,.22,.33,.46,.44,.72,.07,.22,.11,.44,.13,.67,.06,.67,.04,1.35-.06,2.02-.03,.58-.28,1.13-.7,1.54"
            />
        </svg>
    );
}

Randonnee.propTypes = propTypes;
Randonnee.defaultProps = defaultProps;

export default Randonnee;
