import isFunction from 'lodash/isFunction';

export function getBlockHeight({ type = null, body = null }) {
    if (body !== null && type === 'quote') {
        return body.length / 100;
    }
    if (body !== null && type === 'heading') {
        return body.length / 100;
    }
    if (body !== null) {
        return body.length / 250;
    }
    if (type === 'embed' || type === 'image' || type === 'gallery' || type === 'images') {
        return 2;
    }
    return 1;
}

function getBlocksWithAddons(
    blocks,
    {
        addons = [
            // {
            //     block: 'parallax-ad',
            //     max: 1,
            //     interval: 5,
            //     minHeight: 0,
            //     notAfter: ['heading'],
            //     notAround: ['quote'],
            // },
            {
                block: 'ad',
                max: 15,
                interval: 4,
                minHeight: 2,
                minBlocks: 2,
                notAfter: ['heading'],
                notBefore: ['quote', 'image'],
                // notAround: ['quote', 'image'],
            },
            // {
            //     block: 'subscription',
            //     max: 1,
            //     interval: 5,
            //     minHeight: 0,
            //     notAfter: ['heading'],
            //     notAround: ['quote'],
            // },
            // {
            //     block: 'related',
            //     max: 1,
            //     notAfter: ['heading'],
            //     notAround: ['quote', 'image', 'embed'],
            // },
            // {
            //     block: 'newsletter',
            //     max: 1,
            //     notAfter: ['heading'],
            //     notAround: ['quote', 'image', 'embed'],
            // },
        ],
        minSpaceBetween = 2,
        addonsProps = {},
    } = {},
) {
    const { blocks: newBlocks = [] } =
        blocks.length > 0
            ? blocks.reduce(
                  ({ blocks: currentBlocks = [], state: curentState = {} }, block, index) => {
                      const nextBlock = index < blocks.length - 1 ? blocks[index + 1] : null;
                      const blockHeight = getBlockHeight(block);
                      const { type: prevType, body: prevBody = null } = block;
                      const { type: nextType, body: nextBody = null } = nextBlock || {};
                      const blockIsOkay = nextType !== 'text' || nextBody.length > 75;

                      const { height: totalHeight = 0, heightSinceLastAddons = 0 } = curentState;
                      const newTotalHeight = totalHeight + blockHeight;
                      const newHeightSinceLastAddons = heightSinceLastAddons + blockHeight;

                      return addons.reduce(
                          (
                              { blocks: currentAddonsBlocks, state: currentAddonsState = {} },
                              addon,
                          ) => {
                              const {
                                  block: addonBlock,
                                  max = 1,
                                  interval = null,
                                  minHeight = null,
                                  notAfter = null,
                                  notBefore = null,
                                  notAround = null,
                                  minBlocks = null,
                                  ...customAddonProps
                              } = addon;
                              const { heightSinceLastAddons: currentHeightSinceLastAddons } =
                                  currentAddonsState;
                              const addonProps = addonsProps[addonBlock] || null;
                              const {
                                  count = 0,
                                  height = 0,
                                  blocksCount = 0,
                              } = currentAddonsState[addonBlock] || {};
                              const newHeight = height + blockHeight;
                              const newBlocksCount = blocksCount + 1;
                              const needAddons =
                                  blockIsOkay &&
                                  (notAfter === null || notAfter.indexOf(prevType) === -1) &&
                                  (notBefore === null || notBefore.indexOf(nextType) === -1) &&
                                  (notAround === null ||
                                      (notAround.indexOf(prevType) === -1 &&
                                          notAround.indexOf(nextType) === -1)) &&
                                  (interval === null || newHeight >= interval) &&
                                  (max === null || count < max) &&
                                  (minHeight === null || newTotalHeight >= minHeight) &&
                                  (minSpaceBetween === null ||
                                      currentHeightSinceLastAddons >= minSpaceBetween) &&
                                  (minBlocks === null || newBlocksCount >= minBlocks);
                              //   console.log({
                              //       needAddons,
                              //       index,
                              //       height,
                              //       newHeight,
                              //       blockHeight,
                              //       prevType,
                              //       prevBody,
                              //   });
                              return {
                                  blocks: needAddons
                                      ? [
                                            ...currentAddonsBlocks,
                                            {
                                                id: `${addonBlock}-${block.id || index}`,
                                                type: addonBlock,
                                                ...(isFunction(addonProps)
                                                    ? addonProps({
                                                          count,
                                                          block,
                                                          index,
                                                      })
                                                    : addonProps),
                                                ...customAddonProps,
                                            },
                                        ]
                                      : currentAddonsBlocks,
                                  state: {
                                      ...currentAddonsState,
                                      heightSinceLastAddons: needAddons
                                          ? 0
                                          : currentHeightSinceLastAddons,
                                      [addonBlock]: {
                                          count: count + (needAddons ? 1 : 0),
                                          height: needAddons ? 0 : newHeight,
                                          blocksCount: needAddons ? 0 : newBlocksCount,
                                      },
                                  },
                              };
                          },
                          {
                              blocks: [...currentBlocks, block],
                              state: {
                                  ...curentState,
                                  heightSinceLastAddons: newHeightSinceLastAddons,
                                  height: newTotalHeight,
                              },
                          },
                      );
                  },
                  {
                      blocks: [],
                      state: {},
                  },
              )
            : {};
    return newBlocks;
}

export default getBlocksWithAddons;
