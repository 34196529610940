/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from './BasicButton';

import styles from '../../styles/buttons/urbania-button.module.css';

import urbaniaLogo from '../../assets/img/brands/urbania_logo.svg';

const propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    href: '/',
    className: null,
    buttonRef: null,
};

function UrbaniaButton({ className, buttonRef, ...props }) {
    return (
        <BasicButton
            ref={buttonRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <img src={urbaniaLogo} alt="Urbania" className={styles.logo} />
        </BasicButton>
    );
}

UrbaniaButton.propTypes = propTypes;
UrbaniaButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <UrbaniaButton {...props} buttonRef={ref} />);
