import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function InfoIcon({ color, className }) {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM10.0384 5.17396C10.0384 5.98396 10.5964 6.50596 11.4784 6.50596C12.5224 6.50596 13.3144 5.73196 13.3144 4.68796C13.3144 3.87796 12.7564 3.35596 11.8744 3.35596C10.8304 3.35596 10.0384 4.09396 10.0384 5.17396ZM9.85839 7.78396L8.40039 17H11.1004L12.5584 7.78396H9.85839Z"
                fill={color}
            />
        </svg>
    );
}

InfoIcon.propTypes = propTypes;
InfoIcon.defaultProps = defaultProps;

export default InfoIcon;
