/* eslint-disable react/jsx-props-no-spreading */
import { useRouteMatcher, useRoutes, useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuthLogout } from '../../hooks/useAuth';

import RoundedButton from '../buttons/RoundedButton';

import styles from '../../styles/menus/account-menu.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AccountMenu({ className }) {
    const url = useUrlGenerator();

    const routes = useRoutes();
    const routeMatcher = useRouteMatcher();

    const { logout } = useAuthLogout();

    const onClickLogout = useCallback(() => {
        logout();
    }, [logout]);

    const items = [
        {
            route: 'account',
            label: <FormattedMessage defaultMessage="Profil" description="Filter label" />,
        },
        {
            route: 'account.subscriptions',
            label: <FormattedMessage defaultMessage="Abonnements" description="Filter label" />,
        },
        {
            route: 'account.timeline',
            label: <FormattedMessage defaultMessage="Historique" description="Filter label" />,
        },
        {
            id: 'logout',
            label: <FormattedMessage defaultMessage="Déconnexion" description="Filter label" />,
            className: styles.link,
            onClick: onClickLogout,
        },
    ];

    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.inner}>
                {items.map(({ id, route = null, label, className: buttonClassName, ...props }) => {
                    const [match = false] = route !== null ? routeMatcher(routes[route]) : [];
                    return (
                        <RoundedButton
                            key={id || route}
                            href={route !== null ? url(route) : null}
                            compact
                            className={classNames([
                                styles.button,
                                {
                                    [styles.active]: match,
                                },
                                buttonClassName,
                            ])}
                            {...props}
                        >
                            {label}
                        </RoundedButton>
                    );
                })}
            </div>
        </div>
    );
}

AccountMenu.propTypes = propTypes;
AccountMenu.defaultProps = defaultProps;

export default AccountMenu;
