/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

// import PropTypes from 'prop-types';
// import * as AppPropTypes from '../../../lib/PropTypes';
import ContactField from './ContactField';

const propTypes = {};

const defaultProps = {};

const EmailField = (props) => <ContactField type="email" {...props} />;

EmailField.propTypes = propTypes;
EmailField.defaultProps = defaultProps;

export default forwardRef((props, ref) => <EmailField {...props} inputRef={ref} />);
