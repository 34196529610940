import { useRouteMatcher } from "@folklore/routes";

export default function useRoutesMatch(routes, location) {
    const routeMatcher = useRouteMatcher();
    return routes.reduce((isMatching, route) => {
        if (isMatching) {
            return true;
        }
        const [match = false] = routeMatcher(route, location);
        return match;
    }, false)
}
