/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import ToggleIcon from '../icons/ToggleIcon';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/toggle-button.module.css';

const propTypes = {
    value: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
    onChange: PropTypes.func,
};

const defaultProps = {
    value: false,
    loading: false,
    className: null,
    buttonRef: null,
    onChange: null,
};

function ToggleButton({ value, loading, className, buttonRef, onChange, ...props }) {
    const onClick = useCallback(() => {
        if (onChange !== null) {
            onChange(!value);
        }
    }, [onChange, value]);
    return (
        <BasicButton
            ref={buttonRef}
            className={classNames([styles.container, className])}
            onClick={onClick}
            {...props}
        >
            <ToggleIcon checked={value} loading={loading} className={styles.toggle} />
        </BasicButton>
    );
}

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <ToggleButton {...props} buttonRef={ref} />);
