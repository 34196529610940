export const groupTypes = [
    {
        slug: 'loisirs-et-culture',
        name: 'Loisirs et culture',
        types: ['restaurants'],
    },
    {
        slug: 'destinations-a-decouvrir',
        name: 'Destinations à découvrir',
        types: ['roadtrip'],
    },
    {
        slug: 'autres-sports',
        name: 'Autres sports',
    },
];

export function getPointsFromLocations(items) {
    return (items || [])
        .filter(({ latitude = null, longitude = null }) => latitude !== null && longitude !== null)
        .map((location, index) => ({
            type: 'Feature',
            properties: {
                cluster: false,
                location,
            },
            id: `${location.type}-${index}`,
            geometry: { type: 'Point', coordinates: [location.longitude, location.latitude] },
        }));
}
