/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from './Typography';

import styles from '../../styles/typography/promotion-description.module.css';

const propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'div',
    className: null,
};

function PromotionDescription({ tag, className, ...props }) {
    return (
        <Typography tag={tag} className={classNames([styles.container, className])} {...props} />
    );
}

PromotionDescription.propTypes = propTypes;
PromotionDescription.defaultProps = defaultProps;

export default PromotionDescription;
