import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function prefetchLocationType(queryClient, api, slug) {
    return queryClient.prefetchQuery({
        queryKey: ['location_type', slug],
        queryFn: ({ queryKey: [, slugParam], signal }) =>
            api.locations.locationTypes(
                {
                    uri: slugParam,
                },
                null,
                null,
                { signal },
            ),
    });
}

export default function useLocationType(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: locationType, ...queryResult } = useQuery({
        queryKey: ['location_type', slug],
        queryFn: ({ queryKey: [, slugParam], signal }) =>
            api.locations.locationTypes(
                {
                    uri: slugParam,
                },
                null,
                null,
                { signal },
            ),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        locationType,
        ...queryResult,
    };
}
