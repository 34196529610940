/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import seedrandom from 'seedrandom';

import * as AppPropTypes from '../../lib/PropTypes';
import camelCase from 'lodash/camelCase';

// import chien1 from '../../assets/img/authors/Chien 1.png?as=default-author';
// import chien2 from '../../assets/img/authors/Chien 2.png?as=default-author';
// import chien3 from '../../assets/img/authors/Chien 3.png?as=default-author';
// import chien4 from '../../assets/img/authors/Chien 4.png?as=default-author';
// import chien5 from '../../assets/img/authors/Chien 5.png?as=default-author';
// import * as authorImages from '../../assets/img/authors/index';
import Image from './Image';
import Initials from './Initials';

import styles from '../../styles/partials/author-image.module.css';

const propTypes = {
    slug: PropTypes.string,
    media: AppPropTypes.image,
    defaultMedias: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
    replaceMedias: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
    size: PropTypes.string,
    name: PropTypes.string,
    withoutDefault: PropTypes.bool,
    withoutCircle: PropTypes.bool,
    withPlaceholder: PropTypes.bool,
    className: PropTypes.string,
    pictureClassName: PropTypes.string,
    initialsClassName: PropTypes.string,
};

const defaultProps = {
    slug: null,
    media: null,
    defaultMedias: [
        // {
        //     id: 'chien1',
        //     url: chien1,
        // },
        // {
        //     id: 'chien2',
        //     url: chien2,
        // },
        // {
        //     id: 'chien3',
        //     url: chien3,
        // },
        // {
        //     id: 'chien4',
        //     url: chien4,
        // },
        // {
        //     id: 'chien5',
        //     url: chien5,
        // },
    ],
    // replaceMedias: Object.keys(authorImages).map((key) => ({
    //     id: key,
    //     url: authorImages[key],
    // })),
    replaceMedias: [],
    name: null,
    size: 'medium',
    withoutDefault: false,
    withoutCircle: false,
    withPlaceholder: false,
    className: null,
    pictureClassName: null,
    initialsClassName: null,
};

function AuthorImage({
    slug,
    media,
    defaultMedias,
    replaceMedias,
    name,
    withoutDefault,
    withoutCircle,
    withPlaceholder,
    className,
    pictureClassName,
    initialsClassName,
    ...props
}) {
    const { width, height } = media || {};
    const finalMedia = width !== 714 || height !== 706 ? media : null;
    if (withoutDefault && finalMedia === null) {
        return null;
    }

    const initials = useMemo(() => {
        if (name === null) {
            return null;
        }

        return name
            .split(' ')
            .slice(0, 2)
            .map((word) => (word !== null && word.length > 0 ? word[0] : ''))
            .join('');
    }, [name]);

    const defaultMedia = useMemo(
        () =>
            !withPlaceholder
                ? defaultMedias[Math.floor(seedrandom(slug).double() * defaultMedias.length)]
                : null,
        [withPlaceholder, defaultMedias, slug],
    );

    const replacedMedia = useMemo(
        () => replaceMedias.find(({ id }) => id === camelCase(slug)) || null,
        [replaceMedias, slug],
    );

    return finalMedia === null && !withPlaceholder ? (
        <Initials
            name={name}
            className={classNames([
                styles.container,
                {
                    [styles.withoutCircle]: withoutCircle,
                },
                className,
            ])}
            initialsClassName={initialsClassName}
        />
    ) : (
        <Image
            media={replacedMedia || finalMedia || defaultMedia}
            loading="lazy"
            withPlaceholder={withPlaceholder}
            className={classNames([
                styles.container,
                {
                    [styles.withoutCircle]:
                        withoutCircle || replacedMedia !== null || finalMedia === null,
                },
                className,
            ])}
            pictureClassName={classNames([styles.picture, pictureClassName])}
            {...props}
        />
    );
}

AuthorImage.propTypes = propTypes;
AuthorImage.defaultProps = defaultProps;

export default AuthorImage;
