/* eslint-disable react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearch } from 'wouter';

import { usePromotions } from '../../contexts/PromotionsContext';
import * as ModalsComponents from './modals';
import * as NotificationsComponents from './notifications';

const propTypes = {
    minimumDelayBetween: PropTypes.number,
};

const defaultProps = {
    minimumDelayBetween: 30 * 1000,
};

function PromotionsPopups({ minimumDelayBetween }) {
    const { promotions: activePromotions = [], allPromotions = [], markAsDone } = usePromotions();
    const [currentPromotion, setCurrentPromotion] = useState(null);
    const [lastPromotionTime, setLastPromotionTime] = useState(null);

    const tracking = useTracking();
    const search = useSearch();
    const { promotion_popup: promotionPopupQuery = null } = useMemo(
        () => queryString.parse(search),
        [search],
    );

    const promotions = useMemo(
        () =>
            activePromotions.filter(
                ({ placement = null, trigger = null }) =>
                    ['notification', 'modal'].indexOf((placement || '').split(':')[0]) !== -1 &&
                    trigger !== null,
            ),
        [activePromotions],
    );

    useEffect(() => {
        const queryPromotion =
            promotionPopupQuery !== null
                ? allPromotions.find(
                      ({ id, handle }) =>
                          handle === promotionPopupQuery || id === promotionPopupQuery,
                  ) || null
                : null;
        if (queryPromotion !== null) {
            setCurrentPromotion(queryPromotion);
        }
    }, [allPromotions, promotionPopupQuery]);

    useEffect(() => {
        if (currentPromotion !== null) {
            return () => {};
        }
        let hasCurrent = false;
        promotions.forEach((promotion) => {
            const { id, trigger, force = false } = promotion;
            tracking.triggers.register(`promotion_popup_${id}`, trigger, () => {
                const now = new Date().getTime();
                const elapsed = lastPromotionTime !== null ? now - lastPromotionTime : Infinity;
                if (
                    force ||
                    (!hasCurrent &&
                        (minimumDelayBetween === null || elapsed >= minimumDelayBetween))
                ) {
                    setCurrentPromotion(promotion);
                    tracking.trackPromotion(promotion, 'impression');
                    hasCurrent = true;
                }
            });
        });
        return () => {
            promotions.forEach(({ id }) => {
                tracking.triggers.unregister(`promotion_popup_${id}`);
            });
        };
    }, [tracking, promotions, currentPromotion, minimumDelayBetween, lastPromotionTime]);

    const onClosed = useCallback(() => {
        setCurrentPromotion(null);
        const { id: currentId = null, force = false } = currentPromotion || {};
        if (!force) {
            markAsDone(currentId);
        }
        setLastPromotionTime(new Date().getTime());
    }, [currentPromotion, markAsDone]);

    if (currentPromotion === null) {
        return null;
    }

    const { placement = null, type = null } = currentPromotion;
    const [, placementType = null] = (placement || '').split(':');

    const PromotionComponent = getComponentFromName(
        placement === 'notification' ? NotificationsComponents : ModalsComponents,
        placementType || type,
        'promotion',
    );

    return PromotionComponent !== null ? (
        <PromotionComponent promotion={currentPromotion} onClosed={onClosed} />
    ) : null;
}

PromotionsPopups.propTypes = propTypes;
PromotionsPopups.defaultProps = defaultProps;

export default PromotionsPopups;
