/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import { groupTypes } from '../../lib/maps';

import PinIcon from './maps/Pin';
import * as iconComponents from './maps/index';

import styles from '../../styles/icons/map-marker.module.css';

const propTypes = {
    theme: PropTypes.string,
    icons: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    className: PropTypes.string,
    pinClassName: PropTypes.string,
    iconClassName: PropTypes.string,
};

const defaultProps = {
    theme: null,
    icons: [],
    className: null,
    pinClassName: null,
    iconClassName: null,
};

function MapMarker({ theme, icons, className, iconClassName, pinClassName }) {
    const finalIcons = isString(icons) ? [icons] : icons;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[theme]]: typeof styles[theme] !== 'undefined',
                    [className]: className !== null,
                },
            ])}
        >
            <PinIcon
                className={classNames([
                    styles.pin,
                    {
                        [pinClassName]: pinClassName,
                    },
                ])}
                border={theme === 'winter' ? '#fff0e0' : '#222'}
                theme={theme}
            />
            <div
                className={classNames([
                    styles.icons,
                    {
                        [styles.many]: finalIcons.length > 1,
                        [styles.two]: finalIcons.length === 2,
                        [styles.three]: finalIcons.length === 3,
                    },
                ])}
            >
                {finalIcons.map((icon, i) => {
                    const groupType = groupTypes.find(
                        ({ types: subTypes = null }) =>
                            subTypes === null || subTypes.indexOf(icon) !== -1,
                    );
                    const IconComponent =
                        getComponentFromName(iconComponents, icon) ||
                        getComponentFromName(
                            iconComponents,
                            groupType !== null ? groupType.slug : icon,
                        );
                    const iconCamelCase = camelCase(icon);
                    return IconComponent !== null ? (
                        <IconComponent
                            key={`icon-${i + 1}`}
                            className={classNames([
                                styles.icon,
                                {
                                    [styles[iconCamelCase]]:
                                        typeof styles[iconCamelCase] !== 'undefined',
                                    [iconClassName]: iconClassName !== null,
                                },
                            ])}
                        />
                    ) : null;
                })}
            </div>
        </div>
    );
}

MapMarker.propTypes = propTypes;
MapMarker.defaultProps = defaultProps;

export default MapMarker;
