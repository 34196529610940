/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import CloseIcon from '../icons/CloseIcon';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/menu-button.module.css';

const propTypes = {
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    className: null,
    buttonRef: null,
};

function CloseButton({ className, buttonRef, ...props }) {
    return (
        <BasicButton
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={buttonRef}
            {...props}
        >
            <CloseIcon className={styles.icon} />
        </BasicButton>
    );
}

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <CloseButton {...props} buttonRef={ref} />);
