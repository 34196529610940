import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function fetchAuthor(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['author', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.authors.findBySlug(queryParam, { signal }),
    });
}

export default function useAuthor(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: author = null, isFetched = false, ...queryResult } = useQuery({
        queryKey: ['author', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.authors.findBySlug(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        author,
        notFound: author === null && isFetched,
        isFetched,
        ...queryResult,
    };
}
