import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

import { usePopupsContext } from '../../contexts/PopupsContext';

const propTypes = {
    namespace: PropTypes.string,
    id: PropTypes.string,
    requestClose: PropTypes.func,
    children: PropTypes.node,
};

const defaultProps = {
    namespace: null,
    id: null,
    requestClose: null,
    children: null,
};

const PopupPortal = ({ namespace, id, requestClose, children }) => {
    const { containers = null, register, unregister } = usePopupsContext();
    const finalId = useMemo(
        () => (id !== null ? id : `${namespace || 'popup'}-${new Date().getTime()}`),
        [namespace, id],
    );
    const container = containers[namespace || 'default'] || null;
    useEffect(() => {
        register(
            finalId,
            {
                requestClose,
            },
            namespace,
        );
        return () => {
            unregister(finalId, namespace);
        };
    }, [namespace, finalId, requestClose, register, unregister]);
    return container !== null ? ReactDOM.createPortal(children, container) : null;
};

PopupPortal.propTypes = propTypes;
PopupPortal.defaultProps = defaultProps;

export default PopupPortal;
