import Base from './Base';

class Authors extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON('/authors', {
            ...(page !== null ? { page } : null),
            ...(count !== null ? { count } : null),
            ...query,
        }, opts);
    }

    findBySlug(slug, opts = null) {
        return this.getJSON('/authors', {
            uri: slug,
        }, opts).catch(e => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Authors;
