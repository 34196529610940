import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function PromenadeAvecPitou({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.67 29.47" className={className}>
            <path
                fill={color}
                d="M27.94,19.92c-.25-.63-.4-1.29-.44-1.96,.09-1.1,.43-2.17,.98-3.13,.03-.06,.41-.66,.32-.66-.86-.07-1.69-.3-2.47-.66-.94-.44-1.83-.99-2.64-1.63-.79-.6-1.54-1.24-2.28-1.89-.14-.12-.33-.52-.69-1-.25,.11-.5,.24-.73,.38-.5,.12-1.02,.2-1.53,.23-3.6,.39-7.23,.37-10.82-.06-1.5-.12-2.91-.72-4.03-1.72-.45-.62-.97-1.19-1.53-1.7-.74-.51-2.05-.38-2.05,.72,0,.34,.14,.67,.37,.92,1.52,1.72,3.37,3.56,5.7,3.77-.04,4.58-.79,9.12-2.23,13.47-.41,.91-.56,1.92-.44,2.92,.23,.97,1.32,1.8,2.21,1.37,.74-.36,.93-1.3,1.06-2.1,.4-2.53,1.03-5.02,1.87-7.44,.06-.17,.12-.34,.34-.38,.14-.02,.27,.02,.38,.1,.19,.18,.32,.41,.38,.66,.69,2.02,1.25,4.07,1.67,6.16,.07,.77,.3,1.52,.67,2.2,.38,.77,1.31,1.08,2.07,.7,.15-.07,.28-.17,.4-.28,.19-.23,.32-.51,.37-.8,.07-.76-.05-1.52-.34-2.23-.51-1.85-.88-3.74-1.12-5.64,0-.06,0-.12,0-.17,.05-.35,.39-.36,.66-.34,1.73,.14,3.44,.49,5.09,1.03,.26,.05,.48,.2,.62,.43,.06,.19,.05,.39-.02,.58-.4,1.61-.8,3.22-1.21,4.83-.23,.94-.41,2.11,.65,2.59,.3,.13,.63,.17,.96,.11,.32-.02,.62-.11,.89-.29,.22-.23,.36-.51,.41-.82,.51-1.95,1.02-3.9,1.53-5.85,.15-.6,.44-1.31,1.05-1.37,.74-.08,1.14,.82,1.31,1.54,.29,1.26,.59,2.53,.88,3.79,.13,.75,.39,1.47,.77,2.13,.4,.67,1.12,1.07,1.89,1.06,.74-.06,1.29-.7,1.23-1.43,0-.05,0-.09-.02-.14l.15,.06c-.63-2.71-1.38-5.38-2.26-8.02"
            />
            <path
                fill={color}
                d="M35.05,7.97c-.48-.45-.82-1.01-1-1.64-.28-1.02-.16-2.26-.95-2.96-.73-.66-1.86-.51-2.78-.84C29.16,2.11,28.35-.06,27.96,0c-.47,.08-.47,2.23-1.13,3.22-.34,.51-.73,.99-1.16,1.43-1.18,1.21-3.56,3.45-3.64,3.53-.07,.08-.07,.19,0,.27,1,1.04,2.12,1.97,3.32,2.77,.39,.21,.8,.4,1.2,.59l.63,.29c.59,.3,1.2,.53,1.84,.69,.11,.04,.23,0,.28-.11,.16-.48,.3-.97,.42-1.47,1.12,.1,2.24,.29,3.33,.57,1.19,.31,2.45-.04,3.31-.93,.19-.24,.3-.54,.31-.84,0-.91-.97-1.42-1.63-2.03"
            />
        </svg>
    );
}

PromenadeAvecPitou.propTypes = propTypes;
PromenadeAvecPitou.defaultProps = defaultProps;

export default PromenadeAvecPitou;
