/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { useTouchScreen } from '../../contexts/DeviceContext';
import Summary from '../summaries/Summary';
import DocumentsRowBlock from './DocumentsRowBlock';

import styles from '../../styles/blocks/summary-row-block.module.css';

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    summary: PropTypes.object,
    summaryComponent: PropTypes.elementType,
    summaryPosition: PropTypes.oneOf([null, 'center', 'inline', 'bottom']),
    summaryImagePosition: PropTypes.oneOf([null, 'inline', 'left', 'right']),
    background: AppPropTypes.background,
    presentation: PropTypes.string,
    loading: PropTypes.oneOf([null, 'lazy']),
    withBorderRadius: PropTypes.bool,
    withoutSummaryImage: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    summaryClassName: PropTypes.string,
    summaryButtonClassName: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    summary: null,
    summaryComponent: Summary,
    summaryPosition: null,
    summaryImagePosition: null,
    background: null,
    presentation: null,
    loading: 'lazy',
    withBorderRadius: false,
    withoutSummaryImage: false,
    className: null,
    innerClassName: null,
    summaryClassName: null,
    summaryButtonClassName: null,

    containerRef: null,
};

function SummaryRowBlock({
    summary,
    summaryComponent: SummaryComponent,
    summaryPosition,
    summaryImagePosition,
    background,
    presentation,
    withBorderRadius,
    withoutSummaryImage,
    className,
    innerClassName,
    summaryClassName,
    summaryButtonClassName,
    containerRef,
    loading,
    ...props
}) {
    const isTouchScreen = useTouchScreen();
    const summaryElement = (
        <SummaryComponent
            {...summary}
            loading={loading}
            withoutImage={withoutSummaryImage}
            placeholder={summary === null}
            className={classNames([styles.summary, summaryClassName])}
            buttonClassName={summaryButtonClassName}
            innerClassName={styles.summaryInner}
            descriptionClassName={styles.summaryDescription}
            imagePosition={summaryImagePosition || (summaryPosition === 'inline' ? null : 'inline')}
        />
    );
    return (
        <DocumentsRowBlock
            ref={containerRef}
            loading={loading}
            withBorderRadius={withBorderRadius}
            className={classNames([
                styles.container,
                {
                    [styles.rowList]:
                        (isTouchScreen && presentation === null) || presentation === 'row',
                    [styles[summaryPosition]]: summaryPosition !== null,
                    [styles.withBackground]: background !== null,
                    [styles.withBorderRadius]: withBorderRadius,
                    [className]: className !== null,
                },
            ])}
            innerClassName={classNames([styles.inner, innerClassName])}
            listClassName={styles.list}
            backgroundClassName={styles.background}
            itemsClassName={styles.items}
            header={summaryPosition !== 'bottom' ? summaryElement : null}
            footer={summaryPosition === 'bottom' ? summaryElement : null}
            background={background}
            presentation={presentation || (isTouchScreen ? 'row' : 'grid')}
            {...props}
        />
    );
}

SummaryRowBlock.propTypes = propTypes;
SummaryRowBlock.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <SummaryRowBlock containerRef={ref} {...props} />);
