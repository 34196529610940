/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedDateParts, FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';
import SummaryDescription from '../typography/SummaryDescription';
import SummaryTitle from '../typography/SummaryTitle';

import styles from '../../styles/summaries/date-summary.module.css';

const propTypes = {
    buttonLabel: PropTypes.string,
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['top', 'inline']),
    innerClassName: PropTypes.string,
    sponsors: AppPropTypes.sponsors,
    withButton: PropTypes.node,
    withoutDescription: PropTypes.bool,
};

const defaultProps = {
    buttonLabel: 'Voir',
    buttonClassName: null,
    className: null,
    date: null,
    description: null,
    icon: null,
    iconPosition: null,
    innerClassName: null,
    sponsors: null,
    withButton: null,
    withoutDescription: false,
};

function DateSummary({
    buttonLabel,
    buttonClassName,
    className,
    date,
    description,
    icon,
    iconPosition,
    innerClassName,
    sponsors,
    withButton,
    withoutDescription,
    ...props
}) {
    // const { href, label } = withButton || {};
    const parsedDate = useMemo(() => parseISO(date), [date]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[iconPosition]]: iconPosition !== null && icon !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={classNames([
                    styles.inner,
                    {
                        [innerClassName]: innerClassName !== null,
                    },
                ])}
            >
                <SummaryTitle className={styles.date}>
                    <FormattedDateParts
                        value={parsedDate}
                        year="numeric"
                        month="long"
                        day="2-digit"
                    >
                        {(parts) => (
                            <>
                                <div className={styles.day}>{parts[0].value}</div>
                                <div className={styles.month}>{parts[2].value}</div>
                                <div className={styles.year}>{parts[4].value}</div>
                            </>
                        )}
                    </FormattedDateParts>
                </SummaryTitle>

                {!withoutDescription ? (
                    <SummaryDescription className={styles.description}>
                        {description}
                    </SummaryDescription>
                ) : null}
                {withButton ? (
                    <BasicButton
                        // href={href}
                        className={classNames([
                            styles.button,
                            {
                                [buttonClassName]: buttonClassName !== null,
                            },
                        ])}
                    >
                        {buttonLabel}
                        {/* <FormattedMessage
                            defaultMessage="{label}"
                            description="Label button"
                            values={{
                                label: buttonLabel,
                            }}
                        /> */}
                    </BasicButton>
                ) : null}
            </div>
        </div>
    );
}

DateSummary.propTypes = propTypes;
DateSummary.defaultProps = defaultProps;

export default DateSummary;
