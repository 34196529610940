import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import useFormat from '../../hooks/useFormat';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function FormatMetadata() {
    const { slug: slugParam } = useParams();
    const { format = null } = useFormat(slugParam);
    const url = useUrlGenerator();
    if (format === null) {
        return null;
    }
    const { label, slug, image, brand } = format;
    return (
        <Metadata
            brand={brand}
            title={label}
            image={image}
            url={url('format', {
                slug,
            })}
        />
    );
}

FormatMetadata.propTypes = propTypes;
FormatMetadata.defaultProps = defaultProps;

export default FormatMetadata;
