import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function SkiAlpin({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.16 28.54" className={className}>
            <path
                fill={color}
                d="M19.25,23.79c1.08,.63,2.01,1.51,3.13,2.09,1.09,.67,2.45,.7,3.57,.09,.2-.17,.41-.31,.64-.44,.62-.22,1.29,.1,1.51,.72,.04,.12,.06,.24,.07,.36-.03,.8-.84,1.32-1.59,1.57-1.47,.49-3.05,.5-4.52,.03-.99-.35-1.93-.82-2.81-1.38-1.21-.71-2.41-1.44-3.61-2.17L1.14,15.88c-.61-.37-1.31-.97-1.09-1.65,.18-.42,.61-.69,1.07-.65,.45,.04,.88,.19,1.26,.42,3.17,1.65,6.06,3.8,9.15,5.59l7.73,4.21Z"
            />
            <path
                fill={color}
                d="M17.99,18.7c-.39,.74-.86,1.43-1.4,2.07-.1,.14-.23,.25-.37,.34-.49,.16-1.02,.06-1.41-.27-.47-.28-.97-.62-1.07-1.16-.03-.44,.09-.88,.35-1.25,0,0,1.26-1.54,1.14-2.34-.16-1.05-1.38-1.49-2.32-1.98-1.5-.79-2.57-2.21-2.9-3.88-.3-1.5,.13-3.05,1.16-4.17-.99-.62-1.96-1.28-2.91-1.96-.2,.39-.6,.63-1.04,.62-.26-.03-.47-.23-.49-.49-.02-.28,.19-.46,.36-.65,.17-.14,.25-.37,.22-.59-.06-.12-.14-.22-.25-.3-.24-.22-.44-.48-.59-.77,.43,.07,.83,.25,1.17,.52,.16-.14,.3-.3,.41-.49,.14-.22,.44-.29,.66-.14,.12,.08,.2,.21,.22,.35,.02,.14,.03,.28,.05,.42,0,.24,.07,.48,.17,.69,.1,.15,.23,.28,.38,.38,.77,.61,1.58,1.16,2.44,1.65l.03-.03c2.19-1.91,4.8-2.02,7.67-1.71,.7-.08,1.33,.42,1.41,1.11,0,.04,0,.08,0,.12,.23,1.44,.11,2.91-.33,4.29-.25,.52-.32,1.1-.2,1.66,.23,.45,.61,.82,1.07,1.03l2.23,1.33c.36,.21,.76,.51,.68,.95-.04,.2-.17,.36-.35,.46-.36,.15-.76,.16-1.12,.02-1.58-.53-3.06-1.32-4.39-2.33-.22-.13-.41-.3-.57-.5-.52-.77,.15-1.85-.14-2.74-1.27,.25-2.4,.97-3.17,2.02l2.34,1.59c.63,.36,1.17,.86,1.59,1.46,.75,1.26-.76,4.65-.76,4.65"
            />
            <path
                fill={color}
                d="M22.22,5.04c.62,.29,1.32,.36,1.98,.2,.2-.02,.39-.08,.57-.16,.26-.15,.48-.36,.64-.62,.32-.4,.53-.88,.62-1.38,.03-.36,.01-.72-.06-1.07-.01-.5-.27-.97-.68-1.25l-.26-.22c-.17-.16-.39-.27-.62-.31-.53-.19-1.09-.26-1.65-.2-.56,.06-1.08,.35-1.43,.79-.4,.65-.51,1.44-.32,2.18,.09,.82,.53,1.57,1.21,2.05"
            />
        </svg>
    );
}

SkiAlpin.propTypes = propTypes;
SkiAlpin.defaultProps = defaultProps;

export default SkiAlpin;
