import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import isArray from 'lodash/isArray';
import { useCallback, useEffect } from 'react';

import { useApi } from '../contexts/ApiContext';
import { useVisitorId } from '../contexts/VisitorContext';

export default function useTimeline(query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const { data, ...queryResult } = useQuery({
        queryKey: ['timeline', visitorId, query],
        queryFn: ({ queryKey: [, visitorIdParam, queryParam], signal }) =>
            api.timeline.get(visitorIdParam, queryParam, null, null, { signal }),
        suspense: false,
        // staleTime: 0,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};
    return {
        items,
        ...metadata,
        ...queryResult,
    };
}

export function useTimelineInfinite(query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const { data, ...queryResult } = useInfiniteQuery({
        queryKey: ['timeline', visitorId, 'infinite', query],
        initialPageParam: 1,
        queryFn: ({ pageParam = 1, queryKey: [, visitorIdParam, , queryParam], signal }) =>
            api.timeline.get(
                visitorIdParam,
                {
                    snippet: true,
                    ...queryParam,
                },
                pageParam,
                null,
                { signal },
            ),
        getNextPageParam: ({ pagination: { page: currentPage = 1, last_page: lastPage = -1 } }) => {
            if (lastPage === currentPage) {
                return undefined;
            }
            return currentPage + 1;
        },
        suspense: false,
        ...opts,
    });

    const { pages = null, pageParams = null } = data || {};

    return {
        pages,
        pageParams,
        ...queryResult,
    };
}

export function useTimelineFuture(count = 1, query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const queryClient = useQueryClient();
    const { snippet = false } = query || {};
    const { enabled = true } = opts || {};
    const { data, ...queryResult } = useQuery({
        queryKey: ['timeline', visitorId, 'future', count, query],
        queryFn: ({ queryKey: [, visitorIdParam, , countParam, queryParam], signal }) =>
            api.timeline.future(
                visitorIdParam,
                {
                    count: countParam,
                    ...queryParam,
                },
                null,
                null,
                { signal },
            ),
        staleTime: 5 * 60 * 1000,
        suspense: false,
        keepPreviousData: false,
        enabled: enabled && visitorId !== null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    useEffect(() => {
        if (!snippet && items !== null) {
            items.forEach((nextDocument) => {
                queryClient.setQueryData(['document', nextDocument.slug], () => nextDocument);
            });
        }
    }, [items, snippet]);

    const remove = useCallback(() => {
        queryClient.removeQueries({ queryKey: ['timeline', visitorId, 'future', count, query] });
    }, [visitorId, count, query]);

    return {
        items,
        remove,
        ...metadata,
        ...queryResult,
    };
}

export function useTimelineRecommendations(count = 1, query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const queryClient = useQueryClient();
    const { snippet = false } = query || {};
    const { enabled = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['timeline', visitorId, 'recommendations', count, query],
        queryFn: ({ queryKey: [, visitorIdParam, , countParam, queryParam], signal }) =>
            api.timeline.recommendations(
                visitorIdParam,
                {
                    count: countParam,
                    ...queryParam,
                },
                null,
                null,
                { signal },
            ),
        staleTime: 0,
        suspense: false,
        keepPreviousData: false,
        enabled: enabled && visitorId !== null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    useEffect(() => {
        if (!snippet && items !== null) {
            items.forEach((nextDocument) => {
                queryClient.setQueryData(['document', nextDocument.slug], () => nextDocument);
            });
        }
    }, [items, snippet]);

    const remove = useCallback(() => {
        queryClient.removeQueries({
            queryKey: ['timeline', visitorId, 'recommendations', count, query],
        });
    }, [visitorId, count, query]);

    return {
        items,
        remove,
        ...metadata,
        ...queryResult,
    };
}

export function useTimelinePast(count = 1, query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const queryClient = useQueryClient();
    const { snippet = false } = query || {};
    const { data, ...queryResult } = useQuery({
        queryKey: ['timeline', visitorId, 'past', count, query],
        queryFn: ({ queryKey: [, visitorIdParam, , countParam, queryParam], signal }) =>
            api.timeline.past(
                visitorIdParam,
                {
                    count: countParam,
                    ...queryParam,
                },
                null,
                null,
                { signal },
            ),
        // staleTime: 0,
        suspense: false,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    useEffect(() => {
        if (!snippet && items !== null) {
            items.forEach((nextDocument) => {
                queryClient.setQueryData(['document', nextDocument.slug], () => nextDocument);
            });
        }
    }, [items, snippet]);

    return {
        items,
        ...metadata,
        ...queryResult,
    };
}

export function useTimelinePastInfinite(query = null, opts = null) {
    const visitorId = useVisitorId();
    const api = useApi();
    const { data, ...queryResult } = useInfiniteQuery({
        queryKey: ['timeline', visitorId, 'past_infinite', query],
        initialPageParam: 1,
        queryFn: ({ pageParam = 1, queryKey: [, visitorIdParam, , queryParam], signal }) =>
            api.timeline.past(
                visitorIdParam,
                {
                    snippet: true,
                    ...queryParam,
                },
                pageParam,
                null,
                { signal },
            ),
        getNextPageParam: ({ pagination: { page: currentPage = 1, last_page: lastPage = -1 } }) => {
            if (lastPage === currentPage) {
                return undefined;
            }
            return currentPage + 1;
        },
        suspense: false,
        ...opts,
    });

    const { pages = null, pageParams = null } = data || {};

    return {
        pages,
        pageParams,
        ...queryResult,
    };
}

export function useTimelineNext(url, query = null, opts = null) {
    const { items = null, ...queryResult } = useTimelineFuture(
        1,
        {
            referrer: url,
            ...query,
        },
        opts,
    );
    const [next = null] = items || [];
    return {
        next,
        ...queryResult,
    };
}

export function useTimelinePrevious(url, query = null, opts = null) {
    const { items = null, ...queryResult } = useTimelinePast(
        1,
        {
            referrer: url,
            ...query,
        },
        opts,
    );
    const [previous = null] = items || [];
    return {
        previous,
        ...queryResult,
    };
}

export function useMutateTimeline(opts = {}) {
    const { onSuccess = null, ...otherOpts } = opts || {};
    const queryClient = useQueryClient();
    const visitorId = useVisitorId();
    const api = useApi();
    return useMutation({
        mutationFn: (url) => api.timeline.create(visitorId, url),
        onSuccess: (newData) => {
            // queryClient.invalidateQueries({ queryKey: ['timeline', visitorId] });
            // queryClient.refetchQueries({ queryKey: ['next_timeline', visitorId, url] });

            if (onSuccess !== null) {
                onSuccess(newData);
            }
        },
        ...otherOpts,
    });
}

export function useSkipTimeline(opts = {}) {
    const { onSuccess = null, ...otherOpts } = opts || {};
    const queryClient = useQueryClient();
    const visitorId = useVisitorId();
    const api = useApi();
    const { mutate, mutateAsync, ...other } = useMutation({
        mutationFn: (url) => api.timeline.skip(visitorId, url),
        onSuccess: (newData) => {
            // queryClient.invalidateQueries({ queryKey: ['timeline', visitorId] });
            // queryClient.refetchQueries({ queryKey: ['next_timeline', visitorId, url] });

            if (onSuccess !== null) {
                onSuccess(newData);
            }
        },
        ...otherOpts,
    });
    return {
        skip: mutate,
        skipAsync: mutateAsync,
        ...other,
    };
}

export function useTimelineSync(url) {
    const { mutate: mutateTimeline } = useMutateTimeline();
    useEffect(() => {
        mutateTimeline(url);
    }, [url, mutateTimeline]);
}
