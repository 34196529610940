import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function PlayIcon({ color, className }) {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M11.2663 9.47881L1.90697 14.8824C1.21369 15.2827 0.347079 14.7824 0.347079 13.9818L0.347079 3.17456C0.347079 2.37403 1.21369 1.87369 1.90697 2.27396L11.2663 7.67759C11.9596 8.07786 11.9596 9.07854 11.2663 9.47881Z"
                fill={color}
            />
        </svg>
    );
}

PlayIcon.propTypes = propTypes;
PlayIcon.defaultProps = defaultProps;

export default PlayIcon;
