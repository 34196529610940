/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import ItemCount from '../typography/ItemCount';
import LocationTypeIcon from './LocationTypeIcon';

import styles from '../../styles/icons/locations-count.module.css';

const propTypes = {
    count: PropTypes.number,
    color: PropTypes.string,
    iconCircleColor: PropTypes.string,
    countClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    count: null,
    color: '#222',
    iconCircleColor: 'transparent',
    countClassName: null,
    className: null,
};

function LocationsCountIcon({ count, color, iconCircleColor, countClassName, className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <LocationTypeIcon color={color} circleColor={iconCircleColor} className={styles.icon} />
            {count !== null && count > 0 ? (
                <span className={classNames([styles.count, countClassName])}>
                    <ItemCount>{count}</ItemCount>
                </span>
            ) : null}
        </div>
    );
}

LocationsCountIcon.propTypes = propTypes;
LocationsCountIcon.defaultProps = defaultProps;

export default LocationsCountIcon;
