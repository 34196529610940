/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/fields/date-field.module.css';

const propTypes = {
    type: PropTypes.oneOf(['date', 'text']),
    name: PropTypes.string,
    value: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    errors: AppPropTypes.formErrors,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: AppPropTypes.ref,
};

const defaultProps = {
    type: 'date',
    name: null,
    value: null,
    min: null,
    max: null,
    placeholder: null,
    errors: null,
    withBorder: false,
    className: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    inputRef: null,
};

function DateField({
    type,
    name,
    value,
    min,
    max,
    placeholder,
    errors,
    withBorder,
    className,
    onChange,
    onFocus,
    onBlur,
    inputRef,
}) {
    const internalRef = useRef(null);
    const onInputChange = useCallback(
        (e) => {
            internalRef.current.setCustomValidity('');
            const newValue = e.currentTarget.value !== '' ? e.currentTarget.value : null;

            if (onChange !== null) {
                onChange(newValue);
            }
        },
        [onChange],
    );
    useEffect(() => {
        internalRef.current.setCustomValidity(
            errors !== null && errors.length > 0 ? errors[0] : '',
        );
        if (errors !== null && errors.length > 0) {
            internalRef.current.reportValidity();
        }
    }, [errors]);
    return (
        <input
            ref={(ref) => {
                internalRef.current = ref;
                if (inputRef !== null && isFunction(inputRef)) {
                    inputRef(ref);
                } else if (inputRef !== null) {
                    inputRef.current = ref;
                }
            }}
            type={type}
            name={name}
            value={value || ''}
            min={min}
            max={max}
            placeholder={placeholder}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
            onChange={onInputChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
}

DateField.propTypes = propTypes;
DateField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <DateField {...props} inputRef={ref} />);
