import { getSizeWithinBounds } from '@folklore/size';
import React from 'react';

import styles from '../../styles/ads/parallax-ad-wrapper.module.css';

function parallaxAdWrapper(wrapper, { innerWidth, innerHeight }) {
    const { width, height, format, maskHeight, backgroundColor, image } = wrapper;
    const {
        width: newWidth,
        height: newHeight,
        scale: newScale,
    } = getSizeWithinBounds(width, height, innerWidth, innerHeight, {
        cover: format === 'cover',
    });

    let finalMaskHeight = innerHeight * 0.5;
    if (maskHeight !== null && maskHeight.match(/^([0-9]+)%$/) !== null) {
        const [, percentage] = maskHeight.match(/^([0-9]+)%$/);
        finalMaskHeight = innerHeight * (parseInt(percentage, 10) / 100);
    } else if (maskHeight !== null && maskHeight.match(/^([0-9]+)(px)?$/) !== null) {
        const [, pixel] = maskHeight.match(/^([0-9]+)(px)?$/);
        finalMaskHeight = parseInt(pixel, 10);
    }

    const blurBackground = backgroundColor === 'blur' ? image : null;

    return {
        className: styles.container,
        style: {
            height: finalMaskHeight,
        },

        transformClassName: styles.transform,
        transformStyle: {
            top: format === 'centered' && newScale >= 1 ? '50%' : null,
            left: format === 'centered' && newScale >= 1 ? '50%' : null,
            width,
            height,
            transform:
                format === 'centered' && newScale >= 1
                    ? 'translate3d(-50%, -50%, 0)'
                    : `translate3d(${(innerWidth - newWidth) / 2}px, ${(innerHeight - newHeight) / 2}px, 0px) scale(${newScale})`,
        },

        innerClassName: styles.inner,
        innerChildren:
            blurBackground !== null ? (
                <div
                    className={styles.blurBackground}
                    style={{
                        backgroundImage: `url("${blurBackground}")`,
                    }}
                />
            ) : null,
        innerStyle: {
            backgroundColor: backgroundColor !== 'blur' ? backgroundColor : 'transparent',
        },
    };
}

export default parallaxAdWrapper;
