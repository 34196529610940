import Base from './Base';

class Promotions extends Base {
    get(query = null, page = null, count = null, opts = null) {
        return this.getJSON(
            '/promotions',
            {
                ...(page !== null ? { page } : null),
                ...(count !== null ? { count } : null),
                ...query,
            },
            opts,
        );
    }

    findByHandle(handle, opts = null) {
        return this.getJSON(
            '/promotions',
            {
                handle,
            },
            opts,
        ).catch((e) => {
            if (e.status === 404) {
                return null;
            }
            throw e;
        });
    }
}

export default Promotions;
