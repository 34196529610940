/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import arrowImage from '../../../assets/img/icons/facebook.svg';

const propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    alt: 'Facebook',
    className: null,
};

function FacebookIcon({ alt, className }) {
    return <img src={arrowImage} className={className} alt={alt} />;
}

FacebookIcon.propTypes = propTypes;
FacebookIcon.defaultProps = defaultProps;

export default FacebookIcon;
