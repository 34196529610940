import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function DestinationsADecouvrir({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.72 33.33" className={className}>
            <path
                fill={color}
                d="M15.18,31.09l-.82,2.24-.61-3.02c-.44-3.23-.95-6.61-2.93-9.2-.95-1.14-2.03-2.17-3.22-3.07-2.06-1.58-3.9-3.44-5.47-5.51C.56,10.43-.19,7.82,.04,5.21,.18,3.71,.91,2.34,2.06,1.37,3.66,.35,5.62,.06,7.45,.58c2.84,.61,5.63,2.45,6.61,5.1,.33-1.59,4.18-4.82,5.25-4.93,2.47-.26,5.68-1.87,8.93,.63s2.73,7.07,1.68,9.17c-1.15,2.3-1.33,3.61-3.53,4.95-5.14,3.16-8.96,8.08-10.74,13.84l-.47,1.75Z"
            />
        </svg>
    );
}

DestinationsADecouvrir.propTypes = propTypes;
DestinationsADecouvrir.defaultProps = defaultProps;

export default DestinationsADecouvrir;
