import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/partials/loading-bar.module.css';

const propTypes = {
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    barClassName: PropTypes.string,
};

const defaultProps = {
    loading: false,
    loaded: false,
    color: null,
    className: null,
    barClassName: null,
};

const LoadingBar = ({ loading, loaded, color, className, barClassName }) => (
    <div
        className={classNames([
            styles.container,
            {
                [styles.loading]: loading,
                [styles.loaded]: loaded,
            },
            className,
        ])}
    >
        <div
            className={classNames([styles.bar, barClassName])}
            style={{
                backgroundColor: color,
            }}
        />
    </div>
);

LoadingBar.propTypes = propTypes;
LoadingBar.defaultProps = defaultProps;

export default React.memo(LoadingBar);
