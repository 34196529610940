import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import useDocument from '../../hooks/useDocument';
import useLocation from '../../hooks/useLocation';
import useLocationType from '../../hooks/useLocationType';

import { useSite } from '../../contexts/SiteContext';
import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function MapMetadata() {
    const {
        region: regionParam = null,
        locationType: locationTypeParam = null,
        location: locationParam = null,
        document: documentParam = null,
    } = useParams();
    const url = useUrlGenerator();
    const { regions } = useSite();
    const region =
        regionParam !== null ? regions.find(({ slug }) => slug === regionParam) || null : null;
    const { locationType = null } = useLocationType(locationTypeParam, {
        enabled: locationTypeParam !== null,
    });
    const { location = null } = useLocation(locationParam, {
        enabled: locationParam !== null,
    });
    const { document = null } = useDocument(documentParam, {
        enabled: documentParam !== null,
    });
    let title = 'Carte';
    let link = url('map');
    let image = null;
    if (region !== null) {
        title = `${region.label} - Carte`;
        link = url('map.region', { region: region.slug });
    } else if (locationType !== null) {
        title = `${locationType.label} - Carte`;
        link = url('map.locationType', { locationType: locationType.slug });
    } else if (location !== null) {
        title = `${location.name} - Carte`;
        link = url('map.location', { location: location.slug });
    } else if (document !== null) {
        title = `${document.title} - Carte`;
        link = url('map.document', { document: document.slug });
        image = document.image;
    }

    return <Metadata title={title} link={link} image={image} />;
}

MapMetadata.propTypes = propTypes;
MapMetadata.defaultProps = defaultProps;

export default MapMetadata;
