/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { isbot } from 'isbot';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'wouter';

import PageRoutes from '../PageRoutes';

import styles from '../../styles/pages/redirector-page.module.css';

const propTypes = {
    redirectDomain: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    redirectDomain: 'https://urbania.ca',
    className: null,
};
function RedirectorPage({ redirectDomain, className }) {
    const [pageLocation] = useLocation();
    useEffect(() => {
        if (isbot(navigator.userAgent)) {
            return;
        }
        const { search = null, pathname } = window.location;
        const { referrer = null } = document;
        const queryString = [
            search !== null && search.length > 0 ? `${search.replace(/^\?/, '')}` : null,
            referrer !== null && referrer.length > 0
                ? `referrer=${encodeURIComponent(referrer)}`
                : null,
        ]
            .filter((it) => it !== null)
            .join('&');
        window.location.href = `${redirectDomain}${pathname}${queryString.length > 0 ? `?${queryString}` : ''}`;
    }, []);
    return (
        <div className={classNames([styles.container, className])}>
            <PageRoutes location={pageLocation} current entered />
        </div>
    );
}

RedirectorPage.propTypes = propTypes;
RedirectorPage.defaultProps = defaultProps;

export default RedirectorPage;
