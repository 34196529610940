import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/forms/form-row.module.css';

const propTypes = {
    children: PropTypes.node,
    alwaysHorizontal: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    alwaysHorizontal: false,
    className: null,
};

function FormRow({ children, alwaysHorizontal, className }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.alwaysHorizontal]: alwaysHorizontal,
                },
                className,
            ])}
        >
            {children}
        </div>
    );
}

FormRow.propTypes = propTypes;
FormRow.defaultProps = defaultProps;

export default FormRow;
