import { AdsTargetingProvider } from '@folklore/ads';
import { useUser } from '@folklore/auth';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../lib/PropTypes';

import { useVisitorAudiences } from './VisitorContext';

function getDocumentTargeting(item) {
    if (item === null) {
        return null;
    }
    const { metadata = null, identifiers, identifier } = item;
    const {
        brands = [],
        sponsors = [],
        categories = null,
        topics = null,
        formats = null,
        tags = null,
        adsSettings = null,
    } = metadata || {};
    const {
        exclusive = false,
        targeting: customTargeting = {},
        safeContent = true,
    } = adsSettings || {};
    const [brand = null] = brands || [];
    return {
        brand: brand !== null ? brand.handle || brand.slug : null,
        brands: (brands || []).map(({ slug = null, handle = null }) => handle || slug),
        categories: [...(categories || []), ...(topics || []), ...(formats || [])].map(
            ({ slug = null, handle = null }) => handle || slug,
        ),
        tags: (tags || []).map(({ slug = null, handle = null }) => handle || slug),
        safeContent: safeContent ? 'true' : 'false',
        exclusive: exclusive ? 'true' : 'false',
        identifiers,
        identifier,
        isSponsored: sponsors !== null && sponsors.length > 0 ? 'true' : 'false',
        ...customTargeting,
    };
}

function getTaxonomyTargeting(item) {
    if (item === null) {
        return null;
    }

    const { type, brand = null, adsSettings = null, slug: taxonomySlug = null } = item;
    const {
        exclusive = false,
        targeting: customTargeting = {},
        safeContent = taxonomySlug === null || taxonomySlug.indexOf('sex') === -1,
    } = adsSettings || {};

    return {
        brand: brand !== null ? brand.handle || brand.slug : null,
        brands:
            brand !== null ? [brand].map(({ slug = null, handle = null }) => handle || slug) : null,
        categories:
            type !== 'tag' ? [item].map(({ slug = null, handle = null }) => handle || slug) : null,
        tags:
            type === 'tag' ? [item].map(({ slug = null, handle = null }) => handle || slug) : null,
        safeContent: safeContent ? 'true' : 'false',
        exclusive: exclusive ? 'true' : 'false',
        ...customTargeting,
    };
}

function getUserTargeting(item) {
    if (item === null) {
        return null;
    }
    return {};
}

export function DocumentAdsTargetingProvider({ document, disabled, children }) {
    const targeting = useMemo(
        () => ({
            ...getDocumentTargeting(document),
            // disabled,
        }),
        [document, disabled],
    );
    return <AdsTargetingProvider targeting={targeting}>{children}</AdsTargetingProvider>;
}

DocumentAdsTargetingProvider.propTypes = {
    document: AppPropTypes.document,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};
DocumentAdsTargetingProvider.defaultProps = {
    document: null,
    disabled: false,
    children: null,
};

export function CollectionAdsTargetingProvider({ collection, disabled, children }) {
    const targeting = useMemo(
        () => ({
            ...getDocumentTargeting(collection),
            disabled,
        }),
        [collection],
    );
    return <AdsTargetingProvider targeting={targeting}>{children}</AdsTargetingProvider>;
}

CollectionAdsTargetingProvider.propTypes = {
    collection: AppPropTypes.collection,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};
CollectionAdsTargetingProvider.defaultProps = {
    collection: null,
    disabled: false,
    children: null,
};

export function TaxonomyAdsTargetingProvider({ taxonomy, disabled, children }) {
    const targeting = useMemo(
        () => ({
            ...getTaxonomyTargeting(taxonomy),
            disabled,
        }),
        [taxonomy, disabled],
    );
    return <AdsTargetingProvider targeting={targeting}>{children}</AdsTargetingProvider>;
}

TaxonomyAdsTargetingProvider.propTypes = {
    taxonomy: AppPropTypes.taxonomy,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};
TaxonomyAdsTargetingProvider.defaultProps = {
    taxonomy: null,
    disabled: false,
    children: null,
};

export function UserAdsTargetingProvider({ children }) {
    const user = useUser();
    const audiences = useVisitorAudiences();
    const targeting = useMemo(
        () => ({
            ...getUserTargeting(user),
            audiences,
        }),
        [user, audiences],
    );
    return <AdsTargetingProvider targeting={targeting}>{children}</AdsTargetingProvider>;
}

UserAdsTargetingProvider.propTypes = {
    children: PropTypes.node,
};
UserAdsTargetingProvider.defaultProps = {
    children: null,
};
