import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import logoBlack from '../../assets/img/brands/urbania_black.png?as=logo';
import logo from '../../assets/img/brands/urbania_white.png?as=logo';

import styles from '../../styles/headers/urbania-header.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function UrbaniaHeader({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.inner}>
                <div className={styles.logoContainer}>
                    <img
                        src={logo}
                        alt="URBANIA"
                        className={styles.logo}
                        width="1000"
                        height="356"
                    />
                    <img
                        src={logoBlack}
                        alt="URBANIA"
                        className={classNames([styles.logo, styles.shadow])}
                        width="1000"
                        height="356"
                    />
                </div>
            </div>
        </div>
    );
}

UrbaniaHeader.propTypes = propTypes;
UrbaniaHeader.defaultProps = defaultProps;

export default UrbaniaHeader;
