/* eslint-disable react/jsx-props-no-spreading */
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import messages from '../data/messages';

export const PopupsContext = React.createContext(null);

export const MODAL_NAMESPACE = 'modal';
export const NOTIFICATION_NAMESPACE = 'notification';
export const MESSAGE_NAMESPACE = 'message';

export const usePopupsContext = () => useContext(PopupsContext) || {};
export const useHasModals = () => {
    const { popups = null } = usePopupsContext();
    return (
        popups !== null &&
        popups.filter(({ namespace = null }) => namespace === MODAL_NAMESPACE).length > 0
    );
};
export const useHasNotifications = () => {
    const { popups = null } = usePopupsContext();
    return (
        popups !== null &&
        popups.filter(({ namespace = null }) => namespace === NOTIFICATION_NAMESPACE).length > 0
    );
};
export const useHasMessages = () => {
    const { popups = null } = usePopupsContext();
    return (
        popups !== null &&
        popups.filter(({ namespace = null }) => namespace === MESSAGE_NAMESPACE).length > 0
    );
};

const propTypes = {
    children: PropTypes.node.isRequired,
    containers: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
    containers: null,
};

export const PopupsProvider = ({ children, containers: initialContainers }) => {
    const [containers, setContainers] = useState(initialContainers || {});
    const [popups, setPopups] = useState([]);
    const popupsRef = useRef(popups);
    const containersRef = useRef(containers);

    const register = useCallback(
        (id, data = null, namespace = null) => {
            const { current: currentPopups = [] } = popupsRef;
            const foundIndex = currentPopups.findIndex(
                ({ id: popupId, namespace: popupNamespace = null }) =>
                    popupId === id && (namespace === null || popupNamespace === namespace),
            );
            const newPopups =
                foundIndex !== -1
                    ? [
                          ...currentPopups.slice(0, foundIndex),
                          {
                              id,
                              ...data,
                          },
                          ...currentPopups.slice(foundIndex + 1),
                      ]
                    : [
                          ...currentPopups,
                          {
                              id,
                              namespace,
                              ...data,
                          },
                      ];
            setPopups(newPopups);
            popupsRef.current = newPopups;
        },
        [setPopups],
    );

    const unregister = useCallback(
        (id, namespace = null) => {
            const { current: currentPopups = [] } = popupsRef;
            const foundIndex = currentPopups.findIndex(
                ({ id: popupId, namespace: popupNamespace = null }) =>
                    popupId === id && (namespace === null || popupNamespace === namespace),
            );
            if (foundIndex !== -1) {
                const newPopups = currentPopups.filter(
                    ({ id: popupId, namespace: popupNamespace = null }) =>
                        popupId !== id && (namespace === null || namespace !== popupNamespace),
                );
                setPopups(newPopups);
                popupsRef.current = newPopups;
            }
        },
        [setPopups],
    );

    const closePopup = useCallback(
        (id, namespace = null) => {
            const { current: currentPopups = [] } = popupsRef;
            const foundIndex = currentPopups.findIndex(
                ({ id: popupId, namespace: popupNamespace = null }) =>
                    popupId === id && (namespace === null || popupNamespace === namespace),
            );
            if (foundIndex !== -1) {
                const { requestClose = null } = currentPopups[foundIndex] || {};
                if (requestClose !== null) {
                    requestClose();
                }
                // const newPopups = currentPopups.filter(
                //     ({ id: popupId, namespace: popupNamespace = null }) =>
                //         popupId !== id && (namespace === null || popupNamespace === namespace),
                // );
                // setPopups(newPopups);
                // popupsRef.current = newPopups;
            }
        },
        [setPopups],
    );

    const closeLastPopup = useCallback(
        (namespace = null) => {
            const currentPopups = (popupsRef.current || []).filter(
                ({ namespace: popupNamespace }) =>
                    namespace === null || namespace === popupNamespace,
            );
            const lastPopup =
                currentPopups.length > 0 ? currentPopups[currentPopups.length - 1] || null : null;
            if (lastPopup !== null) {
                closePopup(lastPopup.id, namespace);
            }
        },
        [closePopup],
    );

    const getPopupById = useCallback((id, namespace) => {
        const { current: currentPopups = [] } = popupsRef;
        return (
            (currentPopups || []).find(
                ({ id: popupId, namespace: popupNamespace = null }) =>
                    id === popupId && (namespace === null || popupNamespace === namespace),
            ) || null
        );
    }, []);

    const setContainer = useCallback(
        (container, namespace = null) => {
            const { current: currentContainers } = containersRef;
            const newContainers = {
                ...currentContainers,
                [namespace || 'default']: container,
            };
            setContainers(newContainers);
            containersRef.current = newContainers;
        },
        [setContainers],
    );

    const methods = useMemo(
        () => ({
            // Modals
            registerModal: (id, data) => register(id, data, MODAL_NAMESPACE),
            unregisterModal: (id) => unregister(id, MODAL_NAMESPACE),
            closeModal: (id) => closePopup(id, MODAL_NAMESPACE),
            closeLastModal: () => closeLastPopup(MODAL_NAMESPACE),
            getModalById: (id) => getPopupById(id, MODAL_NAMESPACE),

            // Notifications
            registerNotification: (id, data) => register(id, data, NOTIFICATION_NAMESPACE),
            unregisterNotification: (id) => unregister(id, NOTIFICATION_NAMESPACE),
            getNotificationById: (id) => getPopupById(id, NOTIFICATION_NAMESPACE),
            closeNotification: (id) => closePopup(id, NOTIFICATION_NAMESPACE),
            closeLastNotification: () => closeLastPopup(NOTIFICATION_NAMESPACE),

            // Messages
            registerMessage: (id, data) => register(id, data, MESSAGE_NAMESPACE),
            addMessage: (message, data = null) => {
                let finalmessage = message;
                if (typeof messages[message] !== 'undefined') {
                    finalmessage = isFunction(messages[message])
                        ? messages[message](data)
                        : messages[message];
                }
                return register(
                    `message-${new Date().getTime()}`,
                    {
                        message: finalmessage,
                        ...(isString(data) ? { theme: data } : data),
                    },
                    MESSAGE_NAMESPACE,
                );
            },
            unregisterMessage: (id) => unregister(id, MESSAGE_NAMESPACE),
            getMessageById: (id) => getPopupById(id, MESSAGE_NAMESPACE),
            closeMessage: (id) => closePopup(id, MESSAGE_NAMESPACE),
            closeLastMessage: () => closeLastPopup(MESSAGE_NAMESPACE),
        }),
        [register, unregister, getPopupById, closePopup, closeLastPopup],
    );

    const value = useMemo(
        () => ({
            popups,
            containers,
            setContainer,
            register,
            unregister,
            closeLastPopup,
            closePopup,
            getPopupById,

            // Modals
            modals: popups.filter(({ namespace }) => namespace === MODAL_NAMESPACE),
            modalContainer: (containers || {})[MODAL_NAMESPACE] || null,

            // Notifications
            notifications: popups.filter(({ namespace }) => namespace === NOTIFICATION_NAMESPACE),
            notificationContainer: (containers || {})[NOTIFICATION_NAMESPACE] || null,

            // Messages
            messages: popups.filter(({ namespace }) => namespace === MESSAGE_NAMESPACE),
            messageContainer: (containers || {})[MESSAGE_NAMESPACE] || null,
            ...methods,
        }),
        [
            popups,
            containers,
            setContainer,
            register,
            unregister,
            closeLastPopup,
            closePopup,
            getPopupById,
            methods,
        ],
    );

    return <PopupsContext.Provider value={value}>{children}</PopupsContext.Provider>;
};

PopupsProvider.propTypes = propTypes;
PopupsProvider.defaultProps = defaultProps;
