import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import isArray from 'lodash/isArray';

import { useApi } from '../contexts/ApiContext';
import { useVisitorId } from '../contexts/VisitorContext';

export function useRelatedDocuments(query = null, opts = {}) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { id, ...relatedQuery } = query || {};
    const visitorId = useVisitorId();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['document_related', id, { visitor_id: visitorId, ...relatedQuery }],
        queryFn: ({ queryKey: [, related, queryParam], signal }) =>
            api.documents.related(related, queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}

export function usePopularDocuments(query = null, opts = {}) {
    const api = useApi();
    const { keepPreviousData = true, enabled = true, ...otherOpts } = opts || {};
    const visitorId = useVisitorId();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['document_popular', { visitor_id: visitorId, ...query }],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.popular(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        enabled: visitorId !== null && enabled,
        ...otherOpts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}

export function fetchDocuments(queryClient, api, query) {
    return queryClient.fetchQuery({
        queryKey: ['documents', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.get(queryParam, null, null, { signal }),
    });
}

export default function useDocuments(query = null, opts = {}) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['documents', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.get(queryParam, null, null, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}
