/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useCancelContact, useVerifyContact } from '../../hooks/useProfile';
import * as AppPropTypes from '../../lib/PropTypes';

import VerifyToken from './VerifyTokenForm';

const propTypes = {
    contact: AppPropTypes.userContact.isRequired,
    onCancel: PropTypes.func,
};

const defaultProps = {
    onCancel: null,
};

function VerifyContact({ contact, onCancel, ...props }) {
    const { verifyContactAsync } = useVerifyContact();
    const { cancelContact } = useCancelContact();
    const postForm = useCallback(
        (actionForm, data) =>
            verifyContactAsync({
                contact,
                ...data,
            }),
        [verifyContactAsync],
    );
    const onClickCancel = useCallback(() => {
        cancelContact(contact);
        if (onCancel !== null) {
            onCancel();
        }
    }, [contact, cancelContact, onCancel]);
    return <VerifyToken postForm={postForm} onClickCancel={onClickCancel} {...props} />;
}

VerifyContact.propTypes = propTypes;
VerifyContact.defaultProps = defaultProps;

export default VerifyContact;
