import queryString from 'query-string';
import { useMemo } from 'react';
import { useSearch } from 'wouter';

import { useGlobalBrand } from '../contexts/LayoutContext';

export default function useFiltersQuery({ sort } = {}) {
    const search = useSearch();
    const brand = useGlobalBrand();

    const query = useMemo(() => {
        const { sort: sortParam = sort } = queryString.parse(search);
        // const { id: brandId = null } = brand || {};
        const newQuery = {};
        if (sortParam !== null) {
            newQuery.order = sortParam;
        }
        // if (brandId !== null) {
        //     newQuery.brand = brandId;
        // }
        return newQuery;
    }, [search, brand, sort]);

    return query;
}
