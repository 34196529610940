/* eslint-disable react/jsx-props-no-spreading */
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import RoundedButton from './RoundedButton';

import styles from '../../styles/buttons/form-button.module.css';

const propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    children: null,
    loading: false,
    className: null,
    buttonRef: null,
};

function FormButton({ children, loading, className, buttonRef, ...props }) {
    return (
        <RoundedButton
            className={classNames([
                styles.container,
                {
                    [styles.loading]: loading,
                    [className]: className !== null,
                },
            ])}
            ref={buttonRef}
            disabled={loading}
            {...props}
        >
            {children}
            {loading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin className={styles.loadingIcon} />
            ) : null}
        </RoundedButton>
    );
}

FormButton.propTypes = propTypes;
FormButton.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <FormButton {...props} buttonRef={ref} />);
