/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuthLogout } from '../../hooks/useAuth';

import BasicButton from '../buttons/BasicButton';
import AccountIcon from '../icons/AccountIcon';

import styles from '../../styles/fields/user-field.module.css';

const propTypes = {
    withBorder: PropTypes.bool,
    withoutLogout: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

const defaultProps = {
    withBorder: false,
    withoutLogout: false,
    className: null,
    inputClassName: null,
};

function UserField({ withBorder, withoutLogout, className, inputClassName }) {
    const user = useUser();
    const { email, phone } = user || {};
    const { logout } = useAuthLogout();
    const onClickLogout = useCallback(
        (e) => {
            e.preventDefault();
            logout();
        },
        [logout],
    );
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={classNames([styles.input, inputClassName])}>
                <AccountIcon circleColor="transparent" className={styles.icon} loggedIn />
                <span className={styles.label}>{email || phone}</span>
            </div>

            {user !== null && !withoutLogout ? (
                <div className={styles.logout}>
                    <FormattedMessage
                        defaultMessage="Ce n’est pas vous? {link}"
                        values={{
                            link: (
                                <BasicButton className={styles.link} onClick={onClickLogout}>
                                    <FormattedMessage
                                        defaultMessage="Déconnectez-vous"
                                        description="Button label"
                                    />
                                </BasicButton>
                            ),
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}

UserField.propTypes = propTypes;
UserField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <UserField {...props} inputRef={ref} />);
