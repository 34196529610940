/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import { useBrands, useDefaultBrand } from '../../contexts/SiteContext';

import { ReactComponent as Dehors } from '../../assets/img/brands/dehors_normalized.svg';
import { ReactComponent as Mollo } from '../../assets/img/brands/mollo_normalized.svg';
import { ReactComponent as PeriodeLibre } from '../../assets/img/brands/periodelibre_normalized.svg';
import { ReactComponent as Quatre95 } from '../../assets/img/brands/quatre95_normalized.svg';
import urbania from '../../assets/img/brands/urbania_normalized.png';

const logos = {
    urbania,
    france: urbania,
    dehors: Dehors,
    quatre95: Quatre95,
    mollo: Mollo,
    periodelibre: PeriodeLibre,
};

const propTypes = {
    brand: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    className: null,
};

function BrandLogoNormalized({ brand, className }) {
    const defaultBrand = useDefaultBrand();

    const brands = useBrands();
    const { handle: brandHandle = null, label = null } =
        brands.find(({ handle }) => handle === brand) || defaultBrand;

    const LogoComponent = logos[brandHandle];

    return isString(LogoComponent) ? (
        <img
            src={LogoComponent}
            className={className}
            alt={label || 'Logo'}
            title={label || 'Logo'}
        />
    ) : (
        <LogoComponent
            fill="currentColor"
            className={className}
            alt={label || 'Logo'}
            title={label || 'Logo'}
        />
    );
}

BrandLogoNormalized.propTypes = propTypes;
BrandLogoNormalized.defaultProps = defaultProps;

export default BrandLogoNormalized;
