/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingIcon from './LoadingIcon';

import styles from '../../styles/icons/toggle-icon.module.css';

const propTypes = {
    checked: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    checked: false,
    loading: false,
    className: null,
};

function ToggleIcon({ checked, loading, className }) {
    return (
        <span
            className={classNames([
                styles.container,
                {
                    [styles.loading]: loading,
                    [styles.checked]: checked,
                },
                className,
            ])}
        >
            <span className={styles.control}>
                <span className={styles.knob}>
                    {loading ? <LoadingIcon className={styles.loadingIcon} /> : null}
                </span>
            </span>
        </span>
    );
}

ToggleIcon.propTypes = propTypes;
ToggleIcon.defaultProps = defaultProps;

export default ToggleIcon;
