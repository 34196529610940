/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/icons/document-type-icon.module.css';

const propTypes = {
    type: AppPropTypes.documentType.isRequired,
    withoutCircle: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    withoutCircle: false,
    className: null,
};

function DocumentTypeIcon({ type, withoutCircle, className }) {
    return (
        <span
            className={classNames([
                styles.container,
                styles[type],
                {
                    [styles.withoutCircle]: withoutCircle,
                },
                className,
            ])}
        />
    );
}

DocumentTypeIcon.propTypes = propTypes;
DocumentTypeIcon.defaultProps = defaultProps;

export default DocumentTypeIcon;
