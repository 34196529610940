import { useMemo } from 'react';

export default function getImageSize(media = null, size = null) {
    return useMemo(() => {
        const { sizes = null } = media || {};
        const imageSize =
            size !== null
                ? (sizes || []).find(
                      ({ id }) => id === size || (id === 'large' && size === 'responsive'),
                  ) || null
                : null;
        if (imageSize !== null) {
            return imageSize;
        }
        return media || null;
    }, [media]);
}
