import { useRoutes, useRouteMatcher } from '@folklore/routes';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearch } from 'wouter';

import { getTrackingQuery } from '../lib/utils';

import { useConsentGiven } from '../contexts/ConsentContext';
import { useRequestBase } from '../contexts/RequestContext';
import { useSkipTimeline, useTimelineNext } from './useTimeline';
import { useDocumentUrlGenerator } from './useUrlGenerator';

function getPathname(url) {
    return url !== null ? url.split('?')[0] : null;
}

function getSpecificPart(url) {
    return url !== null
        ? getPathname(url)
              .replace(/^\/?(.*)\/?$/, '$1')
              .split('/')
              .slice(0, 2)
              .join('/')
        : null;
}

function useNavigationTimeline(opts = null) {
    const [pathname, setLocation] = useLocation();
    const search = useSearch();
    const { loadNextDelay = 2000, updateLocationDelay = 500 } = opts || {};
    const location = `${pathname}${search !== null && search.length > 0 ? `?${search}` : ''}`;
    // const location = pathname;

    const routes = useRoutes();
    const routeMatcher = useRouteMatcher();
    const [timeline, setTimeline] = useState([location, null]);
    const [index, setIndex] = useState(0);
    const [updateLocationIndex, setUdpatelocationIndex] = useState(null);
    const [loadNext, setLoadNext] = useState(false);
    const documentUrl = useDocumentUrlGenerator();
    const consentGiven = useConsentGiven();
    const { skip } = useSkipTimeline();

    const nextUrl = useMemo(() => {
        const { next = null } = queryString.parse(search) || {};
        return next;
    }, [search]);

    const isSamePage = useCallback(
        (currentUrl, newUrl) =>
            currentUrl !== null &&
            newUrl !== null &&
            (getPathname(currentUrl) === getPathname(newUrl) ||
                getSpecificPart(currentUrl) === getSpecificPart(newUrl) ||
                [
                    routes['map.wildcard'],
                    routes['account.wildcard'],
                    routes['auth.wildcard'],
                    // routes['brand.wildcard'],
                ].reduce((isMatching, route) => {
                    if (isMatching) {
                        return true;
                    }
                    const [currentMatch = false] = routeMatcher(route, getPathname(currentUrl));
                    const [newMatch = false] = routeMatcher(route, getPathname(newUrl));
                    return currentMatch && newMatch;
                }, false)),
        [routes, routeMatcher],
    );

    const lastPage = useMemo(() => timeline[timeline.length - 2], [timeline]);
    const baseUri = useRequestBase();
    const { next: nextDocument = null, recommendationId = null } = useTimelineNext(
        // `${baseUri}${timeline[index]}`,
        `${baseUri}${lastPage}`,
        {
            snippet: true,
        },
        {
            enabled:
                loadNext &&
                lastPage !== null &&
                consentGiven === true &&
                (!isSamePage(location, timeline[index] || null) ||
                    timeline[timeline.length - 1] === null),
        },
    );

    const nextDocumentUrl = useMemo(
        () =>
            nextDocument !== null
                ? documentUrl(
                      nextDocument,
                      getTrackingQuery({ clickRef: 'timeline_next', recommendationId }),
                  )
                : null,
        [nextDocument, documentUrl, recommendationId],
    );

    const scrollYRef = useRef([]);

    useEffect(() => {
        const currentItem = timeline[index] || null;
        setUdpatelocationIndex(null);

        if (currentItem === location) {
            return;
        }

        if (isSamePage(currentItem, location) || (nextUrl !== null && currentItem === nextUrl)) {
            setTimeline([...timeline.slice(0, index), location, ...timeline.slice(index + 1)]);
            return;
        }

        if (index > 0 && timeline[index - 1] === location) {
            setIndex(index - 1);
            return;
        }

        if (index < timeline.length - 1 && timeline[index + 1] === location) {
            const newIndex = index + 1;
            setIndex(newIndex);
            if (newIndex === timeline.length - 1) {
                setTimeline([...timeline, null]);
            }
            return;
        }

        setTimeline([...timeline.slice(0, index + 1), location, null]);
        setIndex(index + 1);
        setLoadNext(false);
        scrollYRef.current = scrollYRef.current.slice(0, index + 1);
    }, [location, nextUrl, isSamePage]);

    useEffect(() => {
        if (nextDocumentUrl !== null) {
            setTimeline(
                index === timeline.length - 1
                    ? [...timeline.slice(0, timeline.length - 1), nextDocumentUrl, null]
                    : [...timeline.slice(0, timeline.length - 1), nextDocumentUrl],
            );
            setLoadNext(false);
        }
    }, [nextDocumentUrl]);

    const updateIndex = useCallback(
        (newIndex) => {
            setUdpatelocationIndex(newIndex);
            setIndex(newIndex);
            const isLastIndex = newIndex === timeline.length - 1;
            const lastItem = timeline[timeline.length - 1];
            if (isLastIndex && lastItem !== null) {
                setTimeline([...timeline, null]);
                setLoadNext(false);
            } else if (isLastIndex && lastItem === null) {
                setLoadNext(true);
            }
        },
        [setIndex, timeline],
    );

    useEffect(() => {
        let timeout = null;
        const newLocation =
            updateLocationIndex !== null ? timeline[updateLocationIndex] || null : null;
        let updated = false;
        if (newLocation !== null) {
            timeout = setTimeout(() => {
                setLocation(newLocation);
                setUdpatelocationIndex(null);
                updated = true;
            }, updateLocationDelay);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
            if (!updated && newLocation !== null) {
                skip(`${baseUri}${newLocation}`);
            }
        };
    }, [timeline, updateLocationIndex, updateLocationDelay]);

    useEffect(() => {
        let timeout = null;
        if (!loadNext) {
            timeout = setTimeout(() => {
                setLoadNext(true);
            }, loadNextDelay);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [loadNext]);

    useEffect(() => {
        let windowHeight = window.innerHeight;
        function updateSize() {
            windowHeight = window.innerHeight;
        }
        function updateScroll() {
            // if (document.body.scrollHeight > windowHeight * 2) {
            const currentScroll = scrollYRef.current[index] || 0;
            const newScroll = window.scrollY;
            const delta = Math.abs(newScroll - currentScroll);
            if (delta < windowHeight) {
                scrollYRef.current[index] = window.scrollY;
            }
            // }
        }
        window.addEventListener('resize', updateSize);
        window.addEventListener('scroll', updateScroll);
        updateScroll();
        return () => {
            window.removeEventListener('resize', updateSize);
            window.removeEventListener('scroll', updateScroll);
        };
    }, [index]);

    return {
        location,
        timeline,
        scroll: scrollYRef.current,
        index,
        setIndex: updateIndex,
    };
}

export default useNavigationTimeline;
