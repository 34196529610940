/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useDefaultBrand } from '../../contexts/SiteContext';

import styles from '../../styles/icons/brand-logo-header.module.css';

import dehors from '../../assets/img/brands/dehors_header.svg';
import mollo from '../../assets/img/brands/mollo_header.svg';
import periodelibre from '../../assets/img/brands/periodelibre_header.svg';
import periodelibreVertical from '../../assets/img/brands/periodelibre_header_vertical.svg';
import quatre95 from '../../assets/img/brands/quatre95_header.svg';
import urbania from '../../assets/img/brands/urbania_header.png';

const brands = {
    urbania,
    dehors,
    quatre95,
    mollo,
    periodelibre,
    periodelibre_vertical: periodelibreVertical,
};

const propTypes = {
    brand: PropTypes.string,
    vertical: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    vertical: null,
    className: null,
};

function BrandLogoHeader({ brand, vertical, className }) {
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const finalBrandHandle = brand || defaultBrandHandle;

    const logo =
        brands[vertical ? `${finalBrandHandle}_vertical` : finalBrandHandle] ||
        brands[finalBrandHandle];

    return <img src={logo} className={classNames([styles.container, className])} alt="Logo" />;
}

BrandLogoHeader.propTypes = propTypes;
BrandLogoHeader.defaultProps = defaultProps;

export default BrandLogoHeader;
