import { useRouteMatcher, useRoutes, useUrlGenerator } from '@folklore/routes';
import queryString from 'query-string';
import { useCallback, useMemo } from 'react';

export const typeToRoute = {
    article_legacy: 'article',
};

export const pathToType = {
    concours: 'contest',
    interactif: 'interactive',
};

export const typeToPath = {
    contest: 'concours',
    article_legacy: 'article',
    interactive: 'interactif',
};

export function useDocumentTypeFromPath(path) {
    const routes = useRoutes();
    const routeMatcher = useRouteMatcher();
    return useMemo(() => {
        const [, { type: routeType = null } = {}] = routeMatcher(routes.document, path);
        return pathToType[routeType] || routeType;
    }, [path, routeMatcher, routes]);
}

export function useDocumentUrlGenerator() {
    const url = useUrlGenerator();
    return useCallback(
        (document, query = null) => {
            const { type = null, slug = null } = document || {};
            return type !== null && slug !== null
                ? `${url(typeToRoute[type] || type, { slug })}${query !== null ? `?${queryString.stringify(query)}` : ''}`
                : null;
        },
        [url],
    );
}

export function useAuthorUrlGenerator() {
    const url = useUrlGenerator();
    return useCallback(
        (author, query = null) => {
            const { slug = null } = author || {};
            return slug !== null
                ? `${url('author', { slug })}${query !== null ? `?${queryString.stringify(query)}` : ''}`
                : null;
        },
        [url],
    );
}
