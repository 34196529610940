/* eslint-disable react/forbid-prop-types */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const propTypes = {
    hook: PropTypes.func.isRequired,
    query: PropTypes.object,
    suspense: PropTypes.bool,
    disabled: PropTypes.bool,
    initialData: PropTypes.array,
    placeholderData: PropTypes.array,
    // keepPreviousData: PropTypes.bool,
    children: PropTypes.node,
    onLoaded: PropTypes.func,
};

const defaultProps = {
    query: null,
    suspense: false,
    disabled: false,
    initialData: null,
    placeholderData: null,
    // keepPreviousData: false,
    children: null,
    onLoaded: null,
};

function ItemsLoader({
    hook,
    query,
    initialData,
    placeholderData,
    suspense,
    disabled,
    // keepPreviousData,
    children,
    onLoaded,
}) {
    const loadResult = hook(query, {
        suspense,
        enabled: !disabled,
        // keepPreviousData,
        ...(initialData !== null
            ? {
                  initialData,
              }
            : null),
        placeholderData,
    });

    const { items, isFetched } = loadResult || {};

    useEffect(() => {
        if (isFetched && items !== null && onLoaded !== null) {
            onLoaded(items);
        }
    }, [items, isFetched]);

    return React.cloneElement(children, loadResult);
}

ItemsLoader.propTypes = propTypes;
ItemsLoader.defaultProps = defaultProps;

export default ItemsLoader;
