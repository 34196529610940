/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BrandIcon from '../icons/BrandIcon';
import DocumentTypeIcon from '../icons/DocumentTypeIcon';
import Image from './Image';

import styles from '../../styles/partials/document-image.module.css';

const propTypes = {
    type: AppPropTypes.documentType,
    brand: PropTypes.string,
    withIcon: PropTypes.bool,
    withoutTypeIcon: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    typeIconClassName: PropTypes.string,
    brandIconClassName: PropTypes.string,
};

const defaultProps = {
    type: null,
    brand: null,
    withIcon: false,
    withoutTypeIcon: false,
    withoutBrandIcon: false,
    children: null,
    className: null,
    iconClassName: null,
    typeIconClassName: null,
    brandIconClassName: null,
};

function DocumentImage({
    type,
    brand,
    withIcon,
    withoutTypeIcon,
    withoutBrandIcon,
    children,
    className,
    iconClassName,
    typeIconClassName,
    brandIconClassName,
    ...props
}) {
    return (
        <Image
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            loading="lazy"
            {...props}
        >
            {withIcon && type !== null && !withoutTypeIcon ? (
                <DocumentTypeIcon
                    type={type}
                    className={classNames([
                        styles.icon,
                        styles.typeIcon,
                        {
                            [iconClassName]: iconClassName !== null,
                            [typeIconClassName]: typeIconClassName !== null,
                        },
                    ])}
                />
            ) : null}
            {withIcon && brand !== null && !withoutBrandIcon ? (
                <BrandIcon
                    type={brand}
                    className={classNames([
                        styles.icon,
                        styles.brandIcon,
                        {
                            [iconClassName]: iconClassName !== null,
                            [brandIconClassName]: brandIconClassName !== null,
                        },
                    ])}
                />
            ) : null}
            {children}
        </Image>
    );
}

DocumentImage.propTypes = propTypes;
DocumentImage.defaultProps = defaultProps;

export default DocumentImage;
