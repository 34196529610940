import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/partials/line-spacer.module.css';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function LineSpacer({ children, className }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.line} />
            <div className={styles.label}>{children}</div>
            <div className={styles.line} />
        </div>
    );
}

LineSpacer.propTypes = propTypes;
LineSpacer.defaultProps = defaultProps;

export default LineSpacer;
