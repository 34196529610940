export default function createLayout(id, { cell = null, bigCell = null, smallCell = null } = {}) {
    const columns = id.split('_').map((it) => parseInt(it, 10));
    return {
        id,
        cells: columns.reduce(
            (cells, count) => [
                ...cells,
                ...[...Array(count).keys()].map(() =>
                    count === 1
                        ? {
                              cardType: 'vertical',
                              cardSize: 'big',
                              cardRatio: 'horizontal',
                              cardResponsive: true,
                              ...cell,
                              ...bigCell,
                          }
                        : {
                              cardType: 'horizontal',
                              cardRatio: 'square',
                              cardResponsive: false,
                              ...cell,
                              ...smallCell,
                          },
                ),
            ],
            [],
        ),
    };
}
