import { useUrlGenerator } from '@folklore/routes';
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import BrandLogoHeader from '../icons/BrandLogoHeader';
import Menu from '../menus/Menu';

import styles from '../../styles/headers/brand-header.module.css';

import urbaniaLogo from '../../assets/img/brands/urbania_logo.svg';
// import { ReactComponent as Dehors } from '../../assets/img/sponsors/dehors_header_sponsor.svg';
// export { ReactComponent as Mollo } from './mollo_menu_sponsor.svg';
import { ReactComponent as Quatre95 } from '../../assets/img/sponsors/quatre95_menu_sponsor.svg';

const BrandsSponsors = {
    // Dehors,
    Quatre95,
};

const propTypes = {
    handle: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    sections: AppPropTypes.taxonomies,
    section: AppPropTypes.taxonomy,
    className: PropTypes.string,
};

const defaultProps = {
    sections: null,
    section: null,
    className: null,
};

function BrandHeader({ handle, label, slug, sections, section, className }) {
    const url = useUrlGenerator();
    const { id: sectionId = null } = section || {};
    const SponsorComponent = getComponentFromName(BrandsSponsors, handle);
    const withUrbaniaLogo = handle !== 'urbania' && handle !== 'france' && handle !== 'quatre95';
    const hasVerticalLogo = handle === 'periodelibre';
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hasVerticalLogo]: hasVerticalLogo,
                    [styles.withUrbania]: withUrbaniaLogo,
                },
                styles[handle],
                className,
            ])}
        >
            {withUrbaniaLogo ? (
                <Link href="/" className={styles.urbania}>
                    <img src={urbaniaLogo} className={styles.urbaniaLogo} alt="URBANIA" />
                </Link>
            ) : null}
            <div className={styles.inner}>
                <div className={styles.logoContainer}>
                    <BrandLogoHeader brand={handle} className={styles.logo} />
                    {hasVerticalLogo ? (
                        <BrandLogoHeader
                            brand={handle}
                            vertical
                            className={classNames([styles.logo, styles.logoVertical])}
                        />
                    ) : null}
                </div>
                {SponsorComponent !== null ? (
                    <div className={styles.sponsorContainer}>
                        <SponsorComponent fill="currentColor" className={styles.sponsor} />
                    </div>
                ) : null}
                <Menu
                    items={(sections || []).map(
                        ({ id, label: sectionLabel, slug: sectionSlug }) => ({
                            id,
                            label: sectionLabel,
                            href: url('section', {
                                brand: slug,
                                section: sectionSlug,
                            }),
                        }),
                    )}
                    className={styles.sectionsMenu}
                    itemsClassName={styles.sections}
                    itemClassName={styles.section}
                    buttonClassName={styles.button}
                />
            </div>
        </div>
    );
}

BrandHeader.propTypes = propTypes;
BrandHeader.defaultProps = defaultProps;

export default BrandHeader;
