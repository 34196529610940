/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from '@folklore/forms';
import { useSpring, animated, easings } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormButton from '../buttons/FormButton';
import RoundedButton from '../buttons/RoundedButton';
import TextField from '../fields/TextField';
import LoadingIcon from '../icons/LoadingIcon';
import FormStatus from '../partials/FormStatus';
import FormControl from './FormControl';

import styles from '../../styles/forms/verify-token-form.module.css';

const propTypes = {
    postForm: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    onComplete: PropTypes.func,
    onClickCancel: PropTypes.func,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    autoFocus: false,
    onComplete: null,
    onClickCancel: null,
    inputClassName: null,
    className: null,
};

function VerifyTokenForm({
    postForm,
    autoFocus,
    onComplete,
    onClickCancel,
    inputClassName,
    className,
}) {
    const intl = useIntl();
    const formFields = useMemo(() => ['token'], []);
    const inputRef = useRef(null);

    const { fields, onSubmit, status, errors } = useForm({
        fields: formFields,
        postForm,
        onComplete,
    });

    // const loadingStyle = useSpring({
    //     config: {
    //         duration: 250,
    //         easing: easings.easeOutCubic,
    //     },
    //     delay: status === 'loading' ? 100 : 0,
    //     to: status === 'loading' ? { opacity: 1 } : { opacity: 0 },
    // });

    useEffect(() => {
        if (autoFocus && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <form
            onSubmit={onSubmit}
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                },
                className,
            ])}
        >
            <FormControl
                {...fields.token}
                withoutErrors
                label={
                    <FormattedMessage
                        defaultMessage="Entrez votre code de vérification"
                        description="Form label"
                    />
                }
                className={styles.formControl}
            >
                <div className={styles.row}>
                    <div
                        className={classNames([
                            styles.field,
                            {
                                [styles.hasStatus]: status !== null,
                            },
                        ])}
                    >
                        <TextField
                            {...fields.token}
                            withoutAutocomplete
                            required
                            ref={inputRef}
                            placeholder={intl.formatMessage({
                                defaultMessage: 'Code',
                                description: 'Field placeholder',
                            })}
                            className={classNames([styles.input, inputClassName])}
                        />
                        <FormStatus status={status} className={styles.status} />
                    </div>
                    <div className={styles.buttons}>
                        <FormButton
                            type="submit"
                            disabled={status === 'loading'}
                            className={styles.button}
                        >
                            <FormattedMessage
                                defaultMessage="Vérifier"
                                description="Button label"
                            />
                        </FormButton>
                        <FormButton
                            onClick={onClickCancel}
                            transparent
                            compact
                            disabled={status === 'loading'}
                            className={styles.button}
                        >
                            <FormattedMessage defaultMessage="Annuler" description="Button label" />
                        </FormButton>
                    </div>
                </div>
            </FormControl>
        </form>
    );
}

VerifyTokenForm.propTypes = propTypes;
VerifyTokenForm.defaultProps = defaultProps;

export default VerifyTokenForm;
