import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function SkiDeRandonnee({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.61 33.41" className={className}>
            <path
                fill={color}
                d="M15.69,4.21c.16,.16,.36,.28,.57,.35,.21,.06,.42,.08,.63,.07,.34,0,.69,0,1.03-.02,.21,0,.41-.04,.6-.11,.22-.12,.39-.3,.5-.52,.35-.72,.45-1.53,.29-2.32-.04-.63-.44-1.19-1.03-1.43l-.44-.19c-1.07-.2-2.15,.32-2.67,1.28-.48,.97-.28,2.14,.5,2.89"
            />
            <path
                fill={color}
                d="M11.18,12.86c.28,.33,.73,.46,1.14,.31,.61-.28,.6-1.12,.5-1.78-.17-1.07-.33-2.14-.5-3.21,.64-.14,1.29-.22,1.95-.26-.34,2.52-1.25,4.92-1.02,7.45,.12,1.37,.41,2.72,.62,4.09,.12,.78,.21,1.57,.27,2.36,.04,.35,.03,.71-.02,1.06-.1,.36-.24,.71-.44,1.03-.35,.65-.7,1.29-1.04,1.94-.27,.43-.44,.91-.51,1.41-.02,.5,.94,1.19,1.43,1.32,.38,.08,.77-.04,1.04-.32,.26-.27,.48-.59,.64-.93,.92-1.5,1.54-3.17,1.82-4.92,0-1.63-.11-3.25-.33-4.86-.01-.28,.03-.62,.29-.72,.14-.04,.3-.03,.44,.03l4.33,1.21c.36,.03,.64,.34,.63,.71l.55,3.26c.02,.52,.24,1.01,.6,1.37,.69,.37,1.55,.11,1.92-.58,.03-.06,.06-.13,.09-.2,.23-.81,.19-1.66-.13-2.44-.36-1.19-.79-2.36-1.3-3.5-.1-.26-.25-.5-.43-.72-.26-.24-.57-.43-.9-.55-1.51-.65-3.05-1.24-4.61-1.78,.15-1.74,.42-3.4,.57-5.14,.9,.63,1.71,1.15,2.65,1.72,.22,.17,.49,.26,.77,.25,.47-.07,.67-.61,.79-1.07,.31-1.22,.62-2.45,.92-3.67,.14-.46,.21-.94,.18-1.42,0-.45-.26-.87-.66-1.07-.55-.24-.96-.21-1.29,.29-.27,.57-.42,1.18-.46,1.81-.09,.67-.28,1.32-.57,1.94-.73-.54-1.48-1.04-2.27-1.5-.58-.38-1.23-.63-1.92-.74-.54-.03-1.09,.03-1.62,.17-1.67,.36-3.33,.79-4.96,1.29-2.46,.75-.29,.16-.28,.41,.05,1.62,.25,3.23,.61,4.81,.08,.42,.26,.81,.52,1.14"
            />
            <path
                fill={color}
                d="M35.11,16.8c-.5-.31-1.16-.16-1.47,.34-.06,.09-.1,.19-.13,.29-.02,.23-.03,.47,0,.7-.12,1.15-.84,2.14-1.89,2.61-.99,.54-2.12,.78-3.14,1.27l-7.06,3.61c-2.9,1.39-9.49,4.11-12.32,5.63-.37,.17-.7,.41-.96,.72-.26,.32-.29,.78-.07,1.13,.39,.51,1.19,.29,1.78,.03,4.63-2.11,12.78-5.88,17.41-7.99,1.15-.52,2.3-1.05,3.45-1.58,.87-.37,1.69-.84,2.46-1.39,1.1-.86,1.91-2.04,2.3-3.38,.29-.67,.16-1.45-.34-1.98"
            />
            <path
                fill={color}
                d="M11.23,13.67l-.28-.27c-.07-.04-.15-.04-.22,0-.35,.27-.68,.56-.98,.89-.51,.52-1,1.04-1.48,1.59-.2,.22-2.32,2.9-2.95,3.59-1.08,1.16-1.96,2.5-3.11,3.59-.62-.23-1.3-.25-1.93-.07-.16,.05-.35,.16-.27,.33,.02,.04,.04,.07,.07,.09,.27,.23,.55,.45,.83,.66-.17,.39-.36,.77-.57,1.13l.86-.7c.21,.21,.44,.41,.68,.59,.11,.08,.23,.16,.4,.04,.07-.05,.12-.12,.15-.19,.11-.27,.16-.57,.13-.86-.02-.1-.02-.21,0-.32,.04-.09,.1-.17,.18-.24,.78-.74,1.6-1.44,2.34-2.22,.73-.76,1.37-1.59,2.03-2.42,1.58-1.97,2.48-3.14,4.09-5.02,.07-.05,.08-.15,.03-.21"
            />
            <path
                fill={color}
                d="M24.94,7.45c.23,.69,.47,1.37,.73,2.05,.11,.27,1.51,3.39,1.82,4.27,.53,1.49,1.31,2.89,1.76,4.41-.49,.44-.82,1.04-.95,1.68-.03,.16-.02,.38,.17,.39,.04,0,.08,0,.12-.02,.33-.14,.65-.28,.97-.43,.27,.33,.51,.67,.73,1.03l-.22-1.08c.29-.09,.56-.2,.83-.33,.12-.06,.25-.13,.22-.33-.01-.08-.05-.16-.1-.22-.19-.23-.43-.4-.71-.51-.1-.03-.2-.08-.28-.15-.06-.08-.11-.17-.13-.27-.3-1.03-.54-2.08-.89-3.1-.34-1-.78-1.95-1.21-2.92-1.02-2.31-1.86-4.22-2.79-6.52-.01-.08-.09-.14-.18-.13l-.37,.12c-.09,.03-.1,.15-.09,.2,.15,.63,.34,1.25,.56,1.85"
            />
        </svg>
    );
}

SkiDeRandonnee.propTypes = propTypes;
SkiDeRandonnee.defaultProps = defaultProps;

export default SkiDeRandonnee;
