/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from './BasicButton';

import styles from '../../styles/buttons/gradient-button.module.css';

const propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf([null, 'yellowPink']),
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    className: null,
    theme: null,
    buttonRef: null,
};

function GradientButton({ className, buttonRef, theme, ...props }) {
    return (
        <BasicButton
            className={classNames([
                styles.container,
                {
                    [styles[theme]]: typeof styles[theme] !== 'undefined',
                    [className]: className !== null,
                },
            ])}
            ref={buttonRef}
            {...props}
        />
    );
}

GradientButton.propTypes = propTypes;
GradientButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <GradientButton {...props} buttonRef={ref} />);
