import { useMemo } from "react";

function usePlaceholderItems(count, props = null) {
    return useMemo(
        () =>
            [...Array(count).keys()].map((index) => ({
                id: `${index}`,
                placeholder: true,
                ...props,
            })),
        [count, props],
    );
}

export default usePlaceholderItems;
