import frLocale from 'date-fns/locale/fr';

const formatDistanceLocale = {
    lessThanXSeconds: {
        one: 'moins d’une seconde',
        other: 'moins de {{count}} secondes',
    },

    xSeconds: {
        one: '1 sec.',
        other: '{{count}} sec.',
    },

    halfAMinute: '30 sec',

    lessThanXMinutes: {
        one: 'moins de 1 min',
        other: 'moins de {{count}} min',
    },

    xMinutes: {
        one: '1 min',
        other: '{{count}} min',
    },

    aboutXHours: {
        one: 'environ 1 h',
        other: 'environ {{count}} h',
    },

    xHours: {
        one: '1 h',
        other: '{{count}} h',
    },

    xDays: {
        one: '1 j',
        other: '{{count}} j',
    },

    aboutXWeeks: {
        one: 'environ 1 semaine',
        other: 'environ {{count}} semaines',
    },

    xWeeks: {
        one: '1 semaine',
        other: '{{count}} semaines',
    },

    aboutXMonths: {
        one: 'environ 1 m',
        other: 'environ {{count}} m',
    },

    xMonths: {
        one: '1 m',
        other: '{{count}} m',
    },

    aboutXYears: {
        one: 'environ 1 an',
        other: 'environ {{count}} ans',
    },

    xYears: {
        one: '1 an',
        other: '{{count}} ans',
    },

    overXYears: {
        one: 'plus d’un an',
        other: 'plus de {{count}} ans',
    },

    almostXYears: {
        one: 'presqu’un an',
        other: 'presque {{count}} ans',
    },
};

const formatDistance = (token, count, options) => {
    let result;
    const form = formatDistanceLocale[token];
    if (typeof form === 'string') {
        result = form;
    } else if (count === 1) {
        result = form.one;
    } else {
        result = form.other.replace('{{count}}', String(count));
    }

    if (options?.addSuffix) {
        if (options.comparison && options.comparison > 0) {
            return `dans ${result}`;
        }
        return `il y a ${result}`;
    }

    return result;
};

export default {
    ...frLocale,
    formatDistance,
};
