/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    loading: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    loading: true,
    className: null,
};

function LoadingIcon({ loading, className }) {
    return <FontAwesomeIcon icon={faCircleNotch} spin={loading} className={className} />;
}

LoadingIcon.propTypes = propTypes;
LoadingIcon.defaultProps = defaultProps;

export default LoadingIcon;
