import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export default function useCollections(query = null, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['collections', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.collections.get(queryParam, null, null, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items, pagination } = data || {};

    return data !== null
        ? {
              items,
              pagination,
              ...queryResult,
          }
        : queryResult;
}
