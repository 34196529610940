/* eslint-disable no-underscore-dangle */
import { createPathToRegexpParser } from '@folklore/routes';
import { QueryClientProvider, hydrate } from '@tanstack/react-query';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Router } from 'wouter';

import Api from './lib/api/Api';
import defaultRichTextElements from './lib/defaultRichTextElements';
import getDevice from './lib/getDevice';

// import installAds from './ads';
import App from './components/App';
import Html from './components/Html';
import { ApiContextProvider } from './contexts/ApiContext';
import { RequestContextProvider } from './contexts/RequestContext';
import { SiteContextProvider } from './contexts/SiteContext';
import createQueryClient from './queryClient';

const queryClient = createQueryClient();

const pathToRegexpParser = createPathToRegexpParser();

window.__hydrateQueryClient = (state) => {
    hydrate(queryClient, state);
};

if (typeof window.__HYDRATE_QUERY_CLIENT !== 'undefined') {
    window.__hydrateQueryClient(window.__HYDRATE_QUERY_CLIENT);
}

// const embeddedStyles = window.__ENTRYPOINTS_CSS || null;
const locale = 'fr';
const embeddedStylesElement = document.getElementById('embedded-styles') || null;
const embeddedStyles = embeddedStylesElement !== null ? embeddedStylesElement.innerText : null;
const embeddedScriptsElement = document.getElementById('embedded-scripts') || null;
const embeddedScripts = embeddedScriptsElement !== null ? embeddedScriptsElement.innerText : null;
const site = window.__SITE || null;
const siteHandle = window.__SITE_HANDLE || 'urbania';
const translations = window.__TRANSLATIONS;
const ssrPath = window.__SSR_PATH;
const assetsManifest = window.__ASSETS_MANIFEST;
const isRedirector = window.__IS_REDIRECTOR || false;
const facebookAppId = window.__FACEBOOK_APP_ID || '1051760225433251';

const api = new Api({
    baseUrl: window.__API_BASE_URL || 'https://v2.urbania.ca/api',
    authBaseUrl: window.__AUTH_BASE_URL || 'https://v2.urbania.ca/auth',
});

const device = window.__DEVICE || getDevice();

// prefetchAuthUser(queryClient, api);

// installAds();

hydrateRoot(
    document,
    <Router parser={pathToRegexpParser} ssrPath={ssrPath}>
        <QueryClientProvider client={queryClient}>
            <IntlProvider
                locale={locale}
                messages={translations}
                defaultRichTextElements={defaultRichTextElements}
            >
                <RequestContextProvider
                    host={window.location.host}
                    protocol={window.location.protocol}
                >
                    <ApiContextProvider client={api}>
                        <SiteContextProvider site={site} handle={siteHandle}>
                            <Html
                                assetsManifest={assetsManifest}
                                embeddedStyles={embeddedStyles}
                                embeddedScripts={embeddedScripts}
                                facebookAppId={facebookAppId}
                            >
                                <App device={device} isRedirector={isRedirector} />
                            </Html>
                        </SiteContextProvider>
                    </ApiContextProvider>
                </RequestContextProvider>
            </IntlProvider>
        </QueryClientProvider>
    </Router>,
    {
        onRecoverableError: (error, { componentStack }) =>
            console.log(error.message, componentStack),
    },
);
