export default function getOptimalImageSize(
    image = null,
    containerWidth = null,
    containerHeight = null,
    { resolution = 1, maxDiff = 400, minDiff = 100 } = {},
) {
    const {
        sizes = null,
        url: defaultUrl = null,
        width: imgWidth,
        height: imgHeight,
    } = image || {};

    if (sizes === null || (containerWidth === null && containerHeight === null)) {
        return image;
    }

    const finalSizes = [
        {
            id: 'original',
            url: defaultUrl,
            width: imgWidth,
            height: imgHeight,
        },
        ...sizes,
    ];

    const finalContainerWidth =
        containerWidth !== null && resolution !== null
            ? containerWidth * resolution
            : containerWidth;
    const finalContainerHeight =
        containerHeight !== null && resolution !== null
            ? containerHeight * resolution
            : containerHeight;

    const { imageSize: finalImageSize } = finalSizes.reduce(
        (acc, imageSize) => {
            const { diff: currentDiff, isLarger: currentIsLarger, size: currentSize } = acc;
            const { id: key, width = null, height = null } = imageSize;
            const diffWidth =
                width !== null && finalContainerWidth !== null ? width - finalContainerWidth : null;
            const diffHeight =
                height !== null && finalContainerHeight !== null
                    ? height - finalContainerHeight
                    : null;
            const isLarger =
                (diffWidth === null || diffWidth >= 0) && (diffHeight === null || diffHeight >= 0);
            // let diff = [diffWidth, diffHeight].reduce(
            //     (total, value) => (value !== null ? (total || 0) + Math.abs(value) : total),
            //     null,
            // );
            let diff =
                diffWidth !== null && diffHeight !== null
                    ? Math.abs(Math.min(diffWidth || 0, diffHeight || 0))
                    : null;
            if (diff === null) {
                diff = Infinity;
            }
            const size = (width || 0) + (height || 0);
            const sizeIsLarger = size > currentSize;
            // console.log(Math.round(containerWidth));
            // if (Math.round(containerWidth) === 252) {
            //     console.log(
            //         {
            //             key,
            //             sizeIsLarger,
            //             diff,
            //             currentDiff,
            //         },
            //         diff < currentDiff && isLarger,
            //         // Difference is lower and current is not larger or diff is greater than max
            //         diff < currentDiff &&
            //             ((!currentIsLarger && sizeIsLarger) || currentDiff > maxDiff),
            //         // Image is larger and diff is smaller than max
            //         diff <= maxDiff && !currentIsLarger && isLarger,
            //         // Image is larger than previous
            //         diff <= maxDiff && !currentIsLarger && !isLarger && sizeIsLarger,
            //     );
            // }

            if (
                // Difference is lower and image is larger
                (diff < currentDiff && isLarger) ||
                // Difference is lower and current is not larger or diff is greater than max
                (diff < currentDiff &&
                    ((!currentIsLarger && sizeIsLarger) || currentDiff > maxDiff)) ||
                // Image is larger and diff is smaller than max
                (diff <= maxDiff &&
                    (currentDiff > minDiff || diff <= minDiff) &&
                    !currentIsLarger &&
                    isLarger) ||
                // Image is larger than previous
                (diff <= maxDiff &&
                    (currentDiff > minDiff || diff <= minDiff) &&
                    !currentIsLarger &&
                    !isLarger &&
                    sizeIsLarger)
            ) {
                return {
                    key,
                    imageSize,
                    diff,
                    isLarger,
                    size,
                };
            }
            return acc;
        },
        { key: null, imageSize: image, diff: Infinity, isLarger: false, size: 0 },
    );

    return finalImageSize;
}
