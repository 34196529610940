import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export default function useLocations(query = null, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['locations', query],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.locations.get({ paginated: false, ...queryParam }, null, null, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    // const { data: items, pagination } = data || {};

    return data !== null
        ? {
              items: data,
              //   pagination,
              ...queryResult,
          }
        : queryResult;
}
