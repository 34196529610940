/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import AuthorImage from '../partials/AuthorImage';
import PlaceholderText from '../partials/PlaceholderText';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/author-horizontal-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    name: PropTypes.string,
    role: PropTypes.string,
    image: AppPropTypes.image,
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    theme: PropTypes.oneOf(['menu', null]),
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    linkQuery: null,
    name: null,
    role: null,
    image: null,
    size: null,
    ratio: null,
    theme: null,
    placeholder: false,
    className: null,
};

function AuthorHorizontalCard({
    slug,
    linkQuery,
    name,
    role,
    image,
    ratio,
    theme,
    size,
    placeholder,
    className,
}) {
    const url = useAuthorUrlGenerator();

    let height = 70;
    if (theme === 'menu') {
        height = 50;
    } else if (size === 'big') {
        height = 115;
    }

    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <HorizontalCard
            href={
                url(
                    {
                        slug,
                    },
                    linkQuery,
                ) || '#'
            }
            thumbnail={
                <AuthorImage
                    name={name}
                    slug={slug}
                    media={image}
                    className={styles.thumbnail}
                    initialsClassName={styles.initials}
                    width={height * thumbnailRatio}
                    height={height}
                    size="small"
                    withPlaceholder={placeholder}
                />
            }
            thumbnailRatio={thumbnailRatio}
            height={height}
            className={classNames([
                styles.container,
                styles[camelCase(theme)],
                styles[camelCase(size)],
                styles[camelCase(ratio)],
                {
                    [className]: className !== null,
                },
            ])}
            labelClassName={styles.label}
        >
            <CardTitle tag="strong" className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : name}
            </CardTitle>
            {role !== null ? (
                <CardSubtitle tag="span" className={styles.role}>
                    {role}
                </CardSubtitle>
            ) : null}
        </HorizontalCard>
    );
}

AuthorHorizontalCard.propTypes = propTypes;
AuthorHorizontalCard.defaultProps = defaultProps;

export default AuthorHorizontalCard;
