import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

// eslint-disable-next-line import/prefer-default-export
export function useContestParticipate() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: ({ id, ...data }) => api.contests.participate(id, data),
        onSuccess: (newUser) => {
            queryClient.setQueryData(['user'], newUser);
        },
    });

    return {
        participate: mutate,
        participateAsync: mutateAsync,
        ...mutationResult,
    };
}
