/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useAuthors from '../../hooks/useAuthors';
import { getComponentFromName } from '@folklore/utils';

import * as CardComponents from '../cards/authors';
import PresentationList from './PresentationList';

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
};

function AuthorsList({ cardComponent, cardType, ...props }) {
    return (
        <PresentationList
            hook={useAuthors}
            cardComponent={
                cardComponent || getComponentFromName(CardComponents, cardType, 'vertical')
            }
            {...props}
        />
    );
}

AuthorsList.propTypes = propTypes;
AuthorsList.defaultProps = defaultProps;

export default AuthorsList;
