/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';
import camelCase from 'lodash/camelCase';

import CollectionImage from '../partials/CollectionImage';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/collection-vertical-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    brands: AppPropTypes.brands,
    image: AppPropTypes.image,
    theme: PropTypes.oneOf([null, 'label-on-top', 'suggestion']),
    size: AppPropTypes.cardSize,
    ratio: PropTypes.string,
    width: PropTypes.number,
    withBorder: PropTypes.bool,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    title: null,
    image: null,
    brands: null,
    ratio: null,
    theme: null,
    size: null,
    width: 240,
    withBorder: false,
    placeholder: false,
    className: null,
};

function CollectionVerticalCard({
    slug,
    title,
    image,
    brands,
    ratio,
    theme,
    size,
    width,
    withBorder,
    placeholder,
    className,
}) {
    const url = useUrlGenerator();
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};

    const labelOnTop = theme === 'label-on-top';
    const thumbnailRatio = getRatio(ratio, 1);

    return (
        <VerticalCard
            href={
                slug !== null
                    ? url('collection', {
                          slug,
                      })
                    : null
            }
            thumbnail={
                <CollectionImage
                    brand={brandHandle}
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    withPlaceholder={placeholder}
                    className={styles.thumbnail}
                    withoutSize
                />
            }
            thumbnailRatio={thumbnailRatio}
            labelOnTop={labelOnTop}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                },
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
            innerClassName={styles.inner}
        >
            <CardTitle className={styles.title}>
                {placeholder ? <PlaceholderText /> : title}
            </CardTitle>
        </VerticalCard>
    );
}

CollectionVerticalCard.propTypes = propTypes;
CollectionVerticalCard.defaultProps = defaultProps;

export default CollectionVerticalCard;
