// eslint-disable-next-line import/prefer-default-export
export function getCollectionBackground(collection) {
    const { header = null, image = null } = collection || {};
    const { background = null } = header || {};
    if (background !== null) {
        return {
            ...background,
            imageFade: 'rgba(0, 0, 0, 0.7)'
        }
    }
    if (image !== null) {
        return {
            image,
            imageFade: 'rgba(0, 0, 0, 0.5)'
        };
    }
    return null;
}
