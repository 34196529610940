import { useInfiniteQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function createDocumentsInfiniteQuery(api, providedQuery, opts) {
    const { withOffset = false } = opts || {};
    const query = {
        count: 12,
        ...providedQuery,
    };
    const { offset = null, ...queryWithoutOffset } = query || {};
    return {
        initialPageParam: withOffset ? 0 : 1,
        queryKey: [
            'documents',
            withOffset ? 'infinite_offset' : 'infinite_page',
            withOffset ? queryWithoutOffset : query,
        ],
        queryFn: ({ pageParam = withOffset ? 0 : 1, queryKey: [, , queryParam], signal }) =>
            withOffset
                ? api.documents.get(
                      {
                          ...queryParam,
                          offset: pageParam,
                      },
                      null,
                      null,
                      { signal },
                  )
                : api.documents.get(queryParam, pageParam, null, { signal }),
    };
}

export function prefetchDocumentsInfinite(queryClient, api, query, opts) {
    return queryClient.prefetchInfiniteQuery(createDocumentsInfiniteQuery(api, query, opts));
}

export default function useDocumentsInfinite(query = null, opts = null) {
    const { withOffset = false, keepPreviousData = true, ...queryOpts } = opts || {};
    const api = useApi();
    const { data = null, ...queryResult } = useInfiniteQuery({
        ...createDocumentsInfiniteQuery(api, query, opts),
        getNextPageParam: ({
            pagination: {
                page: currentPage = 1,
                next_offset: nextOffset = 0,
                last_page: lastPage = -1,
                total,
            },
        }) => {
            if ((withOffset && nextOffset === total) || (!withOffset && lastPage === currentPage)) {
                return undefined;
            }
            return withOffset ? nextOffset : currentPage + 1;
        },
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...queryOpts,
    });

    const { pages = null, pageParams = null } = data || {};

    return {
        pages,
        pageParams,

        ...queryResult,
    };
}
