/* globals __SERVER__:true */
import estimateScreenSize from './estimateScreenSize';
import isTouchScreen from './isTouchScreen';

function getDevice(device) {
    if (__SERVER__) {
        const { device: subDevice } = device || {};
        const { type: deviceType } = subDevice || {};
        // const { width: screenWidth, height: screenHeight } = estimateScreenSize(device);
        return {
            touchScreen:
                deviceType === 'smartphone' || deviceType === 'mobile' || deviceType === 'phone',
            // screenWidth,
            // screenHeight,
        };
    }

    return {
        touchScreen: isTouchScreen(),
        // screenWidth: typeof window !== 'undefined' ? window.screen.width : null,
        // screenHeight: typeof window !== 'undefined' ? window.screen.height : null,
    };
}

export default getDevice;
