import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

// import * as AppPropTypes from '../lib/PropTypes';

export const IdleContext = React.createContext({
    visitorId: null,
});

export function useIdleContext() {
    const context = useContext(IdleContext);
    return context;
}

export function useIsIdle() {
    const { idle = false } = useIdleContext();
    return idle;
}

const propTypes = {
    idle: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    idle: false,
    children: null,
};

export function IdleContextProvider({ children, idle }) {
    const { idle: previousIdle = false } = useIdleContext();
    const value = useMemo(
        () => ({
            idle: idle || previousIdle,
        }),
        [idle, previousIdle],
    );

    return <IdleContext.Provider value={value}>{children}</IdleContext.Provider>;
}

IdleContextProvider.propTypes = propTypes;
IdleContextProvider.defaultProps = defaultProps;
