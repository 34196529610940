/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BrandIcon from '../icons/BrandIcon';
import Image from './Image';

import styles from '../../styles/partials/collection-image.module.css';

const propTypes = {
    brand: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    brandIconClassName: PropTypes.string,
};

const defaultProps = {
    brand: null,
    className: null,
    iconClassName: null,
    brandIconClassName: null,
};

function CollectionImage({ brand, className, iconClassName, brandIconClassName, ...props }) {
    return (
        <Image
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            loading="lazy"
            {...props}
        >
            {brand !== null ? (
                <BrandIcon
                    type={brand}
                    className={classNames([
                        styles.icon,
                        styles.brandIcon,
                        {
                            [iconClassName]: iconClassName !== null,
                            [brandIconClassName]: brandIconClassName !== null,
                        },
                    ])}
                />
            ) : null}
        </Image>
    );
}

CollectionImage.propTypes = propTypes;
CollectionImage.defaultProps = defaultProps;

export default CollectionImage;
