/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import PillButton from '../buttons/PillButton';
import Menu from './Menu';

import styles from '../../styles/menus/pills-menu.module.css';

const propTypes = {
    buttonComponent: PropTypes.elementType,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
};

const defaultProps = {
    buttonComponent: PillButton,
    className: null,
    itemsClassName: null,
    itemClassName: null,
    buttonClassName: null,
};

function PillsMenu({ itemsClassName, itemClassName, buttonClassName, className, ...props }) {
    return (
        <Menu
            compact
            className={classNames([styles.container, className])}
            itemsClassName={classNames([styles.items, itemsClassName])}
            itemClassName={classNames([styles.item, itemClassName])}
            buttonClassName={classNames([styles.button, buttonClassName])}
            {...props}
        />
    );
}

PillsMenu.propTypes = propTypes;
PillsMenu.defaultProps = defaultProps;

export default PillsMenu;
