import { useCallback, useMemo, useState } from 'react';

export default function useUniqueDocumentsBlocks(blocks) {
    const [loadedDocuments, setLoadedDocuments] = useState(
        blocks.map(({ uniqueDocuments = false }) => (uniqueDocuments ? false : null)),
    );
    const onDocumentsLoaded = useCallback(
        (blockIndex, documents) => {
            const newLoadedDocuments = [...loadedDocuments];
            newLoadedDocuments[blockIndex] = documents.map(({ id }) => id);
            setLoadedDocuments(newLoadedDocuments);
        },
        [loadedDocuments],
    );

    const newBlocks = useMemo(
        () =>
            blocks.map(({ uniqueDocuments = false, query, ...block }, blockIndex) =>
                uniqueDocuments
                    ? {
                          ...block,
                          query:
                              blockIndex > 0
                                  ? {
                                        ...query,
                                        exclude_id: loadedDocuments
                                            .slice(0, blockIndex)
                                            .reduce((acc, it) => [...acc, ...(it || [])], []),
                                    }
                                  : query,
                          disabled: !loadedDocuments
                              .slice(0, blockIndex)
                              .reduce((acc, val) => acc && (val === null || val !== false), true),
                          onLoaded: (items) => onDocumentsLoaded(blockIndex, items),
                      }
                    : {
                          ...block,
                          query,
                      },
            ),
        [blocks, loadedDocuments, onDocumentsLoaded],
    );

    return newBlocks;
}
