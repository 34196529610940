/* eslint-disable react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

// import * as AppPropTypes from '../../lib/PropTypes';
import * as CardComponents from '../cards/promotions';
import Modal from './Modal';

import styles from '../../styles/popups/promotion-modal.module.css';

// @TODO: Swtch input type
const propTypes = {
    promotion: PropTypes.shape({
        id: PropTypes.string.isRequired,
        handle: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
    delay: PropTypes.number,
    className: PropTypes.string,
    onClosed: PropTypes.func,
};

const defaultProps = {
    delay: null,
    className: null,
    onClosed: null,
};

function PromotionModal({ promotion, delay, className, onClosed, ...props }) {
    const [opened, setOpened] = useState(delay === null);

    useEffect(() => {
        if (delay === null) {
            return () => {};
        }
        const timeout = setTimeout(() => {
            setOpened(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    const { id, handle, type } = promotion;
    const PromotionComponent = getComponentFromName(CardComponents, type, CardComponents.Default);

    const tracking = useTracking();
    const onPromotionComplete = useCallback(() => {
        setOpened(false);
        tracking.trackPromotion(promotion, 'complete');
    }, [promotion, setOpened]);

    const onPromotionCancel = useCallback(() => {
        setOpened(false);
        tracking.trackPromotion(promotion, 'cancel');
    }, [promotion, setOpened]);

    return PromotionComponent !== null ? (
        <Modal
            id={`promotion-${handle || id}`}
            opened={opened}
            withBackdrop
            withTransition
            withScrollLock
            className={classNames([styles.container, className])}
            onClosed={onClosed}
            requestClose={onRequestClose}
        >
            <PromotionComponent
                {...promotion}
                {...props}
                className={styles.card}
                onComplete={onPromotionComplete}
                onCancel={onPromotionCancel}
            />
        </Modal>
    ) : null;
}

PromotionModal.propTypes = propTypes;
PromotionModal.defaultProps = defaultProps;

export default PromotionModal;
