/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useDocuments from '../../hooks/useDocuments';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import * as AppPropTypes from '../../lib/PropTypes';

import { useTouchScreen } from '../../contexts/DeviceContext';
import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';
import * as CardComponents from '../cards/documents';
import GridList from '../lists/GridList';
import RowList from '../lists/RowList';

import styles from '../../styles/headers/featured-over-header.module.css';

const propTypes = {
    count: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    featuredCardTheme: PropTypes.string,
    cardType: PropTypes.string,
    cardTheme: PropTypes.string,
    cardWidth: PropTypes.number,
    cardSize: PropTypes.string,
    cardRatio: PropTypes.string,
    cardWith: PropTypes.arrayOf(PropTypes.string),
    cardWithout: PropTypes.arrayOf(PropTypes.string),
    withShadow: PropTypes.bool,
    className: PropTypes.string,
    listClassName: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    count: 14,
    query: null,
    featuredCardTheme: 'over-bottom-left',
    cardType: 'vertical',
    cardTheme: 'box',
    cardWidth: 200,
    cardSize: 'medium',
    cardRatio: 'horizontal',
    cardWith: null,
    cardWithout: null,
    withShadow: false,
    className: null,
    listClassName: null,
    cardClassName: null,
};

function FeaturedOverHeader({
    count,
    query,
    featuredCardTheme,
    cardType,
    cardTheme,
    cardWidth,
    cardSize,
    cardRatio,
    cardWith,
    cardWithout,
    withShadow,
    className,
    listClassName,
    cardClassName,
    ...props
}) {
    const isTouchScreen = useTouchScreen();
    const { items = null } = useDocuments({
        ...query,
        count: count + 1, // featured + overCount
    });
    const [featuredItem = null, ...otherItems] = items || [];
    const placeholderItems = usePlaceholderItems(count - 1);
    const ListComponent = isTouchScreen ? RowList : GridList;
    const CardComponent = getComponentFromName(CardComponents, cardType, CardComponents.Vertical);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withShadow]: withShadow === true,
                    [className]: className !== null,
                },
            ])}
        >
            <DocumentFeaturedCard
                {...featuredItem}
                placeholder={featuredItem === null}
                theme={featuredCardTheme}
                placholder={featuredItem === null}
                imageLoading={null}
                withoutBorderRadius
                withBreakpoints
                className={classNames([styles.featuredItem, cardClassName])}
                {...props}
            />

            <ListComponent
                items={items !== null ? otherItems : placeholderItems}
                cardWidth={cardWidth}
                // rows={1}
                minColumns={2}
                cardComponent={CardComponent}
                cardTheme={cardTheme}
                cardRatio={cardRatio}
                cardSize={cardSize}
                cardWith={cardWith}
                cardWithout={cardWithout}
                className={classNames([
                    styles.list,
                    {
                        [styles.grid]: !isTouchScreen,
                    },
                    listClassName,
                ])}
                itemsClassName={styles.items}
                itemClassName={styles.item}
                cardClassName={styles.card}
            />
        </div>
    );
}

FeaturedOverHeader.propTypes = propTypes;
FeaturedOverHeader.defaultProps = defaultProps;

export default FeaturedOverHeader;
