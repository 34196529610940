/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import PlaceholderText from '../partials/PlaceholderText';

const propTypes = {
    lines: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    lines: 10,
    className: null,
};

function PlaceholderBlock({ lines, className }) {
    return (
        <div className={className}>
            <p>
                <PlaceholderText lines={lines} minCharacters={20} maxCharacters={40} />
            </p>
        </div>
    );
}

PlaceholderBlock.propTypes = propTypes;
PlaceholderBlock.defaultProps = defaultProps;

export default PlaceholderBlock;
