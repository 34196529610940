import { useCallback } from 'react';

import useScrollTrigger from '../hooks/useScrollTrigger';
import { useTracking } from '../hooks/useTracking';


const triggers = [0.15, 0.5, 0.9];

export default function useScrollTracking(opts) {
    const tracking = useTracking();
    const onScrollTrigger = useCallback(
        (trigger) => {
            const scrollPercent = Math.round(trigger * 100);
            tracking.trackEvent('Navigation', 'scroll', scrollPercent, null, {
                scrollPercent,
            });
        },
        [tracking],
    );
    return useScrollTrigger({
        triggers,
        ...opts,
        onTrigger: onScrollTrigger,
    });
}
