import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import PillButton from '../buttons/PillButton';
import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/partials/home-section-title.module.css';

const propTypes = {
    url: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
};

const defaultProps = {
    url: null,
    children: null,
    className: null,
    titleClassName: null,
    buttonClassName: null,
};

function HomeSectionTitle({ url, children, className, titleClassName, buttonClassName }) {
    return (
        <div className={classNames([styles.container, className])}>
            <SectionTitle colored className={classNames([styles.title, titleClassName])}>
                {url !== null ? <Link href={`${url}?_ref=home_section_title`}>{children}</Link> : children}
            </SectionTitle>
            {url !== null ? (
                <PillButton
                    compact
                    branded
                    bordered
                    href={`${url}?_ref=home_view_all`}
                    className={classNames([styles.button, buttonClassName])}
                >
                    <FormattedMessage defaultMessage="Voir tout" description="Button label" />
                </PillButton>
            ) : null}
        </div>
    );
}

HomeSectionTitle.propTypes = propTypes;
HomeSectionTitle.defaultProps = defaultProps;

export default HomeSectionTitle;
