import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function ZoomInIcon({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.09 19.09" className={className}>
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.58,8.54c0,4.44-3.6,8.04-8.04,8.04S.5,12.98,.5,8.54,4.1,.5,8.54,.5s8.04,3.6,8.04,8.04h0Z"
            />
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.59,18.59l-4.37-4.37"
            />
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.54,5.52v6.03"
            />
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.52,8.54h6.03"
            />
        </svg>
    );
}

ZoomInIcon.propTypes = propTypes;
ZoomInIcon.defaultProps = defaultProps;

export default ZoomInIcon;
