/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import SectionTitle from '../typography/SectionTitle';

import styles from '../../styles/headers/index-default-header.module.css';

const propTypes = {
    title: PropTypes.string,
    brand: AppPropTypes.brand,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    brand: null,
    placeholder: false,
    className: null,
};

function IndexDefaultHeader({ title, brand, placeholder, className }) {
    const { handle: brandHandle = null } = brand || {};
    return (
        <div className={classNames([styles.container, className])} data-brand={brandHandle}>
            <div className={styles.bar}>
                <SectionTitle colored className={styles.title} placeholder={placeholder}>
                    {title}
                </SectionTitle>
            </div>
        </div>
    );
}

IndexDefaultHeader.propTypes = propTypes;
IndexDefaultHeader.defaultProps = defaultProps;

export default IndexDefaultHeader;
