import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

function VeloDeMontagne({ color, className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.72 34.79" className={className}>
            <path
                fill={color}
                d="M27.67,4.87c.18,.2,.37,.38,.57,.56,.21,.21,.48,.34,.78,.37,.45,.07,.91,.07,1.36-.01,.45-.08,.87-.3,1.2-.61,.43-.48,.69-1.08,.76-1.72,.08-.34,.08-.7,0-1.04-.06-.18-.13-.35-.22-.52-.13-.28-.3-.55-.49-.8-.19-.25-.46-.43-.76-.52l-.71-.12c-.48-.05-.97,0-1.43,.16-.46,.16-.86,.46-1.14,.86-.24,.41-.39,.86-.44,1.33-.11,.5-.06,1.02,.12,1.49,.11,.21,.25,.41,.41,.58"
            />
            <path
                fill={color}
                d="M9.5,21.44c2.01,.01,3.94-.77,5.38-2.18,.83-.8,1.44-1.81,1.77-2.91,.23-.84,.3-1.71,.23-2.58-.12-1.97-.93-3.83-2.29-5.25-1.38-1.42-3.34-2.13-5.31-1.94l-.61,.04c-1.78,.08-3.44,.91-4.56,2.3-1.14,1.37-1.77,3.11-1.77,4.89-.04,1.92,.64,3.79,1.89,5.25,1.35,1.49,3.26,2.35,5.27,2.39M5.03,11.24c.5-1.11,1.42-1.98,2.55-2.42,.52-.14,1.05-.2,1.58-.18,1.42-.11,2.83,.32,3.94,1.21l.41,.4c1.96,2.06,2.03,5.27,.16,7.42-1.17,1.31-2.86,2.02-4.61,1.94-2.72,0-4.92-2.21-4.91-4.93,0-.06,0-.12,0-.18,.03-1.14,.33-2.26,.87-3.27"
            />
            <path
                fill={color}
                d="M33.62,17.47c-.93-1.21-2.3-2.01-3.81-2.24l-.64-.07c-1.47-.27-2.99,.04-4.23,.86-1.22,.8-2.2,1.92-2.84,3.23-.27,.51-.48,1.06-.6,1.63-.12,.72-.12,1.45-.02,2.18,.21,1.95,.79,4.02,2.33,5.23,.38,.28,.78,.53,1.2,.73,.49,.27,1,.48,1.53,.64,.78,.19,1.58,.24,2.37,.17,.87-.04,1.74-.2,2.56-.48,2.24-.9,3.84-2.93,4.19-5.32,.31-2.38-.44-4.78-2.03-6.57m.02,6.73c-.31,1.18-1.04,2.22-2.05,2.91-1.11,.72-2.45,1-3.75,.77-1.3-.23-2.48-.88-3.39-1.83-.34-.34-.63-.74-.84-1.18-.24-.54-.37-1.13-.39-1.72-.13-1.37,.22-2.75,1.01-3.88,.49-.59,1.06-1.11,1.7-1.53,.24-.18,.51-.34,.78-.47,.71-.23,1.46-.29,2.2-.17l.63,.12c1.81,.37,3.29,1.66,3.9,3.41,.43,1.14,.5,2.39,.19,3.57"
            />
            <path
                fill={color}
                d="M14.32,5.05l4,5.49,.39,.65c-.4,2.42-.75,4.84-1.06,7.27-.12,.5-.06,1.03,.17,1.49,.26,.35,.68,.56,1.12,.56,.27,.02,.54-.05,.77-.18,.54-.45,.85-1.11,.84-1.82l.97-7.42c.05-.26,.06-.52,.04-.79-.09-.49-.3-.95-.6-1.35-1.01-1.37-1.83-2.88-2.42-4.48,2.08,.43,4.11,1.07,6.05,1.92l.27,.38c.16,1.5,.73,2.93,1.65,4.12,.92,1.18,1.96,2.27,3.1,3.25,.22,.27,.55,.43,.9,.45,.46-.11,.74-.58,.62-1.03-.13-.44-.37-.83-.71-1.14l-1.11-1.21c-.8-.78-1.45-1.7-1.92-2.71-.29-1-.46-2.04-.51-3.09-.05-1.05-.47-2.06-1.18-2.83-.69-.53-1.5-.89-2.36-1.05C21.15,.84,18.91,.34,16.63,.04c-.61-.08-1.23-.05-1.83,.08-.61,.13-1.13,.5-1.47,1.02-.33,.64-.37,1.4-.1,2.07,.27,.66,.63,1.28,1.08,1.83"
            />
            <path
                fill={color}
                d="M31.82,33.43c-.11-.24-.32-.41-.58-.47l-7.11-1.83c-.12-.03-.24-.09-.33-.17l-3.88-3.13c-.13-.1-.28-.17-.44-.19l-3.46-.43c-.13-.02-.26-.06-.38-.14l-6.81-4.51c-.11-.07-.24-.12-.37-.14l-4.63-.6c-.15-.02-.29-.08-.42-.17l-1.99-1.49c-.39-.29-.94-.21-1.23,.18-.06,.08-.11,.18-.14,.28v.04c-.11,.34,0,.71,.28,.93l2.47,1.99c.13,.1,.28,.17,.44,.19l4.42,.57c.13,.02,.26,.06,.37,.14l6.87,4.53c.12,.08,.25,.12,.38,.14l3.37,.39c.17,.02,.32,.09,.45,.19l3.84,3.11c.1,.08,.23,.14,.36,.17l7.45,1.73c.06,.01,.12,.02,.18,.02,.2,0,.69,.02,.75,0,.07-.06,.13-.14,.18-.22,.18-.26,.21-.6,.07-.89l-.12-.25Z"
            />
        </svg>
    );
}

VeloDeMontagne.propTypes = propTypes;
VeloDeMontagne.defaultProps = defaultProps;

export default VeloDeMontagne;
