/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import arrowImage from '../../../assets/img/icons/google.svg';

const propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    alt: 'Google',
    className: null,
};

function GoogleIcon({ alt, className }) {
    return <img src={arrowImage} className={className} alt={alt} />;
}

GoogleIcon.propTypes = propTypes;
GoogleIcon.defaultProps = defaultProps;

export default GoogleIcon;
