import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

// import * as AppPropTypes from '../lib/PropTypes';

export const RequestContext = React.createContext({
    host: typeof window !== 'undefined' ? window.location.host : null,
    protocol: typeof window !== 'undefined' ? window.location.protocol : null,
});

export function useRequestContext() {
    const context = useContext(RequestContext);
    return context;
}

export function useRequestHost() {
    const { host } = useRequestContext();
    return host;
}

export function useRequestProtocol() {
    const { protocol } = useRequestContext();
    return protocol;
}

export function useRequestBase() {
    const { protocol, host } = useRequestContext();
    return `${(protocol || 'https:').replace(/:$/, '')}://${host || 'urbania.ca'}`;
}

const propTypes = {
    host: PropTypes.string,
    protocol: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    host: null,
    protocol: null,
    children: null,
};

export function RequestContextProvider({ host, protocol, children }) {
    const value = useMemo(
        () => ({
            host,
            protocol,
        }),
        [host, protocol],
    );

    return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
}

RequestContextProvider.propTypes = propTypes;
RequestContextProvider.defaultProps = defaultProps;
