/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getOptimalImageSize from '../../lib/getOptimalImageSize';

const resolution = typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1;

const propTypes = {
    media: AppPropTypes.image,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.string,
    loading: PropTypes.oneOf(['lazy', null]),
    className: PropTypes.string,
    imgRef: AppPropTypes.ref,
};

const defaultProps = {
    media: null,
    alt: null,
    width: null,
    height: null,
    size: null,
    loading: null,
    className: null,
    imgRef: null,
};

function Picture({ media, width, height, size, alt, loading, className, imgRef, ...props }) {
    const { sizes = null } = media;
    const {
        id: sizeId,
        url: imageUrl,
        width: imageWidth,
        height: imageHeight,
    } = useMemo(() => {
        const imageSize =
            size !== null
                ? (sizes || []).find(
                      ({ id }) => id === size || (id === 'large' && size === 'responsive'),
                  ) || null
                : null;
        if (imageSize !== null) {
            return imageSize;
        }
        return getOptimalImageSize(media, width, height, {
            // resolution: 1.5,
            resolution,
        });
    }, [media, sizes, size, width, height]);

    const sources =
        sizeId !== null && size !== 'responsive'
            ? (sizes || []).filter(({ id }) => id === sizeId)
            : null;

    return (
        <picture
            className={className}
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {sources !== null
                ? sources
                      .sort(({ width: widthA }, { width: widthB }) => (widthA > widthB ? 1 : -1))
                      .map(({ id, mime, url: sizeUrl, width: sizeWidth }) => (
                          <source
                              key={`${id}-${mime}`}
                              srcSet={`${sizeUrl} ${sizeWidth}w`}
                              type={mime}
                          />
                      ))
                : null}
            {size === 'responsive'
                ? sizes
                      .filter(({ id }) => id !== 'original')
                      .sort(({ width: widthA }, { width: widthB }) => (widthA > widthB ? 1 : -1))
                      .map(({ id, mime, url: sizeUrl, width: sizeWidth }) => (
                          <source
                              key={`${id}-${mime}`}
                              media={
                                  id !== 'large'
                                      ? `(max-width: ${Math.round(sizeWidth / 1.5)}px)`
                                      : null
                              }
                              srcSet={`${sizeUrl}`}
                              type={mime}
                          />
                      ))
                : null}
            <img
                {...props}
                src={imageUrl}
                alt={alt}
                width={imageWidth}
                height={imageHeight}
                loading={loading}
                className={className}
                ref={imgRef}
            />
        </picture>
    );
}

Picture.propTypes = propTypes;
Picture.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Picture {...props} imgRef={ref} />);
