import { useTracking } from '@folklore/tracking';
import queryString from 'query-string';
import { useEffect, useCallback } from 'react';
import { useLocation, useSearch } from 'wouter';

import { useRequestBase } from '../contexts/RequestContext';
import useOnClickLink from './useOnClickLink';

export { useTracking };

const useTrackingWithPage = (page, { isDisabled = true } = {}) => {
    const tracking = useTracking();
    useEffect(() => {
        if (page !== null && !isDisabled) {
            tracking.trackPageViewFromPage(page);
        }
    }, [tracking, page, isDisabled]);
};

export const useImagePixels = (pixels) => {
    const tracking = useTracking();
    const baseUri = useRequestBase();
    const searchQuery = useSearch();
    const [location] = useLocation();

    useEffect(() => {
        if (pixels !== null) {
            const urls = (pixels || []).map(({ url = null, params = null }) => {
                const urlParams = queryString.parse(searchQuery);
                const { fbclid = null } = urlParams || {};
                const paramsArray = params || [];
                const newQuery = {};

                if (paramsArray.indexOf('sourceUrl') !== -1) {
                    newQuery.sourceUrl = `${baseUri}${location}`;
                }

                if (paramsArray.indexOf('fbclid') !== -1 && fbclid !== null) {
                    newQuery.fbclid = fbclid;
                }

                // Try to get fbp from cookies?
                // if (paramsArray.indexOf('fbp') !== -1 && fbp !== null) {
                //     newQuery.fbc = fbp;
                // }

                return `${url}?${queryString.stringify(newQuery)}`;
            });
            urls.forEach((url) => {
                tracking.loadImagePixel(url);
            });
        }
    }, [
        tracking,
        baseUri,
        searchQuery,
        location,
        (pixels || [])
            .map((p) => p.url)
            .sort()
            .join(','),
    ]);
};

export const useTrackEvent = () => {
    const tracking = useTracking();
    const trackEvent = useCallback(
        (category, action, label, value, data) => {
            if (category !== null && action !== null) {
                tracking.trackEvent(category, action, label, value, data);
            }
        },
        [tracking],
    );
    return trackEvent;
};

export const useTrackExperiment = (id = null) => {
    const tracking = useTracking();
    const trackExperiment = useCallback(
        (action, variant, data) => {
            if (id !== null) {
                tracking.trackEvent('Experiment', action, `${id}:${variant}`, null, {
                    [`experiments.${id}.variant`]: action !== 'mark_done' ? variant : null,
                    experiment: id,
                    experimentVariant: variant,
                    ...data,
                });
            }
        },
        [tracking, id],
    );
    return trackExperiment;
};

export const useTrackVideo = () => {
    const tracking = useTracking();
    const trackEvent = useCallback(
        (action, video) => {
            tracking.trackVideo(action, video);
        },
        [tracking],
    );
    return trackEvent;
};

export const useTrackAd = () => {
    const tracking = useTracking();
    const trackEvent = useCallback(
        (action, slot, renderEvent) => {
            tracking.trackAd(action, slot, renderEvent);
        },
        [tracking],
    );
    return trackEvent;
};

export const useTrackLink = () => {
    const tracking = useTracking();
    const trackEvent = useCallback(
        (link, data) => {
            tracking.trackLink(link, data);
        },
        [tracking],
    );
    return trackEvent;
};

export const useTrackOnClickLink = (action = 'click', label = null) => {
    const tracking = useTracking();
    const onClick = useCallback(
        ({ url }) => {
            tracking.trackEvent('Navigation', action, label || url);
        },
        [tracking, action, label],
    );
    return useOnClickLink(onClick);
};

export const useTrackOnClickCard = (action = 'click') => {
    const tracking = useTracking();
    const onClick = useCallback(
        ({ url }, { title } = {}) => {
            tracking.trackEvent('Navigation', action, title || url);
        },
        [tracking, action],
    );
    return useOnClickLink(onClick);
};

export default useTrackingWithPage;
