import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import ControlErrors from '../typography/ControlErrors';
import ControlLabel from '../typography/ControlLabel';

import styles from '../../styles/forms/form-control.module.css';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    isRequired: PropTypes.bool, // postpend asterisk
    errors: AppPropTypes.formErrors,
    withoutErrors: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
};

const defaultProps = {
    name: null,
    label: null,
    children: null,
    isRequired: false,
    errors: null,
    withoutErrors: false,
    className: null,
    labelClassName: null,
};

function FormControl({
    name,
    label,
    children,
    isRequired,
    errors,
    withoutErrors,
    className,
    labelClassName,
}) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isRequired]: isRequired,
                    [className]: className !== null,
                },
            ])}
        >
            {label !== null ? (
                <ControlLabel htmlFor={name} className={classNames(styles.label, labelClassName)}>
                    {label}
                </ControlLabel>
            ) : null}
            {children}
            {errors !== null && !withoutErrors ? (
                <ControlErrors className={styles.errors}>
                    <ul>
                        {(errors || []).map((error) => (
                            <li key={`error-${error}`}>{error}</li>
                        ))}
                    </ul>
                </ControlErrors>
            ) : null}
        </div>
    );
}

FormControl.propTypes = propTypes;
FormControl.defaultProps = defaultProps;

export default FormControl;
