/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import * as AppPropTypes from '../../lib/PropTypes';
import PlaceholderText from '../partials/PlaceholderText';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/region-horizontal-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.bool,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    label: null,
    placeholder: false,
    withBorder: false,
    className: null,
};

function RegionHorizontalCard({ slug, label, placeholder, withBorder, className }) {
    const url = useUrlGenerator();

    return (
        <HorizontalCard
            href={
                slug !== null
                    ? url('map.region', {
                          region: slug,
                      })
                    : null
            }
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                },
                className,
            ])}
            innerClassName={styles.inner}
            thumbnailClassName={styles.thumbnailContainer}
        >
            <CardTitle tag="span" className={styles.title}>
                {placeholder ? <PlaceholderText lines={1} /> : label}
            </CardTitle>
        </HorizontalCard>
    );
}

RegionHorizontalCard.propTypes = propTypes;
RegionHorizontalCard.defaultProps = defaultProps;

export default RegionHorizontalCard;
