/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import PlayIcon from '../icons/PlayIcon';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/control-button.module.css';
import PauseIcon from '../icons/PauseIcon';

const propTypes = {
    playing: PropTypes.bool,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    playing: false,
    className: null,
    buttonRef: null,
};

function PlayButton({ playing, className, buttonRef, ...props }) {
    return (
        <BasicButton
            ref={buttonRef}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            {playing ? <PauseIcon className={styles.icon} /> : <PlayIcon className={styles.icon} />}
        </BasicButton>
    );
}

PlayButton.propTypes = propTypes;
PlayButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <PlayButton {...props} buttonRef={ref} />);
