/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { getComponentFromName } from '@folklore/utils';

import * as ListComponents from '../lists/index';

import styles from '../../styles/blocks/items-block.module.css';

const propTypes = {
    title: PropTypes.node,
    itemsType: PropTypes.string.isRequired,
    cardType: PropTypes.string,
    className: PropTypes.string,
    titleClassName: PropTypes.string, // Add titleClassName prop
};

const defaultProps = {
    title: null,
    cardType: null,
    className: null,
    titleClassName: null, // Add default value for titleClassName prop
};

function ItemsBlock({ title, itemsType, cardType, className, titleClassName, ...props }) {
    const ListComponent = getComponentFromName(ListComponents, itemsType);
    return ListComponent !== null ? (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withShadow]: cardType === 'over',
                    [className]: className !== null,
                },
            ])}
        >
            {title !== null ? (
                <div className={classNames([styles.title, titleClassName])}>{title}</div>
            ) : null}
            <ListComponent
                cardType={cardType}
                itemsClassName={styles.items}
                itemClassName={styles.item}
                cardClassName={styles.card}
                {...props}
            />
        </div>
    ) : null;
}

ItemsBlock.propTypes = propTypes;
ItemsBlock.defaultProps = defaultProps;

export default ItemsBlock;
