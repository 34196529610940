import { QueryClient } from '@tanstack/react-query';

export default (opts) =>
    new QueryClient({
        defaultOptions: {
            queries: {
                suspense: false,
                staleTime: 60 * 1000,
                ...opts
            },
        },
    });
