/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useRegions from '../../hooks/useRegions';
import { getComponentFromName } from '@folklore/utils';

import RegionHorizontalCard from '../cards/RegionHorizontalCard';
import PresentationList from './PresentationList';

import styles from '../../styles/lists/regions-list.module.css';
import { useSite } from '../../contexts/SiteContext';

const CardComponents = {
    Horizontal: RegionHorizontalCard,
};

const propTypes = {
    cardComponent: PropTypes.elementType,
    cardType: PropTypes.string,
    className: PropTypes.string,
    itemsClassName: PropTypes.string,
    cardClassName: PropTypes.string,
};

const defaultProps = {
    cardComponent: null,
    cardType: null,
    className: null,
    itemsClassName: null,
    cardClassName: null,
};

function RegionsList({
    cardType,
    cardComponent,
    className,
    itemsClassName,
    cardClassName,
    ...props
}) {
    const { regions } = useSite();
    return (
        <PresentationList
            // hook={useRegions}
            items={regions}
            withoutLoader
            paginated={false}
            {...props}
            cardComponent={
                cardComponent ||
                getComponentFromName(CardComponents, cardType, RegionHorizontalCard)
            }
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            itemsClassName={classNames([
                styles.items,
                { [itemsClassName]: itemsClassName !== null },
            ])}
            cardClassName={classNames([
                styles.card,
                {
                    [cardClassName]: cardClassName !== null,
                },
            ])}
        />
    );
}

RegionsList.propTypes = propTypes;
RegionsList.defaultProps = defaultProps;

export default RegionsList;
